// Generated by purs version 0.12.0
"use strict";
var Control_Category = require("../Control.Category/index.js");
var Control_Monad_Cont_Trans = require("../Control.Monad.Cont.Trans/index.js");
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Control_Monad_List_Trans = require("../Control.Monad.List.Trans/index.js");
var Control_Monad_Maybe_Trans = require("../Control.Monad.Maybe.Trans/index.js");
var Control_Monad_RWS_Trans = require("../Control.Monad.RWS.Trans/index.js");
var Control_Monad_Reader_Trans = require("../Control.Monad.Reader.Trans/index.js");
var Control_Monad_State_Trans = require("../Control.Monad.State.Trans/index.js");
var Control_Monad_Trans_Class = require("../Control.Monad.Trans.Class/index.js");
var Control_Monad_Writer_Trans = require("../Control.Monad.Writer.Trans/index.js");
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Prelude = require("../Prelude/index.js");
var MonadAff = function (MonadEffect0, liftAff) {
    this.MonadEffect0 = MonadEffect0;
    this.liftAff = liftAff;
};
var monadAffAff = new MonadAff(function () {
    return Effect_Aff.monadEffectAff;
}, Control_Category.identity(Control_Category.categoryFn));
var liftAff = function (dict) {
    return dict.liftAff;
};
var monadAffContT = function (dictMonadAff) {
    return new MonadAff(function () {
        return Control_Monad_Cont_Trans.monadEffectContT(dictMonadAff.MonadEffect0());
    }, function ($10) {
        return Control_Monad_Trans_Class.lift(Control_Monad_Cont_Trans.monadTransContT)((dictMonadAff.MonadEffect0()).Monad0())(liftAff(dictMonadAff)($10));
    });
};
var monadAffExceptT = function (dictMonadAff) {
    return new MonadAff(function () {
        return Control_Monad_Except_Trans.monadEffectExceptT(dictMonadAff.MonadEffect0());
    }, function ($11) {
        return Control_Monad_Trans_Class.lift(Control_Monad_Except_Trans.monadTransExceptT)((dictMonadAff.MonadEffect0()).Monad0())(liftAff(dictMonadAff)($11));
    });
};
var monadAffListT = function (dictMonadAff) {
    return new MonadAff(function () {
        return Control_Monad_List_Trans.monadEffectListT(dictMonadAff.MonadEffect0());
    }, function ($12) {
        return Control_Monad_Trans_Class.lift(Control_Monad_List_Trans.monadTransListT)((dictMonadAff.MonadEffect0()).Monad0())(liftAff(dictMonadAff)($12));
    });
};
var monadAffMaybe = function (dictMonadAff) {
    return new MonadAff(function () {
        return Control_Monad_Maybe_Trans.monadEffectMaybe(dictMonadAff.MonadEffect0());
    }, function ($13) {
        return Control_Monad_Trans_Class.lift(Control_Monad_Maybe_Trans.monadTransMaybeT)((dictMonadAff.MonadEffect0()).Monad0())(liftAff(dictMonadAff)($13));
    });
};
var monadAffRWS = function (dictMonadAff) {
    return function (dictMonoid) {
        return new MonadAff(function () {
            return Control_Monad_RWS_Trans.monadEffectRWS(dictMonoid)(dictMonadAff.MonadEffect0());
        }, function ($14) {
            return Control_Monad_Trans_Class.lift(Control_Monad_RWS_Trans.monadTransRWST(dictMonoid))((dictMonadAff.MonadEffect0()).Monad0())(liftAff(dictMonadAff)($14));
        });
    };
};
var monadAffReader = function (dictMonadAff) {
    return new MonadAff(function () {
        return Control_Monad_Reader_Trans.monadEffectReader(dictMonadAff.MonadEffect0());
    }, function ($15) {
        return Control_Monad_Trans_Class.lift(Control_Monad_Reader_Trans.monadTransReaderT)((dictMonadAff.MonadEffect0()).Monad0())(liftAff(dictMonadAff)($15));
    });
};
var monadAffState = function (dictMonadAff) {
    return new MonadAff(function () {
        return Control_Monad_State_Trans.monadEffectState(dictMonadAff.MonadEffect0());
    }, function ($16) {
        return Control_Monad_Trans_Class.lift(Control_Monad_State_Trans.monadTransStateT)((dictMonadAff.MonadEffect0()).Monad0())(liftAff(dictMonadAff)($16));
    });
};
var monadAffWriter = function (dictMonadAff) {
    return function (dictMonoid) {
        return new MonadAff(function () {
            return Control_Monad_Writer_Trans.monadEffectWriter(dictMonoid)(dictMonadAff.MonadEffect0());
        }, function ($17) {
            return Control_Monad_Trans_Class.lift(Control_Monad_Writer_Trans.monadTransWriterT(dictMonoid))((dictMonadAff.MonadEffect0()).Monad0())(liftAff(dictMonadAff)($17));
        });
    };
};
module.exports = {
    liftAff: liftAff,
    MonadAff: MonadAff,
    monadAffAff: monadAffAff,
    monadAffContT: monadAffContT,
    monadAffExceptT: monadAffExceptT,
    monadAffListT: monadAffListT,
    monadAffMaybe: monadAffMaybe,
    monadAffReader: monadAffReader,
    monadAffRWS: monadAffRWS,
    monadAffState: monadAffState,
    monadAffWriter: monadAffWriter
};
