// Generated by purs version 0.12.0
"use strict";
var $foreign = require("./foreign.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Show = require("../Data.Show/index.js");
var Effect = require("../Effect/index.js");
var Prelude = require("../Prelude/index.js");
var UUID = function (x) {
    return x;
};
var showUUID = new Data_Show.Show(function (v) {
    return v;
});
var parseUUID = function (str) {
    var v = $foreign.validateV4UUID(str);
    if (v) {
        return Data_Maybe.Just.create(str);
    };
    return Data_Maybe.Nothing.value;
};
var genUUID = function __do() {
    var $12 = $foreign.getUUIDImpl();
    return UUID($12);
};
var eqUUID = new Data_Eq.Eq(function (v) {
    return function (v1) {
        return v === v1;
    };
});
var ordUUID = new Data_Ord.Ord(function () {
    return eqUUID;
}, function (v) {
    return function (v1) {
        return Data_Ord.compare(Data_Ord.ordString)(v)(v1);
    };
});
module.exports = {
    genUUID: genUUID,
    parseUUID: parseUUID,
    showUUID: showUUID,
    eqUUID: eqUUID,
    ordUUID: ordUUID
};
