// Generated by purs version 0.12.0
"use strict";
var Data_Function = require("../Data.Function/index.js");
var Data_Lens_Lens = require("../Data.Lens.Lens/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Prelude = require("../Prelude/index.js");
var united = function (dictStrong) {
    return Data_Lens_Lens.lens(Data_Function["const"](Data_Unit.unit))(Data_Function["const"])(dictStrong);
};
module.exports = {
    united: united
};
