// Generated by purs version 0.12.0
"use strict";
var Data_Lens_Iso = require("../Data.Lens.Iso/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var _Newtype = function (dictNewtype) {
    return function (dictNewtype1) {
        return function (dictProfunctor) {
            return Data_Lens_Iso.iso(Data_Newtype.unwrap(dictNewtype))(Data_Newtype.wrap(dictNewtype1))(dictProfunctor);
        };
    };
};
module.exports = {
    _Newtype: _Newtype
};
