// Generated by purs version 0.12.0
"use strict";
var Control_Alternative = require("../Control.Alternative/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Plus = require("../Control.Plus/index.js");
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Lens_Indexed = require("../Data.Lens.Indexed/index.js");
var Data_Lens_Internal_Indexed = require("../Data.Lens.Internal.Indexed/index.js");
var Data_Lens_Internal_Wander = require("../Data.Lens.Internal.Wander/index.js");
var Data_Lens_Types = require("../Data.Lens.Types/index.js");
var Data_Monoid_Disj = require("../Data.Monoid.Disj/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Profunctor_Star = require("../Data.Profunctor.Star/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Prelude = require("../Prelude/index.js");
var traversed = function (dictTraversable) {
    return function (dictWander) {
        return Data_Lens_Internal_Wander.wander(dictWander)(function (dictApplicative) {
            return Data_Traversable.traverse(dictTraversable)(dictApplicative);
        });
    };
};
var traverseOf = function (dictApplicative) {
    return Data_Newtype.under(Data_Profunctor_Star.newtypeStar)(Data_Profunctor_Star.newtypeStar)(Data_Profunctor_Star.Star);
};
var sequenceOf = function (dictApplicative) {
    return function (t) {
        return traverseOf(dictApplicative)(t)(Control_Category.identity(Control_Category.categoryFn));
    };
};
var itraverseOf = function (dictApplicative) {
    return function (t) {
        return function ($25) {
            return Data_Newtype.under(Data_Profunctor_Star.newtypeStar)(Data_Profunctor_Star.newtypeStar)(Data_Profunctor_Star.Star)(function ($26) {
                return t(Data_Lens_Internal_Indexed.Indexed($26));
            })(Data_Tuple.uncurry($25));
        };
    };
};
var iforOf = function (dictApplicative) {
    return function ($27) {
        return Data_Function.flip(itraverseOf(dictApplicative)($27));
    };
};
var failover = function (dictAlternative) {
    return function (t) {
        return function (f) {
            return function (s) {
                var v = Data_Newtype.unwrap(Data_Profunctor_Star.newtypeStar)(t(Data_Profunctor_Star.Star(function ($28) {
                    return Data_Tuple.Tuple.create(true)(f($28));
                })))(s);
                if (v.value0) {
                    return Control_Applicative.pure(dictAlternative.Applicative0())(v.value1);
                };
                if (!v.value0) {
                    return Control_Plus.empty(dictAlternative.Plus1());
                };
                throw new Error("Failed pattern match at Data.Lens.Traversal line 100, column 18 - line 102, column 32: " + [ v.constructor.name ]);
            };
        };
    };
};
var elementsOf = function (dictWander) {
    return function (tr) {
        return function (pr) {
            return Data_Lens_Indexed.iwander(function (dictApplicative) {
                return function (f) {
                    return Data_Newtype.unwrap(Data_Profunctor_Star.newtypeStar)(tr(Data_Lens_Internal_Wander.wanderStar(dictApplicative))(Data_Lens_Internal_Indexed.Indexed(Data_Profunctor_Star.Star(function (v) {
                        var $22 = pr(v.value0);
                        if ($22) {
                            return f(v.value0)(v.value1);
                        };
                        return Control_Applicative.pure(dictApplicative)(v.value1);
                    }))));
                };
            })(dictWander);
        };
    };
};
var element = function (dictWander) {
    return function (n) {
        return function (tr) {
            return Data_Lens_Indexed.unIndex((dictWander.Choice1()).Profunctor0())(elementsOf(dictWander)(function (dictWander1) {
                return Data_Lens_Indexed.positions(function (dictWander2) {
                    return tr(dictWander2);
                })(dictWander1);
            })(function (v) {
                return v === n;
            }));
        };
    };
};
module.exports = {
    traversed: traversed,
    element: element,
    traverseOf: traverseOf,
    sequenceOf: sequenceOf,
    failover: failover,
    elementsOf: elementsOf,
    itraverseOf: itraverseOf
};
