// Generated by purs version 0.12.0
"use strict";
var Data_Show = require("../Data.Show/index.js");
var Prelude = require("../Prelude/index.js");
var Visible = (function () {
    function Visible() {

    };
    Visible.value = new Visible();
    return Visible;
})();
var Hidden = (function () {
    function Hidden() {

    };
    Hidden.value = new Hidden();
    return Hidden;
})();
var Scroll = (function () {
    function Scroll() {

    };
    Scroll.value = new Scroll();
    return Scroll;
})();
var showOverflow = new Data_Show.Show(function (v) {
    if (v instanceof Visible) {
        return "visible";
    };
    if (v instanceof Hidden) {
        return "hidden";
    };
    if (v instanceof Scroll) {
        return "scroll";
    };
    throw new Error("Failed pattern match at UI.Style.PropTypes.Overflow line 7, column 1 - line 7, column 39: " + [ v.constructor.name ]);
});
module.exports = {
    Visible: Visible,
    Hidden: Hidden,
    Scroll: Scroll,
    showOverflow: showOverflow
};
