// Generated by purs version 0.12.0
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var Core_Data = require("../Core.Data/index.js");
var Data_Lens = require("../Data.Lens/index.js");
var Data_Lens_Getter = require("../Data.Lens.Getter/index.js");
var Data_Lens_Internal_Forget = require("../Data.Lens.Internal.Forget/index.js");
var Prelude = require("../Prelude/index.js");
var Run = require("../Run/index.js");
var UI_App_Common = require("../UI.App.Common/index.js");
var UI_App_Dashboard_DashboardHeader = require("../UI.App.Dashboard.DashboardHeader/index.js");
var UI_App_Tiles = require("../UI.App.Tiles/index.js");
var UI_Components = require("../UI.Components/index.js");
var UI_Style_Layout = require("../UI.Style.Layout/index.js");
var UI_Style_PropTypes_FlexDirection = require("../UI.Style.PropTypes.FlexDirection/index.js");
var style = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_App_Common.panel)(function () {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_App_Common.outline)(function () {
        return UI_Style_Layout.flexDirection(UI_Style_PropTypes_FlexDirection.Column.value);
    });
});
var render = function (v) {
    var tiles = Data_Lens_Getter.view(function ($9) {
        return Core_Data._Dashboard(Data_Lens_Internal_Forget.strongForget)(Core_Data._tiles(Data_Lens_Internal_Forget.strongForget)($9));
    })(v.dashboard);
    var dashboardId = Data_Lens_Getter.view(function ($10) {
        return Core_Data._Dashboard(Data_Lens_Internal_Forget.strongForget)(Core_Data._id(Data_Lens_Internal_Forget.strongForget)($10));
    })(v.dashboard);
    return Control_Bind.bind(Run.bindRun)(UI_App_Dashboard_DashboardHeader.component({
        dashboard: v.dashboard
    }))(function (v1) {
        return Control_Bind.bind(Run.bindRun)(UI_App_Tiles.component({
            tiles: tiles,
            dashboardId: dashboardId,
            focusTile: v.focusTile,
            publish: v.publish
        }))(function (v2) {
            return UI_Components.section({
                style: style
            })(UI_Components.fragment([ v1, v2 ]));
        });
    });
};
var component = function (props) {
    return UI_Components.stateless("Dashboard")(props)(render);
};
module.exports = {
    component: component,
    render: render,
    style: style
};
