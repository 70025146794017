// Generated by purs version 0.12.0
"use strict";
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var Data_EuclideanRing = require("../Data.EuclideanRing/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Lens_Internal_Indexed = require("../Data.Lens.Internal.Indexed/index.js");
var Data_Lens_Types = require("../Data.Lens.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ring = require("../Data.Ring/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Prelude = require("../Prelude/index.js");
var over = function (l) {
    return l;
};
var set = function (l) {
    return function (b) {
        return over(l)(Data_Function["const"](b));
    };
};
var setJust = function (p) {
    return function ($24) {
        return set(p)(Data_Maybe.Just.create($24));
    };
};
var subOver = function (dictRing) {
    return function (p) {
        return function ($25) {
            return over(p)(Data_Function.flip(Data_Ring.sub(dictRing))($25));
        };
    };
};
var mulOver = function (dictSemiring) {
    return function (p) {
        return function ($26) {
            return over(p)(Data_Function.flip(Data_Semiring.mul(dictSemiring))($26));
        };
    };
};
var modifying = function (dictMonadState) {
    return function (p) {
        return function (f) {
            return Data_Functor["void"]((((dictMonadState.Monad0()).Bind1()).Apply0()).Functor0())(Control_Monad_State_Class.modify(dictMonadState)(over(p)(f)));
        };
    };
};
var mulModifying = function (dictMonadState) {
    return function (dictSemiring) {
        return function (p) {
            return function ($27) {
                return modifying(dictMonadState)(p)(Data_Function.flip(Data_Semiring.mul(dictSemiring))($27));
            };
        };
    };
};
var subModifying = function (dictMonadState) {
    return function (dictRing) {
        return function (p) {
            return function ($28) {
                return modifying(dictMonadState)(p)(Data_Function.flip(Data_Ring.sub(dictRing))($28));
            };
        };
    };
};
var iover = function (l) {
    return function (f) {
        return l(Data_Lens_Internal_Indexed.Indexed(Data_Tuple.uncurry(f)));
    };
};
var divOver = function (dictEuclideanRing) {
    return function (p) {
        return function ($29) {
            return over(p)(Data_Function.flip(Data_EuclideanRing.div(dictEuclideanRing))($29));
        };
    };
};
var divModifying = function (dictMonadState) {
    return function (dictEuclideanRing) {
        return function (p) {
            return function ($30) {
                return modifying(dictMonadState)(p)(Data_Function.flip(Data_EuclideanRing.div(dictEuclideanRing))($30));
            };
        };
    };
};
var disjOver = function (dictHeytingAlgebra) {
    return function (p) {
        return function ($31) {
            return over(p)(Data_Function.flip(Data_HeytingAlgebra.disj(dictHeytingAlgebra))($31));
        };
    };
};
var disjModifying = function (dictMonadState) {
    return function (dictHeytingAlgebra) {
        return function (p) {
            return function ($32) {
                return modifying(dictMonadState)(p)(Data_Function.flip(Data_HeytingAlgebra.disj(dictHeytingAlgebra))($32));
            };
        };
    };
};
var conjOver = function (dictHeytingAlgebra) {
    return function (p) {
        return function ($33) {
            return over(p)(Data_Function.flip(Data_HeytingAlgebra.conj(dictHeytingAlgebra))($33));
        };
    };
};
var conjModifying = function (dictMonadState) {
    return function (dictHeytingAlgebra) {
        return function (p) {
            return function ($34) {
                return modifying(dictMonadState)(p)(Data_Function.flip(Data_HeytingAlgebra.conj(dictHeytingAlgebra))($34));
            };
        };
    };
};
var assign = function (dictMonadState) {
    return function (p) {
        return function (b) {
            return Data_Functor["void"]((((dictMonadState.Monad0()).Bind1()).Apply0()).Functor0())(Control_Monad_State_Class.modify(dictMonadState)(set(p)(b)));
        };
    };
};
var assignJust = function (dictMonadState) {
    return function (p) {
        return function ($35) {
            return assign(dictMonadState)(p)(Data_Maybe.Just.create($35));
        };
    };
};
var appendOver = function (dictSemigroup) {
    return function (p) {
        return function ($36) {
            return over(p)(Data_Function.flip(Data_Semigroup.append(dictSemigroup))($36));
        };
    };
};
var appendModifying = function (dictMonadState) {
    return function (dictSemigroup) {
        return function (p) {
            return function ($37) {
                return modifying(dictMonadState)(p)(Data_Function.flip(Data_Semigroup.append(dictSemigroup))($37));
            };
        };
    };
};
var addOver = function (dictSemiring) {
    return function (p) {
        return function ($38) {
            return over(p)(Data_Semiring.add(dictSemiring)($38));
        };
    };
};
var addModifying = function (dictMonadState) {
    return function (dictSemiring) {
        return function (p) {
            return function ($39) {
                return modifying(dictMonadState)(p)(Data_Semiring.add(dictSemiring)($39));
            };
        };
    };
};
module.exports = {
    over: over,
    iover: iover,
    set: set,
    addOver: addOver,
    subOver: subOver,
    mulOver: mulOver,
    divOver: divOver,
    disjOver: disjOver,
    conjOver: conjOver,
    appendOver: appendOver,
    setJust: setJust,
    assign: assign,
    modifying: modifying,
    addModifying: addModifying,
    mulModifying: mulModifying,
    subModifying: subModifying,
    divModifying: divModifying,
    disjModifying: disjModifying,
    conjModifying: conjModifying,
    appendModifying: appendModifying,
    assignJust: assignJust
};
