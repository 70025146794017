// Generated by purs version 0.12.0
"use strict";
var $foreign = require("./foreign.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String = require("../Data.String/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_String_Pattern = require("../Data.String.Pattern/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Effect = require("../Effect/index.js");
var Prelude = require("../Prelude/index.js");
var Run = require("../Run/index.js");
var Run_Writer = require("../Run.Writer/index.js");
var UI_Components = require("../UI.Components/index.js");
var UI_Style = require("../UI.Style/index.js");
var UI_Style_Layout = require("../UI.Style.Layout/index.js");
var UI_Style_Text = require("../UI.Style.Text/index.js");
var UI_Style_View = require("../UI.Style.View/index.js");
var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");
var quote = function (s) {
    var $4 = Data_String_CodeUnits.contains("\"")(s);
    if ($4) {
        return s;
    };
    return "\"" + (s + "\"");
};
var toKVStr = function (v) {
    if (v instanceof UI_Style.IntProperty) {
        return quote(v.value0) + (":" + Data_Show.show(Data_Show.showInt)(v.value1));
    };
    if (v instanceof UI_Style.NumberProperty) {
        return quote(v.value0) + (":" + Data_Show.show(Data_Show.showNumber)(v.value1));
    };
    if (v instanceof UI_Style.StringProperty) {
        return quote(v.value0) + (":" + quote(v.value1));
    };
    throw new Error("Failed pattern match at Interpreters line 71, column 1 - line 71, column 30: " + [ v.constructor.name ]);
};
var toJsonString = function (array) {
    var body = Data_Foldable.intercalate(Data_Foldable.foldableArray)(Data_Monoid.monoidString)(",")(Data_Functor.mapFlipped(Data_Functor.functorArray)(array)(toKVStr));
    return "{" + (body + "}");
};
var textViewStyleInterpreter = function (style) {
    var v = Run.extract(UI_Style.runStyle(UI_Style.handleStyle)(UI_Style_Text.runText(UI_Style_Text.handleText)(UI_Style_View.runView(UI_Style_View.handleView)(UI_Style_Layout.runLayout(UI_Style_Layout.handleLayout)(Run_Writer.foldWriter(Data_Array.snoc)([  ])(style))))));
    return toJsonString(v.value0);
};
var viewStyleInterpreter = function (style) {
    var v = Run.extract(UI_Style.runStyle(UI_Style.handleStyle)(UI_Style_View.runView(UI_Style_View.handleView)(UI_Style_Layout.runLayout(UI_Style_Layout.handleLayout)(Run_Writer.foldWriter(Data_Array.snoc)([  ])(style)))));
    return toJsonString(v.value0);
};
var handleComponent = function (v) {
    if (v instanceof UI_Components.Component) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0)(componentInterpreter));
    };
    if (v instanceof UI_Components.MakeTouchable) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value2($foreign.makeTouchable_(v.value0)(v.value1)));
    };
    if (v instanceof UI_Components.OnChange) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value2($foreign.onChange(v.value0)(v.value1)));
    };
    if (v instanceof UI_Components.Chart) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value3($foreign.createChart(v.value1.type)(Data_Show.show(UI_Components.showLayoutChain)(v.value1.layoutChain))(Data_Show.show(UI_Components.showSiblingGrows)(v.value1.siblingGrows))(v.value2)(v.value0)(viewStyleInterpreter)));
    };
    if (v instanceof UI_Components.Article) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value2($foreign.domElement("article")({})(v.value1)(v.value0)(viewStyleInterpreter)));
    };
    if (v instanceof UI_Components.Footer) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value2($foreign.domElement("footer")({})(v.value1)(v.value0)(viewStyleInterpreter)));
    };
    if (v instanceof UI_Components.Header) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value2($foreign.domElement("header")({})(v.value1)(v.value0)(viewStyleInterpreter)));
    };
    if (v instanceof UI_Components.Nav) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value2($foreign.domElement("nav")({})(v.value1)(v.value0)(viewStyleInterpreter)));
    };
    if (v instanceof UI_Components.Section) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value2($foreign.domElement("section")({})(v.value1)(v.value0)(viewStyleInterpreter)));
    };
    if (v instanceof UI_Components.Div) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value2($foreign.domElement("div")({})(v.value1)(v.value0)(viewStyleInterpreter)));
    };
    if (v instanceof UI_Components.Main) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value2($foreign.domElement("main")({})(v.value1)(v.value0)(viewStyleInterpreter)));
    };
    if (v instanceof UI_Components.Image) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value2($foreign.domElement("img")(v.value1)(UI_Components.fragment([  ]))(v.value0)(viewStyleInterpreter)));
    };
    if (v instanceof UI_Components.TextInput) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1($foreign.textInput(v.value0)(textViewStyleInterpreter)));
    };
    if (v instanceof UI_Components.Checkbox) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value2($foreign.checkbox(v.value0)(v.value1)(viewStyleInterpreter)));
    };
    if (v instanceof UI_Components.RadioButton) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value2($foreign.radioButton(v.value0)(v.value1)(viewStyleInterpreter)));
    };
    if (v instanceof UI_Components.H1) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value2($foreign.domElement("h1")({})(v.value1)(v.value0)(textViewStyleInterpreter)));
    };
    if (v instanceof UI_Components.H2) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value2($foreign.domElement("h2")({})(v.value1)(v.value0)(textViewStyleInterpreter)));
    };
    if (v instanceof UI_Components.H3) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value2($foreign.domElement("h3")({})(v.value1)(v.value0)(textViewStyleInterpreter)));
    };
    if (v instanceof UI_Components.H4) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value2($foreign.domElement("h4")({})(v.value1)(v.value0)(textViewStyleInterpreter)));
    };
    if (v instanceof UI_Components.H5) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value2($foreign.domElement("h5")({})(v.value1)(v.value0)(textViewStyleInterpreter)));
    };
    if (v instanceof UI_Components.H6) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value2($foreign.domElement("h6")({})(v.value1)(v.value0)(textViewStyleInterpreter)));
    };
    if (v instanceof UI_Components.P) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value2($foreign.domElement("p")({})(v.value1)(v.value0)(textViewStyleInterpreter)));
    };
    if (v instanceof UI_Components.Text) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    throw new Error("Failed pattern match at Interpreters line 20, column 19 - line 47, column 61: " + [ v.constructor.name ]);
};
var componentInterpreter = function (component) {
    return Run.extract(UI_Components.runComponent(handleComponent)(component));
};
module.exports = {
    handleComponent: handleComponent,
    componentInterpreter: componentInterpreter,
    viewStyleInterpreter: viewStyleInterpreter,
    textViewStyleInterpreter: textViewStyleInterpreter,
    toJsonString: toJsonString,
    toKVStr: toKVStr,
    quote: quote,
    createChart: $foreign.createChart,
    domElement: $foreign.domElement,
    textInput: $foreign.textInput,
    checkbox: $foreign.checkbox,
    radioButton: $foreign.radioButton,
    makeTouchable_: $foreign.makeTouchable_,
    onChange: $foreign.onChange
};
