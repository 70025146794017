// Generated by purs version 0.12.0
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Prelude = require("../Prelude/index.js");
var Run = require("../Run/index.js");
var UI_App_Common = require("../UI.App.Common/index.js");
var UI_Components = require("../UI.Components/index.js");
var UI_Style = require("../UI.Style/index.js");
var UI_Style_Layout = require("../UI.Style.Layout/index.js");
var UI_Style_PropTypes_AlignItems = require("../UI.Style.PropTypes.AlignItems/index.js");
var UI_Style_PropTypes_Display = require("../UI.Style.PropTypes.Display/index.js");
var UI_Style_PropTypes_FlexDirection = require("../UI.Style.PropTypes.FlexDirection/index.js");
var UI_Style_PropTypes_FontStyle = require("../UI.Style.PropTypes.FontStyle/index.js");
var UI_Style_Text = require("../UI.Style.Text/index.js");
var labelStyle = function (props) {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Text.color((function () {
        var $4 = Data_Maybe.isJust(props.error);
        if ($4) {
            return UI_App_Common.red;
        };
        return UI_App_Common.dark2;
    })()))(function () {
        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.display(UI_Style_PropTypes_Display.Flex.value))(function () {
            return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Text.fontSize(12.0))(function () {
                return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.margin(new UI_Style.Pixel(0)))(function () {
                    return UI_Style_Layout.marginBottom(new UI_Style.Pixel(0));
                });
            });
        });
    });
};
var hintStyle = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.display(UI_Style_PropTypes_Display.Flex.value))(function () {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Text.fontStyle(UI_Style_PropTypes_FontStyle.Italic.value))(function () {
        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Text.color(UI_App_Common.gray3))(function () {
            return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Text.fontSize(10.0))(function () {
                return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.marginTop(new UI_Style.Pixel(0)))(function () {
                    return UI_Style_Layout.marginBottom(new UI_Style.Pixel(0));
                });
            });
        });
    });
});
var hint = function (props) {
    return Control_Bind.bindFlipped(Run.bindRun)(UI_Components.p({
        style: hintStyle
    }))(UI_Components.text(Data_Maybe.fromMaybe("")(props.hint)));
};
var fieldContainerStyle = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.alignItems(UI_Style_PropTypes_AlignItems.FlexStart.value))(function () {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.display(UI_Style_PropTypes_Display.Flex.value))(function () {
        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.flexDirection(UI_Style_PropTypes_FlexDirection.Column.value))(function () {
            return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.paddingTop(new UI_Style.Pixel(15)))(function () {
                return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.marginRight(new UI_Style.Pixel(5)))(function () {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.marginBottom(new UI_Style.Pixel(0)))(function () {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.marginLeft(new UI_Style.Pixel(15)))(function () {
                            return UI_Style_Layout.paddingBottom(new UI_Style.Pixel(15));
                        });
                    });
                });
            });
        });
    });
});
var errorStyle = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.display(UI_Style_PropTypes_Display.Flex.value))(function () {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Text.fontStyle(UI_Style_PropTypes_FontStyle.Italic.value))(function () {
        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Text.color(UI_App_Common.red))(function () {
            return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Text.fontSize(10.0))(function () {
                return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.marginTop(new UI_Style.Pixel(0)))(function () {
                    return UI_Style_Layout.marginBottom(new UI_Style.Pixel(0));
                });
            });
        });
    });
});
var error = function (props) {
    return Control_Bind.bindFlipped(Run.bindRun)(UI_Components.p({
        style: errorStyle
    }))(UI_Components.text(Data_Maybe.fromMaybe("")(props.error)));
};
var component = function (props) {
    return Control_Bind.bind(Run.bindRun)(Control_Bind.bindFlipped(Run.bindRun)(UI_Components.p({
        style: labelStyle(props)
    }))(UI_Components.text(props.label)))(function (v) {
        return Control_Bind.bind(Run.bindRun)(props.child)(function (v1) {
            return Control_Bind.bind(Run.bindRun)(hint(props))(function (v2) {
                return Control_Bind.bind(Run.bindRun)(error(props))(function (v3) {
                    return UI_Components.div({
                        style: fieldContainerStyle
                    })(UI_Components.fragment([ v, v2, v1, v3 ]));
                });
            });
        });
    });
};
module.exports = {
    component: component,
    hint: hint,
    error: error,
    hintStyle: hintStyle,
    errorStyle: errorStyle,
    fieldContainerStyle: fieldContainerStyle,
    labelStyle: labelStyle
};
