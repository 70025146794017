// Generated by purs version 0.12.0
"use strict";
var Control_Alternative = require("../Control.Alternative/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Plus = require("../Control.Plus/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_List = require("../Data.List/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Partial_Unsafe = require("../Partial.Unsafe/index.js");
var Prelude = require("../Prelude/index.js");
var Record_Unsafe = require("../Record.Unsafe/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var Type_Proxy = require("../Type.Proxy/index.js");
var Type_Row = require("../Type.Row/index.js");
var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");
var VariantFRep = function (x) {
    return x;
};
var UnvariantF = function (x) {
    return x;
};
var VariantFShows = function (variantFShows) {
    this.variantFShows = variantFShows;
};
var variantFShows = function (dict) {
    return dict.variantFShows;
};
var unvariantF = function (v) {
    return function (f) {
        return (function (dictIsSymbol) {
            return function (dictCons) {
                return function (dictFunctor) {
                    return f(dictIsSymbol)(dictCons)(dictFunctor);
                };
            };
        })({
            reflectSymbol: Data_Function["const"](v.type)
        })({})({
            map: v.map
        })(Data_Symbol.SProxy.value)(v.value);
    };
};
var showVariantFNil = new VariantFShows(function (v) {
    return function (v1) {
        return Data_List_Types.Nil.value;
    };
});
var showVariantFCons = function (dictVariantFShows) {
    return function (dictTypeEquals) {
        return function (dictShow) {
            return function (dictShow1) {
                return new VariantFShows(function (v) {
                    return function (p) {
                        return new Data_List_Types.Cons(Data_Show.show(dictShow), variantFShows(dictVariantFShows)(Type_Row.RLProxy.value)(p));
                    };
                });
            };
        };
    };
};
var showVariantF = function (dictRowToList) {
    return function (dictVariantTags) {
        return function (dictVariantFShows) {
            return function (dictShow) {
                return new Data_Show.Show(function (v1) {
                    var tags = Data_Variant_Internal.variantTags(dictVariantTags)(Type_Row.RLProxy.value);
                    var shows = variantFShows(dictVariantFShows)(Type_Row.RLProxy.value)(Type_Proxy["Proxy"].value);
                    var body = Data_Variant_Internal.lookup("show")(v1.type)(tags)(shows)(v1.value);
                    return "(inj @" + (Data_Show.show(Data_Show.showString)(v1.type) + (" " + (body + ")")));
                });
            };
        };
    };
};
var onMatch = function (dictRowToList) {
    return function (dictVariantFMatchCases) {
        return function (dictUnion) {
            return function (r) {
                return function (k) {
                    return function (v) {
                        if (Record_Unsafe.unsafeHas(v.type)(r)) {
                            return Record_Unsafe.unsafeGet(v.type)(r)(v.value);
                        };
                        return k(v);
                    };
                };
            };
        };
    };
};
var on = function (dictCons) {
    return function (dictIsSymbol) {
        return function (p) {
            return function (f) {
                return function (g) {
                    return function (r) {
                        if (r.type === Data_Symbol.reflectSymbol(dictIsSymbol)(p)) {
                            return f(r.value);
                        };
                        return g(r);
                    };
                };
            };
        };
    };
};
var prj = function (dictCons) {
    return function (dictAlternative) {
        return function (dictIsSymbol) {
            return function (p) {
                return on(dictCons)(dictIsSymbol)(p)(Control_Applicative.pure(dictAlternative.Applicative0()))(Data_Function["const"](Control_Plus.empty(dictAlternative.Plus1())));
            };
        };
    };
};
var inj = function (dictCons) {
    return function (dictIsSymbol) {
        return function (dictFunctor) {
            return function (p) {
                return function (value) {
                    return {
                        type: Data_Symbol.reflectSymbol(dictIsSymbol)(p),
                        value: value,
                        map: Data_Functor.map(dictFunctor)
                    };
                };
            };
        };
    };
};
var revariantF = function (v) {
    return v(function (dictIsSymbol) {
        return function (dictCons) {
            return function (dictFunctor) {
                return inj(dictCons)(dictIsSymbol)(dictFunctor);
            };
        };
    });
};
var functorVariantF = new Data_Functor.Functor(function (f) {
    return function (a) {
        return {
            type: a.type,
            value: a.map(f)(a.value),
            map: a.map
        };
    };
});
var expand = function (dictUnion) {
    return Unsafe_Coerce.unsafeCoerce;
};
var $$default = function (a) {
    return function (v) {
        return a;
    };
};
var contract = function (dictAlternative) {
    return function (dictContractable) {
        return function (v) {
            return Data_Variant_Internal.contractWith(dictContractable)(dictAlternative)(Type_Row.RProxy.value)(Type_Row.RProxy.value)(v.type)(v);
        };
    };
};
var case_ = function (r) {
    return Partial_Unsafe.unsafeCrashWith("Data.Functor.Variant: pattern match failure [" + (r.type + "]"));
};
var match = function (dictRowToList) {
    return function (dictVariantFMatchCases) {
        return function (dictUnion) {
            return function (r) {
                return onMatch(dictRowToList)(dictVariantFMatchCases)(dictUnion)(r)(case_);
            };
        };
    };
};
module.exports = {
    inj: inj,
    prj: prj,
    on: on,
    onMatch: onMatch,
    case_: case_,
    match: match,
    "default": $$default,
    expand: expand,
    contract: contract,
    UnvariantF: UnvariantF,
    unvariantF: unvariantF,
    revariantF: revariantF,
    VariantFShows: VariantFShows,
    variantFShows: variantFShows,
    functorVariantF: functorVariantF,
    showVariantFNil: showVariantFNil,
    showVariantFCons: showVariantFCons,
    showVariantF: showVariantF
};
