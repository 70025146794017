// Generated by purs version 0.12.0
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Prelude = require("../Prelude/index.js");
var Run = require("../Run/index.js");
var UI_Components = require("../UI.Components/index.js");
var UI_Style = require("../UI.Style/index.js");
var UI_Style_Layout = require("../UI.Style.Layout/index.js");
var UI_Style_PropTypes_AlignItems = require("../UI.Style.PropTypes.AlignItems/index.js");
var UI_Style_PropTypes_Display = require("../UI.Style.PropTypes.Display/index.js");
var UI_Style_PropTypes_FlexDirection = require("../UI.Style.PropTypes.FlexDirection/index.js");
var radioButtonStyle = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.alignItems(UI_Style_PropTypes_AlignItems.Center.value))(function () {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.display(UI_Style_PropTypes_Display.Flex.value))(function () {
        return UI_Style_Layout.flexDirection(UI_Style_PropTypes_FlexDirection.Row.value);
    });
});
var radioButtonLabelStyle = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.display(UI_Style_PropTypes_Display.Flex.value))(function () {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.margin(new UI_Style.Pixel(0)))(function () {
        return UI_Style_Layout.marginRight(new UI_Style.Pixel(10));
    });
});
var component = function (props) {
    return Control_Bind.bind(Run.bindRun)(Control_Bind.bind(Run.bindRun)(UI_Components.radioButton({
        style: radioButtonStyle,
        name: Data_Maybe.Nothing.value,
        value: new Data_Maybe.Just(props.value),
        checked: props.isChecked,
        disabled: props.disabled
    }))(UI_Components.onChange((function () {
        if (props.disabled) {
            return function (v) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            };
        };
        return props.effect;
    })())))(function (v) {
        return Control_Bind.bind(Run.bindRun)(Control_Bind.bind(Run.bindRun)(Control_Bind.bindFlipped(Run.bindRun)(UI_Components.p({
            style: radioButtonLabelStyle
        }))(UI_Components.text(props.label)))(UI_Components.makeTouchable((function () {
            if (props.disabled) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            };
            return props.effect(props.label);
        })())))(function (v1) {
            return UI_Components.div({
                style: radioButtonStyle
            })(UI_Components.fragment([ v, v1 ]));
        });
    });
};
module.exports = {
    component: component,
    radioButtonLabelStyle: radioButtonLabelStyle,
    radioButtonStyle: radioButtonStyle
};
