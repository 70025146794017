// Generated by purs version 0.12.0
"use strict";
var Core_Data = require("../Core.Data/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var UI_App_Common_Text = require("../UI.App.Common.Text/index.js");
var UI_Components = require("../UI.Components/index.js");
var component = function (v) {
    if (v instanceof Data_Maybe.Nothing) {
        return UI_App_Common_Text.mainHeader({
            text: "Apple Workspace"
        });
    };
    if (v instanceof Data_Maybe.Just) {
        return UI_App_Common_Text.mainHeader({
            text: v.value0
        });
    };
    throw new Error("Failed pattern match at UI.App.Workspace.WorkspaceHeader line 8, column 1 - line 8, column 51: " + [ v.constructor.name ]);
};
module.exports = {
    component: component
};
