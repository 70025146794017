// Generated by purs version 0.12.0
"use strict";
var Control_Alternative = require("../Control.Alternative/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Plus = require("../Control.Plus/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_List = require("../Data.List/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Ring = require("../Data.Ring/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Partial_Unsafe = require("../Partial.Unsafe/index.js");
var Prelude = require("../Prelude/index.js");
var Record_Unsafe = require("../Record.Unsafe/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var Type_Row = require("../Type.Row/index.js");
var VariantRep = function (x) {
    return x;
};
var FProxy = (function () {
    function FProxy() {

    };
    FProxy.value = new FProxy();
    return FProxy;
})();
var VariantMatchCases = {};
var VariantFMatchCases = {};
var VariantTags = function (variantTags) {
    this.variantTags = variantTags;
};
var Contractable = function (contractWith) {
    this.contractWith = contractWith;
};
var variantTagsNil = new VariantTags(function (v) {
    return Data_List_Types.Nil.value;
});
var variantTags = function (dict) {
    return dict.variantTags;
};
var variantTagsCons = function (dictVariantTags) {
    return function (dictIsSymbol) {
        return new VariantTags(function (v) {
            return new Data_List_Types.Cons(Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value), variantTags(dictVariantTags)(Type_Row.RLProxy.value));
        });
    };
};
var variantMatchNil = VariantMatchCases;
var variantMatchCons = function (dictVariantMatchCases) {
    return function (dictCons) {
        return function (dictTypeEquals) {
            return VariantMatchCases;
        };
    };
};
var variantFMatchNil = VariantFMatchCases;
var variantFMatchCons = function (dictVariantFMatchCases) {
    return function (dictCons) {
        return function (dictTypeEquals) {
            return VariantFMatchCases;
        };
    };
};
var lookupToEnum = (function () {
    var go = function ($copy_ix) {
        return function ($copy_v) {
            return function ($copy_v1) {
                var $tco_var_ix = $copy_ix;
                var $tco_var_v = $copy_v;
                var $tco_done = false;
                var $tco_result;
                function $tco_loop(ix, v, v1) {
                    if (v instanceof Data_List_Types.Cons && v1 instanceof Data_List_Types.Cons) {
                        if (v1.value0.cardinality > ix) {
                            var v2 = v1.value0.toEnum(ix);
                            if (v2 instanceof Data_Maybe.Just) {
                                $tco_done = true;
                                return Data_Maybe.Just.create({
                                    type: v.value0,
                                    value: v2.value0
                                });
                            };
                            $tco_done = true;
                            return Data_Maybe.Nothing.value;
                        };
                        if (Data_Boolean.otherwise) {
                            $tco_var_ix = ix - v1.value0.cardinality | 0;
                            $tco_var_v = v.value1;
                            $copy_v1 = v1.value1;
                            return;
                        };
                    };
                    $tco_done = true;
                    return Data_Maybe.Nothing.value;
                };
                while (!$tco_done) {
                    $tco_result = $tco_loop($tco_var_ix, $tco_var_v, $copy_v1);
                };
                return $tco_result;
            };
        };
    };
    return go;
})();
var lookupTag = function (tag) {
    var go = function ($copy_v) {
        var $tco_done = false;
        var $tco_result;
        function $tco_loop(v) {
            if (v instanceof Data_List_Types.Cons) {
                if (v.value0 === tag) {
                    $tco_done = true;
                    return true;
                };
                if (Data_Boolean.otherwise) {
                    $copy_v = v.value1;
                    return;
                };
            };
            if (v instanceof Data_List_Types.Nil) {
                $tco_done = true;
                return false;
            };
            throw new Error("Failed pattern match at Data.Variant.Internal line 91, column 8 - line 95, column 18: " + [ v.constructor.name ]);
        };
        while (!$tco_done) {
            $tco_result = $tco_loop($copy_v);
        };
        return $tco_result;
    };
    return go;
};
var lookupCardinality = (function () {
    var go = function ($copy_acc) {
        return function ($copy_v) {
            var $tco_var_acc = $copy_acc;
            var $tco_done = false;
            var $tco_result;
            function $tco_loop(acc, v) {
                if (v instanceof Data_List_Types.Cons) {
                    $tco_var_acc = acc + v.value0.cardinality | 0;
                    $copy_v = v.value1;
                    return;
                };
                if (v instanceof Data_List_Types.Nil) {
                    $tco_done = true;
                    return acc;
                };
                throw new Error("Failed pattern match at Data.Variant.Internal line 213, column 12 - line 215, column 16: " + [ v.constructor.name ]);
            };
            while (!$tco_done) {
                $tco_result = $tco_loop($tco_var_acc, $copy_v);
            };
            return $tco_result;
        };
    };
    return go(0);
})();
var impossible = function (str) {
    return Partial_Unsafe.unsafeCrashWith("Data.Variant: impossible `" + (str + "`"));
};
var lookup = function (name) {
    return function (tag) {
        var go = function ($copy_v) {
            return function ($copy_v1) {
                var $tco_var_v = $copy_v;
                var $tco_done = false;
                var $tco_result;
                function $tco_loop(v, v1) {
                    if (v instanceof Data_List_Types.Cons && v1 instanceof Data_List_Types.Cons) {
                        if (v.value0 === tag) {
                            $tco_done = true;
                            return v1.value0;
                        };
                        if (Data_Boolean.otherwise) {
                            $tco_var_v = v.value1;
                            $copy_v1 = v1.value1;
                            return;
                        };
                    };
                    $tco_done = true;
                    return impossible(name);
                };
                while (!$tco_done) {
                    $tco_result = $tco_loop($tco_var_v, $copy_v1);
                };
                return $tco_result;
            };
        };
        return go;
    };
};
var lookupEq = function (tags) {
    return function (eqs) {
        return function (v) {
            return function (v1) {
                if (v.type === v1.type) {
                    return lookup("eq")(v.type)(tags)(eqs)(v.value)(v1.value);
                };
                if (Data_Boolean.otherwise) {
                    return false;
                };
                throw new Error("Failed pattern match at Data.Variant.Internal line 97, column 1 - line 102, column 12: " + [ tags.constructor.name, eqs.constructor.name, v.constructor.name, v1.constructor.name ]);
            };
        };
    };
};
var lookupOrd = function (tags) {
    return function (ords) {
        return function (v) {
            return function (v1) {
                var v3 = Data_Ord.compare(Data_Ord.ordString)(v.type)(v1.type);
                if (v3 instanceof Data_Ordering.EQ) {
                    return lookup("compare")(v.type)(tags)(ords)(v.value)(v1.value);
                };
                return v3;
            };
        };
    };
};
var lookupFirst = function (name) {
    return function (f) {
        var go = function (v) {
            return function (v1) {
                if (v instanceof Data_List_Types.Cons && v1 instanceof Data_List_Types.Cons) {
                    return {
                        type: v.value0,
                        value: f(v1.value0)
                    };
                };
                return impossible(name);
            };
        };
        return go;
    };
};
var lookupFromEnum = function (v) {
    var go = function ($copy_acc) {
        return function ($copy_v1) {
            return function ($copy_v2) {
                var $tco_var_acc = $copy_acc;
                var $tco_var_v1 = $copy_v1;
                var $tco_done = false;
                var $tco_result;
                function $tco_loop(acc, v1, v2) {
                    if (v1 instanceof Data_List_Types.Cons && v2 instanceof Data_List_Types.Cons) {
                        if (v1.value0 === v.type) {
                            $tco_done = true;
                            return acc + v2.value0.fromEnum(v.value) | 0;
                        };
                        if (Data_Boolean.otherwise) {
                            $tco_var_acc = acc + v2.value0.cardinality | 0;
                            $tco_var_v1 = v1.value1;
                            $copy_v2 = v2.value1;
                            return;
                        };
                    };
                    $tco_done = true;
                    return impossible("fromEnum");
                };
                while (!$tco_done) {
                    $tco_result = $tco_loop($tco_var_acc, $tco_var_v1, $copy_v2);
                };
                return $tco_result;
            };
        };
    };
    return go(0);
};
var lookupLast = function (name) {
    return function (f) {
        var go = function ($copy_v) {
            return function ($copy_v1) {
                var $tco_var_v = $copy_v;
                var $tco_done = false;
                var $tco_result;
                function $tco_loop(v, v1) {
                    if (v instanceof Data_List_Types.Cons && (v.value1 instanceof Data_List_Types.Nil && (v1 instanceof Data_List_Types.Cons && v1.value1 instanceof Data_List_Types.Nil))) {
                        $tco_done = true;
                        return {
                            type: v.value0,
                            value: f(v1.value0)
                        };
                    };
                    if (v instanceof Data_List_Types.Cons && v1 instanceof Data_List_Types.Cons) {
                        $tco_var_v = v.value1;
                        $copy_v1 = v1.value1;
                        return;
                    };
                    $tco_done = true;
                    return impossible(name);
                };
                while (!$tco_done) {
                    $tco_result = $tco_loop($tco_var_v, $copy_v1);
                };
                return $tco_result;
            };
        };
        return go;
    };
};
var lookupPred = function (v) {
    var go2 = function ($copy_t1) {
        return function ($copy_b1) {
            return function ($copy_d1) {
                return function ($copy_v1) {
                    return function ($copy_v2) {
                        return function ($copy_v3) {
                            var $tco_var_t1 = $copy_t1;
                            var $tco_var_b1 = $copy_b1;
                            var $tco_var_d1 = $copy_d1;
                            var $tco_var_v1 = $copy_v1;
                            var $tco_var_v2 = $copy_v2;
                            var $tco_done = false;
                            var $tco_result;
                            function $tco_loop(t1, b1, d1, v1, v2, v3) {
                                if (v1 instanceof Data_List_Types.Cons && (v2 instanceof Data_List_Types.Cons && v3 instanceof Data_List_Types.Cons)) {
                                    if (v1.value0 === v.type) {
                                        var v4 = v3.value0.pred(v.value);
                                        if (v4 instanceof Data_Maybe.Nothing) {
                                            $tco_done = true;
                                            return Data_Maybe.Just.create({
                                                type: t1,
                                                value: b1.top
                                            });
                                        };
                                        if (v4 instanceof Data_Maybe.Just) {
                                            $tco_done = true;
                                            return Data_Maybe.Just.create({
                                                type: v.type,
                                                value: v4.value0
                                            });
                                        };
                                        throw new Error("Failed pattern match at Data.Variant.Internal line 181, column 11 - line 183, column 69: " + [ v4.constructor.name ]);
                                    };
                                    if (Data_Boolean.otherwise) {
                                        $tco_var_t1 = v1.value0;
                                        $tco_var_b1 = v2.value0;
                                        $tco_var_d1 = v3.value0;
                                        $tco_var_v1 = v1.value1;
                                        $tco_var_v2 = v2.value1;
                                        $copy_v3 = v3.value1;
                                        return;
                                    };
                                };
                                $tco_done = true;
                                return impossible("pred");
                            };
                            while (!$tco_done) {
                                $tco_result = $tco_loop($tco_var_t1, $tco_var_b1, $tco_var_d1, $tco_var_v1, $tco_var_v2, $copy_v3);
                            };
                            return $tco_result;
                        };
                    };
                };
            };
        };
    };
    var go1 = function (v1) {
        return function (v2) {
            return function (v3) {
                if (v1 instanceof Data_List_Types.Cons && (v2 instanceof Data_List_Types.Cons && v3 instanceof Data_List_Types.Cons)) {
                    if (v1.value0 === v.type) {
                        var v4 = v3.value0.pred(v.value);
                        if (v4 instanceof Data_Maybe.Nothing) {
                            return Data_Maybe.Nothing.value;
                        };
                        if (v4 instanceof Data_Maybe.Just) {
                            return Data_Maybe.Just.create({
                                type: v.type,
                                value: v4.value0
                            });
                        };
                        throw new Error("Failed pattern match at Data.Variant.Internal line 172, column 11 - line 174, column 69: " + [ v4.constructor.name ]);
                    };
                    if (Data_Boolean.otherwise) {
                        return go2(v1.value0)(v2.value0)(v3.value0)(v1.value1)(v2.value1)(v3.value1);
                    };
                };
                return impossible("pred");
            };
        };
    };
    return go1;
};
var lookupSucc = function (v) {
    var go = function ($copy_v1) {
        return function ($copy_v2) {
            return function ($copy_v3) {
                var $tco_var_v1 = $copy_v1;
                var $tco_var_v2 = $copy_v2;
                var $tco_done = false;
                var $tco_result;
                function $tco_loop(v1, v2, v3) {
                    if (v1 instanceof Data_List_Types.Cons && (v2 instanceof Data_List_Types.Cons && v3 instanceof Data_List_Types.Cons)) {
                        if (v1.value0 === v.type) {
                            var v4 = v3.value0.succ(v.value);
                            if (v4 instanceof Data_Maybe.Just) {
                                $tco_done = true;
                                return Data_Maybe.Just.create({
                                    type: v1.value0,
                                    value: v4.value0
                                });
                            };
                            if (v4 instanceof Data_Maybe.Nothing) {
                                if (v1.value1 instanceof Data_List_Types.Cons && v2.value1 instanceof Data_List_Types.Cons) {
                                    $tco_done = true;
                                    return Data_Maybe.Just.create({
                                        type: v1.value1.value0,
                                        value: v2.value1.value0.bottom
                                    });
                                };
                                $tco_done = true;
                                return Data_Maybe.Nothing.value;
                            };
                            throw new Error("Failed pattern match at Data.Variant.Internal line 199, column 11 - line 203, column 29: " + [ v4.constructor.name ]);
                        };
                        if (Data_Boolean.otherwise) {
                            $tco_var_v1 = v1.value1;
                            $tco_var_v2 = v2.value1;
                            $copy_v3 = v3.value1;
                            return;
                        };
                    };
                    $tco_done = true;
                    return impossible("succ");
                };
                while (!$tco_done) {
                    $tco_result = $tco_loop($tco_var_v1, $tco_var_v2, $copy_v3);
                };
                return $tco_result;
            };
        };
    };
    return go;
};
var contractWithInstance = function (dictRowToList) {
    return function (dictUnion) {
        return function (dictVariantTags) {
            return new Contractable(function (dictAlternative) {
                return function (v) {
                    return function (v1) {
                        return function (tag) {
                            return function (a) {
                                if (lookupTag(tag)(variantTags(dictVariantTags)(Type_Row.RLProxy.value))) {
                                    return Control_Applicative.pure(dictAlternative.Applicative0())(a);
                                };
                                if (Data_Boolean.otherwise) {
                                    return Control_Plus.empty(dictAlternative.Plus1());
                                };
                                throw new Error("Failed pattern match at Data.Variant.Internal line 251, column 1 - line 256, column 23: " + [ v.constructor.name, v1.constructor.name, tag.constructor.name, a.constructor.name ]);
                            };
                        };
                    };
                };
            });
        };
    };
};
var contractWith = function (dict) {
    return dict.contractWith;
};
module.exports = {
    FProxy: FProxy,
    VariantRep: VariantRep,
    VariantTags: VariantTags,
    variantTags: variantTags,
    Contractable: Contractable,
    contractWith: contractWith,
    VariantMatchCases: VariantMatchCases,
    VariantFMatchCases: VariantFMatchCases,
    lookup: lookup,
    lookupTag: lookupTag,
    lookupEq: lookupEq,
    lookupOrd: lookupOrd,
    lookupLast: lookupLast,
    lookupFirst: lookupFirst,
    lookupPred: lookupPred,
    lookupSucc: lookupSucc,
    lookupCardinality: lookupCardinality,
    lookupFromEnum: lookupFromEnum,
    lookupToEnum: lookupToEnum,
    impossible: impossible,
    variantMatchCons: variantMatchCons,
    variantMatchNil: variantMatchNil,
    variantFMatchCons: variantFMatchCons,
    variantFMatchNil: variantFMatchNil,
    variantTagsNil: variantTagsNil,
    variantTagsCons: variantTagsCons,
    contractWithInstance: contractWithInstance
};
