// Generated by purs version 0.12.0
"use strict";
var Data_Show = require("../Data.Show/index.js");
var Prelude = require("../Prelude/index.js");
var Absolute = (function () {
    function Absolute() {

    };
    Absolute.value = new Absolute();
    return Absolute;
})();
var Relative = (function () {
    function Relative() {

    };
    Relative.value = new Relative();
    return Relative;
})();
var showPosition = new Data_Show.Show(function (v) {
    if (v instanceof Absolute) {
        return "absolute";
    };
    if (v instanceof Relative) {
        return "position";
    };
    throw new Error("Failed pattern match at UI.Style.PropTypes.Position line 7, column 1 - line 7, column 39: " + [ v.constructor.name ]);
});
module.exports = {
    Absolute: Absolute,
    Relative: Relative,
    showPosition: showPosition
};
