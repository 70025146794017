// Generated by purs version 0.12.0
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Functor_Variant = require("../Data.Functor.Variant/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Prelude = require("../Prelude/index.js");
var Run = require("../Run/index.js");
var Run_Writer = require("../Run.Writer/index.js");
var UI_Style = require("../UI.Style/index.js");
var UI_Style_PropTypes_BorderStyle = require("../UI.Style.PropTypes.BorderStyle/index.js");
var UI_Style_PropTypes_Color = require("../UI.Style.PropTypes.Color/index.js");
var UI_Style_PropTypes_Visibility = require("../UI.Style.PropTypes.Visibility/index.js");
var BackfaceVisibility = (function () {
    function BackfaceVisibility(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    BackfaceVisibility.create = function (value0) {
        return function (value1) {
            return new BackfaceVisibility(value0, value1);
        };
    };
    return BackfaceVisibility;
})();
var BackgroundColor = (function () {
    function BackgroundColor(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    BackgroundColor.create = function (value0) {
        return function (value1) {
            return new BackgroundColor(value0, value1);
        };
    };
    return BackgroundColor;
})();
var BorderBottomColor = (function () {
    function BorderBottomColor(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    BorderBottomColor.create = function (value0) {
        return function (value1) {
            return new BorderBottomColor(value0, value1);
        };
    };
    return BorderBottomColor;
})();
var BorderBottomEndRadius = (function () {
    function BorderBottomEndRadius(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    BorderBottomEndRadius.create = function (value0) {
        return function (value1) {
            return new BorderBottomEndRadius(value0, value1);
        };
    };
    return BorderBottomEndRadius;
})();
var BorderBottomLeftRadius = (function () {
    function BorderBottomLeftRadius(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    BorderBottomLeftRadius.create = function (value0) {
        return function (value1) {
            return new BorderBottomLeftRadius(value0, value1);
        };
    };
    return BorderBottomLeftRadius;
})();
var BorderBottomRightRadius = (function () {
    function BorderBottomRightRadius(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    BorderBottomRightRadius.create = function (value0) {
        return function (value1) {
            return new BorderBottomRightRadius(value0, value1);
        };
    };
    return BorderBottomRightRadius;
})();
var BorderBottomStartRadius = (function () {
    function BorderBottomStartRadius(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    BorderBottomStartRadius.create = function (value0) {
        return function (value1) {
            return new BorderBottomStartRadius(value0, value1);
        };
    };
    return BorderBottomStartRadius;
})();
var BorderBottomWidth = (function () {
    function BorderBottomWidth(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    BorderBottomWidth.create = function (value0) {
        return function (value1) {
            return new BorderBottomWidth(value0, value1);
        };
    };
    return BorderBottomWidth;
})();
var BorderColor = (function () {
    function BorderColor(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    BorderColor.create = function (value0) {
        return function (value1) {
            return new BorderColor(value0, value1);
        };
    };
    return BorderColor;
})();
var BorderEndColor = (function () {
    function BorderEndColor(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    BorderEndColor.create = function (value0) {
        return function (value1) {
            return new BorderEndColor(value0, value1);
        };
    };
    return BorderEndColor;
})();
var BorderLeftColor = (function () {
    function BorderLeftColor(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    BorderLeftColor.create = function (value0) {
        return function (value1) {
            return new BorderLeftColor(value0, value1);
        };
    };
    return BorderLeftColor;
})();
var BorderLeftWidth = (function () {
    function BorderLeftWidth(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    BorderLeftWidth.create = function (value0) {
        return function (value1) {
            return new BorderLeftWidth(value0, value1);
        };
    };
    return BorderLeftWidth;
})();
var BorderRadius = (function () {
    function BorderRadius(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    BorderRadius.create = function (value0) {
        return function (value1) {
            return new BorderRadius(value0, value1);
        };
    };
    return BorderRadius;
})();
var BorderRightColor = (function () {
    function BorderRightColor(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    BorderRightColor.create = function (value0) {
        return function (value1) {
            return new BorderRightColor(value0, value1);
        };
    };
    return BorderRightColor;
})();
var BorderRightWidth = (function () {
    function BorderRightWidth(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    BorderRightWidth.create = function (value0) {
        return function (value1) {
            return new BorderRightWidth(value0, value1);
        };
    };
    return BorderRightWidth;
})();
var BorderStartColor = (function () {
    function BorderStartColor(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    BorderStartColor.create = function (value0) {
        return function (value1) {
            return new BorderStartColor(value0, value1);
        };
    };
    return BorderStartColor;
})();
var BorderStyle = (function () {
    function BorderStyle(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    BorderStyle.create = function (value0) {
        return function (value1) {
            return new BorderStyle(value0, value1);
        };
    };
    return BorderStyle;
})();
var BorderTopColor = (function () {
    function BorderTopColor(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    BorderTopColor.create = function (value0) {
        return function (value1) {
            return new BorderTopColor(value0, value1);
        };
    };
    return BorderTopColor;
})();
var BorderTopEndRadius = (function () {
    function BorderTopEndRadius(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    BorderTopEndRadius.create = function (value0) {
        return function (value1) {
            return new BorderTopEndRadius(value0, value1);
        };
    };
    return BorderTopEndRadius;
})();
var BorderTopLeftRadius = (function () {
    function BorderTopLeftRadius(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    BorderTopLeftRadius.create = function (value0) {
        return function (value1) {
            return new BorderTopLeftRadius(value0, value1);
        };
    };
    return BorderTopLeftRadius;
})();
var BorderTopRightRadius = (function () {
    function BorderTopRightRadius(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    BorderTopRightRadius.create = function (value0) {
        return function (value1) {
            return new BorderTopRightRadius(value0, value1);
        };
    };
    return BorderTopRightRadius;
})();
var BorderTopStartRadius = (function () {
    function BorderTopStartRadius(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    BorderTopStartRadius.create = function (value0) {
        return function (value1) {
            return new BorderTopStartRadius(value0, value1);
        };
    };
    return BorderTopStartRadius;
})();
var BorderTopWidth = (function () {
    function BorderTopWidth(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    BorderTopWidth.create = function (value0) {
        return function (value1) {
            return new BorderTopWidth(value0, value1);
        };
    };
    return BorderTopWidth;
})();
var BorderWidth = (function () {
    function BorderWidth(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    BorderWidth.create = function (value0) {
        return function (value1) {
            return new BorderWidth(value0, value1);
        };
    };
    return BorderWidth;
})();
var Opacity = (function () {
    function Opacity(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Opacity.create = function (value0) {
        return function (value1) {
            return new Opacity(value0, value1);
        };
    };
    return Opacity;
})();
var WebOnly = (function () {
    function WebOnly(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    WebOnly.create = function (value0) {
        return function (value1) {
            return new WebOnly(value0, value1);
        };
    };
    return WebOnly;
})();
var handleView = function (v) {
    if (v instanceof BackfaceVisibility) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof BackgroundColor) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof BorderBottomColor) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof BorderBottomEndRadius) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof BorderBottomLeftRadius) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof BorderBottomRightRadius) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof BorderBottomStartRadius) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof BorderBottomWidth) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof BorderColor) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof BorderEndColor) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof BorderLeftColor) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof BorderLeftWidth) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof BorderRadius) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof BorderRightColor) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof BorderRightWidth) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof BorderStartColor) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof BorderStyle) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof BorderTopColor) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof BorderTopEndRadius) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof BorderTopLeftRadius) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof BorderTopRightRadius) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof BorderTopStartRadius) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof BorderTopWidth) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof BorderWidth) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof Opacity) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof WebOnly) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    throw new Error("Failed pattern match at UI.Style.View line 175, column 14 - line 201, column 55: " + [ v.constructor.name ]);
};
var functorViewDSL = new Data_Functor.Functor(function (f) {
    return function (m) {
        if (m instanceof BackfaceVisibility) {
            return new BackfaceVisibility(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof BackgroundColor) {
            return new BackgroundColor(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof BorderBottomColor) {
            return new BorderBottomColor(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof BorderBottomEndRadius) {
            return new BorderBottomEndRadius(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof BorderBottomLeftRadius) {
            return new BorderBottomLeftRadius(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof BorderBottomRightRadius) {
            return new BorderBottomRightRadius(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof BorderBottomStartRadius) {
            return new BorderBottomStartRadius(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof BorderBottomWidth) {
            return new BorderBottomWidth(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof BorderColor) {
            return new BorderColor(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof BorderEndColor) {
            return new BorderEndColor(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof BorderLeftColor) {
            return new BorderLeftColor(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof BorderLeftWidth) {
            return new BorderLeftWidth(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof BorderRadius) {
            return new BorderRadius(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof BorderRightColor) {
            return new BorderRightColor(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof BorderRightWidth) {
            return new BorderRightWidth(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof BorderStartColor) {
            return new BorderStartColor(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof BorderStyle) {
            return new BorderStyle(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof BorderTopColor) {
            return new BorderTopColor(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof BorderTopEndRadius) {
            return new BorderTopEndRadius(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof BorderTopLeftRadius) {
            return new BorderTopLeftRadius(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof BorderTopRightRadius) {
            return new BorderTopRightRadius(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof BorderTopStartRadius) {
            return new BorderTopStartRadius(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof BorderTopWidth) {
            return new BorderTopWidth(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof BorderWidth) {
            return new BorderWidth(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof Opacity) {
            return new Opacity(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof WebOnly) {
            return new WebOnly(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        throw new Error("Failed pattern match at UI.Style.View line 85, column 8 - line 85, column 50: " + [ m.constructor.name ]);
    };
});
var _view = Data_Symbol.SProxy.value;
var buildDimensionProperty = function (dictCategory) {
    return function (name) {
        return function (dsl) {
            return function (writer) {
                var doValue = function (d) {
                    return function (wrtr) {
                        return Control_Bind.bind(Run.bindRun)(Run.lift()(new Data_Symbol.IsSymbol(function () {
                            return "view";
                        }))(functorViewDSL)(_view)(d))(wrtr);
                    };
                };
                return Control_Bind.bind(Run.bindRun)(doValue(dsl(Control_Category.identity(dictCategory)))(writer))(function (v) {
                    if (v instanceof UI_Style.Pixel) {
                        return Run_Writer.tell(new UI_Style.IntProperty(name, v.value0));
                    };
                    if (v instanceof UI_Style.Percent) {
                        return Run_Writer.tell(new UI_Style.StringProperty(name, Data_Show.show(Data_Show.showInt)(v.value0) + "%"));
                    };
                    throw new Error("Failed pattern match at UI.Style.View line 231, column 78 - line 233, column 68: " + [ v.constructor.name ]);
                });
            };
        };
    };
};
var borderBottomWidth = function (c) {
    return buildDimensionProperty(Control_Category.categoryFn)("borderBottomWidth")(BorderBottomWidth.create(c))(UI_Style.writeDimension);
};
var borderLeftWidth = function (c) {
    return buildDimensionProperty(Control_Category.categoryFn)("borderLeftWidth")(BorderLeftWidth.create(c))(UI_Style.writeDimension);
};
var borderRightWidth = function (c) {
    return buildDimensionProperty(Control_Category.categoryFn)("borderRightWidth")(BorderRightWidth.create(c))(UI_Style.writeDimension);
};
var borderTopWidth = function (c) {
    return buildDimensionProperty(Control_Category.categoryFn)("borderTopWidth")(BorderTopWidth.create(c))(UI_Style.writeDimension);
};
var borderWidth = function (c) {
    return buildDimensionProperty(Control_Category.categoryFn)("borderWidth")(BorderWidth.create(c))(UI_Style.writeDimension);
};
var buildIntProperty = function (dictCategory) {
    return function (name) {
        return function (dsl) {
            return function (writer) {
                var doValue = function (d) {
                    return function (wrtr) {
                        return Control_Bind.bind(Run.bindRun)(Run.lift()(new Data_Symbol.IsSymbol(function () {
                            return "view";
                        }))(functorViewDSL)(_view)(d))(wrtr);
                    };
                };
                return Control_Bind.bind(Run.bindRun)(doValue(dsl(Control_Category.identity(dictCategory)))(writer))(function (value) {
                    return Run_Writer.tell(new UI_Style.IntProperty(name, value));
                });
            };
        };
    };
};
var buildNumberProperty = function (dictCategory) {
    return function (name) {
        return function (dsl) {
            return function (writer) {
                var doValue = function (d) {
                    return function (wrtr) {
                        return Control_Bind.bind(Run.bindRun)(Run.lift()(new Data_Symbol.IsSymbol(function () {
                            return "view";
                        }))(functorViewDSL)(_view)(d))(wrtr);
                    };
                };
                return Control_Bind.bind(Run.bindRun)(doValue(dsl(Control_Category.identity(dictCategory)))(writer))(function (value) {
                    return Run_Writer.tell(new UI_Style.NumberProperty(name, value));
                });
            };
        };
    };
};
var borderBottomEndRadius = function (c) {
    return buildNumberProperty(Control_Category.categoryFn)("borderBottomEndRadius")(BorderBottomEndRadius.create(c))(UI_Style.writeNumber);
};
var borderBottomLeftRadius = function (c) {
    return buildNumberProperty(Control_Category.categoryFn)("borderBottomLeftRadius")(BorderBottomLeftRadius.create(c))(UI_Style.writeNumber);
};
var borderBottomRightRadius = function (c) {
    return buildNumberProperty(Control_Category.categoryFn)("borderBottomRightRadius")(BorderBottomRightRadius.create(c))(UI_Style.writeNumber);
};
var borderBottomStartRadius = function (c) {
    return buildNumberProperty(Control_Category.categoryFn)("borderBottomStartRadius")(BorderBottomStartRadius.create(c))(UI_Style.writeNumber);
};
var borderRadius = function (c) {
    return buildNumberProperty(Control_Category.categoryFn)("borderRadius")(BorderRadius.create(c))(UI_Style.writeNumber);
};
var borderTopEndRadius = function (c) {
    return buildNumberProperty(Control_Category.categoryFn)("borderTopEndRadius")(BorderTopEndRadius.create(c))(UI_Style.writeNumber);
};
var borderTopLeftRadius = function (c) {
    return buildNumberProperty(Control_Category.categoryFn)("borderTopLeftRadius")(BorderTopLeftRadius.create(c))(UI_Style.writeNumber);
};
var borderTopRightRadius = function (c) {
    return buildNumberProperty(Control_Category.categoryFn)("borderTopRightRadius")(BorderTopRightRadius.create(c))(UI_Style.writeNumber);
};
var borderTopStartRadius = function (c) {
    return buildNumberProperty(Control_Category.categoryFn)("borderTopStartRadius")(BorderTopStartRadius.create(c))(UI_Style.writeNumber);
};
var opacity = function (c) {
    return buildNumberProperty(Control_Category.categoryFn)("opacity")(Opacity.create(c))(UI_Style.writeNumber);
};
var buildStringProperty = function (dictCategory) {
    return function (dictShow) {
        return function (name) {
            return function (dsl) {
                return function (writer) {
                    var doValue = function (d) {
                        return function (wrtr) {
                            return Data_Functor.mapFlipped(Run.functorRun)(Control_Bind.bind(Run.bindRun)(Run.lift()(new Data_Symbol.IsSymbol(function () {
                                return "view";
                            }))(functorViewDSL)(_view)(d))(wrtr))(Data_Show.show(dictShow));
                        };
                    };
                    return Control_Bind.bind(Run.bindRun)(doValue(dsl(Control_Category.identity(dictCategory)))(writer))(function (value) {
                        return Run_Writer.tell(new UI_Style.StringProperty(name, value));
                    });
                };
            };
        };
    };
};
var backfaceVisibility = function (c) {
    return buildStringProperty(Control_Category.categoryFn)(UI_Style_PropTypes_Visibility.showVisibility)("backfaceVisibility")(BackfaceVisibility.create(c))(UI_Style.writeVisibility);
};
var backgroundColor = function (c) {
    return buildStringProperty(Control_Category.categoryFn)(UI_Style_PropTypes_Color.showColor)("backgroundColor")(BackgroundColor.create(c))(UI_Style.writeColor);
};
var borderBottomColor = function (c) {
    return buildStringProperty(Control_Category.categoryFn)(UI_Style_PropTypes_Color.showColor)("borderBottomColor")(BorderBottomColor.create(c))(UI_Style.writeColor);
};
var borderColor = function (c) {
    return buildStringProperty(Control_Category.categoryFn)(UI_Style_PropTypes_Color.showColor)("borderColor")(BorderColor.create(c))(UI_Style.writeColor);
};
var borderEndColor = function (c) {
    return buildStringProperty(Control_Category.categoryFn)(UI_Style_PropTypes_Color.showColor)("borderEndColor")(BorderEndColor.create(c))(UI_Style.writeColor);
};
var borderLeftColor = function (c) {
    return buildStringProperty(Control_Category.categoryFn)(UI_Style_PropTypes_Color.showColor)("borderLeftColor")(BorderLeftColor.create(c))(UI_Style.writeColor);
};
var borderRightColor = function (c) {
    return buildStringProperty(Control_Category.categoryFn)(UI_Style_PropTypes_Color.showColor)("borderRightColor")(BorderRightColor.create(c))(UI_Style.writeColor);
};
var borderStartColor = function (c) {
    return buildStringProperty(Control_Category.categoryFn)(UI_Style_PropTypes_Color.showColor)("borderStartColor")(BorderStartColor.create(c))(UI_Style.writeColor);
};
var borderStyle = function (c) {
    return buildStringProperty(Control_Category.categoryFn)(UI_Style_PropTypes_BorderStyle.showBorderStyle)("borderStyle")(BorderStyle.create(c))(UI_Style.writeBorderStyle);
};
var borderTopColor = function (c) {
    return buildStringProperty(Control_Category.categoryFn)(UI_Style_PropTypes_Color.showColor)("borderTopColor")(BorderTopColor.create(c))(UI_Style.writeColor);
};
var webOnly = function (name) {
    return function (value) {
        return buildStringProperty(Control_Category.categoryFn)(Data_Show.showString)(name)(WebOnly.create(value))(UI_Style.writeString);
    };
};
var runView = function (handler) {
    return Run.interpret(Run.monadRun)(Data_Functor_Variant.on()(new Data_Symbol.IsSymbol(function () {
        return "view";
    }))(_view)(handler)(Run.send));
};
module.exports = {
    _view: _view,
    BackfaceVisibility: BackfaceVisibility,
    BackgroundColor: BackgroundColor,
    BorderBottomColor: BorderBottomColor,
    BorderBottomEndRadius: BorderBottomEndRadius,
    BorderBottomLeftRadius: BorderBottomLeftRadius,
    BorderBottomRightRadius: BorderBottomRightRadius,
    BorderBottomStartRadius: BorderBottomStartRadius,
    BorderBottomWidth: BorderBottomWidth,
    BorderColor: BorderColor,
    BorderEndColor: BorderEndColor,
    BorderLeftColor: BorderLeftColor,
    BorderLeftWidth: BorderLeftWidth,
    BorderRadius: BorderRadius,
    BorderRightColor: BorderRightColor,
    BorderRightWidth: BorderRightWidth,
    BorderStartColor: BorderStartColor,
    BorderStyle: BorderStyle,
    BorderTopColor: BorderTopColor,
    BorderTopEndRadius: BorderTopEndRadius,
    BorderTopLeftRadius: BorderTopLeftRadius,
    BorderTopRightRadius: BorderTopRightRadius,
    BorderTopStartRadius: BorderTopStartRadius,
    BorderTopWidth: BorderTopWidth,
    BorderWidth: BorderWidth,
    Opacity: Opacity,
    WebOnly: WebOnly,
    runView: runView,
    handleView: handleView,
    backfaceVisibility: backfaceVisibility,
    backgroundColor: backgroundColor,
    borderBottomColor: borderBottomColor,
    borderBottomEndRadius: borderBottomEndRadius,
    borderBottomLeftRadius: borderBottomLeftRadius,
    borderBottomRightRadius: borderBottomRightRadius,
    borderBottomStartRadius: borderBottomStartRadius,
    borderBottomWidth: borderBottomWidth,
    borderColor: borderColor,
    borderEndColor: borderEndColor,
    borderLeftColor: borderLeftColor,
    borderLeftWidth: borderLeftWidth,
    borderRadius: borderRadius,
    borderRightColor: borderRightColor,
    borderRightWidth: borderRightWidth,
    borderStartColor: borderStartColor,
    borderStyle: borderStyle,
    borderTopColor: borderTopColor,
    borderTopEndRadius: borderTopEndRadius,
    borderTopLeftRadius: borderTopLeftRadius,
    borderTopRightRadius: borderTopRightRadius,
    borderTopStartRadius: borderTopStartRadius,
    borderTopWidth: borderTopWidth,
    borderWidth: borderWidth,
    opacity: opacity,
    webOnly: webOnly,
    functorViewDSL: functorViewDSL
};
