// Generated by purs version 0.12.0
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var Core_Data = require("../Core.Data/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Lens = require("../Data.Lens/index.js");
var Data_Lens_Getter = require("../Data.Lens.Getter/index.js");
var Data_Lens_Internal_Forget = require("../Data.Lens.Internal.Forget/index.js");
var Prelude = require("../Prelude/index.js");
var Run = require("../Run/index.js");
var UI_App_Common = require("../UI.App.Common/index.js");
var UI_App_Tiles_Tab = require("../UI.App.Tiles.Tab/index.js");
var UI_App_Tiles_TileSettingsButton = require("../UI.App.Tiles.TileSettingsButton/index.js");
var UI_Components = require("../UI.Components/index.js");
var UI_Style = require("../UI.Style/index.js");
var UI_Style_Layout = require("../UI.Style.Layout/index.js");
var UI_Style_PropTypes_Display = require("../UI.Style.PropTypes.Display/index.js");
var UI_Style_PropTypes_JustifyContent = require("../UI.Style.PropTypes.JustifyContent/index.js");
var style = function (v) {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.flex(1.0))(function () {
        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)((function () {
            if (v.tile.showNav) {
                return UI_Style_Layout.display(UI_Style_PropTypes_Display.Flex.value);
            };
            return UI_Style_Layout.display(UI_Style_PropTypes_Display.None.value);
        })())(function () {
            return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.justifyContent(UI_Style_PropTypes_JustifyContent.SpaceBetween.value))(function () {
                return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.maxHeight(new UI_Style.Pixel(25)))(function () {
                    return UI_App_Common.outlineSpec(0)(0)(2)(0);
                });
            });
        });
    });
};
var render = function (v) {
    return Control_Bind.bind(Run.bindRun)(UI_App_Tiles_Tab.component({
        tile: v.tile,
        isFocus: v.isFocus
    }))(function (v1) {
        return Control_Bind.bind(Run.bindRun)(UI_App_Tiles_TileSettingsButton.component({
            tileId: Data_Lens_Getter.viewOn(v.tile)(function ($14) {
                return Core_Data._Tile(Data_Lens_Internal_Forget.strongForget)(Core_Data._id(Data_Lens_Internal_Forget.strongForget)($14));
            }),
            publish: v.publish
        }))(function (v2) {
            return UI_Components.nav({
                style: style(v)
            })(UI_Components.fragment([ v1, v2 ]));
        });
    });
};
var component = function (props) {
    return UI_Components.stateless("TileNavBar")(props)(render);
};
module.exports = {
    component: component,
    render: render,
    style: style
};
