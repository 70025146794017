// Generated by purs version 0.12.0
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var Core_Data = require("../Core.Data/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Lens = require("../Data.Lens/index.js");
var Data_Lens_Fold = require("../Data.Lens.Fold/index.js");
var Data_Lens_Getter = require("../Data.Lens.Getter/index.js");
var Data_Lens_Internal_Forget = require("../Data.Lens.Internal.Forget/index.js");
var Data_Lens_Prism_Maybe = require("../Data.Lens.Prism.Maybe/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Maybe_First = require("../Data.Maybe.First/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Data_Tree_Zipper = require("../Data.Tree.Zipper/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Prelude = require("../Prelude/index.js");
var Run = require("../Run/index.js");
var UI_App_Common = require("../UI.App.Common/index.js");
var UI_App_Tiles_TileNavBar = require("../UI.App.Tiles.TileNavBar/index.js");
var UI_Components = require("../UI.Components/index.js");
var UI_Event_NavigationEvent = require("../UI.Event.NavigationEvent/index.js");
var UI_Style_Layout = require("../UI.Style.Layout/index.js");
var UI_Style_PropTypes_Display = require("../UI.Style.PropTypes.Display/index.js");
var UI_Style_PropTypes_FlexDirection = require("../UI.Style.PropTypes.FlexDirection/index.js");
var UI_Style_View = require("../UI.Style.View/index.js");
var updateLayoutChain = function (tiles) {
    return function (v) {
        var tile = Data_Lens_Getter.view(Core_Data._Tile(Data_Lens_Internal_Forget.strongForget))(Core_Data.firstTileInTiles(tiles));
        return v + ("," + (Data_Show.show(Core_Data.showLayout)(tile.layout) + Data_Show.show(Data_Show.showBoolean)(tile.showNav)));
    };
};
var tileSectionStyle = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.display(UI_Style_PropTypes_Display.Flex.value))(function () {
    return UI_Style_Layout.flex(1.0);
});
var tileRecFromLoc = function (loc) {
    return Data_Lens_Getter.view(Core_Data._Tile(Data_Lens_Internal_Forget.strongForget))(Data_Tree_Zipper.value(loc));
};
var tileWrapperStyle = function (loc) {
    return function (focusTile) {
        return Control_Bind.bind(Run.bindRun)(Control_Applicative.pure(Run.applicativeRun)(tileRecFromLoc(loc)))(function (v) {
            return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_App_Common.panel)(function () {
                return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.flexDirection(UI_Style_PropTypes_FlexDirection.Column.value))(function () {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.flexGrow(v.grow))(function () {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)((function () {
                            var $15 = Core_Data.isLeaf(loc) || Data_Eq.eq(Core_Data.tileIdEq)(v.id)(focusTile);
                            if ($15) {
                                return UI_App_Common.outlineSpec(1)(1)(1)(1);
                            };
                            return Control_Applicative.pure(Run.applicativeRun)(Data_Unit.unit);
                        })())(function () {
                            var $16 = Data_Eq.eq(Core_Data.tileIdEq)(v.id)(focusTile);
                            if ($16) {
                                return UI_Style_View.borderColor(UI_App_Common.yellow);
                            };
                            return Control_Applicative.pure(Run.applicativeRun)(Data_Unit.unit);
                        });
                    });
                });
            });
        });
    };
};
var tileBodyStyle = function (loc) {
    return Control_Bind.bind(Run.bindRun)(Control_Applicative.pure(Run.applicativeRun)(tileRecFromLoc(loc)))(function (v) {
        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_App_Common.panel)(function () {
            var $18 = Data_Eq.eq(Core_Data.layoutEq)(v.layout)(Core_Data.Row.value);
            if ($18) {
                return UI_Style_Layout.flexDirection(UI_Style_PropTypes_FlexDirection.Row.value);
            };
            return UI_Style_Layout.flexDirection(UI_Style_PropTypes_FlexDirection.Column.value);
        });
    });
};
var onClick = function (tileId) {
    return function (publish) {
        return publish.navigation(new UI_Event_NavigationEvent.FocusTile(tileId));
    };
};
var getStubChartOrNot = function (v) {
    return function (layoutChain) {
        return function (siblingGrows) {
            var v1 = Data_Lens_Fold.preview(function ($37) {
                return Data_Lens_Prism_Maybe._Just(Data_Lens_Internal_Forget.choiceForget(Data_Maybe_First.monoidFirst))(Core_Data._Widget(Data_Lens_Internal_Forget.strongForget)(Core_Data._title(Data_Lens_Internal_Forget.strongForget)($37)));
            })(v.widget);
            if (v1 instanceof Data_Maybe.Nothing) {
                return UI_Components.text("");
            };
            if (v1 instanceof Data_Maybe.Just) {
                return Control_Bind.bindFlipped(Run.bindRun)(UI_Components.chart({
                    style: UI_Style_Layout.flex(1.0),
                    type: Data_Maybe.fromMaybe("")(Core_Data.getWidgetTitle(v)),
                    layoutChain: layoutChain,
                    siblingGrows: siblingGrows
                }))(UI_Components.text("placeholder"));
            };
            throw new Error("Failed pattern match at UI.App.Tiles line 111, column 3 - line 113, column 138: " + [ v1.constructor.name ]);
        };
    };
};
var getSiblingGrows = function (tiles) {
    return function (v) {
        var v1 = Data_Foldable.intercalate(Data_List_Types.foldableList)(Data_Monoid.monoidString)(",")(Data_Functor.mapFlipped(Data_List_Types.functorList)(Core_Data.childTiles(Core_Data.firstTileLoc(tiles)))(function (v2) {
            return Data_Show.show(Data_Show.showNumber)(v2.grow);
        }));
        return v + ("," + v1);
    };
};
var renderTile = function (publish) {
    return function (focusTile) {
        return function (layoutChain) {
            return function (siblingGrows) {
                return function (tiles) {
                    var thisTileLoc = Core_Data.firstTileLoc(tiles);
                    var thisTile = Data_Tree_Zipper.value(thisTileLoc);
                    var newSiblingGrows = getSiblingGrows(tiles)(siblingGrows);
                    var newLayoutChain = updateLayoutChain(tiles)(layoutChain);
                    var tileChildren = Data_Functor.map(Run.functorRun)(UI_Components.fragment)(Data_Traversable.sequence(Data_Traversable.traversableArray)(Run.applicativeRun)(Data_Array.fromFoldable(Data_List_Types.foldableList)(Data_Functor.mapFlipped(Data_List_Types.functorList)(Data_Tree_Zipper.children(Data_Tree_Zipper.fromTree(tiles)))(renderTile(publish)(focusTile)(newLayoutChain)(newSiblingGrows)))));
                    var isFocus = Data_Eq.eq(Core_Data.tileIdEq)(Data_Lens_Getter.viewOn(thisTile)(function ($38) {
                        return Core_Data._Tile(Data_Lens_Internal_Forget.strongForget)(Core_Data._id(Data_Lens_Internal_Forget.strongForget)($38));
                    }))(focusTile);
                    var child = (function () {
                        if (Core_Data.isLeaf(Data_Tree_Zipper.fromTree(tiles))) {
                            return getStubChartOrNot(thisTile)(newLayoutChain)(siblingGrows);
                        };
                        return tileChildren;
                    })();
                    return Control_Bind.bind(Run.bindRun)(UI_App_Tiles_TileNavBar.component({
                        tile: Core_Data.firstTileInTiles(tiles),
                        isFocus: isFocus,
                        publish: publish
                    }))(function (v) {
                        return Control_Bind.bind(Run.bindRun)(Control_Bind.bindFlipped(Run.bindRun)(UI_Components.section({
                            style: tileBodyStyle(thisTileLoc)
                        }))(child))(function (v1) {
                            return Control_Bind.bind(Run.bindRun)(UI_Components.div({
                                style: tileWrapperStyle(thisTileLoc)(focusTile)
                            })(UI_Components.fragment([ v, v1 ])))(function (v2) {
                                var $31 = Core_Data.isLeaf(thisTileLoc);
                                if ($31) {
                                    return UI_Components.makeTouchable(onClick((tileRecFromLoc(thisTileLoc)).id)(publish))(v2);
                                };
                                return Control_Applicative.pure(Run.applicativeRun)(v2);
                            });
                        });
                    });
                };
            };
        };
    };
};
var render = function (v) {
    var siblingGrows = getSiblingGrows(v.tiles)("root");
    return renderTile(v.publish)(v.focusTile)("root")(siblingGrows)(v.tiles);
};
var component = function (props) {
    return UI_Components.stateless("Tiles")(props)(render);
};
module.exports = {
    component: component,
    render: render,
    renderTile: renderTile,
    updateLayoutChain: updateLayoutChain,
    getSiblingGrows: getSiblingGrows,
    onClick: onClick,
    tileBodyStyle: tileBodyStyle,
    tileWrapperStyle: tileWrapperStyle,
    tileSectionStyle: tileSectionStyle,
    tileRecFromLoc: tileRecFromLoc,
    getStubChartOrNot: getStubChartOrNot
};
