// Generated by purs version 0.12.0
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Core_Data = require("../Core.Data/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Prelude = require("../Prelude/index.js");
var UI_Data_AppData = require("../UI.Data.AppData/index.js");
var UI_Data_LightBoxProps = require("../UI.Data.LightBoxProps/index.js");
var UI_Data_Navigation = require("../UI.Data.Navigation/index.js");
var UI_Event_ModelEvent = require("../UI.Event.ModelEvent/index.js");
var FocusWorkspace = (function () {
    function FocusWorkspace(value0) {
        this.value0 = value0;
    };
    FocusWorkspace.create = function (value0) {
        return new FocusWorkspace(value0);
    };
    return FocusWorkspace;
})();
var FocusDashboard = (function () {
    function FocusDashboard(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    FocusDashboard.create = function (value0) {
        return function (value1) {
            return new FocusDashboard(value0, value1);
        };
    };
    return FocusDashboard;
})();
var FocusTile = (function () {
    function FocusTile(value0) {
        this.value0 = value0;
    };
    FocusTile.create = function (value0) {
        return new FocusTile(value0);
    };
    return FocusTile;
})();
var FocusNextTile = (function () {
    function FocusNextTile() {

    };
    FocusNextTile.value = new FocusNextTile();
    return FocusNextTile;
})();
var FocusPreviousTile = (function () {
    function FocusPreviousTile() {

    };
    FocusPreviousTile.value = new FocusPreviousTile();
    return FocusPreviousTile;
})();
var ShowLightBox = (function () {
    function ShowLightBox(value0) {
        this.value0 = value0;
    };
    ShowLightBox.create = function (value0) {
        return new ShowLightBox(value0);
    };
    return ShowLightBox;
})();
var HideLightBox = (function () {
    function HideLightBox() {

    };
    HideLightBox.value = new HideLightBox();
    return HideLightBox;
})();
var NavigationError = (function () {
    function NavigationError() {

    };
    NavigationError.value = new NavigationError();
    return NavigationError;
})();
var showLightBox = function (lightBox) {
    return function (v) {
        return new Data_Either.Right({
            navigation: new UI_Data_Navigation.AppNav({
                modelId: v.navigation.value0.modelId,
                selections: v.navigation.value0.selections,
                lightBox: lightBox
            }),
            model: v.model
        });
    };
};
var hideLightBox = function (v) {
    return new Data_Either.Right({
        navigation: new UI_Data_Navigation.AppNav({
            modelId: v.navigation.value0.modelId,
            selections: v.navigation.value0.selections,
            lightBox: {
                show: false,
                title: v.navigation.value0.lightBox.title,
                child: v.navigation.value0.lightBox.child
            }
        }),
        model: v.model
    });
};
var handleNavigationEvent = function (v) {
    return function (appData) {
        if (v instanceof FocusWorkspace) {
            return Data_Functor.mapFlipped(Data_Either.functorEither)(Core_Data.focusWorkspace(v.value0)(appData.model))(UI_Event_ModelEvent.updateAppData(appData));
        };
        if (v instanceof FocusDashboard) {
            return Data_Functor.mapFlipped(Data_Either.functorEither)(Core_Data.focusDashboard(v.value1)(appData.model))(UI_Event_ModelEvent.updateAppData(appData));
        };
        if (v instanceof FocusTile) {
            return Data_Functor.mapFlipped(Data_Either.functorEither)(Core_Data.focusTile(v.value0)(appData.model))(UI_Event_ModelEvent.updateAppData(appData));
        };
        if (v instanceof FocusNextTile) {
            return Data_Functor.mapFlipped(Data_Either.functorEither)(Control_Bind.bind(Data_Either.bindEither)(Core_Data.nextTile(appData.model)(UI_Event_ModelEvent.getFocusTile(appData.model)))(function (id) {
                return Core_Data.focusTile(id)(appData.model);
            }))(UI_Event_ModelEvent.updateAppData(appData));
        };
        if (v instanceof FocusPreviousTile) {
            return Data_Functor.mapFlipped(Data_Either.functorEither)(Control_Bind.bind(Data_Either.bindEither)(Core_Data.previousTile(appData.model)(UI_Event_ModelEvent.getFocusTile(appData.model)))(function (id) {
                return Core_Data.focusTile(id)(appData.model);
            }))(UI_Event_ModelEvent.updateAppData(appData));
        };
        if (v instanceof ShowLightBox) {
            return showLightBox(v.value0)(appData);
        };
        if (v instanceof HideLightBox) {
            return hideLightBox(appData);
        };
        throw new Error("Failed pattern match at UI.Event.NavigationEvent line 25, column 1 - line 25, column 81: " + [ v.constructor.name, appData.constructor.name ]);
    };
};
module.exports = {
    FocusWorkspace: FocusWorkspace,
    FocusDashboard: FocusDashboard,
    FocusTile: FocusTile,
    FocusNextTile: FocusNextTile,
    FocusPreviousTile: FocusPreviousTile,
    ShowLightBox: ShowLightBox,
    HideLightBox: HideLightBox,
    NavigationError: NavigationError,
    handleNavigationEvent: handleNavigationEvent,
    showLightBox: showLightBox,
    hideLightBox: hideLightBox
};
