// Generated by purs version 0.12.0
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Prelude = require("../Prelude/index.js");
var Run = require("../Run/index.js");
var UI_App_Common = require("../UI.App.Common/index.js");
var UI_App_Common_Text = require("../UI.App.Common.Text/index.js");
var UI_Components = require("../UI.Components/index.js");
var UI_Style_View = require("../UI.Style.View/index.js");
var style = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_App_Common.outlineSpec(1)(1)(1)(1))(function () {
    return UI_Style_View.borderColor(UI_App_Common.white1);
});
var render = function (props) {
    return Control_Bind.bind(Run.bindRun)(UI_App_Common_Text.whiteH2({
        text: props.label
    }))(function (v) {
        return Control_Bind.bind(Run.bindRun)(props.child)(function (v1) {
            return UI_Components.section({
                style: style
            })(UI_Components.fragment([ v, v1 ]));
        });
    });
};
var component = function (props) {
    return UI_Components.stateless("FieldSet")(props)(render);
};
module.exports = {
    component: component,
    render: render,
    style: style
};
