// Generated by purs version 0.12.0
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_String = require("../Data.String/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Prelude = require("../Prelude/index.js");
var Run = require("../Run/index.js");
var UI_App_Common = require("../UI.App.Common/index.js");
var UI_Components = require("../UI.Components/index.js");
var UI_Style = require("../UI.Style/index.js");
var UI_Style_Layout = require("../UI.Style.Layout/index.js");
var UI_Style_PropTypes_AlignItems = require("../UI.Style.PropTypes.AlignItems/index.js");
var UI_Style_PropTypes_Display = require("../UI.Style.PropTypes.Display/index.js");
var UI_Style_PropTypes_FlexDirection = require("../UI.Style.PropTypes.FlexDirection/index.js");
var UI_Style_PropTypes_FlexWrap = require("../UI.Style.PropTypes.FlexWrap/index.js");
var UI_Style_PropTypes_JustifyContent = require("../UI.Style.PropTypes.JustifyContent/index.js");
var UI_Style_PropTypes_TextOverflow = require("../UI.Style.PropTypes.TextOverflow/index.js");
var UI_Style_Text = require("../UI.Style.Text/index.js");
var UI_Style_View = require("../UI.Style.View/index.js");
var textOnWhite = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Text.color(UI_App_Common.dark2))(function () {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.display(UI_Style_PropTypes_Display.Flex.value))(function () {
        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.flexDirection(UI_Style_PropTypes_FlexDirection.Column.value))(function () {
            return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.flexWrap(UI_Style_PropTypes_FlexWrap.Wrap.value))(function () {
                return UI_Style_Text.textOverflow(UI_Style_PropTypes_TextOverflow.Ellipsis.value);
            });
        });
    });
});
var whiteH1Style = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(textOnWhite)(function () {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_View.backgroundColor(UI_App_Common.white1))(function () {
        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.alignItems(UI_Style_PropTypes_AlignItems.Center.value))(function () {
            return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Text.fontSize(16.0))(function () {
                return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.height(new UI_Style.Pixel(32)))(function () {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.justifyContent(UI_Style_PropTypes_JustifyContent.Center.value))(function () {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.margin(new UI_Style.Pixel(0)))(function () {
                            return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.padding(new UI_Style.Pixel(10)))(function () {
                                return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.maxHeight(new UI_Style.Pixel(32)))(function () {
                                    return UI_Style_Layout.minHeight(new UI_Style.Pixel(32));
                                });
                            });
                        });
                    });
                });
            });
        });
    });
});
var whiteH1 = function (props) {
    return Control_Bind.bindFlipped(Run.bindRun)(UI_Components.h1({
        style: whiteH1Style
    }))(UI_Components.text(props.text));
};
var whiteH2Style = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(textOnWhite)(function () {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_View.backgroundColor(UI_App_Common.white2))(function () {
        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.alignItems(UI_Style_PropTypes_AlignItems.FlexStart.value))(function () {
            return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Text.fontSize(12.0))(function () {
                return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.justifyContent(UI_Style_PropTypes_JustifyContent.FlexStart.value))(function () {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.margin(new UI_Style.Pixel(0)))(function () {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.padding(new UI_Style.Pixel(5)))(function () {
                            return UI_Style_Text.color(UI_App_Common.gray2);
                        });
                    });
                });
            });
        });
    });
});
var whiteH2 = function (props) {
    return Control_Bind.bindFlipped(Run.bindRun)(UI_Components.h2({
        style: whiteH2Style
    }))(UI_Components.text(Data_String_Common.toUpper(props.text)));
};
var mainHeaderStyle = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_App_Common.standardText)(function () {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.alignItems(UI_Style_PropTypes_AlignItems.Center.value))(function () {
        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Text.color(UI_App_Common.white2))(function () {
            return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.display(UI_Style_PropTypes_Display.Flex.value))(function () {
                return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.flexWrap(UI_Style_PropTypes_FlexWrap.Wrap.value))(function () {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Text.fontSize(12.0))(function () {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.height(new UI_Style.Pixel(32)))(function () {
                            return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.margin(new UI_Style.Pixel(0)))(function () {
                                return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.padding(new UI_Style.Pixel(10)))(function () {
                                    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.maxHeight(new UI_Style.Pixel(32)))(function () {
                                        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.minHeight(new UI_Style.Pixel(32)))(function () {
                                            return UI_Style_Text.textOverflow(UI_Style_PropTypes_TextOverflow.Ellipsis.value);
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });
        });
    });
});
var divStyle = UI_App_Common.outlineSpec(0)(0)(2)(0);
var render = function (words) {
    return Control_Bind.bind(Run.bindRun)(Control_Bind.bindFlipped(Run.bindRun)(UI_Components.h1({
        style: mainHeaderStyle
    }))(UI_Components.text(words.text)))(function (v) {
        return UI_Components.div({
            style: divStyle
        })(v);
    });
};
var mainHeader = function (props) {
    return UI_Components.stateless("MainHeader")(props)(render);
};
module.exports = {
    mainHeader: mainHeader,
    render: render,
    divStyle: divStyle,
    mainHeaderStyle: mainHeaderStyle,
    whiteH1: whiteH1,
    textOnWhite: textOnWhite,
    whiteH1Style: whiteH1Style,
    whiteH2: whiteH2,
    whiteH2Style: whiteH2Style
};
