// Generated by purs version 0.12.0
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var Core_Data = require("../Core.Data/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Lens = require("../Data.Lens/index.js");
var Data_Lens_Getter = require("../Data.Lens.Getter/index.js");
var Data_Lens_Internal_Forget = require("../Data.Lens.Internal.Forget/index.js");
var Data_List = require("../Data.List/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Effect = require("../Effect/index.js");
var Prelude = require("../Prelude/index.js");
var Run = require("../Run/index.js");
var UI_App_Common = require("../UI.App.Common/index.js");
var UI_App_Common_Images = require("../UI.App.Common.Images/index.js");
var UI_App_Tiles_TileProperties = require("../UI.App.Tiles.TileProperties/index.js");
var UI_Components = require("../UI.Components/index.js");
var UI_Event_NavigationEvent = require("../UI.Event.NavigationEvent/index.js");
var UI_Style = require("../UI.Style/index.js");
var UI_Style_Layout = require("../UI.Style.Layout/index.js");
var UI_Style_PropTypes_AlignContent = require("../UI.Style.PropTypes.AlignContent/index.js");
var UI_Style_PropTypes_AlignItems = require("../UI.Style.PropTypes.AlignItems/index.js");
var UI_Style_PropTypes_AlignSelf = require("../UI.Style.PropTypes.AlignSelf/index.js");
var UI_Style_PropTypes_Display = require("../UI.Style.PropTypes.Display/index.js");
var UI_Style_PropTypes_JustifyContent = require("../UI.Style.PropTypes.JustifyContent/index.js");
var style = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_App_Common.outlineSpec(0)(0)(0)(2))(function () {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.display(UI_Style_PropTypes_Display.Flex.value))(function () {
        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.paddingRight(new UI_Style.Pixel(5)))(function () {
            return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.paddingLeft(new UI_Style.Pixel(5)))(function () {
                return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.alignContent(UI_Style_PropTypes_AlignContent.Center.value))(function () {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.alignItems(UI_Style_PropTypes_AlignItems.Center.value))(function () {
                        return UI_Style_Layout.justifyContent(UI_Style_PropTypes_JustifyContent.Center.value);
                    });
                });
            });
        });
    });
});
var onClick = function (publish) {
    return function (tileId) {
        var child = Data_Maybe.Just.create(function (model) {
            var propsEither = Control_Bind.bind(Data_Either.bindEither)(Core_Data.getTile(tileId)(model))(function (v) {
                return Control_Bind.bind(Data_Either.bindEither)(Data_Functor.mapFlipped(Data_Either.functorEither)(Core_Data.getDashboardFromTileId(model)(tileId))(Data_Lens_Getter.view(function ($21) {
                    return Core_Data._Dashboard(Data_Lens_Internal_Forget.strongForget)(Core_Data._id(Data_Lens_Internal_Forget.strongForget)($21));
                })))(function (v1) {
                    return Control_Bind.bind(Data_Either.bindEither)(Core_Data.getTileLoc(tileId)(model))(function (v2) {
                        return Control_Bind.bind(Data_Either.bindEither)(Control_Applicative.pure(Data_Either.applicativeEither)(Data_List.length(Core_Data.childTiles(v2))))(function (v3) {
                            return Control_Bind.bind(Data_Either.bindEither)(Control_Applicative.pure(Data_Either.applicativeEither)(Data_List.filter(function (v4) {
                                return Data_Eq.notEq(Core_Data.tileIdEq)(v4.id)(tileId);
                            })(Core_Data.siblingTiles(v2))))(function (v4) {
                                return Control_Applicative.pure(Data_Either.applicativeEither)({
                                    tile: v,
                                    dashboardId: v1,
                                    publish: publish,
                                    childCount: v3,
                                    siblings: v4
                                });
                            });
                        });
                    });
                });
            });
            if (propsEither instanceof Data_Either.Right) {
                return UI_App_Tiles_TileProperties.component(propsEither.value0);
            };
            if (propsEither instanceof Data_Either.Left) {
                return UI_Components.text("Tile not Found");
            };
            throw new Error("Failed pattern match at UI.App.Tiles.TileSettingsButton line 53, column 19 - line 55, column 59: " + [ propsEither.constructor.name ]);
        });
        return publish.navigation(new UI_Event_NavigationEvent.ShowLightBox({
            show: true,
            title: new Data_Maybe.Just("Tile Properties"),
            child: child
        }));
    };
};
var render = function (v) {
    return Control_Bind.bind(Run.bindRun)(UI_App_Common_Images.cogIcon({
        additionalStyle: UI_Style_Layout.alignSelf(UI_Style_PropTypes_AlignSelf.Center.value)
    }))(function (v1) {
        return Control_Bind.bind(Run.bindRun)(UI_Components.div({
            style: style
        })(v1))(UI_Components.makeTouchable(onClick(v.publish)(v.tileId)));
    });
};
var component = function (props) {
    return UI_Components.stateless("TileSettingsButton")(props)(render);
};
module.exports = {
    component: component,
    render: render,
    onClick: onClick,
    style: style
};
