// Generated by purs version 0.12.0
"use strict";
var Data_Show = require("../Data.Show/index.js");
var Prelude = require("../Prelude/index.js");
var Flex = (function () {
    function Flex() {

    };
    Flex.value = new Flex();
    return Flex;
})();
var None = (function () {
    function None() {

    };
    None.value = new None();
    return None;
})();
var showDisplay = new Data_Show.Show(function (v) {
    if (v instanceof Flex) {
        return "flex";
    };
    if (v instanceof None) {
        return "none";
    };
    throw new Error("Failed pattern match at UI.Style.PropTypes.Display line 7, column 1 - line 7, column 37: " + [ v.constructor.name ]);
});
module.exports = {
    Flex: Flex,
    None: None,
    showDisplay: showDisplay
};
