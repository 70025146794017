// Generated by purs version 0.12.0
"use strict";
var Data_Show = require("../Data.Show/index.js");
var Prelude = require("../Prelude/index.js");
var Normal = (function () {
    function Normal() {

    };
    Normal.value = new Normal();
    return Normal;
})();
var Bold = (function () {
    function Bold() {

    };
    Bold.value = new Bold();
    return Bold;
})();
var OneHundred = (function () {
    function OneHundred() {

    };
    OneHundred.value = new OneHundred();
    return OneHundred;
})();
var TwoHundred = (function () {
    function TwoHundred() {

    };
    TwoHundred.value = new TwoHundred();
    return TwoHundred;
})();
var ThreeHundred = (function () {
    function ThreeHundred() {

    };
    ThreeHundred.value = new ThreeHundred();
    return ThreeHundred;
})();
var FourHundred = (function () {
    function FourHundred() {

    };
    FourHundred.value = new FourHundred();
    return FourHundred;
})();
var FiveHundred = (function () {
    function FiveHundred() {

    };
    FiveHundred.value = new FiveHundred();
    return FiveHundred;
})();
var SixHundred = (function () {
    function SixHundred() {

    };
    SixHundred.value = new SixHundred();
    return SixHundred;
})();
var SevenHundred = (function () {
    function SevenHundred() {

    };
    SevenHundred.value = new SevenHundred();
    return SevenHundred;
})();
var EightHundred = (function () {
    function EightHundred() {

    };
    EightHundred.value = new EightHundred();
    return EightHundred;
})();
var NineHundred = (function () {
    function NineHundred() {

    };
    NineHundred.value = new NineHundred();
    return NineHundred;
})();
var showFontWeight = new Data_Show.Show(function (v) {
    if (v instanceof Normal) {
        return "normal";
    };
    if (v instanceof Bold) {
        return "bold";
    };
    if (v instanceof OneHundred) {
        return "100";
    };
    if (v instanceof TwoHundred) {
        return "200";
    };
    if (v instanceof ThreeHundred) {
        return "300";
    };
    if (v instanceof FourHundred) {
        return "400";
    };
    if (v instanceof FiveHundred) {
        return "500";
    };
    if (v instanceof SixHundred) {
        return "600";
    };
    if (v instanceof SevenHundred) {
        return "700";
    };
    if (v instanceof EightHundred) {
        return "800";
    };
    if (v instanceof NineHundred) {
        return "900";
    };
    throw new Error("Failed pattern match at UI.Style.PropTypes.FontWeight line 7, column 1 - line 7, column 43: " + [ v.constructor.name ]);
});
module.exports = {
    Normal: Normal,
    Bold: Bold,
    OneHundred: OneHundred,
    TwoHundred: TwoHundred,
    ThreeHundred: ThreeHundred,
    FourHundred: FourHundred,
    FiveHundred: FiveHundred,
    SixHundred: SixHundred,
    SevenHundred: SevenHundred,
    EightHundred: EightHundred,
    NineHundred: NineHundred,
    showFontWeight: showFontWeight
};
