// Generated by purs version 0.12.0
"use strict";
var Data_Show = require("../Data.Show/index.js");
var Prelude = require("../Prelude/index.js");
var FlexStart = (function () {
    function FlexStart() {

    };
    FlexStart.value = new FlexStart();
    return FlexStart;
})();
var FlexEnd = (function () {
    function FlexEnd() {

    };
    FlexEnd.value = new FlexEnd();
    return FlexEnd;
})();
var Center = (function () {
    function Center() {

    };
    Center.value = new Center();
    return Center;
})();
var SpaceBetween = (function () {
    function SpaceBetween() {

    };
    SpaceBetween.value = new SpaceBetween();
    return SpaceBetween;
})();
var SpaceAround = (function () {
    function SpaceAround() {

    };
    SpaceAround.value = new SpaceAround();
    return SpaceAround;
})();
var SpaceEvenly = (function () {
    function SpaceEvenly() {

    };
    SpaceEvenly.value = new SpaceEvenly();
    return SpaceEvenly;
})();
var showJustifyContent = new Data_Show.Show(function (v) {
    if (v instanceof FlexStart) {
        return "flex-start";
    };
    if (v instanceof FlexEnd) {
        return "flex-end";
    };
    if (v instanceof Center) {
        return "center";
    };
    if (v instanceof SpaceBetween) {
        return "space-between";
    };
    if (v instanceof SpaceAround) {
        return "space-around";
    };
    if (v instanceof SpaceEvenly) {
        return "space-evenly";
    };
    throw new Error("Failed pattern match at UI.Style.PropTypes.JustifyContent line 7, column 1 - line 7, column 51: " + [ v.constructor.name ]);
});
module.exports = {
    FlexStart: FlexStart,
    FlexEnd: FlexEnd,
    Center: Center,
    SpaceBetween: SpaceBetween,
    SpaceAround: SpaceAround,
    SpaceEvenly: SpaceEvenly,
    showJustifyContent: showJustifyContent
};
