// Generated by purs version 0.12.0
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Monad_State = require("../Control.Monad.State/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Control_Monad_State_Trans = require("../Control.Monad.State.Trans/index.js");
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Functor_Compose = require("../Data.Functor.Compose/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Lens_Internal_Indexed = require("../Data.Lens.Internal.Indexed/index.js");
var Data_Lens_Internal_Wander = require("../Data.Lens.Internal.Wander/index.js");
var Data_Lens_Iso_Newtype = require("../Data.Lens.Iso.Newtype/index.js");
var Data_Lens_Setter = require("../Data.Lens.Setter/index.js");
var Data_Lens_Types = require("../Data.Lens.Types/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Profunctor = require("../Data.Profunctor/index.js");
var Data_Profunctor_Star = require("../Data.Profunctor.Star/index.js");
var Data_Profunctor_Strong = require("../Data.Profunctor.Strong/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_TraversableWithIndex = require("../Data.TraversableWithIndex/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Prelude = require("../Prelude/index.js");
var unIndex = function (dictProfunctor) {
    return function (l) {
        return function ($12) {
            return l(Data_Lens_Internal_Indexed.Indexed(Data_Profunctor.dimap(dictProfunctor)(Data_Tuple.snd)(Control_Category.identity(Control_Category.categoryFn))($12)));
        };
    };
};
var reindexed = function (dictProfunctor) {
    return function (ij) {
        return function (v) {
            return function ($13) {
                return v(Data_Lens_Setter.over(Data_Lens_Iso_Newtype._Newtype(Data_Lens_Internal_Indexed.newtypeIndexed)(Data_Lens_Internal_Indexed.newtypeIndexed)(Data_Profunctor.profunctorFn))(Data_Profunctor.lcmap(dictProfunctor)(Data_Profunctor_Strong.first(Data_Profunctor_Strong.strongFn)(ij)))($13));
            };
        };
    };
};
var iwander = function (itr) {
    return function (dictWander) {
        return function ($14) {
            return Data_Lens_Internal_Wander.wander(dictWander)(function (dictApplicative) {
                return function (f) {
                    return function (s) {
                        return itr(dictApplicative)(Data_Tuple.curry(f))(s);
                    };
                };
            })(Data_Newtype.unwrap(Data_Lens_Internal_Indexed.newtypeIndexed)($14));
        };
    };
};
var positions = function (tr) {
    return function (dictWander) {
        return iwander(function (dictApplicative) {
            return function (f) {
                return function (s) {
                    return Data_Function.flip(Control_Monad_State.evalState)(0)(Data_Newtype.unwrap(Data_Functor_Compose.newtypeCompose)(Data_Function.flip(Data_Newtype.unwrap(Data_Profunctor_Star.newtypeStar))(s)(tr(Data_Lens_Internal_Wander.wanderStar(Data_Functor_Compose.applicativeCompose(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity))(dictApplicative)))(function (a) {
                        return Data_Functor_Compose.Compose(Control_Apply.applyFirst(Control_Monad_State_Trans.applyStateT(Data_Identity.monadIdentity))(Control_Apply.apply(Control_Monad_State_Trans.applyStateT(Data_Identity.monadIdentity))(Data_Functor.map(Control_Monad_State_Trans.functorStateT(Data_Identity.functorIdentity))(f)(Control_Monad_State_Class.get(Control_Monad_State_Trans.monadStateStateT(Data_Identity.monadIdentity))))(Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity))(a)))(Control_Monad_State_Class.modify(Control_Monad_State_Trans.monadStateStateT(Data_Identity.monadIdentity))(function (v) {
                            return v + 1 | 0;
                        })));
                    }))));
                };
            };
        })(dictWander);
    };
};
var itraversed = function (dictTraversableWithIndex) {
    return function (dictWander) {
        return iwander(function (dictApplicative) {
            return Data_TraversableWithIndex.traverseWithIndex(dictTraversableWithIndex)(dictApplicative);
        })(dictWander);
    };
};
var asIndex = function (dictProfunctor) {
    return function (l) {
        return function ($15) {
            return l(Data_Lens_Internal_Indexed.Indexed(Data_Profunctor.dimap(dictProfunctor)(Data_Tuple.fst)(Control_Category.identity(Control_Category.categoryFn))($15)));
        };
    };
};
module.exports = {
    unIndex: unIndex,
    asIndex: asIndex,
    reindexed: reindexed,
    iwander: iwander,
    itraversed: itraversed,
    positions: positions
};
