// Generated by purs version 0.12.0
"use strict";
var Prelude = require("../Prelude/index.js");
var MonadTrans = function (lift) {
    this.lift = lift;
};
var lift = function (dict) {
    return dict.lift;
};
module.exports = {
    lift: lift,
    MonadTrans: MonadTrans
};
