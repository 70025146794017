// Generated by purs version 0.12.0
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Core_Data = require("../Core.Data/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_NonEmpty = require("../Data.NonEmpty/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Prelude = require("../Prelude/index.js");
var Run = require("../Run/index.js");
var UI_App_Common = require("../UI.App.Common/index.js");
var UI_App_Common_MenuButton = require("../UI.App.Common.MenuButton/index.js");
var UI_Components = require("../UI.Components/index.js");
var UI_Event_ModelEvent = require("../UI.Event.ModelEvent/index.js");
var UI_Event_NavigationEvent = require("../UI.Event.NavigationEvent/index.js");
var UI_Style = require("../UI.Style/index.js");
var UI_Style_Layout = require("../UI.Style.Layout/index.js");
var UI_Style_PropTypes_FlexDirection = require("../UI.Style.PropTypes.FlexDirection/index.js");
var UI_Style_PropTypes_Overflow = require("../UI.Style.PropTypes.Overflow/index.js");
var UI_Style_PropTypes_TextOverflow = require("../UI.Style.PropTypes.TextOverflow/index.js");
var UI_Style_Text = require("../UI.Style.Text/index.js");
var UI_Style_View = require("../UI.Style.View/index.js");
var titleOrElse = function (v) {
    if (v instanceof Data_Maybe.Nothing) {
        return "Untitled Dashboard";
    };
    if (v instanceof Data_Maybe.Just) {
        return v.value0;
    };
    throw new Error("Failed pattern match at UI.App.Workspace.DashboardList line 52, column 1 - line 52, column 37: " + [ v.constructor.name ]);
};
var style = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.flex(2.0))(function () {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.flexDirection(UI_Style_PropTypes_FlexDirection.Column.value))(function () {
        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.margin(new UI_Style.Pixel(2)))(function () {
            return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.overflowY(UI_Style_PropTypes_Overflow.Scroll.value))(function () {
                return UI_App_Common.hideLeftScroll;
            });
        });
    });
});
var setFocusDashboard = function (v) {
    return function (workspaceId) {
        return function (publish) {
            return publish.navigation(new UI_Event_NavigationEvent.FocusDashboard(workspaceId, v.id));
        };
    };
};
var onPress = function (workspaceId) {
    return function (publish) {
        return publish.model(new UI_Event_ModelEvent.AddDashboard(workspaceId, Data_Maybe.Nothing.value));
    };
};
var itemStyle = function (isFocus) {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_App_Common.standardText)(function () {
        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)((function () {
            if (isFocus) {
                return UI_Style_Text.color(UI_App_Common.white2);
            };
            return UI_Style_Text.color(UI_App_Common.gray3);
        })())(function () {
            return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Text.fontSize(12.0))(function () {
                return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.margin(new UI_Style.Pixel(0)))(function () {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.marginBottom(new UI_Style.Pixel(10)))(function () {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.marginLeft(new UI_Style.Pixel(15)))(function () {
                            return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Text.textOverflow(UI_Style_PropTypes_TextOverflow.Ellipsis.value))(function () {
                                return UI_Style_View.webOnly("whiteSpace")("nowrap");
                            });
                        });
                    });
                });
            });
        });
    });
};
var additionalStyle = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.paddingLeft(new UI_Style.Pixel(10)))(function () {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.paddingRight(new UI_Style.Pixel(5)))(function () {
        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.marginTop(new UI_Style.Pixel(10)))(function () {
            return UI_Style_Layout.marginBottom(new UI_Style.Pixel(20));
        });
    });
});
var additionalHeaderStyle = Control_Applicative.pure(Run.applicativeRun)(Data_Unit.unit);
var render = function (v) {
    var makeLink = function (v1) {
        return Control_Bind.bind(Run.bindRun)(Control_Bind.bindFlipped(Run.bindRun)(UI_Components.h3({
            style: itemStyle(Data_Eq.eq(Core_Data.dashboardIdEq)(v1.id)(v.focusDashboard))
        }))(UI_Components.text(titleOrElse(v1.title))))(function (v2) {
            return UI_Components.makeTouchable(setFocusDashboard(v1)(v.workspaceId)(v.publish))(v2);
        });
    };
    return Control_Bind.bind(Run.bindRun)(UI_App_Common_MenuButton.component({
        additionalStyle: additionalStyle,
        additionalHeaderStyle: additionalHeaderStyle,
        label: "Dashboards",
        onPress: onPress(v.workspaceId)(v.publish)
    }))(function (v1) {
        return Control_Bind.bind(Run.bindRun)(Data_Traversable.sequence(Data_NonEmpty.traversableNonEmpty(Data_Traversable.traversableArray))(Run.applicativeRun)(Data_Functor.mapFlipped(Data_NonEmpty.functorNonEmpty(Data_Functor.functorArray))(v.dashboards)(makeLink)))(function (v2) {
            return UI_Components.nav({
                style: style
            })(UI_Components.fragment([ v1, UI_Components.fragment(Core_Data.nonEmptyToArray(v2)) ]));
        });
    });
};
var component = function (props) {
    return UI_Components.stateless("DashboardList")(props)(render);
};
module.exports = {
    component: component,
    render: render,
    onPress: onPress,
    setFocusDashboard: setFocusDashboard,
    titleOrElse: titleOrElse,
    additionalStyle: additionalStyle,
    additionalHeaderStyle: additionalHeaderStyle,
    style: style,
    itemStyle: itemStyle
};
