// Generated by purs version 0.12.0
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Functor_Variant = require("../Data.Functor.Variant/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Prelude = require("../Prelude/index.js");
var Run = require("../Run/index.js");
var UI_Style_PropTypes_AlignContent = require("../UI.Style.PropTypes.AlignContent/index.js");
var UI_Style_PropTypes_AlignItems = require("../UI.Style.PropTypes.AlignItems/index.js");
var UI_Style_PropTypes_AlignSelf = require("../UI.Style.PropTypes.AlignSelf/index.js");
var UI_Style_PropTypes_BorderStyle = require("../UI.Style.PropTypes.BorderStyle/index.js");
var UI_Style_PropTypes_Color = require("../UI.Style.PropTypes.Color/index.js");
var UI_Style_PropTypes_Display = require("../UI.Style.PropTypes.Display/index.js");
var UI_Style_PropTypes_FlexDirection = require("../UI.Style.PropTypes.FlexDirection/index.js");
var UI_Style_PropTypes_FlexWrap = require("../UI.Style.PropTypes.FlexWrap/index.js");
var UI_Style_PropTypes_FontFamily = require("../UI.Style.PropTypes.FontFamily/index.js");
var UI_Style_PropTypes_FontStyle = require("../UI.Style.PropTypes.FontStyle/index.js");
var UI_Style_PropTypes_FontWeight = require("../UI.Style.PropTypes.FontWeight/index.js");
var UI_Style_PropTypes_JustifyContent = require("../UI.Style.PropTypes.JustifyContent/index.js");
var UI_Style_PropTypes_Overflow = require("../UI.Style.PropTypes.Overflow/index.js");
var UI_Style_PropTypes_Position = require("../UI.Style.PropTypes.Position/index.js");
var UI_Style_PropTypes_TextAlign = require("../UI.Style.PropTypes.TextAlign/index.js");
var UI_Style_PropTypes_TextDecorationLine = require("../UI.Style.PropTypes.TextDecorationLine/index.js");
var UI_Style_PropTypes_TextOverflow = require("../UI.Style.PropTypes.TextOverflow/index.js");
var UI_Style_PropTypes_Visibility = require("../UI.Style.PropTypes.Visibility/index.js");
var IntProperty = (function () {
    function IntProperty(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    IntProperty.create = function (value0) {
        return function (value1) {
            return new IntProperty(value0, value1);
        };
    };
    return IntProperty;
})();
var NumberProperty = (function () {
    function NumberProperty(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    NumberProperty.create = function (value0) {
        return function (value1) {
            return new NumberProperty(value0, value1);
        };
    };
    return NumberProperty;
})();
var StringProperty = (function () {
    function StringProperty(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    StringProperty.create = function (value0) {
        return function (value1) {
            return new StringProperty(value0, value1);
        };
    };
    return StringProperty;
})();
var Pixel = (function () {
    function Pixel(value0) {
        this.value0 = value0;
    };
    Pixel.create = function (value0) {
        return new Pixel(value0);
    };
    return Pixel;
})();
var Percent = (function () {
    function Percent(value0) {
        this.value0 = value0;
    };
    Percent.create = function (value0) {
        return new Percent(value0);
    };
    return Percent;
})();
var WriteDimension = (function () {
    function WriteDimension(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    WriteDimension.create = function (value0) {
        return function (value1) {
            return new WriteDimension(value0, value1);
        };
    };
    return WriteDimension;
})();
var WriteNumber = (function () {
    function WriteNumber(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    WriteNumber.create = function (value0) {
        return function (value1) {
            return new WriteNumber(value0, value1);
        };
    };
    return WriteNumber;
})();
var WriteInt = (function () {
    function WriteInt(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    WriteInt.create = function (value0) {
        return function (value1) {
            return new WriteInt(value0, value1);
        };
    };
    return WriteInt;
})();
var WriteBorderStyle = (function () {
    function WriteBorderStyle(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    WriteBorderStyle.create = function (value0) {
        return function (value1) {
            return new WriteBorderStyle(value0, value1);
        };
    };
    return WriteBorderStyle;
})();
var WriteColor = (function () {
    function WriteColor(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    WriteColor.create = function (value0) {
        return function (value1) {
            return new WriteColor(value0, value1);
        };
    };
    return WriteColor;
})();
var WriteDisplay = (function () {
    function WriteDisplay(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    WriteDisplay.create = function (value0) {
        return function (value1) {
            return new WriteDisplay(value0, value1);
        };
    };
    return WriteDisplay;
})();
var WritePosition = (function () {
    function WritePosition(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    WritePosition.create = function (value0) {
        return function (value1) {
            return new WritePosition(value0, value1);
        };
    };
    return WritePosition;
})();
var WriteFlexDirection = (function () {
    function WriteFlexDirection(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    WriteFlexDirection.create = function (value0) {
        return function (value1) {
            return new WriteFlexDirection(value0, value1);
        };
    };
    return WriteFlexDirection;
})();
var WriteFlexWrap = (function () {
    function WriteFlexWrap(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    WriteFlexWrap.create = function (value0) {
        return function (value1) {
            return new WriteFlexWrap(value0, value1);
        };
    };
    return WriteFlexWrap;
})();
var WriteJustifyContent = (function () {
    function WriteJustifyContent(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    WriteJustifyContent.create = function (value0) {
        return function (value1) {
            return new WriteJustifyContent(value0, value1);
        };
    };
    return WriteJustifyContent;
})();
var WriteAlignItems = (function () {
    function WriteAlignItems(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    WriteAlignItems.create = function (value0) {
        return function (value1) {
            return new WriteAlignItems(value0, value1);
        };
    };
    return WriteAlignItems;
})();
var WriteAlignSelf = (function () {
    function WriteAlignSelf(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    WriteAlignSelf.create = function (value0) {
        return function (value1) {
            return new WriteAlignSelf(value0, value1);
        };
    };
    return WriteAlignSelf;
})();
var WriteAlignContent = (function () {
    function WriteAlignContent(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    WriteAlignContent.create = function (value0) {
        return function (value1) {
            return new WriteAlignContent(value0, value1);
        };
    };
    return WriteAlignContent;
})();
var WriteOverflow = (function () {
    function WriteOverflow(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    WriteOverflow.create = function (value0) {
        return function (value1) {
            return new WriteOverflow(value0, value1);
        };
    };
    return WriteOverflow;
})();
var WriteFontWeight = (function () {
    function WriteFontWeight(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    WriteFontWeight.create = function (value0) {
        return function (value1) {
            return new WriteFontWeight(value0, value1);
        };
    };
    return WriteFontWeight;
})();
var WriteFontFamily = (function () {
    function WriteFontFamily(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    WriteFontFamily.create = function (value0) {
        return function (value1) {
            return new WriteFontFamily(value0, value1);
        };
    };
    return WriteFontFamily;
})();
var WriteFontStyle = (function () {
    function WriteFontStyle(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    WriteFontStyle.create = function (value0) {
        return function (value1) {
            return new WriteFontStyle(value0, value1);
        };
    };
    return WriteFontStyle;
})();
var WriteTextDecorationLine = (function () {
    function WriteTextDecorationLine(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    WriteTextDecorationLine.create = function (value0) {
        return function (value1) {
            return new WriteTextDecorationLine(value0, value1);
        };
    };
    return WriteTextDecorationLine;
})();
var WriteTextAlign = (function () {
    function WriteTextAlign(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    WriteTextAlign.create = function (value0) {
        return function (value1) {
            return new WriteTextAlign(value0, value1);
        };
    };
    return WriteTextAlign;
})();
var WriteTextOverflow = (function () {
    function WriteTextOverflow(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    WriteTextOverflow.create = function (value0) {
        return function (value1) {
            return new WriteTextOverflow(value0, value1);
        };
    };
    return WriteTextOverflow;
})();
var WriteVisibility = (function () {
    function WriteVisibility(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    WriteVisibility.create = function (value0) {
        return function (value1) {
            return new WriteVisibility(value0, value1);
        };
    };
    return WriteVisibility;
})();
var WriteString = (function () {
    function WriteString(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    WriteString.create = function (value0) {
        return function (value1) {
            return new WriteString(value0, value1);
        };
    };
    return WriteString;
})();
var handleStyle = function (v) {
    if (v instanceof WriteDimension) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof WriteNumber) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof WriteInt) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof WriteBorderStyle) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof WriteColor) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof WriteDisplay) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof WritePosition) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof WriteFlexDirection) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof WriteFlexWrap) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof WriteJustifyContent) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof WriteAlignItems) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof WriteAlignSelf) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof WriteAlignContent) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof WriteOverflow) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof WriteFontWeight) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof WriteFontStyle) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof WriteFontFamily) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof WriteTextDecorationLine) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof WriteTextAlign) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof WriteTextOverflow) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof WriteVisibility) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof WriteString) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    throw new Error("Failed pattern match at UI.Style line 173, column 15 - line 195, column 52: " + [ v.constructor.name ]);
};
var functorStyleDSL = new Data_Functor.Functor(function (f) {
    return function (m) {
        if (m instanceof WriteDimension) {
            return new WriteDimension(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof WriteNumber) {
            return new WriteNumber(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof WriteInt) {
            return new WriteInt(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof WriteBorderStyle) {
            return new WriteBorderStyle(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof WriteColor) {
            return new WriteColor(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof WriteDisplay) {
            return new WriteDisplay(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof WritePosition) {
            return new WritePosition(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof WriteFlexDirection) {
            return new WriteFlexDirection(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof WriteFlexWrap) {
            return new WriteFlexWrap(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof WriteJustifyContent) {
            return new WriteJustifyContent(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof WriteAlignItems) {
            return new WriteAlignItems(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof WriteAlignSelf) {
            return new WriteAlignSelf(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof WriteAlignContent) {
            return new WriteAlignContent(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof WriteOverflow) {
            return new WriteOverflow(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof WriteFontWeight) {
            return new WriteFontWeight(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof WriteFontFamily) {
            return new WriteFontFamily(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof WriteFontStyle) {
            return new WriteFontStyle(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof WriteTextDecorationLine) {
            return new WriteTextDecorationLine(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof WriteTextAlign) {
            return new WriteTextAlign(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof WriteTextOverflow) {
            return new WriteTextOverflow(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof WriteVisibility) {
            return new WriteVisibility(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof WriteString) {
            return new WriteString(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        throw new Error("Failed pattern match at UI.Style line 95, column 8 - line 95, column 52: " + [ m.constructor.name ]);
    };
});
var _style = Data_Symbol.SProxy.value;
var runStyle = function (handler) {
    return Run.interpret(Run.monadRun)(Data_Functor_Variant.on()(new Data_Symbol.IsSymbol(function () {
        return "style";
    }))(_style)(handler)(Run.send));
};
var writeAlignContent = function (alignContent) {
    return Run.lift()(new Data_Symbol.IsSymbol(function () {
        return "style";
    }))(functorStyleDSL)(_style)(new WriteAlignContent(alignContent, Control_Category.identity(Control_Category.categoryFn)));
};
var writeAlignItems = function (alignItems) {
    return Run.lift()(new Data_Symbol.IsSymbol(function () {
        return "style";
    }))(functorStyleDSL)(_style)(new WriteAlignItems(alignItems, Control_Category.identity(Control_Category.categoryFn)));
};
var writeAlignSelf = function (alignSelf) {
    return Run.lift()(new Data_Symbol.IsSymbol(function () {
        return "style";
    }))(functorStyleDSL)(_style)(new WriteAlignSelf(alignSelf, Control_Category.identity(Control_Category.categoryFn)));
};
var writeBorderStyle = function (borderStyle) {
    return Run.lift()(new Data_Symbol.IsSymbol(function () {
        return "style";
    }))(functorStyleDSL)(_style)(new WriteBorderStyle(borderStyle, Control_Category.identity(Control_Category.categoryFn)));
};
var writeColor = function (color) {
    return Run.lift()(new Data_Symbol.IsSymbol(function () {
        return "style";
    }))(functorStyleDSL)(_style)(new WriteColor(color, Control_Category.identity(Control_Category.categoryFn)));
};
var writeDimension = function (dimension) {
    return Run.lift()(new Data_Symbol.IsSymbol(function () {
        return "style";
    }))(functorStyleDSL)(_style)(new WriteDimension(dimension, Control_Category.identity(Control_Category.categoryFn)));
};
var writeDisplay = function (display) {
    return Run.lift()(new Data_Symbol.IsSymbol(function () {
        return "style";
    }))(functorStyleDSL)(_style)(new WriteDisplay(display, Control_Category.identity(Control_Category.categoryFn)));
};
var writeFlexDirection = function (flexDirection) {
    return Run.lift()(new Data_Symbol.IsSymbol(function () {
        return "style";
    }))(functorStyleDSL)(_style)(new WriteFlexDirection(flexDirection, Control_Category.identity(Control_Category.categoryFn)));
};
var writeFlexWrap = function (flexWrap) {
    return Run.lift()(new Data_Symbol.IsSymbol(function () {
        return "style";
    }))(functorStyleDSL)(_style)(new WriteFlexWrap(flexWrap, Control_Category.identity(Control_Category.categoryFn)));
};
var writeFontFamily = function (fontFamily) {
    return Run.lift()(new Data_Symbol.IsSymbol(function () {
        return "style";
    }))(functorStyleDSL)(_style)(new WriteFontFamily(fontFamily, Control_Category.identity(Control_Category.categoryFn)));
};
var writeFontStyle = function (fontStyle) {
    return Run.lift()(new Data_Symbol.IsSymbol(function () {
        return "style";
    }))(functorStyleDSL)(_style)(new WriteFontStyle(fontStyle, Control_Category.identity(Control_Category.categoryFn)));
};
var writeFontWeight = function (fontWeight) {
    return Run.lift()(new Data_Symbol.IsSymbol(function () {
        return "style";
    }))(functorStyleDSL)(_style)(new WriteFontWeight(fontWeight, Control_Category.identity(Control_Category.categoryFn)));
};
var writeInt = function ($$int) {
    return Run.lift()(new Data_Symbol.IsSymbol(function () {
        return "style";
    }))(functorStyleDSL)(_style)(new WriteInt($$int, Control_Category.identity(Control_Category.categoryFn)));
};
var writeJustifyContent = function (justifyContent) {
    return Run.lift()(new Data_Symbol.IsSymbol(function () {
        return "style";
    }))(functorStyleDSL)(_style)(new WriteJustifyContent(justifyContent, Control_Category.identity(Control_Category.categoryFn)));
};
var writeNumber = function (number) {
    return Run.lift()(new Data_Symbol.IsSymbol(function () {
        return "style";
    }))(functorStyleDSL)(_style)(new WriteNumber(number, Control_Category.identity(Control_Category.categoryFn)));
};
var writeOverflow = function (overflow) {
    return Run.lift()(new Data_Symbol.IsSymbol(function () {
        return "style";
    }))(functorStyleDSL)(_style)(new WriteOverflow(overflow, Control_Category.identity(Control_Category.categoryFn)));
};
var writePosition = function (position) {
    return Run.lift()(new Data_Symbol.IsSymbol(function () {
        return "style";
    }))(functorStyleDSL)(_style)(new WritePosition(position, Control_Category.identity(Control_Category.categoryFn)));
};
var writeString = function (str) {
    return Run.lift()(new Data_Symbol.IsSymbol(function () {
        return "style";
    }))(functorStyleDSL)(_style)(new WriteString(str, Control_Category.identity(Control_Category.categoryFn)));
};
var writeTextAlign = function (textAlign) {
    return Run.lift()(new Data_Symbol.IsSymbol(function () {
        return "style";
    }))(functorStyleDSL)(_style)(new WriteTextAlign(textAlign, Control_Category.identity(Control_Category.categoryFn)));
};
var writeTextDecorationLine = function (accum) {
    return Run.lift()(new Data_Symbol.IsSymbol(function () {
        return "style";
    }))(functorStyleDSL)(_style)(new WriteTextDecorationLine(accum, Control_Category.identity(Control_Category.categoryFn)));
};
var writeTextOverflow = function (textOverflow) {
    return Run.lift()(new Data_Symbol.IsSymbol(function () {
        return "style";
    }))(functorStyleDSL)(_style)(new WriteTextOverflow(textOverflow, Control_Category.identity(Control_Category.categoryFn)));
};
var writeVisibility = function (visibility) {
    return Run.lift()(new Data_Symbol.IsSymbol(function () {
        return "style";
    }))(functorStyleDSL)(_style)(new WriteVisibility(visibility, Control_Category.identity(Control_Category.categoryFn)));
};
module.exports = {
    Pixel: Pixel,
    Percent: Percent,
    IntProperty: IntProperty,
    NumberProperty: NumberProperty,
    StringProperty: StringProperty,
    WriteDimension: WriteDimension,
    WriteNumber: WriteNumber,
    WriteInt: WriteInt,
    WriteBorderStyle: WriteBorderStyle,
    WriteColor: WriteColor,
    WriteDisplay: WriteDisplay,
    WritePosition: WritePosition,
    WriteFlexDirection: WriteFlexDirection,
    WriteFlexWrap: WriteFlexWrap,
    WriteJustifyContent: WriteJustifyContent,
    WriteAlignItems: WriteAlignItems,
    WriteAlignSelf: WriteAlignSelf,
    WriteAlignContent: WriteAlignContent,
    WriteOverflow: WriteOverflow,
    WriteFontWeight: WriteFontWeight,
    WriteFontFamily: WriteFontFamily,
    WriteFontStyle: WriteFontStyle,
    WriteTextDecorationLine: WriteTextDecorationLine,
    WriteTextAlign: WriteTextAlign,
    WriteTextOverflow: WriteTextOverflow,
    WriteVisibility: WriteVisibility,
    WriteString: WriteString,
    _style: _style,
    writeDimension: writeDimension,
    writeNumber: writeNumber,
    writeInt: writeInt,
    writeBorderStyle: writeBorderStyle,
    writeColor: writeColor,
    writeDisplay: writeDisplay,
    writePosition: writePosition,
    writeFlexDirection: writeFlexDirection,
    writeFlexWrap: writeFlexWrap,
    writeJustifyContent: writeJustifyContent,
    writeAlignItems: writeAlignItems,
    writeAlignSelf: writeAlignSelf,
    writeAlignContent: writeAlignContent,
    writeOverflow: writeOverflow,
    writeFontWeight: writeFontWeight,
    writeFontFamily: writeFontFamily,
    writeFontStyle: writeFontStyle,
    writeTextDecorationLine: writeTextDecorationLine,
    writeTextAlign: writeTextAlign,
    writeTextOverflow: writeTextOverflow,
    writeVisibility: writeVisibility,
    writeString: writeString,
    handleStyle: handleStyle,
    runStyle: runStyle,
    functorStyleDSL: functorStyleDSL
};
