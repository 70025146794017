// Generated by purs version 0.12.0
"use strict";
var Data_Show = require("../Data.Show/index.js");
var Prelude = require("../Prelude/index.js");
var Auto = (function () {
    function Auto() {

    };
    Auto.value = new Auto();
    return Auto;
})();
var FlexStart = (function () {
    function FlexStart() {

    };
    FlexStart.value = new FlexStart();
    return FlexStart;
})();
var FlexEnd = (function () {
    function FlexEnd() {

    };
    FlexEnd.value = new FlexEnd();
    return FlexEnd;
})();
var Center = (function () {
    function Center() {

    };
    Center.value = new Center();
    return Center;
})();
var Stretch = (function () {
    function Stretch() {

    };
    Stretch.value = new Stretch();
    return Stretch;
})();
var Baseline = (function () {
    function Baseline() {

    };
    Baseline.value = new Baseline();
    return Baseline;
})();
var showAlignSelf = new Data_Show.Show(function (v) {
    if (v instanceof Auto) {
        return "auto";
    };
    if (v instanceof FlexStart) {
        return "flex-start";
    };
    if (v instanceof FlexEnd) {
        return "flex-end";
    };
    if (v instanceof Center) {
        return "center";
    };
    if (v instanceof Stretch) {
        return "stretch";
    };
    if (v instanceof Baseline) {
        return "baseline";
    };
    throw new Error("Failed pattern match at UI.Style.PropTypes.AlignSelf line 7, column 1 - line 7, column 41: " + [ v.constructor.name ]);
});
module.exports = {
    Auto: Auto,
    FlexStart: FlexStart,
    FlexEnd: FlexEnd,
    Center: Center,
    Stretch: Stretch,
    Baseline: Baseline,
    showAlignSelf: showAlignSelf
};
