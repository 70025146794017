// Generated by purs version 0.12.0
"use strict";
var Control_Alt = require("../Control.Alt/index.js");
var Control_Alternative = require("../Control.Alternative/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Monad = require("../Control.Monad/index.js");
var Control_Monad_Free = require("../Control.Monad.Free/index.js");
var Control_Monad_Rec_Class = require("../Control.Monad.Rec.Class/index.js");
var Control_Plus = require("../Control.Plus/index.js");
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Functor_Variant = require("../Data.Functor.Variant/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Aff_Class = require("../Effect.Aff.Class/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Partial_Unsafe = require("../Partial.Unsafe/index.js");
var Prelude = require("../Prelude/index.js");
var Run_Internal = require("../Run.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var Type_Row = require("../Type.Row/index.js");
var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");
var Run = function (x) {
    return x;
};
var send = function ($44) {
    return Run(Control_Monad_Free.liftF($44));
};
var newtypeRun = new Data_Newtype.Newtype(function (n) {
    return n;
}, Run);
var resume = function (k1) {
    return function (k2) {
        return function ($45) {
            return Control_Monad_Free["resume'"](function (x) {
                return function (f) {
                    return k1(Data_Functor.map(Data_Functor_Variant.functorVariantF)(function ($46) {
                        return Run(f($46));
                    })(x));
                };
            })(k2)(Data_Newtype.unwrap(newtypeRun)($45));
        };
    };
};
var peel = resume(Data_Either.Left.create)(Data_Either.Right.create);
var run = function (dictMonad) {
    return function (k) {
        var loop = resume(function (a) {
            return Control_Bind.bindFlipped(dictMonad.Bind1())(loop)(k(a));
        })(Control_Applicative.pure(dictMonad.Applicative0()));
        return loop;
    };
};
var runBaseAff = run(Effect_Aff.monadAff)(Data_Functor_Variant.match()(Data_Variant_Internal.variantFMatchCons(Data_Variant_Internal.variantFMatchNil)()(Type_Equality.refl))()({
    aff: function (a) {
        return a;
    }
}));
var runBaseAff$prime = run(Effect_Aff.monadAff)(Data_Functor_Variant.match()(Data_Variant_Internal.variantFMatchCons(Data_Variant_Internal.variantFMatchCons(Data_Variant_Internal.variantFMatchNil)()(Type_Equality.refl))()(Type_Equality.refl))()({
    aff: function (a) {
        return a;
    },
    effect: function (a) {
        return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(a);
    }
}));
var runAccum = function (dictMonad) {
    return function (k) {
        var loop = function (s) {
            return resume(function (b) {
                return Control_Bind.bindFlipped(dictMonad.Bind1())(Data_Tuple.uncurry(loop))(k(s)(b));
            })(Control_Applicative.pure(dictMonad.Applicative0()));
        };
        return loop;
    };
};
var runAccumCont = function (k1) {
    return function (k2) {
        var loop = function (s) {
            return resume(function (b) {
                return k1(s)(Data_Functor.map(Data_Functor_Variant.functorVariantF)(Data_Function.flip(loop))(b));
            })(k2(s));
        };
        return loop;
    };
};
var runAccumRec = function (dictMonadRec) {
    return function (k) {
        var loop = function (s) {
            return resume(function (b) {
                return Data_Functor.map((((dictMonadRec.Monad0()).Bind1()).Apply0()).Functor0())(Control_Monad_Rec_Class.Loop.create)(k(s)(b));
            })(function ($47) {
                return Control_Applicative.pure((dictMonadRec.Monad0()).Applicative0())(Control_Monad_Rec_Class.Done.create($47));
            });
        };
        return Data_Tuple.curry(Control_Monad_Rec_Class.tailRecM(dictMonadRec)(Data_Tuple.uncurry(loop)));
    };
};
var runCont = function (k1) {
    return function (k2) {
        var loop = resume(function (b) {
            return k1(Data_Functor.map(Data_Functor_Variant.functorVariantF)(loop)(b));
        })(k2);
        return loop;
    };
};
var runRec = function (dictMonadRec) {
    return function (k) {
        return function ($48) {
            return Control_Monad_Free.runFreeM(Data_Functor_Variant.functorVariantF)(dictMonadRec)(k)(Data_Newtype.unwrap(newtypeRun)($48));
        };
    };
};
var runBaseEffect = runRec(Control_Monad_Rec_Class.monadRecEffect)(Data_Functor_Variant.match()(Data_Variant_Internal.variantFMatchCons(Data_Variant_Internal.variantFMatchNil)()(Type_Equality.refl))()({
    effect: function (a) {
        return a;
    }
}));
var monadRun = Control_Monad_Free.freeMonad;
var lift = function (dictCons) {
    return function (dictIsSymbol) {
        return function (dictFunctor) {
            return function (p) {
                return function ($49) {
                    return Run(Control_Monad_Free.liftF(Data_Functor_Variant.inj(dictCons)(dictIsSymbol)(dictFunctor)(p)($49)));
                };
            };
        };
    };
};
var liftAff = lift()(new Data_Symbol.IsSymbol(function () {
    return "aff";
}))(Effect_Aff.functorAff)(Data_Symbol.SProxy.value);
var liftChoose = lift()(new Data_Symbol.IsSymbol(function () {
    return "choose";
}))(Run_Internal.functorChoose)(Run_Internal._choose);
var liftEffect = lift()(new Data_Symbol.IsSymbol(function () {
    return "effect";
}))(Effect.functorEffect)(Data_Symbol.SProxy.value);
var runMonadEff = function (dictTypeEquals) {
    return new Effect_Class.MonadEffect(function () {
        return monadRun;
    }, function ($50) {
        return Run_Internal.fromRows(dictTypeEquals)(liftEffect($50));
    });
};
var runMonadAff = function (dictTypeEquals) {
    return new Effect_Aff_Class.MonadAff(function () {
        return runMonadEff(dictTypeEquals);
    }, function ($51) {
        return Run_Internal.fromRows(dictTypeEquals)(liftAff($51));
    });
};
var interpretRec = function (dictMonadRec) {
    return runRec(dictMonadRec);
};
var interpret = function (dictMonad) {
    return run(dictMonad);
};
var functorRun = Control_Monad_Free.freeFunctor;
var extract = function ($52) {
    return Control_Monad_Free.runFree(Data_Functor_Variant.functorVariantF)(function (v) {
        return Partial_Unsafe.unsafeCrashWith("Run: the impossible happened");
    })(Data_Newtype.unwrap(newtypeRun)($52));
};
var expand = function (dictUnion) {
    return Unsafe_Coerce.unsafeCoerce;
};
var bindRun = Control_Monad_Free.freeBind;
var runAlt = function (dictTypeEquals) {
    return new Control_Alt.Alt(function () {
        return functorRun;
    }, function (a) {
        return function (b) {
            return Run_Internal.fromRows(dictTypeEquals)(Control_Bind.bind(bindRun)(liftChoose(new Run_Internal.Alt(Control_Category.identity(Control_Category.categoryFn))))(function (v) {
                if (v) {
                    return Run_Internal.toRows(dictTypeEquals)(a);
                };
                return Run_Internal.toRows(dictTypeEquals)(b);
            }));
        };
    });
};
var runPlus = function (dictTypeEquals) {
    return new Control_Plus.Plus(function () {
        return runAlt(dictTypeEquals);
    }, Run_Internal.fromRows(dictTypeEquals)(liftChoose(Run_Internal.Empty.value)));
};
var applyRun = Control_Monad_Free.freeApply;
var applicativeRun = Control_Monad_Free.freeApplicative;
var monadRecRun = new Control_Monad_Rec_Class.MonadRec(function () {
    return monadRun;
}, function (f) {
    var loop = function (a) {
        return Control_Bind.bind(bindRun)(f(a))(function (v) {
            if (v instanceof Control_Monad_Rec_Class.Done) {
                return Control_Applicative.pure(applicativeRun)(v.value0);
            };
            if (v instanceof Control_Monad_Rec_Class.Loop) {
                return loop(v.value0);
            };
            throw new Error("Failed pattern match at Run line 100, column 7 - line 102, column 24: " + [ v.constructor.name ]);
        });
    };
    return loop;
});
var runAccumPure = function (k1) {
    return function (k2) {
        var loop = function ($copy_s) {
            return function ($copy_r) {
                var $tco_var_s = $copy_s;
                var $tco_done = false;
                var $tco_result;
                function $tco_loop(s, r) {
                    var v = peel(r);
                    if (v instanceof Data_Either.Left) {
                        var v1 = k1(s)(v.value0);
                        if (v1 instanceof Control_Monad_Rec_Class.Loop) {
                            $tco_var_s = v1.value0.value0;
                            $copy_r = v1.value0.value1;
                            return;
                        };
                        if (v1 instanceof Control_Monad_Rec_Class.Done) {
                            $tco_done = true;
                            return Control_Bind.bind(bindRun)(send(v1.value0))(runAccumPure(k1)(k2)(s));
                        };
                        throw new Error("Failed pattern match at Run line 297, column 14 - line 299, column 49: " + [ v1.constructor.name ]);
                    };
                    if (v instanceof Data_Either.Right) {
                        $tco_done = true;
                        return Control_Applicative.pure(applicativeRun)(k2(s)(v.value0));
                    };
                    throw new Error("Failed pattern match at Run line 296, column 14 - line 301, column 20: " + [ v.constructor.name ]);
                };
                while (!$tco_done) {
                    $tco_result = $tco_loop($tco_var_s, $copy_r);
                };
                return $tco_result;
            };
        };
        return loop;
    };
};
var runAlternative = function (dictTypeEquals) {
    return new Control_Alternative.Alternative(function () {
        return applicativeRun;
    }, function () {
        return runPlus(dictTypeEquals);
    });
};
var runPure = function (k) {
    var loop = function ($copy_r) {
        var $tco_done = false;
        var $tco_result;
        function $tco_loop(r) {
            var v = peel(r);
            if (v instanceof Data_Either.Left) {
                var v1 = k(v.value0);
                if (v1 instanceof Control_Monad_Rec_Class.Loop) {
                    $copy_r = v1.value0;
                    return;
                };
                if (v1 instanceof Control_Monad_Rec_Class.Done) {
                    $tco_done = true;
                    return Control_Bind.bind(bindRun)(send(v1.value0))(runPure(k));
                };
                throw new Error("Failed pattern match at Run line 278, column 14 - line 280, column 38: " + [ v1.constructor.name ]);
            };
            if (v instanceof Data_Either.Right) {
                $tco_done = true;
                return Control_Applicative.pure(applicativeRun)(v.value0);
            };
            throw new Error("Failed pattern match at Run line 277, column 12 - line 282, column 13: " + [ v.constructor.name ]);
        };
        while (!$tco_done) {
            $tco_result = $tco_loop($copy_r);
        };
        return $tco_result;
    };
    return loop;
};
module.exports = {
    Run: Run,
    lift: lift,
    send: send,
    extract: extract,
    interpret: interpret,
    interpretRec: interpretRec,
    run: run,
    runRec: runRec,
    runCont: runCont,
    runPure: runPure,
    runAccum: runAccum,
    runAccumRec: runAccumRec,
    runAccumCont: runAccumCont,
    runAccumPure: runAccumPure,
    peel: peel,
    resume: resume,
    expand: expand,
    liftEffect: liftEffect,
    liftAff: liftAff,
    runBaseEffect: runBaseEffect,
    runBaseAff: runBaseAff,
    "runBaseAff'": runBaseAff$prime,
    newtypeRun: newtypeRun,
    functorRun: functorRun,
    applyRun: applyRun,
    applicativeRun: applicativeRun,
    bindRun: bindRun,
    monadRun: monadRun,
    monadRecRun: monadRecRun,
    runMonadEff: runMonadEff,
    runMonadAff: runMonadAff,
    runAlt: runAlt,
    runPlus: runPlus,
    runAlternative: runAlternative
};
