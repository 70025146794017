"use strict";

var _server = require("react-dom/server");

var ReactDOM = require("react-dom");

var _require = require("javascript"),
    web = _require.web,
    ui = _require.ui,
    aws = _require.aws;

exports.render = function (jsx) {
  return ReactDOM.render(jsx, document.getElementById("app"));
};

exports.toString = function (element) {
  return function () {
    return (0, _server.renderToString)(element);
  };
};

function runCmd(cmd) {
  if (!cmd.startsWith("/")) {
    return;
  }

  var tokens = cmd.trim().slice(1).toLowerCase().split(" ");

  switch (tokens[0]) {
    case "logout":
      aws.currentUser().then(aws.logout);
      break;
    case "login":
      if (tokens.length === 3) {
        aws.currentUserOrLogin(tokens[1], tokens[2]).then(function (user) {
          return console.log("Valid user", user);
        }).catch(console.error);
      } else console.log("usage: /login <user> <pass>");
      break;
    case "signup":
      if (tokens.length === 3) {
        aws.signup(tokens[1], tokens[2]).then(function (user) {
          return console.log("welcome aboard!", user);
        }).catch(console.error);
      } else {
        console.log("usage: /signup <user> <pass>");
      }
      break;
    case "session":
      aws.currentUser().then(aws.session).then(console.log).catch(console.error);
      break;
    default:
      console.log("unknown cmd", cmd);
      console.log("valid: login logout signup session");
  }
}

var showCurrentUser = function showCurrentUser() {
  return aws.currentUser().then(function (user) {
    return console.log("User:", user.username);
  }).catch(console.log);
};
/*
window.addEventListener("load", () => {
  showCurrentUser();
  const search = document.querySelector("input[type=text]");
  search.addEventListener("keydown", e => {
    e.stopPropagation();
    if (e.key === "Enter") {
      const cmd = search.value;
      search.value = "";
      runCmd(cmd);
    }
  });
});*/