
"use strict";

var React = require("react");
var Fragment = React.Fragment;
var javascript = require("javascript");
var ui = javascript.ui;

/**
 *  Wraps the given children in a Fragment 
 * @param {*} children 
 */
exports.fragment = function (children) {
  return React.createElement.apply(null, [Fragment, {}].concat(children));
};

exports.createReactComponent = ui.createReactComponent;
exports.createReactElement = ui.createReactElement;