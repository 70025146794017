// Generated by purs version 0.12.0
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Effect = require("../Effect/index.js");
var Prelude = require("../Prelude/index.js");
var Run = require("../Run/index.js");
var UI_App_Common = require("../UI.App.Common/index.js");
var UI_App_Common_Text = require("../UI.App.Common.Text/index.js");
var UI_App_Common_XButton = require("../UI.App.Common.XButton/index.js");
var UI_Components = require("../UI.Components/index.js");
var UI_Data_AppData = require("../UI.Data.AppData/index.js");
var UI_Data_LightBoxProps = require("../UI.Data.LightBoxProps/index.js");
var UI_Event_NavigationEvent = require("../UI.Event.NavigationEvent/index.js");
var UI_Style = require("../UI.Style/index.js");
var UI_Style_Layout = require("../UI.Style.Layout/index.js");
var UI_Style_PropTypes_AlignItems = require("../UI.Style.PropTypes.AlignItems/index.js");
var UI_Style_PropTypes_Color = require("../UI.Style.PropTypes.Color/index.js");
var UI_Style_PropTypes_Display = require("../UI.Style.PropTypes.Display/index.js");
var UI_Style_PropTypes_FlexDirection = require("../UI.Style.PropTypes.FlexDirection/index.js");
var UI_Style_PropTypes_JustifyContent = require("../UI.Style.PropTypes.JustifyContent/index.js");
var UI_Style_PropTypes_Overflow = require("../UI.Style.PropTypes.Overflow/index.js");
var UI_Style_PropTypes_Position = require("../UI.Style.PropTypes.Position/index.js");
var UI_Style_View = require("../UI.Style.View/index.js");
var xWrapperStyle = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.display(UI_Style_PropTypes_Display.Flex.value))(function () {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.flex(1.0))(function () {
        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.padding(new UI_Style.Pixel(5)))(function () {
            return UI_Style_Layout.position(UI_Style_PropTypes_Position.Absolute.value);
        });
    });
});
var style = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.position(UI_Style_PropTypes_Position.Absolute.value))(function () {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.alignItems(UI_Style_PropTypes_AlignItems.Center.value))(function () {
        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_View.backgroundColor(new UI_Style_PropTypes_Color.RGBA(0, 0, 0, 0.5)))(function () {
            return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.display(UI_Style_PropTypes_Display.Flex.value))(function () {
                return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.height(new UI_Style.Percent(100)))(function () {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.justifyContent(UI_Style_PropTypes_JustifyContent.Center.value))(function () {
                        return UI_Style_Layout.width(new UI_Style.Percent(100));
                    });
                });
            });
        });
    });
});
var panelStyle = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_App_Common.outline)(function () {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_View.borderColor(UI_App_Common.white1))(function () {
        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_View.backgroundColor(UI_App_Common.white2))(function () {
            return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.display(UI_Style_PropTypes_Display.Flex.value))(function () {
                return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.flex(1.0))(function () {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.flexDirection(UI_Style_PropTypes_FlexDirection.Column.value))(function () {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.minWidth(new UI_Style.Pixel(320)))(function () {
                            return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.maxWidth(new UI_Style.Pixel(540)))(function () {
                                return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.width(new UI_Style.Pixel(320)))(function () {
                                    return UI_Style_Layout.maxHeight(new UI_Style.Percent(90));
                                });
                            });
                        });
                    });
                });
            });
        });
    });
});
var hideLightBox = function (publish) {
    return publish.navigation(UI_Event_NavigationEvent.HideLightBox.value);
};
var childWrapperStyle = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.padding(new UI_Style.Pixel(0)))(function () {
    return UI_Style_Layout.overflow(UI_Style_PropTypes_Overflow.Scroll.value);
});
var render = function (v) {
    if (v.lightBox.child instanceof Data_Maybe.Just && v.lightBox.show) {
        return Control_Bind.bind(Run.bindRun)(UI_App_Common_Text.whiteH1({
            text: Data_Maybe.fromMaybe("Properties")(v.lightBox.title)
        }))(function (v1) {
            return Control_Bind.bind(Run.bindRun)(UI_App_Common_XButton.component({
                onClick: hideLightBox(v.publish)
            }))(function (v2) {
                return Control_Bind.bind(Run.bindRun)(UI_Components.div({
                    style: xWrapperStyle
                })(v2))(function (v3) {
                    return Control_Bind.bind(Run.bindRun)(v.lightBox.child.value0(v.appData.model))(function (v4) {
                        return Control_Bind.bind(Run.bindRun)(UI_Components.div({
                            style: childWrapperStyle
                        })(v4))(function (v5) {
                            return Control_Bind.bind(Run.bindRun)(UI_Components.div({
                                style: panelStyle
                            })(UI_Components.fragment([ v1, v3, v5 ])))(function (v6) {
                                return UI_Components.div({
                                    style: style
                                })(v6);
                            });
                        });
                    });
                });
            });
        });
    };
    return Control_Bind.bindFlipped(Run.bindRun)(UI_Components.div({
        style: UI_Style_Layout.display(UI_Style_PropTypes_Display.None.value)
    }))(UI_Components.text("empty lightbox"));
};
var component = function (props) {
    return UI_Components.stateless("LightBox")(props)(render);
};
module.exports = {
    component: component,
    render: render,
    hideLightBox: hideLightBox,
    style: style,
    panelStyle: panelStyle,
    childWrapperStyle: childWrapperStyle,
    xWrapperStyle: xWrapperStyle
};
