// Generated by purs version 0.12.0
"use strict";
var Data_Show = require("../Data.Show/index.js");
var Prelude = require("../Prelude/index.js");
var Auto = (function () {
    function Auto() {

    };
    Auto.value = new Auto();
    return Auto;
})();
var Left = (function () {
    function Left() {

    };
    Left.value = new Left();
    return Left;
})();
var Right = (function () {
    function Right() {

    };
    Right.value = new Right();
    return Right;
})();
var Center = (function () {
    function Center() {

    };
    Center.value = new Center();
    return Center;
})();
var Justify = (function () {
    function Justify() {

    };
    Justify.value = new Justify();
    return Justify;
})();
var showTextAlign = new Data_Show.Show(function (v) {
    if (v instanceof Auto) {
        return "auto";
    };
    if (v instanceof Left) {
        return "left";
    };
    if (v instanceof Right) {
        return "right";
    };
    if (v instanceof Center) {
        return "center";
    };
    if (v instanceof Justify) {
        return "justify";
    };
    throw new Error("Failed pattern match at UI.Style.PropTypes.TextAlign line 7, column 1 - line 7, column 41: " + [ v.constructor.name ]);
});
module.exports = {
    Auto: Auto,
    Left: Left,
    Right: Right,
    Center: Center,
    Justify: Justify,
    showTextAlign: showTextAlign
};
