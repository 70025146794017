// Generated by purs version 0.12.0
"use strict";
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var Core_Data = require("../Core.Data/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Lens = require("../Data.Lens/index.js");
var Data_Lens_Getter = require("../Data.Lens.Getter/index.js");
var Data_Lens_Internal_Forget = require("../Data.Lens.Internal.Forget/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Prelude = require("../Prelude/index.js");
var UI_Data_AppData = require("../UI.Data.AppData/index.js");
var UI_Data_Navigation = require("../UI.Data.Navigation/index.js");
var AddDashboard = (function () {
    function AddDashboard(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    AddDashboard.create = function (value0) {
        return function (value1) {
            return new AddDashboard(value0, value1);
        };
    };
    return AddDashboard;
})();
var AddWidget = (function () {
    function AddWidget(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    AddWidget.create = function (value0) {
        return function (value1) {
            return new AddWidget(value0, value1);
        };
    };
    return AddWidget;
})();
var AddWorkspace = (function () {
    function AddWorkspace(value0) {
        this.value0 = value0;
    };
    AddWorkspace.create = function (value0) {
        return new AddWorkspace(value0);
    };
    return AddWorkspace;
})();
var AddTile = (function () {
    function AddTile(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    AddTile.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new AddTile(value0, value1, value2);
            };
        };
    };
    return AddTile;
})();
var DeleteTile = (function () {
    function DeleteTile() {

    };
    DeleteTile.value = new DeleteTile();
    return DeleteTile;
})();
var IncreaseGrow = (function () {
    function IncreaseGrow() {

    };
    IncreaseGrow.value = new IncreaseGrow();
    return IncreaseGrow;
})();
var DecreaseGrow = (function () {
    function DecreaseGrow() {

    };
    DecreaseGrow.value = new DecreaseGrow();
    return DecreaseGrow;
})();
var SetGrow = (function () {
    function SetGrow(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    SetGrow.create = function (value0) {
        return function (value1) {
            return new SetGrow(value0, value1);
        };
    };
    return SetGrow;
})();
var ToggleTileLayout = (function () {
    function ToggleTileLayout(value0) {
        this.value0 = value0;
    };
    ToggleTileLayout.create = function (value0) {
        return new ToggleTileLayout(value0);
    };
    return ToggleTileLayout;
})();
var ToggleShowTileNav = (function () {
    function ToggleShowTileNav(value0) {
        this.value0 = value0;
    };
    ToggleShowTileNav.create = function (value0) {
        return new ToggleShowTileNav(value0);
    };
    return ToggleShowTileNav;
})();
var updateAppNav = function (v) {
    var newAppNav = new UI_Data_Navigation.AppNav({
        modelId: v.model.id,
        selections: {
            focusWorkspace: v.model.localState.focusWorkspace,
            focusDashboard: v.model.localState.focusDashboard,
            focusTile: v.model.localState.focusTile
        },
        lightBox: v.navigation.value0.lightBox
    });
    return {
        model: v.model,
        navigation: newAppNav
    };
};
var updateAppData = function (oldData) {
    return function (model) {
        return updateAppNav({
            model: model,
            navigation: oldData.navigation
        });
    };
};
var getFocusTile = function (model) {
    return Data_Lens_Getter.view(function ($24) {
        return Core_Data._Model(Data_Lens_Internal_Forget.strongForget)(Core_Data._localState(Data_Lens_Internal_Forget.strongForget)(Core_Data._LocalState(Data_Lens_Internal_Forget.strongForget)(Core_Data._focusTile(Data_Lens_Internal_Forget.strongForget)($24))));
    })(model);
};
var handleModelEvent = function (v) {
    return function (appData) {
        if (v instanceof AddDashboard) {
            return Data_Functor.mapFlipped(Data_Either.functorEither)(Core_Data.addDashboard(v.value0)(v.value1)(appData.model))(updateAppData(appData));
        };
        if (v instanceof AddWidget) {
            return Data_Functor.mapFlipped(Data_Either.functorEither)(Core_Data.setWidget({
                title: v.value1
            })(v.value0)(appData.model))(updateAppData(appData));
        };
        if (v instanceof AddWorkspace) {
            return Data_Functor.mapFlipped(Data_Either.functorEither)(Core_Data.addWorkspace(v.value0)(appData.model))(updateAppData(appData));
        };
        if (v instanceof DeleteTile) {
            return Data_Functor.mapFlipped(Data_Either.functorEither)(Core_Data.deleteTile(getFocusTile(appData.model))(appData.model))(updateAppData(appData));
        };
        if (v instanceof ToggleTileLayout) {
            return Data_Functor.mapFlipped(Data_Either.functorEither)(Core_Data.toggleTileLayout(appData.model)(v.value0))(updateAppData(appData));
        };
        if (v instanceof ToggleShowTileNav) {
            return Data_Functor.mapFlipped(Data_Either.functorEither)(Core_Data.toggleShowNav(v.value0)(appData.model))(updateAppData(appData));
        };
        if (v instanceof IncreaseGrow) {
            return Data_Functor.mapFlipped(Data_Either.functorEither)(Core_Data.increaseGrow(getFocusTile(appData.model))(appData.model))(updateAppData(appData));
        };
        if (v instanceof DecreaseGrow) {
            return Data_Functor.mapFlipped(Data_Either.functorEither)(Core_Data.decreaseGrow(getFocusTile(appData.model))(appData.model))(updateAppData(appData));
        };
        if (v instanceof SetGrow) {
            return Data_Functor.mapFlipped(Data_Either.functorEither)(Core_Data.setGrow(v.value0)(v.value1)(appData.model))(updateAppData(appData));
        };
        if (v instanceof AddTile) {
            return Data_Functor.mapFlipped(Data_Either.functorEither)(Core_Data.addTile(v.value0)(v.value1)(v.value2)(appData.model))(updateAppData(appData));
        };
        throw new Error("Failed pattern match at UI.Event.ModelEvent line 24, column 1 - line 24, column 72: " + [ v.constructor.name, appData.constructor.name ]);
    };
};
module.exports = {
    AddDashboard: AddDashboard,
    AddWidget: AddWidget,
    AddWorkspace: AddWorkspace,
    AddTile: AddTile,
    DeleteTile: DeleteTile,
    IncreaseGrow: IncreaseGrow,
    DecreaseGrow: DecreaseGrow,
    SetGrow: SetGrow,
    ToggleTileLayout: ToggleTileLayout,
    ToggleShowTileNav: ToggleShowTileNav,
    handleModelEvent: handleModelEvent,
    updateAppData: updateAppData,
    updateAppNav: updateAppNav,
    getFocusTile: getFocusTile
};
