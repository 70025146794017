// Generated by purs version 0.12.0
"use strict";
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var Core_Data = require("../Core.Data/index.js");
var Data_Lens = require("../Data.Lens/index.js");
var Data_Lens_Fold = require("../Data.Lens.Fold/index.js");
var Data_Lens_Internal_Forget = require("../Data.Lens.Internal.Forget/index.js");
var Data_Lens_Prism_Maybe = require("../Data.Lens.Prism.Maybe/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Maybe_First = require("../Data.Maybe.First/index.js");
var Prelude = require("../Prelude/index.js");
var UI_App_Common_Text = require("../UI.App.Common.Text/index.js");
var UI_Components = require("../UI.Components/index.js");
var render = function (v) {
    var dashboardTitle = function (v1) {
        return Data_Maybe.fromMaybe("Apple Summary")(Data_Lens_Fold.preview(function ($5) {
            return Data_Lens_Prism_Maybe._Just(Data_Lens_Internal_Forget.choiceForget(Data_Maybe_First.monoidFirst))(Core_Data._Title(Data_Lens_Internal_Forget.strongForget)($5));
        })(v1.title));
    };
    return UI_App_Common_Text.mainHeader({
        text: dashboardTitle(v.dashboard)
    });
};
var component = function (props) {
    return UI_Components.stateless("DashboardHeader")(props)(render);
};
module.exports = {
    component: component,
    render: render
};
