// Generated by purs version 0.12.0
"use strict";
var Data_Show = require("../Data.Show/index.js");
var Prelude = require("../Prelude/index.js");
var Solid = (function () {
    function Solid() {

    };
    Solid.value = new Solid();
    return Solid;
})();
var Dotted = (function () {
    function Dotted() {

    };
    Dotted.value = new Dotted();
    return Dotted;
})();
var Dashed = (function () {
    function Dashed() {

    };
    Dashed.value = new Dashed();
    return Dashed;
})();
var showBorderStyle = new Data_Show.Show(function (v) {
    if (v instanceof Solid) {
        return "solid";
    };
    if (v instanceof Dotted) {
        return "dotted";
    };
    if (v instanceof Dashed) {
        return "dashed";
    };
    throw new Error("Failed pattern match at UI.Style.PropTypes.BorderStyle line 7, column 1 - line 7, column 45: " + [ v.constructor.name ]);
});
module.exports = {
    Solid: Solid,
    Dotted: Dotted,
    Dashed: Dashed,
    showBorderStyle: showBorderStyle
};
