// Generated by purs version 0.12.0
"use strict";
var Data_Show = require("../Data.Show/index.js");
var Prelude = require("../Prelude/index.js");
var Normal = (function () {
    function Normal() {

    };
    Normal.value = new Normal();
    return Normal;
})();
var Italic = (function () {
    function Italic() {

    };
    Italic.value = new Italic();
    return Italic;
})();
var showFontStyle = new Data_Show.Show(function (v) {
    if (v instanceof Normal) {
        return "normal";
    };
    if (v instanceof Italic) {
        return "italic";
    };
    throw new Error("Failed pattern match at UI.Style.PropTypes.FontStyle line 7, column 1 - line 7, column 41: " + [ v.constructor.name ]);
});
module.exports = {
    Normal: Normal,
    Italic: Italic,
    showFontStyle: showFontStyle
};
