// Generated by purs version 0.12.0
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var Core_Data = require("../Core.Data/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Lens = require("../Data.Lens/index.js");
var Data_Lens_Getter = require("../Data.Lens.Getter/index.js");
var Data_Lens_Internal_Forget = require("../Data.Lens.Internal.Forget/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_NonEmpty = require("../Data.NonEmpty/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Prelude = require("../Prelude/index.js");
var Run = require("../Run/index.js");
var UI_App_Common = require("../UI.App.Common/index.js");
var UI_App_Dashboard = require("../UI.App.Dashboard/index.js");
var UI_App_Workspace_WorkspaceNav = require("../UI.App.Workspace.WorkspaceNav/index.js");
var UI_Components = require("../UI.Components/index.js");
var UI_Data_Navigation = require("../UI.Data.Navigation/index.js");
var UI_Style_Layout = require("../UI.Style.Layout/index.js");
var UI_Style_PropTypes_FlexDirection = require("../UI.Style.PropTypes.FlexDirection/index.js");
var style = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_App_Common.panel)(function () {
    return UI_Style_Layout.flexDirection(UI_Style_PropTypes_FlexDirection.Row.value);
});
var render = function (v) {
    var dashboards = Data_Lens_Getter.view(function ($15) {
        return Core_Data._Workspace(Data_Lens_Internal_Forget.strongForget)(Core_Data._dashboards(Data_Lens_Internal_Forget.strongForget)($15));
    })(v.workspace);
    var defaultDashboard = Data_NonEmpty.head(dashboards);
    var dashboard = Data_Maybe.fromMaybe(defaultDashboard)(Data_Foldable.find(Data_NonEmpty.foldableNonEmpty(Data_Foldable.foldableArray))(function (v1) {
        return Data_Eq.eq(Core_Data.dashboardIdEq)(v1.id)(v.appNav.selections.focusDashboard);
    })((Data_Lens_Getter.view(Core_Data._Workspace(Data_Lens_Internal_Forget.strongForget))(v.workspace)).dashboards));
    var $7 = v.size.width > 650;
    if ($7) {
        return Control_Bind.bind(Run.bindRun)(UI_App_Dashboard.component({
            dashboard: dashboard,
            focusTile: v.appNav.selections.focusTile,
            publish: v.publish
        }))(function (v1) {
            return Control_Bind.bind(Run.bindRun)(UI_App_Workspace_WorkspaceNav.component({
                workspace: v.workspace,
                publish: v.publish,
                focusDashboard: v.appNav.selections.focusDashboard
            }))(function (v2) {
                return UI_Components.section({
                    style: style
                })(UI_Components.fragment([ v2, v1 ]));
            });
        });
    };
    return Control_Bind.bind(Run.bindRun)(UI_App_Dashboard.component({
        dashboard: dashboard,
        focusTile: v.appNav.selections.focusTile,
        publish: v.publish
    }))(function (v1) {
        return UI_Components.section({
            style: style
        })(UI_Components.fragment([ v1 ]));
    });
};
var component = function (props) {
    return UI_Components.stateless("Workspace")(props)(render);
};
module.exports = {
    component: component,
    render: render,
    style: style
};
