// Generated by purs version 0.12.0
"use strict";
var Data_Show = require("../Data.Show/index.js");
var Prelude = require("../Prelude/index.js");
var FontFamily = function (x) {
    return x;
};
var showFontFamily = new Data_Show.Show(function (v) {
    return v;
});
module.exports = {
    FontFamily: FontFamily,
    showFontFamily: showFontFamily
};
