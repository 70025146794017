// Generated by purs version 0.12.0
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Comonad = require("../Control.Comonad/index.js");
var Control_Extend = require("../Control.Extend/index.js");
var Control_Monad = require("../Control.Monad/index.js");
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var Data_Distributive = require("../Data.Distributive/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Functor_Invariant = require("../Data.Functor.Invariant/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Profunctor = require("../Data.Profunctor/index.js");
var Data_Profunctor_Closed = require("../Data.Profunctor.Closed/index.js");
var Data_Profunctor_Cochoice = require("../Data.Profunctor.Cochoice/index.js");
var Data_Profunctor_Costrong = require("../Data.Profunctor.Costrong/index.js");
var Data_Profunctor_Strong = require("../Data.Profunctor.Strong/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Prelude = require("../Prelude/index.js");
var Costar = function (x) {
    return x;
};
var semigroupoidCostar = function (dictExtend) {
    return new Control_Semigroupoid.Semigroupoid(function (v) {
        return function (v1) {
            return Control_Extend.composeCoKleisliFlipped(dictExtend)(v)(v1);
        };
    });
};
var profunctorCostar = function (dictFunctor) {
    return new Data_Profunctor.Profunctor(function (f) {
        return function (g) {
            return function (v) {
                return function ($54) {
                    return g(v(Data_Functor.map(dictFunctor)(f)($54)));
                };
            };
        };
    });
};
var strongCostar = function (dictComonad) {
    return new Data_Profunctor_Strong.Strong(function () {
        return profunctorCostar((dictComonad.Extend0()).Functor0());
    }, function (v) {
        return function (x) {
            return new Data_Tuple.Tuple(v(Data_Functor.map((dictComonad.Extend0()).Functor0())(Data_Tuple.fst)(x)), Data_Tuple.snd(Control_Comonad.extract(dictComonad)(x)));
        };
    }, function (v) {
        return function (x) {
            return new Data_Tuple.Tuple(Data_Tuple.fst(Control_Comonad.extract(dictComonad)(x)), v(Data_Functor.map((dictComonad.Extend0()).Functor0())(Data_Tuple.snd)(x)));
        };
    });
};
var newtypeCostar = new Data_Newtype.Newtype(function (n) {
    return n;
}, Costar);
var hoistCostar = function (f) {
    return function (v) {
        return Data_Profunctor.lcmap(Data_Profunctor.profunctorFn)(f)(v);
    };
};
var functorCostar = new Data_Functor.Functor(function (f) {
    return function (v) {
        return function ($55) {
            return f(v($55));
        };
    };
});
var invariantCostar = new Data_Functor_Invariant.Invariant(Data_Functor_Invariant.imapF(functorCostar));
var distributiveCostar = new Data_Distributive.Distributive(function () {
    return functorCostar;
}, function (dictFunctor) {
    return function (f) {
        return function ($56) {
            return Data_Distributive.distribute(distributiveCostar)(dictFunctor)(Data_Functor.map(dictFunctor)(f)($56));
        };
    };
}, function (dictFunctor) {
    return function (f) {
        return function (a) {
            return Data_Functor.map(dictFunctor)(function (v) {
                return v(a);
            })(f);
        };
    };
});
var costrongCostar = function (dictFunctor) {
    return new Data_Profunctor_Costrong.Costrong(function () {
        return profunctorCostar(dictFunctor);
    }, function (v) {
        return function (fb) {
            var bd = v(Data_Functor.map(dictFunctor)(function (a) {
                return new Data_Tuple.Tuple(a, Data_Tuple.snd(bd));
            })(fb));
            return Data_Tuple.fst(bd);
        };
    }, function (v) {
        return function (fb) {
            var db = v(Data_Functor.map(dictFunctor)(function (a) {
                return new Data_Tuple.Tuple(Data_Tuple.fst(db), a);
            })(fb));
            return Data_Tuple.snd(db);
        };
    });
};
var cochoiceCostar = function (dictApplicative) {
    return new Data_Profunctor_Cochoice.Cochoice(function () {
        return profunctorCostar((dictApplicative.Apply0()).Functor0());
    }, function (v) {
        var g = function ($57) {
            return Data_Either.either(Control_Category.identity(Control_Category.categoryFn))(function (r) {
                return g(Control_Applicative.pure(dictApplicative)(new Data_Either.Right(r)));
            })(v($57));
        };
        return function ($58) {
            return g(Data_Functor.map((dictApplicative.Apply0()).Functor0())(Data_Either.Left.create)($58));
        };
    }, function (v) {
        var g = function ($59) {
            return Data_Either.either(function (l) {
                return g(Control_Applicative.pure(dictApplicative)(new Data_Either.Left(l)));
            })(Control_Category.identity(Control_Category.categoryFn))(v($59));
        };
        return function ($60) {
            return g(Data_Functor.map((dictApplicative.Apply0()).Functor0())(Data_Either.Right.create)($60));
        };
    });
};
var closedCostar = function (dictFunctor) {
    return new Data_Profunctor_Closed.Closed(function () {
        return profunctorCostar(dictFunctor);
    }, function (v) {
        return function (g) {
            return function (x) {
                return v(Data_Functor.map(dictFunctor)(function (v1) {
                    return v1(x);
                })(g));
            };
        };
    });
};
var categoryCostar = function (dictComonad) {
    return new Control_Category.Category(function () {
        return semigroupoidCostar(dictComonad.Extend0());
    }, Control_Comonad.extract(dictComonad));
};
var applyCostar = new Control_Apply.Apply(function () {
    return functorCostar;
}, function (v) {
    return function (v1) {
        return function (a) {
            return v(a)(v1(a));
        };
    };
});
var bindCostar = new Control_Bind.Bind(function () {
    return applyCostar;
}, function (v) {
    return function (f) {
        return function (x) {
            var v1 = f(v(x));
            return v1(x);
        };
    };
});
var applicativeCostar = new Control_Applicative.Applicative(function () {
    return applyCostar;
}, function (a) {
    return function (v) {
        return a;
    };
});
var monadCostar = new Control_Monad.Monad(function () {
    return applicativeCostar;
}, function () {
    return bindCostar;
});
module.exports = {
    Costar: Costar,
    hoistCostar: hoistCostar,
    newtypeCostar: newtypeCostar,
    semigroupoidCostar: semigroupoidCostar,
    categoryCostar: categoryCostar,
    functorCostar: functorCostar,
    invariantCostar: invariantCostar,
    applyCostar: applyCostar,
    applicativeCostar: applicativeCostar,
    bindCostar: bindCostar,
    monadCostar: monadCostar,
    distributiveCostar: distributiveCostar,
    profunctorCostar: profunctorCostar,
    strongCostar: strongCostar,
    costrongCostar: costrongCostar,
    cochoiceCostar: cochoiceCostar,
    closedCostar: closedCostar
};
