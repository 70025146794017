// Generated by purs version 0.12.0
"use strict";
var Control_Comonad_Cofree = require("../Control.Comonad.Cofree/index.js");
var Control_Monad_Rec_Class = require("../Control.Monad.Rec.Class/index.js");
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_List = require("../Data.List/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Prelude = require("../Prelude/index.js");
var setNodeValue = function (a) {
    return function (n) {
        return Control_Comonad_Cofree.mkCofree(a)(Control_Comonad_Cofree.tail(n));
    };
};
var scanTreeAccum = function (f) {
    return function (b) {
        return function (n) {
            var go = function (v) {
                if (v.current instanceof Data_List_Types.Nil) {
                    return new Control_Monad_Rec_Class.Done(v["final"]);
                };
                if (v.current instanceof Data_List_Types.Cons) {
                    var fb$prime = f(Control_Comonad_Cofree.head(v.current.value0))(v.b);
                    return new Control_Monad_Rec_Class.Loop({
                        b: v.b,
                        current: v.current.value1,
                        "final": Data_List.snoc(v["final"])(Control_Comonad_Cofree.mkCofree(fb$prime.value)(Control_Monad_Rec_Class.tailRec(go)({
                            b: fb$prime.accum,
                            current: Control_Comonad_Cofree.tail(v.current.value0),
                            "final": Data_List_Types.Nil.value
                        })))
                    });
                };
                throw new Error("Failed pattern match at Data.Tree line 55, column 5 - line 55, column 135: " + [ v.constructor.name ]);
            };
            var fb = f(Control_Comonad_Cofree.head(n))(b);
            return Control_Comonad_Cofree.mkCofree(fb.value)(Control_Monad_Rec_Class.tailRec(go)({
                b: fb.accum,
                current: Control_Comonad_Cofree.tail(n),
                "final": Data_List_Types.Nil.value
            }));
        };
    };
};
var scanTree = function (f) {
    return function (b) {
        return function (n) {
            var go = function (v) {
                if (v.current instanceof Data_List_Types.Nil) {
                    return new Control_Monad_Rec_Class.Done(v["final"]);
                };
                if (v.current instanceof Data_List_Types.Cons) {
                    var fb$prime = f(Control_Comonad_Cofree.head(v.current.value0))(v.b);
                    return new Control_Monad_Rec_Class.Loop({
                        b: v.b,
                        current: v.current.value1,
                        "final": Data_List.snoc(v["final"])(Control_Comonad_Cofree.mkCofree(fb$prime)(Control_Monad_Rec_Class.tailRec(go)({
                            b: fb$prime,
                            current: Control_Comonad_Cofree.tail(v.current.value0),
                            "final": Data_List_Types.Nil.value
                        })))
                    });
                };
                throw new Error("Failed pattern match at Data.Tree line 42, column 5 - line 42, column 135: " + [ v.constructor.name ]);
            };
            var fb = f(Control_Comonad_Cofree.head(n))(b);
            return Control_Comonad_Cofree.mkCofree(fb)(Control_Monad_Rec_Class.tailRec(go)({
                b: fb,
                current: Control_Comonad_Cofree.tail(n),
                "final": Data_List_Types.Nil.value
            }));
        };
    };
};
var modifyNodeValue = function (f) {
    return function (n) {
        return Control_Comonad_Cofree.mkCofree(f(Control_Comonad_Cofree.head(n)))(Control_Comonad_Cofree.tail(n));
    };
};
var mkTree = Control_Comonad_Cofree.mkCofree;
var drawTree = function (t) {
    var go = function (v) {
        if (v.current instanceof Data_List_Types.Nil) {
            return new Control_Monad_Rec_Class.Done(v.drawn);
        };
        if (v.current instanceof Data_List_Types.Cons) {
            var drawn = Data_Monoid.power(Data_Monoid.monoidString)("       ")(v.level) + ("|----> " + (Control_Comonad_Cofree.head(v.current.value0) + "\x0a"));
            return new Control_Monad_Rec_Class.Loop({
                level: v.level,
                drawn: v.drawn + (drawn + Control_Monad_Rec_Class.tailRec(go)({
                    level: v.level + 1 | 0,
                    drawn: "",
                    current: Control_Comonad_Cofree.tail(v.current.value0)
                })),
                current: v.current.value1
            });
        };
        throw new Error("Failed pattern match at Data.Tree line 24, column 5 - line 24, column 149: " + [ v.constructor.name ]);
    };
    return Control_Monad_Rec_Class.tailRec(go)({
        level: 0,
        drawn: Control_Comonad_Cofree.head(t) + "\x0a",
        current: Control_Comonad_Cofree.tail(t)
    });
};
var showTree = function (dictShow) {
    return function ($31) {
        return drawTree(Data_Functor.map(Control_Comonad_Cofree.functorCofree(Data_List_Types.functorList))(Data_Show.show(dictShow))($31));
    };
};
var appendChild = function (c) {
    return function (n) {
        return Control_Comonad_Cofree.mkCofree(Control_Comonad_Cofree.head(n))(Data_List.snoc(Control_Comonad_Cofree.tail(n))(c));
    };
};
module.exports = {
    mkTree: mkTree,
    drawTree: drawTree,
    showTree: showTree,
    scanTree: scanTree,
    scanTreeAccum: scanTreeAccum,
    setNodeValue: setNodeValue,
    modifyNodeValue: modifyNodeValue,
    appendChild: appendChild
};
