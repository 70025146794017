"use strict";

var React = require("react");
var ReactDOM = require("react-dom");
var Main = require("../output/Main/index.js");

var pathAndQueryString = (window.location.pathname || "") + (window.location.search || "");

var elem = Main.main(pathAndQueryString)();

ReactDOM.hydrate(
  elem,
  document.getElementById("app")
);
