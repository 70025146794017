// Generated by purs version 0.12.0
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Core_Data = require("../Core.Data/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_NonEmpty = require("../Data.NonEmpty/index.js");
var Prelude = require("../Prelude/index.js");
var Run = require("../Run/index.js");
var UI_App_Common = require("../UI.App.Common/index.js");
var UI_App_Workspaces_AddWorkspaceButton = require("../UI.App.Workspaces.AddWorkspaceButton/index.js");
var UI_App_Workspaces_WorkspaceIconList = require("../UI.App.Workspaces.WorkspaceIconList/index.js");
var UI_Components = require("../UI.Components/index.js");
var UI_Style = require("../UI.Style/index.js");
var UI_Style_Layout = require("../UI.Style.Layout/index.js");
var UI_Style_PropTypes_FlexDirection = require("../UI.Style.PropTypes.FlexDirection/index.js");
var style = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_App_Common.panel)(function () {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_App_Common.outline)(function () {
        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.flexDirection(UI_Style_PropTypes_FlexDirection.Column.value))(function () {
            return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.maxWidth(new UI_Style.Pixel(60)))(function () {
                return UI_Style_Layout.paddingTop(new UI_Style.Pixel(10));
            });
        });
    });
});
var render = function (v) {
    return Control_Bind.bind(Run.bindRun)(UI_App_Workspaces_WorkspaceIconList.component({
        workspaces: v.workspaces,
        focusWorkspace: v.focusWorkspace,
        publish: v.publish
    }))(function (v1) {
        return Control_Bind.bind(Run.bindRun)(UI_App_Workspaces_AddWorkspaceButton.component({
            publish: v.publish
        }))(function (v2) {
            return UI_Components.nav({
                style: style
            })(UI_Components.fragment([ v1, v2 ]));
        });
    });
};
var component = function (props) {
    return UI_Components.stateless("WorkspaceNav")(props)(render);
};
module.exports = {
    component: component,
    render: render,
    style: style
};
