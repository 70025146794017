// Generated by purs version 0.12.0
"use strict";
var Data_Either = require("../Data.Either/index.js");
var Data_Profunctor = require("../Data.Profunctor/index.js");
var Cochoice = function (Profunctor0, unleft, unright) {
    this.Profunctor0 = Profunctor0;
    this.unleft = unleft;
    this.unright = unright;
};
var unright = function (dict) {
    return dict.unright;
};
var unleft = function (dict) {
    return dict.unleft;
};
module.exports = {
    unleft: unleft,
    unright: unright,
    Cochoice: Cochoice
};
