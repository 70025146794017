// Generated by purs version 0.12.0
"use strict";
var Data_Show = require("../Data.Show/index.js");
var Prelude = require("../Prelude/index.js");
var Row = (function () {
    function Row() {

    };
    Row.value = new Row();
    return Row;
})();
var RowReverse = (function () {
    function RowReverse() {

    };
    RowReverse.value = new RowReverse();
    return RowReverse;
})();
var Column = (function () {
    function Column() {

    };
    Column.value = new Column();
    return Column;
})();
var ColumnReverse = (function () {
    function ColumnReverse() {

    };
    ColumnReverse.value = new ColumnReverse();
    return ColumnReverse;
})();
var showFlexDirection = new Data_Show.Show(function (v) {
    if (v instanceof Row) {
        return "row";
    };
    if (v instanceof RowReverse) {
        return "row-reverse";
    };
    if (v instanceof Column) {
        return "column";
    };
    if (v instanceof ColumnReverse) {
        return "column-reverse";
    };
    throw new Error("Failed pattern match at UI.Style.PropTypes.FlexDirection line 7, column 1 - line 7, column 49: " + [ v.constructor.name ]);
});
module.exports = {
    Row: Row,
    RowReverse: RowReverse,
    Column: Column,
    ColumnReverse: ColumnReverse,
    showFlexDirection: showFlexDirection
};
