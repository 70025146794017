// Generated by purs version 0.12.0
"use strict";
var Data_Either = require("../Data.Either/index.js");
var Data_Lens_Prism = require("../Data.Lens.Prism/index.js");
var Data_Profunctor_Choice = require("../Data.Profunctor.Choice/index.js");
var _Right = function (dictChoice) {
    return Data_Profunctor_Choice.right(dictChoice);
};
var _Left = function (dictChoice) {
    return Data_Profunctor_Choice.left(dictChoice);
};
module.exports = {
    _Left: _Left,
    _Right: _Right
};
