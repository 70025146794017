// Generated by purs version 0.12.0
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Data_Function = require("../Data.Function/index.js");
var Effect = require("../Effect/index.js");
var Prelude = require("../Prelude/index.js");
var Run = require("../Run/index.js");
var UI_Components = require("../UI.Components/index.js");
var UI_Style = require("../UI.Style/index.js");
var UI_Style_Layout = require("../UI.Style.Layout/index.js");
var UI_Style_PropTypes_AlignSelf = require("../UI.Style.PropTypes.AlignSelf/index.js");
var UI_Style_PropTypes_Display = require("../UI.Style.PropTypes.Display/index.js");
var UI_Style_PropTypes_FlexDirection = require("../UI.Style.PropTypes.FlexDirection/index.js");
var style = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.alignSelf(UI_Style_PropTypes_AlignSelf.Center.value))(function () {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.height(new UI_Style.Pixel(12)))(function () {
        return UI_Style_Layout.width(new UI_Style.Pixel(12));
    });
});
var divStyle = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.display(UI_Style_PropTypes_Display.Flex.value))(function () {
    return UI_Style_Layout.flexDirection(UI_Style_PropTypes_FlexDirection.Column.value);
});
var render = function (v) {
    return Control_Bind.bind(Run.bindRun)(Control_Bind.bind(Run.bindRun)(UI_Components.image({
        src: "/assets/images/x.png",
        style: style
    }))(UI_Components.makeTouchable(v.onClick)))(function (v1) {
        return UI_Components.div({
            style: divStyle
        })(v1);
    });
};
var component = function (props) {
    return UI_Components.stateless("XPlusButton")(props)(render);
};
module.exports = {
    component: component,
    render: render,
    style: style,
    divStyle: divStyle
};
