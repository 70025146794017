// Generated by purs version 0.12.0
"use strict";
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Comonad_Cofree = require("../Control.Comonad.Cofree/index.js");
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Lens = require("../Data.Lens/index.js");
var Data_Lens_Fold = require("../Data.Lens.Fold/index.js");
var Data_Lens_Getter = require("../Data.Lens.Getter/index.js");
var Data_Lens_Internal_Forget = require("../Data.Lens.Internal.Forget/index.js");
var Data_Lens_Internal_Wander = require("../Data.Lens.Internal.Wander/index.js");
var Data_Lens_Lens = require("../Data.Lens.Lens/index.js");
var Data_Lens_Prism_Maybe = require("../Data.Lens.Prism.Maybe/index.js");
var Data_Lens_Record = require("../Data.Lens.Record/index.js");
var Data_Lens_Setter = require("../Data.Lens.Setter/index.js");
var Data_Lens_Traversal = require("../Data.Lens.Traversal/index.js");
var Data_List = require("../Data.List/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Maybe_First = require("../Data.Maybe.First/index.js");
var Data_NonEmpty = require("../Data.NonEmpty/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Profunctor_Strong = require("../Data.Profunctor.Strong/index.js");
var Data_Ring = require("../Data.Ring/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Data_Tree = require("../Data.Tree/index.js");
var Data_Tree_Zipper = require("../Data.Tree.Zipper/index.js");
var Data_UUID = require("../Data.UUID/index.js");
var Partial_Unsafe = require("../Partial.Unsafe/index.js");
var Prelude = require("../Prelude/index.js");
var Record = require("../Record/index.js");
var WorkspaceId = function (x) {
    return x;
};
var LoggedOut = (function () {
    function LoggedOut(value0) {
        this.value0 = value0;
    };
    LoggedOut.create = function (value0) {
        return new LoggedOut(value0);
    };
    return LoggedOut;
})();
var LoggedIn = (function () {
    function LoggedIn(value0) {
        this.value0 = value0;
    };
    LoggedIn.create = function (value0) {
        return new LoggedIn(value0);
    };
    return LoggedIn;
})();
var Title = function (x) {
    return x;
};
var Widget = function (x) {
    return x;
};
var TileId = function (x) {
    return x;
};
var ModelId = function (x) {
    return x;
};
var WorkspaceNotFound = (function () {
    function WorkspaceNotFound() {

    };
    WorkspaceNotFound.value = new WorkspaceNotFound();
    return WorkspaceNotFound;
})();
var DashboardNotFound = (function () {
    function DashboardNotFound() {

    };
    DashboardNotFound.value = new DashboardNotFound();
    return DashboardNotFound;
})();
var TileNotFound = (function () {
    function TileNotFound() {

    };
    TileNotFound.value = new TileNotFound();
    return TileNotFound;
})();
var TileDeleteAttemptedOnTileWithChildren = (function () {
    function TileDeleteAttemptedOnTileWithChildren() {

    };
    TileDeleteAttemptedOnTileWithChildren.value = new TileDeleteAttemptedOnTileWithChildren();
    return TileDeleteAttemptedOnTileWithChildren;
})();
var TileDeleteAttemptedOnRootTile = (function () {
    function TileDeleteAttemptedOnRootTile() {

    };
    TileDeleteAttemptedOnRootTile.value = new TileDeleteAttemptedOnRootTile();
    return TileDeleteAttemptedOnRootTile;
})();
var TileSetWidgetOnNonLeafTile = (function () {
    function TileSetWidgetOnNonLeafTile() {

    };
    TileSetWidgetOnNonLeafTile.value = new TileSetWidgetOnNonLeafTile();
    return TileSetWidgetOnNonLeafTile;
})();
var Row = (function () {
    function Row() {

    };
    Row.value = new Row();
    return Row;
})();
var Column = (function () {
    function Column() {

    };
    Column.value = new Column();
    return Column;
})();
var Tabbed = (function () {
    function Tabbed() {

    };
    Tabbed.value = new Tabbed();
    return Tabbed;
})();
var Tile = function (x) {
    return x;
};
var DashboardId = function (x) {
    return x;
};
var LocalState = function (x) {
    return x;
};
var Dashboard = function (x) {
    return x;
};
var Workspace = function (x) {
    return x;
};
var Model = function (x) {
    return x;
};
var workspaceIdEq = new Data_Eq.Eq(function (v) {
    return function (v1) {
        return v === v1;
    };
});
var userStateEq = new Data_Eq.Eq(function (v) {
    return function (v1) {
        if (v instanceof LoggedOut && v1 instanceof LoggedOut) {
            return Data_Eq.eq(Data_UUID.eqUUID)(v.value0)(v1.value0);
        };
        if (v instanceof LoggedIn && v1 instanceof LoggedIn) {
            return Data_Eq.eq(Data_UUID.eqUUID)(v.value0)(v1.value0);
        };
        return false;
    };
});
var titleEq = new Data_Eq.Eq(function (v) {
    return function (v1) {
        return v === v1;
    };
});
var widgetEq = new Data_Eq.Eq(function (v) {
    return function (v1) {
        return Record.equal()(Record.equalFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "title";
        }))(Data_Maybe.eqMaybe(titleEq))()(Record.equalFieldsNil))(v)(v1);
    };
});
var tileIdEq = new Data_Eq.Eq(function (v) {
    return function (v1) {
        return v === v1;
    };
});
var siblingTiles = function (locTile) {
    return Data_Functor.mapFlipped(Data_List_Types.functorList)(Data_Tree_Zipper.siblings(locTile))(function ($300) {
        return Data_Tree_Zipper.value(Data_Tree_Zipper.fromTree($300));
    });
};
var showLayout = new Data_Show.Show(function (v) {
    if (v instanceof Row) {
        return "Row";
    };
    if (v instanceof Column) {
        return "Column";
    };
    if (v instanceof Tabbed) {
        return "Tabbed";
    };
    throw new Error("Failed pattern match at Core.Data line 338, column 1 - line 338, column 35: " + [ v.constructor.name ]);
});
var nonEmptyToArray = function (nonEmpty) {
    return Data_Array.cons(Data_NonEmpty.head(nonEmpty))(Data_NonEmpty.tail(nonEmpty));
};
var moveWidget = function (v) {
    return function (v1) {
        return {
            giver: {
                id: v.id,
                layout: v.layout,
                grow: v.grow,
                showNav: v.showNav,
                widget: Data_Maybe.Nothing.value
            },
            taker: {
                id: v1.id,
                layout: v1.layout,
                grow: v1.grow,
                showNav: v1.showNav,
                widget: v.widget
            }
        };
    };
};
var modelIdEq = new Data_Eq.Eq(function (v) {
    return function (v1) {
        return Data_Eq.eq(Data_UUID.eqUUID)(v)(v1);
    };
});
var layoutEq = new Data_Eq.Eq(function (v) {
    return function (v1) {
        if (v instanceof Row && v1 instanceof Row) {
            return true;
        };
        if (v instanceof Column && v1 instanceof Column) {
            return true;
        };
        if (v instanceof Tabbed && v1 instanceof Tabbed) {
            return true;
        };
        return false;
    };
});
var tileEq = new Data_Eq.Eq(function (v) {
    return function (v1) {
        return Record.equal()(Record.equalFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "grow";
        }))(Data_Eq.eqNumber)()(Record.equalFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "id";
        }))(tileIdEq)()(Record.equalFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "layout";
        }))(layoutEq)()(Record.equalFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "showNav";
        }))(Data_Eq.eqBoolean)()(Record.equalFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "widget";
        }))(Data_Maybe.eqMaybe(widgetEq))()(Record.equalFieldsNil))))))(v)(v1);
    };
});
var isLeaf = function ($301) {
    return Data_Maybe.isNothing(Data_Tree_Zipper.down($301));
};
var hasParent = function ($302) {
    return Data_Maybe.isJust(Data_Tree_Zipper.up($302));
};
var getWorkspace = function (workspaceId) {
    return function (v) {
        var maybeWorkspace = Data_Foldable.find(Data_NonEmpty.foldableNonEmpty(Data_Foldable.foldableArray))(function (v1) {
            return Data_Eq.eq(workspaceIdEq)(v1.id)(workspaceId);
        })(v.workspaces);
        if (maybeWorkspace instanceof Data_Maybe.Just) {
            return new Data_Either.Right(maybeWorkspace.value0);
        };
        if (maybeWorkspace instanceof Data_Maybe.Nothing) {
            return new Data_Either.Left(WorkspaceNotFound.value);
        };
        throw new Error("Failed pattern match at Core.Data line 393, column 3 - line 395, column 38: " + [ maybeWorkspace.constructor.name ]);
    };
};
var flattenLocDepthFirst = function (loc) {
    var goDir = function (loc$prime) {
        return function (dirFn) {
            var v = dirFn(loc$prime);
            if (v instanceof Data_Maybe.Just) {
                return new Data_List_Types.Cons(v.value0, go(v.value0));
            };
            if (v instanceof Data_Maybe.Nothing) {
                return Data_List_Types.Nil.value;
            };
            throw new Error("Failed pattern match at Core.Data line 836, column 24 - line 838, column 21: " + [ v.constructor.name ]);
        };
    };
    var go = function (loc$prime) {
        var nexts = goDir(loc$prime)(Data_Tree_Zipper.next);
        var downs = goDir(loc$prime)(Data_Tree_Zipper.down);
        return Data_Semigroup.append(Data_List_Types.semigroupList)(downs)(nexts);
    };
    return new Data_List_Types.Cons(loc, go(loc));
};
var firstWorkspace = function (v) {
    return Data_NonEmpty.head(v.workspaces);
};
var firstTileLoc = function ($303) {
    return Data_Tree_Zipper.root(Data_Tree_Zipper.fromTree($303));
};
var firstTileInTiles = function ($304) {
    return Data_Tree_Zipper.value(firstTileLoc($304));
};
var findDownWhere = function (predicate) {
    return function (loc) {
        if (predicate(Data_Tree_Zipper.value(loc))) {
            return new Data_Maybe.Just(loc);
        };
        var lookNext = Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Tree_Zipper.next(loc))(findDownWhere(predicate));
        var lookDown = Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Tree_Zipper.down(loc))(findDownWhere(predicate));
        return Control_Alt.alt(Data_Maybe.altMaybe)(lookNext)(lookDown);
    };
};
var findFromRootWhere = function (predicate) {
    return function (loc) {
        if (predicate(Data_Tree_Zipper.value(loc))) {
            return new Data_Maybe.Just(loc);
        };
        return findDownWhere(predicate)(Data_Tree_Zipper.root(loc));
    };
};
var eqModelError = new Data_Eq.Eq(function (v) {
    return function (v1) {
        if (v instanceof WorkspaceNotFound && v1 instanceof WorkspaceNotFound) {
            return true;
        };
        if (v instanceof DashboardNotFound && v1 instanceof DashboardNotFound) {
            return true;
        };
        if (v instanceof TileNotFound && v1 instanceof TileNotFound) {
            return true;
        };
        return false;
    };
});
var defaultLocalState = {
    focusWorkspace: 1,
    focusDashboard: 1,
    focusTile: 1
};
var defaultGrow = 100.0;
var defaultTile = {
    id: 1,
    layout: Row.value,
    grow: defaultGrow,
    showNav: true,
    widget: Data_Maybe.Nothing.value
};
var defaultTiles = Data_Tree.mkTree(defaultTile)(Data_List_Types.Nil.value);
var defaultDashboard = {
    id: 1,
    tiles: defaultTiles,
    title: Data_Maybe.Nothing.value
};
var defaultDashboards = new Data_NonEmpty.NonEmpty(defaultDashboard, [  ]);
var defaultWorkspace = {
    id: 1,
    dashboards: defaultDashboards,
    title: Data_Maybe.Nothing.value
};
var defaultWorkspaces = new Data_NonEmpty.NonEmpty(defaultWorkspace, [  ]);
var newModel = function (id) {
    return function (who) {
        return {
            id: id,
            workspaces: defaultWorkspaces,
            who: who,
            localState: defaultLocalState
        };
    };
};
var dashboardIdEq = new Data_Eq.Eq(function (v) {
    return function (v1) {
        return v === v1;
    };
});
var localStateEq = new Data_Eq.Eq(function (v) {
    return function (v1) {
        return Record.equal()(Record.equalFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "focusDashboard";
        }))(dashboardIdEq)()(Record.equalFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "focusTile";
        }))(tileIdEq)()(Record.equalFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "focusWorkspace";
        }))(workspaceIdEq)()(Record.equalFieldsNil))))(v)(v1);
    };
});
var dashboardEq = new Data_Eq.Eq(function (v) {
    return function (v1) {
        return Record.equal()(Record.equalFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "id";
        }))(dashboardIdEq)()(Record.equalFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "tiles";
        }))(Control_Comonad_Cofree.eqCofree(Data_List_Types.eq1List)(tileEq))()(Record.equalFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "title";
        }))(Data_Maybe.eqMaybe(titleEq))()(Record.equalFieldsNil))))(v)(v1);
    };
});
var workspaceEq = new Data_Eq.Eq(function (v) {
    return function (v1) {
        return Record.equal()(Record.equalFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "dashboards";
        }))(Data_NonEmpty.eqNonEmpty(Data_Eq.eq1Array)(dashboardEq))()(Record.equalFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "id";
        }))(workspaceIdEq)()(Record.equalFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "title";
        }))(Data_Maybe.eqMaybe(titleEq))()(Record.equalFieldsNil))))(v)(v1);
    };
});
var modelEq = new Data_Eq.Eq(function (v) {
    return function (v1) {
        return Record.equal()(Record.equalFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "id";
        }))(modelIdEq)()(Record.equalFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "localState";
        }))(localStateEq)()(Record.equalFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "who";
        }))(userStateEq)()(Record.equalFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "workspaces";
        }))(Data_NonEmpty.eqNonEmpty(Data_Eq.eq1Array)(workspaceEq))()(Record.equalFieldsNil)))))(v)(v1);
    };
});
var childTiles = function (locTile) {
    return Data_Functor.mapFlipped(Data_List_Types.functorList)(Data_Tree_Zipper.children(locTile))(function ($305) {
        return Data_Tree_Zipper.value(Data_Tree_Zipper.fromTree($305));
    });
};
var _workspaces = function (dictStrong) {
    return Data_Lens_Record.prop(new Data_Symbol.IsSymbol(function () {
        return "workspaces";
    }))()()(Data_Symbol.SProxy.value)(dictStrong);
};
var _widget = function (dictStrong) {
    return Data_Lens_Record.prop(new Data_Symbol.IsSymbol(function () {
        return "widget";
    }))()()(Data_Symbol.SProxy.value)(dictStrong);
};
var _title = function (dictStrong) {
    return Data_Lens_Record.prop(new Data_Symbol.IsSymbol(function () {
        return "title";
    }))()()(Data_Symbol.SProxy.value)(dictStrong);
};
var _tiles = function (dictStrong) {
    return Data_Lens_Record.prop(new Data_Symbol.IsSymbol(function () {
        return "tiles";
    }))()()(Data_Symbol.SProxy.value)(dictStrong);
};
var _showNav = function (dictStrong) {
    return Data_Lens_Record.prop(new Data_Symbol.IsSymbol(function () {
        return "showNav";
    }))()()(Data_Symbol.SProxy.value)(dictStrong);
};
var _localState = function (dictStrong) {
    return Data_Lens_Record.prop(new Data_Symbol.IsSymbol(function () {
        return "localState";
    }))()()(Data_Symbol.SProxy.value)(dictStrong);
};
var _layout = function (dictStrong) {
    return Data_Lens_Record.prop(new Data_Symbol.IsSymbol(function () {
        return "layout";
    }))()()(Data_Symbol.SProxy.value)(dictStrong);
};
var _id = function (dictStrong) {
    return Data_Lens_Record.prop(new Data_Symbol.IsSymbol(function () {
        return "id";
    }))()()(Data_Symbol.SProxy.value)(dictStrong);
};
var _grow = function (dictStrong) {
    return Data_Lens_Record.prop(new Data_Symbol.IsSymbol(function () {
        return "grow";
    }))()()(Data_Symbol.SProxy.value)(dictStrong);
};
var _focusWorkspace = function (dictStrong) {
    return Data_Lens_Record.prop(new Data_Symbol.IsSymbol(function () {
        return "focusWorkspace";
    }))()()(Data_Symbol.SProxy.value)(dictStrong);
};
var _focusTile = function (dictStrong) {
    return Data_Lens_Record.prop(new Data_Symbol.IsSymbol(function () {
        return "focusTile";
    }))()()(Data_Symbol.SProxy.value)(dictStrong);
};
var _focusDashboard = function (dictStrong) {
    return Data_Lens_Record.prop(new Data_Symbol.IsSymbol(function () {
        return "focusDashboard";
    }))()()(Data_Symbol.SProxy.value)(dictStrong);
};
var _dashboards = function (dictStrong) {
    return Data_Lens_Record.prop(new Data_Symbol.IsSymbol(function () {
        return "dashboards";
    }))()()(Data_Symbol.SProxy.value)(dictStrong);
};
var _WorkspaceId = function (dictStrong) {
    return Data_Lens_Lens.lens(function (v) {
        return v;
    })(function (v) {
        return WorkspaceId;
    })(dictStrong);
};
var _Workspace = function (dictStrong) {
    return Data_Lens_Lens.lens(function (v) {
        return v;
    })(function (v) {
        return Workspace;
    })(dictStrong);
};
var dashboards = function (v) {
    return Data_NonEmpty.foldl1(Data_Foldable.foldableArray)(function (l) {
        return function (r) {
            return new Data_NonEmpty.NonEmpty(Data_NonEmpty.head(l), Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_NonEmpty.tail(l))(nonEmptyToArray(r)));
        };
    })(Data_Functor.mapFlipped(Data_NonEmpty.functorNonEmpty(Data_Functor.functorArray))(v.workspaces)(Data_Lens_Getter.view(function ($306) {
        return _Workspace(Data_Lens_Internal_Forget.strongForget)(_dashboards(Data_Lens_Internal_Forget.strongForget)($306));
    })));
};
var findDashboard = function (model) {
    return function (predicate) {
        return Data_Foldable.find(Data_NonEmpty.foldableNonEmpty(Data_Foldable.foldableArray))(predicate)(dashboards(model));
    };
};
var getDashboard = function (dashboardId) {
    return function (model) {
        var maybeDashboard = findDashboard(model)(function (v) {
            return Data_Eq.eq(dashboardIdEq)(v.id)(dashboardId);
        });
        return Data_Either.note(DashboardNotFound.value)(maybeDashboard);
    };
};
var getDashboardFromTileId = function (model) {
    return function (tileId) {
        var predicate = function (v) {
            return Data_Maybe.isJust(Data_Foldable.find(Control_Comonad_Cofree.foldableCofree(Data_List_Types.foldableList))(function (v1) {
                return Data_Eq.eq(tileIdEq)(v1.id)(tileId);
            })(v.tiles));
        };
        var maybeDashboard = findDashboard(model)(predicate);
        return Data_Either.note(DashboardNotFound.value)(maybeDashboard);
    };
};
var firstDashboard = function (model) {
    return Data_NonEmpty.head(Data_Lens_Getter.view(function ($307) {
        return _Workspace(Data_Lens_Internal_Forget.strongForget)(_dashboards(Data_Lens_Internal_Forget.strongForget)($307));
    })(firstWorkspace(model)));
};
var firstWorkspaceId = function (model) {
    return Data_Lens_Getter.view(function ($308) {
        return _Workspace(Data_Lens_Internal_Forget.strongForget)(_id(Data_Lens_Internal_Forget.strongForget)($308));
    })(firstWorkspace(model));
};
var nextWorkspaceId = function (v) {
    var maxId = Data_Maybe.fromJust()(Data_Foldable.maximum(Data_Ord.ordInt)(Data_NonEmpty.foldableNonEmpty(Data_Foldable.foldableArray))(Data_Functor.map(Data_NonEmpty.functorNonEmpty(Data_Functor.functorArray))(Data_Lens_Getter.view(function ($309) {
        return _Workspace(Data_Lens_Internal_Forget.strongForget)(_id(Data_Lens_Internal_Forget.strongForget)(_WorkspaceId(Data_Lens_Internal_Forget.strongForget)($309)));
    }))(v.workspaces)));
    return maxId + 1 | 0;
};
var _Widget = function (dictStrong) {
    return Data_Lens_Lens.lens(function (v) {
        return v;
    })(function (v) {
        return Widget;
    })(dictStrong);
};
var _Title = function (dictStrong) {
    return Data_Lens_Lens.lens(function (v) {
        return v;
    })(function (v) {
        return Title;
    })(dictStrong);
};
var getWidgetTitle = function (v) {
    return Data_Lens_Fold.previewOn(v.widget)(function ($310) {
        return Data_Lens_Prism_Maybe._Just(Data_Lens_Internal_Forget.choiceForget(Data_Maybe_First.monoidFirst))(_Widget(Data_Lens_Internal_Forget.strongForget)(_title(Data_Lens_Internal_Forget.strongForget)(Data_Lens_Prism_Maybe._Just(Data_Lens_Internal_Forget.choiceForget(Data_Maybe_First.monoidFirst))(_Title(Data_Lens_Internal_Forget.strongForget)($310)))));
    });
};
var _TileId = function (dictStrong) {
    return Data_Lens_Lens.lens(function (v) {
        return v;
    })(function (v) {
        return TileId;
    })(dictStrong);
};
var _Tile = function (dictStrong) {
    return Data_Lens_Lens.lens(function (v) {
        return v;
    })(function (v) {
        return Tile;
    })(dictStrong);
};
var grow = Data_Lens_Getter.view(function ($311) {
    return _Tile(Data_Lens_Internal_Forget.strongForget)(_grow(Data_Lens_Internal_Forget.strongForget)($311));
});
var locGrow = function ($312) {
    return grow(Data_Tree_Zipper.value($312));
};
var showTileIdTree = function ($313) {
    return Data_Tree.showTree(Data_Show.showInt)(Data_Functor.map(Control_Comonad_Cofree.functorCofree(Data_List_Types.functorList))(Data_Lens_Getter.view(function ($314) {
        return _Tile(Data_Lens_Internal_Forget.strongForget)(_id(Data_Lens_Internal_Forget.strongForget)(_TileId(Data_Lens_Internal_Forget.strongForget)($314)));
    }))($313));
};
var showTileIdTreeFromDashboard = function (model) {
    return function (dashboardId) {
        var eitherTree = Control_Bind.bind(Data_Either.bindEither)(getDashboard(dashboardId)(model))(function (v) {
            return Data_Either.Right.create(showTileIdTree(v.tiles));
        });
        return Data_Either.either(function (v) {
            return "";
        })(Control_Category.identity(Control_Category.categoryFn))(eitherTree);
    };
};
var _ModelId = function (dictStrong) {
    return Data_Lens_Lens.lens(function (v) {
        return v;
    })(function (v) {
        return ModelId;
    })(dictStrong);
};
var _Model = function (dictStrong) {
    return Data_Lens_Lens.lens(function (v) {
        return v;
    })(function (v) {
        return Model;
    })(dictStrong);
};
var modifyWorkspaces = function (fn) {
    return Data_Lens_Setter.over(function ($315) {
        return _Model(Data_Profunctor_Strong.strongFn)(_workspaces(Data_Profunctor_Strong.strongFn)($315));
    })(Data_Lens_Traversal.traversed(Data_NonEmpty.traversableNonEmpty(Data_Traversable.traversableArray))(Data_Lens_Internal_Wander.wanderFunction)(Data_Lens_Setter.over(Control_Category.identity(Control_Category.categoryFn))(fn)));
};
var modifyDashboards = function (fn) {
    return modifyWorkspaces(function (workspace) {
        return Data_Lens_Setter.over(function ($316) {
            return _Workspace(Data_Profunctor_Strong.strongFn)(_dashboards(Data_Profunctor_Strong.strongFn)($316));
        })(Data_Lens_Traversal.traversed(Data_NonEmpty.traversableNonEmpty(Data_Traversable.traversableArray))(Data_Lens_Internal_Wander.wanderFunction)(Data_Lens_Setter.over(Control_Category.identity(Control_Category.categoryFn))(fn)))(workspace);
    });
};
var setDashboardTitle = function (dashboardId) {
    return function (title) {
        return function (model) {
            var update = function (v) {
                if (Data_Eq.eq(dashboardIdEq)(v.id)(dashboardId)) {
                    return {
                        id: v.id,
                        tiles: v.tiles,
                        title: new Data_Maybe.Just(title)
                    };
                };
                return v;
            };
            return Control_Bind.bind(Data_Either.bindEither)(getDashboard(dashboardId)(model))(function (v) {
                return Control_Applicative.pure(Data_Either.applicativeEither)(modifyDashboards(update)(model));
            });
        };
    };
};
var _LocalState = function (dictStrong) {
    return Data_Lens_Lens.lens(function (v) {
        return v;
    })(function (v) {
        return LocalState;
    })(dictStrong);
};
var focusDashboard = function (focusDash) {
    return function (v) {
        return Data_Functor.mapFlipped(Data_Either.functorEither)(getDashboard(focusDash)(v))(function (v1) {
            var tile = Control_Comonad_Cofree.head(v1.tiles);
            var localStateRec = Data_Lens_Getter.view(_LocalState(Data_Lens_Internal_Forget.strongForget))(v.localState);
            var focusTle = Data_Lens_Getter.view(function ($317) {
                return _Tile(Data_Lens_Internal_Forget.strongForget)(_id(Data_Lens_Internal_Forget.strongForget)($317));
            })(tile);
            return {
                id: v.id,
                workspaces: v.workspaces,
                who: v.who,
                localState: {
                    focusWorkspace: localStateRec.focusWorkspace,
                    focusDashboard: focusDash,
                    focusTile: focusTle
                }
            };
        });
    };
};
var getFocusTile = Data_Lens_Getter.view(function ($318) {
    return _Model(Data_Lens_Internal_Forget.strongForget)(_localState(Data_Lens_Internal_Forget.strongForget)(_LocalState(Data_Lens_Internal_Forget.strongForget)(_focusTile(Data_Lens_Internal_Forget.strongForget)($318))));
});
var _DashboardId = function (dictStrong) {
    return Data_Lens_Lens.lens(function (v) {
        return v;
    })(function (v) {
        return DashboardId;
    })(dictStrong);
};
var _Dashboard = function (dictStrong) {
    return Data_Lens_Lens.lens(function (v) {
        return v;
    })(function (v) {
        return Dashboard;
    })(dictStrong);
};
var firstDashboardId = function (model) {
    return Data_Lens_Getter.view(function ($319) {
        return _Dashboard(Data_Lens_Internal_Forget.strongForget)(_id(Data_Lens_Internal_Forget.strongForget)($319));
    })(firstDashboard(model));
};
var firstTile = function (model) {
    return Control_Comonad_Cofree.head(Data_Lens_Getter.view(function ($320) {
        return _Dashboard(Data_Lens_Internal_Forget.strongForget)(_tiles(Data_Lens_Internal_Forget.strongForget)($320));
    })(firstDashboard(model)));
};
var firstTileId = function (model) {
    return Data_Lens_Getter.view(function ($321) {
        return _Tile(Data_Lens_Internal_Forget.strongForget)(_id(Data_Lens_Internal_Forget.strongForget)($321));
    })(firstTile(model));
};
var focusWorkspace = function (workspaceId) {
    return function (v) {
        return Data_Functor.mapFlipped(Data_Either.functorEither)(getWorkspace(workspaceId)(v))(function (workspace) {
            var focusWorkspaceId = Data_Lens_Getter.view(function ($322) {
                return _Workspace(Data_Lens_Internal_Forget.strongForget)(_id(Data_Lens_Internal_Forget.strongForget)($322));
            })(workspace);
            var updateFocusWorkspace = Data_Lens_Setter.set(function ($323) {
                return _LocalState(Data_Profunctor_Strong.strongFn)(_focusWorkspace(Data_Profunctor_Strong.strongFn)($323));
            })(focusWorkspaceId);
            var focusDash = Data_NonEmpty.head(Data_Lens_Getter.view(function ($324) {
                return _Workspace(Data_Lens_Internal_Forget.strongForget)(_dashboards(Data_Lens_Internal_Forget.strongForget)($324));
            })(workspace));
            var focusDashboardId = Data_Lens_Getter.view(function ($325) {
                return _Dashboard(Data_Lens_Internal_Forget.strongForget)(_id(Data_Lens_Internal_Forget.strongForget)($325));
            })(focusDash);
            var updateFocusDashboard = Data_Lens_Setter.set(function ($326) {
                return _LocalState(Data_Profunctor_Strong.strongFn)(_focusDashboard(Data_Profunctor_Strong.strongFn)($326));
            })(focusDashboardId);
            var focusTiles = Data_Lens_Getter.view(function ($327) {
                return _Dashboard(Data_Lens_Internal_Forget.strongForget)(_tiles(Data_Lens_Internal_Forget.strongForget)($327));
            })(focusDash);
            var focusTle = Control_Comonad_Cofree.head(focusTiles);
            var focusTileId = Data_Lens_Getter.view(function ($328) {
                return _Tile(Data_Lens_Internal_Forget.strongForget)(_id(Data_Lens_Internal_Forget.strongForget)($328));
            })(focusTle);
            var updateFocusTile = Data_Lens_Setter.set(function ($329) {
                return _LocalState(Data_Profunctor_Strong.strongFn)(_focusTile(Data_Profunctor_Strong.strongFn)($329));
            })(focusTileId);
            var localState = function ($330) {
                return updateFocusWorkspace(updateFocusDashboard(updateFocusTile($330)));
            };
            return {
                id: v.id,
                workspaces: v.workspaces,
                who: v.who,
                localState: localState(v.localState)
            };
        });
    };
};
var getTileLoc = function (tileId) {
    return function (model) {
        return Control_Bind.bind(Data_Either.bindEither)(Data_Functor.mapFlipped(Data_Either.functorEither)(getDashboardFromTileId(model)(tileId))(Data_Lens_Getter.view(_Dashboard(Data_Lens_Internal_Forget.strongForget))))(function (v) {
            return Data_Either.note(TileNotFound.value)(findFromRootWhere(function (v1) {
                return Data_Eq.eq(tileIdEq)(v1.id)(tileId);
            })(Data_Tree_Zipper.fromTree(v.tiles)));
        });
    };
};
var modifyTiles = function (fn) {
    return modifyDashboards(function (dashboard) {
        return Data_Lens_Setter.over(function ($331) {
            return _Dashboard(Data_Profunctor_Strong.strongFn)(_tiles(Data_Profunctor_Strong.strongFn)($331));
        })(Data_Lens_Traversal.traversed(Control_Comonad_Cofree.traversableCofree(Data_List_Types.traversableList))(Data_Lens_Internal_Wander.wanderFunction)(Data_Lens_Setter.over(Control_Category.identity(Control_Category.categoryFn))(fn)))(dashboard);
    });
};
var setWidget = function (widget) {
    return function (tileId) {
        return function (model) {
            var update = function (v) {
                if (Data_Eq.eq(tileIdEq)(v.id)(tileId)) {
                    return {
                        id: v.id,
                        layout: v.layout,
                        grow: v.grow,
                        showNav: v.showNav,
                        widget: new Data_Maybe.Just(widget)
                    };
                };
                return v;
            };
            return Control_Bind.bind(Data_Either.bindEither)(getTileLoc(tileId)(model))(function (v) {
                var $243 = isLeaf(v);
                if ($243) {
                    return Data_Either.Right.create(modifyTiles(update)(model));
                };
                return new Data_Either.Left(TileSetWidgetOnNonLeafTile.value);
            });
        };
    };
};
var nextDashboardId = function (model) {
    var maxId = Data_Maybe.fromJust()(Data_Foldable.maximum(Data_Ord.ordInt)(Data_NonEmpty.foldableNonEmpty(Data_Foldable.foldableArray))(Data_Functor.map(Data_NonEmpty.functorNonEmpty(Data_Functor.functorArray))(Data_Lens_Getter.view(function ($332) {
        return _Dashboard(Data_Lens_Internal_Forget.strongForget)(_id(Data_Lens_Internal_Forget.strongForget)(_DashboardId(Data_Lens_Internal_Forget.strongForget)($332)));
    }))(dashboards(model))));
    return maxId + 1 | 0;
};
var tiles = function (model) {
    var array = Control_Bind.bind(Control_Bind.bindArray)(nonEmptyToArray(dashboards(model)))(function (dashboard) {
        return Data_Array.fromFoldable(Control_Comonad_Cofree.foldableCofree(Data_List_Types.foldableList))(Data_Lens_Getter.view(function ($333) {
            return _Dashboard(Data_Lens_Internal_Forget.strongForget)(_tiles(Data_Lens_Internal_Forget.strongForget)($333));
        })(dashboard));
    });
    var tileHead = Data_Maybe.fromJust()(Data_Array.head(array));
    var tileTail = Data_Maybe.fromMaybe([  ])(Data_Array.tail(array));
    return new Data_NonEmpty.NonEmpty(tileHead, tileTail);
};
var getTile = function (tileId) {
    return function (model) {
        var maybeTile = Data_Foldable.find(Data_NonEmpty.foldableNonEmpty(Data_Foldable.foldableArray))(function (v) {
            return Data_Eq.eq(tileIdEq)(v.id)(tileId);
        })(tiles(model));
        if (maybeTile instanceof Data_Maybe.Just) {
            return new Data_Either.Right(maybeTile.value0);
        };
        if (maybeTile instanceof Data_Maybe.Nothing) {
            return new Data_Either.Left(TileNotFound.value);
        };
        throw new Error("Failed pattern match at Core.Data line 605, column 3 - line 607, column 33: " + [ maybeTile.constructor.name ]);
    };
};
var decreaseGrow = function (tileId) {
    return function (model) {
        var update = function (v) {
            if (Data_Eq.eq(tileIdEq)(v.id)(tileId)) {
                return {
                    id: v.id,
                    layout: v.layout,
                    grow: Data_Ord.max(Data_Ord.ordNumber)(1.0)(v.grow - 10.0),
                    showNav: v.showNav,
                    widget: v.widget
                };
            };
            return v;
        };
        return Control_Bind.bind(Data_Either.bindEither)(getTile(tileId)(model))(function (v) {
            return Control_Applicative.pure(Data_Either.applicativeEither)(modifyTiles(update)(model));
        });
    };
};
var focusTile = function (tileId) {
    return function (model) {
        var setTile = Data_Lens_Setter.set(function ($334) {
            return _Model(Data_Profunctor_Strong.strongFn)(_localState(Data_Profunctor_Strong.strongFn)(_LocalState(Data_Profunctor_Strong.strongFn)(_focusTile(Data_Profunctor_Strong.strongFn)($334))));
        })(tileId);
        return Control_Bind.bind(Data_Either.bindEither)(getTile(tileId)(model))(function (v) {
            return Control_Bind.bind(Data_Either.bindEither)(Data_Functor.mapFlipped(Data_Either.functorEither)(getDashboardFromTileId(model)(tileId))(Data_Lens_Getter.view(function ($335) {
                return _Dashboard(Data_Lens_Internal_Forget.strongForget)(_id(Data_Lens_Internal_Forget.strongForget)($335));
            })))(function (v1) {
                return Data_Functor.map(Data_Either.functorEither)(setTile)(focusDashboard(v1)(model));
            });
        });
    };
};
var deleteTile = function (tileId) {
    return function (model) {
        var update = function (v) {
            var v1 = findFromRootWhere(function (v2) {
                return Data_Eq.eq(tileIdEq)(v2.id)(tileId);
            })(Data_Tree_Zipper.fromTree(v.tiles));
            if (v1 instanceof Data_Maybe.Nothing) {
                return v;
            };
            if (v1 instanceof Data_Maybe.Just) {
                return {
                    id: v.id,
                    tiles: Data_Tree_Zipper.toTree(Data_Tree_Zipper.root(Data_Tree_Zipper["delete"](v1.value0))),
                    title: v.title
                };
            };
            throw new Error("Failed pattern match at Core.Data line 683, column 9 - line 685, column 76: " + [ v1.constructor.name ]);
        };
        var setNewFocusTile = function (v) {
            return function (v1) {
                if (Data_Eq.eq(tileIdEq)(tileId)(getFocusTile(v1))) {
                    var newFocusTileId = (function () {
                        var v2 = Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(Control_Alt.alt(Data_Maybe.altMaybe)(Control_Alt.alt(Data_Maybe.altMaybe)(Data_Tree_Zipper.prev(v))(Data_Tree_Zipper.next(v)))(Data_Tree_Zipper.up(v)))(function ($336) {
                            return Data_Lens_Getter.view(function ($337) {
                                return _Tile(Data_Lens_Internal_Forget.strongForget)(_id(Data_Lens_Internal_Forget.strongForget)($337));
                            })(Data_Tree_Zipper.value($336));
                        });
                        if (v2 instanceof Data_Maybe.Nothing) {
                            return Data_Lens_Getter.view(function ($338) {
                                return _Tile(Data_Lens_Internal_Forget.strongForget)(_id(Data_Lens_Internal_Forget.strongForget)($338));
                            })(Data_Tree_Zipper.value(Data_Tree_Zipper.root(v)));
                        };
                        if (v2 instanceof Data_Maybe.Just) {
                            return v2.value0;
                        };
                        throw new Error("Failed pattern match at Core.Data line 690, column 28 - line 692, column 45: " + [ v2.constructor.name ]);
                    })();
                    return focusTile(newFocusTileId)(v1);
                };
                return new Data_Either.Right(v1);
            };
        };
        var makeNode = function (child) {
            return function (parent) {
                var parentGrow = locGrow(parent);
                var oldTile = Data_Tree_Zipper.value(child);
                var updatedTile = Data_Lens_Setter.set(function ($339) {
                    return _Tile(Data_Profunctor_Strong.strongFn)(_grow(Data_Profunctor_Strong.strongFn)($339));
                })(parentGrow)(oldTile);
                return Data_Tree.mkTree(updatedTile)(Data_List_Types.Nil.value);
            };
        };
        return Control_Bind.bind(Data_Either.bindEither)(getTile(tileId)(model))(function (v) {
            return Control_Bind.bind(Data_Either.bindEither)(getTileLoc(tileId)(model))(function (v1) {
                var $258 = isLeaf(v1);
                if ($258) {
                    var $259 = hasParent(v1);
                    if ($259) {
                        return setNewFocusTile(v1)(modifyDashboards(update)(model));
                    };
                    return new Data_Either.Left(TileDeleteAttemptedOnRootTile.value);
                };
                return new Data_Either.Left(TileDeleteAttemptedOnTileWithChildren.value);
            });
        });
    };
};
var increaseGrow = function (tileId) {
    return function (model) {
        var update = function (v) {
            if (Data_Eq.eq(tileIdEq)(v.id)(tileId)) {
                return {
                    id: v.id,
                    layout: v.layout,
                    grow: v.grow + 10.0,
                    showNav: v.showNav,
                    widget: v.widget
                };
            };
            return v;
        };
        return Control_Bind.bind(Data_Either.bindEither)(getTile(tileId)(model))(function (v) {
            return Control_Applicative.pure(Data_Either.applicativeEither)(modifyTiles(update)(model));
        });
    };
};
var nextTile = function (model) {
    return function (currentFocus) {
        var tileIdFromLoc = function (loc) {
            return Data_Lens_Getter.view(function ($340) {
                return _Tile(Data_Lens_Internal_Forget.strongForget)(_id(Data_Lens_Internal_Forget.strongForget)($340));
            })(Data_Tree_Zipper.value(loc));
        };
        var go = function ($copy_tree) {
            return function ($copy_v) {
                var $tco_var_tree = $copy_tree;
                var $tco_done = false;
                var $tco_result;
                function $tco_loop(tree, v) {
                    if (v instanceof Data_List_Types.Nil) {
                        $tco_done = true;
                        return new Data_Either.Right(Data_Tree_Zipper.value(Data_Tree_Zipper.root(Data_Tree_Zipper.fromTree(tree))));
                    };
                    if (v instanceof Data_List_Types.Cons && (v.value1 instanceof Data_List_Types.Cons && Data_Eq.eq(tileIdEq)(tileIdFromLoc(v.value0))(currentFocus))) {
                        $tco_done = true;
                        return new Data_Either.Right(Data_Tree_Zipper.value(v.value1.value0));
                    };
                    if (v instanceof Data_List_Types.Cons) {
                        $tco_var_tree = tree;
                        $copy_v = v.value1;
                        return;
                    };
                    throw new Error("Failed pattern match at Core.Data line 646, column 17 - line 649, column 75: " + [ v.constructor.name ]);
                };
                while (!$tco_done) {
                    $tco_result = $tco_loop($tco_var_tree, $copy_v);
                };
                return $tco_result;
            };
        };
        return Control_Bind.bind(Data_Either.bindEither)(getTile(currentFocus)(model))(function (v) {
            return Control_Bind.bind(Data_Either.bindEither)(getDashboardFromTileId(model)(currentFocus))(function (v1) {
                return Control_Bind.bind(Data_Either.bindEither)(Control_Applicative.pure(Data_Either.applicativeEither)(Data_Lens_Getter.view(function ($341) {
                    return _Dashboard(Data_Lens_Internal_Forget.strongForget)(_tiles(Data_Lens_Internal_Forget.strongForget)($341));
                })(v1)))(function (v2) {
                    return Data_Functor.mapFlipped(Data_Either.functorEither)(go(v2)(flattenLocDepthFirst(Data_Tree_Zipper.root(Data_Tree_Zipper.fromTree(v2)))))(function (v3) {
                        return v3.id;
                    });
                });
            });
        });
    };
};
var previousTile = function (model) {
    return function (currentFocus) {
        var tileIdFromLoc = function (loc) {
            return Data_Lens_Getter.view(function ($342) {
                return _Tile(Data_Lens_Internal_Forget.strongForget)(_id(Data_Lens_Internal_Forget.strongForget)($342));
            })(Data_Tree_Zipper.value(loc));
        };
        var flattened = function (tree) {
            return flattenLocDepthFirst(Data_Tree_Zipper.root(Data_Tree_Zipper.fromTree(tree)));
        };
        var go = function ($copy_tree) {
            return function ($copy_v) {
                var $tco_var_tree = $copy_tree;
                var $tco_done = false;
                var $tco_result;
                function $tco_loop(tree, v) {
                    if (v instanceof Data_List_Types.Nil) {
                        $tco_done = true;
                        return Data_Either.note(TileNotFound.value)(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Tree_Zipper.value)(Data_List.head(Data_List.reverse(flattened(tree)))));
                    };
                    if (v instanceof Data_List_Types.Cons && (v.value1 instanceof Data_List_Types.Cons && Data_Eq.eq(tileIdEq)(tileIdFromLoc(v.value1.value0))(currentFocus))) {
                        $tco_done = true;
                        return new Data_Either.Right(Data_Tree_Zipper.value(v.value0));
                    };
                    if (v instanceof Data_List_Types.Cons) {
                        $tco_var_tree = tree;
                        $copy_v = v.value1;
                        return;
                    };
                    throw new Error("Failed pattern match at Core.Data line 664, column 17 - line 667, column 75: " + [ v.constructor.name ]);
                };
                while (!$tco_done) {
                    $tco_result = $tco_loop($tco_var_tree, $copy_v);
                };
                return $tco_result;
            };
        };
        return Control_Bind.bind(Data_Either.bindEither)(getTile(currentFocus)(model))(function (v) {
            return Control_Bind.bind(Data_Either.bindEither)(getDashboardFromTileId(model)(currentFocus))(function (v1) {
                return Control_Bind.bind(Data_Either.bindEither)(Control_Applicative.pure(Data_Either.applicativeEither)(Data_Lens_Getter.view(function ($343) {
                    return _Dashboard(Data_Lens_Internal_Forget.strongForget)(_tiles(Data_Lens_Internal_Forget.strongForget)($343));
                })(v1)))(function (v2) {
                    return Data_Functor.mapFlipped(Data_Either.functorEither)(go(v2)(flattened(v2)))(function (v3) {
                        return v3.id;
                    });
                });
            });
        });
    };
};
var setGrow = function (tileId) {
    return function (g) {
        return function (model) {
            var update = function (v) {
                if (Data_Eq.eq(tileIdEq)(v.id)(tileId)) {
                    return {
                        id: v.id,
                        layout: v.layout,
                        grow: g,
                        showNav: v.showNav,
                        widget: v.widget
                    };
                };
                return v;
            };
            return Control_Bind.bind(Data_Either.bindEither)(getTile(tileId)(model))(function (v) {
                return Control_Applicative.pure(Data_Either.applicativeEither)(modifyTiles(update)(model));
            });
        };
    };
};
var toggleShowNav = function (tileId) {
    return function (model) {
        var update = function (v) {
            if (Data_Eq.eq(tileIdEq)(v.id)(tileId)) {
                return {
                    id: v.id,
                    layout: v.layout,
                    grow: v.grow,
                    showNav: !v.showNav,
                    widget: v.widget
                };
            };
            return v;
        };
        return Control_Bind.bind(Data_Either.bindEither)(getTile(tileId)(model))(function (v) {
            return Data_Either.Right.create(modifyTiles(update)(model));
        });
    };
};
var toggleTileLayout = function (model) {
    return function (tileId) {
        var toggle = function (v) {
            if (Data_Eq.eq(layoutEq)(v.layout)(Row.value)) {
                return {
                    id: v.id,
                    layout: Column.value,
                    grow: v.grow,
                    showNav: v.showNav,
                    widget: v.widget
                };
            };
            if (Data_Eq.eq(layoutEq)(v.layout)(Column.value)) {
                return {
                    id: v.id,
                    layout: Row.value,
                    grow: v.grow,
                    showNav: v.showNav,
                    widget: v.widget
                };
            };
            if (Data_Eq.eq(layoutEq)(v.layout)(Tabbed.value)) {
                return {
                    id: v.id,
                    layout: Row.value,
                    grow: v.grow,
                    showNav: v.showNav,
                    widget: v.widget
                };
            };
            return v;
        };
        var update = function (v) {
            if (Data_Eq.eq(tileIdEq)(v.id)(tileId)) {
                return toggle(v);
            };
            return v;
        };
        return Control_Bind.bind(Data_Either.bindEither)(getTile(tileId)(model))(function (v) {
            return Control_Applicative.pure(Data_Either.applicativeEither)(modifyTiles(update)(model));
        });
    };
};
var nextTileId = function (model) {
    var maxId = Data_Maybe.fromJust()(Data_Foldable.maximum(Data_Ord.ordInt)(Data_NonEmpty.foldableNonEmpty(Data_Foldable.foldableArray))(Data_Functor.map(Data_NonEmpty.functorNonEmpty(Data_Functor.functorArray))(Data_Lens_Getter.view(function ($344) {
        return _Tile(Data_Lens_Internal_Forget.strongForget)(_id(Data_Lens_Internal_Forget.strongForget)(_TileId(Data_Lens_Internal_Forget.strongForget)($344)));
    }))(tiles(model))));
    return maxId + 1 | 0;
};
var newTile = function (model) {
    return function (maybeTitle) {
        return {
            id: nextTileId(model),
            layout: Row.value,
            grow: defaultGrow,
            showNav: true,
            widget: new Data_Maybe.Just({
                title: maybeTitle
            })
        };
    };
};
var addTile = function (dashboardId) {
    return function (tileId) {
        return function (maybeTitle) {
            return function (model) {
                var updateLocalState = function (v) {
                    var workspaceId = Data_Lens_Getter.view(function ($345) {
                        return _localState(Data_Lens_Internal_Forget.strongForget)(_LocalState(Data_Lens_Internal_Forget.strongForget)(_focusWorkspace(Data_Lens_Internal_Forget.strongForget)($345)));
                    })(v);
                    return {
                        id: v.id,
                        workspaces: v.workspaces,
                        who: v.who,
                        localState: {
                            focusWorkspace: workspaceId,
                            focusDashboard: dashboardId,
                            focusTile: tileId
                        }
                    };
                };
                var update = function (v) {
                    return function (v1) {
                        if (Data_Eq.eq(dashboardIdEq)(v1.id)(dashboardId)) {
                            return {
                                id: v1.id,
                                tiles: v,
                                title: v1.title
                            };
                        };
                        return v1;
                    };
                };
                var currentTiles = function (dashboard) {
                    return (Data_Lens_Getter.view(_Dashboard(Data_Lens_Internal_Forget.strongForget))(dashboard)).tiles;
                };
                var tileLoc = function (dashboard) {
                    return findFromRootWhere(function (v) {
                        return Data_Eq.eq(tileIdEq)(v.id)(tileId);
                    })(Data_Tree_Zipper.fromTree(currentTiles(dashboard)));
                };
                return Control_Bind.bind(Data_Either.bindEither)(getDashboard(dashboardId)(model))(function (v) {
                    return Control_Bind.bind(Data_Either.bindEither)(Data_Either.note(TileNotFound.value)(tileLoc(v)))(function (v1) {
                        var childTileIds = Data_Functor.mapFlipped(Data_List_Types.functorList)(childTiles(v1))(function (v2) {
                            return v2.id;
                        });
                        var swap = moveWidget(Data_Tree_Zipper.value(v1))(newTile(model)(maybeTitle));
                        return Control_Bind.bind(Data_Either.bindEither)(Control_Applicative.pure(Data_Either.applicativeEither)(Data_Tree_Zipper.insertChild(Data_Tree.mkTree(swap.taker)(Data_List_Types.Nil.value))(Data_Tree_Zipper.setValue(swap.giver)(v1))))(function (v2) {
                            return Control_Bind.bind(Data_Either.bindEither)(Control_Applicative.pure(Data_Either.applicativeEither)(updateLocalState(modifyDashboards(update(Data_Tree_Zipper.toTree(Data_Tree_Zipper.root(v2))))(model))))(function (v3) {
                                return Control_Applicative.pure(Data_Either.applicativeEither)(modifyTiles(function (v4) {
                                    var $296 = Data_Foldable.elem(Data_List_Types.foldableList)(tileIdEq)(v4.id)(childTileIds);
                                    if ($296) {
                                        return {
                                            id: v4.id,
                                            layout: v4.layout,
                                            grow: defaultGrow,
                                            showNav: v4.showNav,
                                            widget: v4.widget
                                        };
                                    };
                                    return v4;
                                })(v3));
                            });
                        });
                    });
                });
            };
        };
    };
};
var newTiles = function (model) {
    return Data_Tree.mkTree(newTile(model)(Data_Maybe.Nothing.value))(Data_List_Types.Nil.value);
};
var newDashboard = function (model) {
    return function (maybeTitle) {
        var ts = newTiles(model);
        return {
            id: nextDashboardId(model),
            tiles: ts,
            title: maybeTitle
        };
    };
};
var addDashboard = function (workspaceId) {
    return function (maybeTitle) {
        return function (model) {
            var update = function (v) {
                if (Data_Eq.eq(workspaceIdEq)(v.id)(workspaceId)) {
                    return {
                        id: v.id,
                        dashboards: new Data_NonEmpty.NonEmpty(Data_NonEmpty.head(v.dashboards), Data_Array.snoc(Data_NonEmpty.tail(v.dashboards))(newDashboard(model)(maybeTitle))),
                        title: v.title
                    };
                };
                return v;
            };
            return Control_Bind.bind(Data_Either.bindEither)(getWorkspace(workspaceId)(model))(function (v) {
                var focusDashboardId = nextDashboardId(model);
                return focusDashboard(focusDashboardId)(modifyWorkspaces(update)(model));
            });
        };
    };
};
var newDashboards = function (model) {
    return new Data_NonEmpty.NonEmpty(newDashboard(model)(Data_Maybe.Nothing.value), [  ]);
};
var newWorkspace = function (model) {
    return function (maybeTitle) {
        var dashes = newDashboards(model);
        return {
            id: nextWorkspaceId(model),
            dashboards: dashes,
            title: maybeTitle
        };
    };
};
var addWorkspace = function (maybeTitle) {
    return function (v) {
        var workspace = newWorkspace(v)(maybeTitle);
        var newSpaces = new Data_NonEmpty.NonEmpty(Data_NonEmpty.head(v.workspaces), Data_Array.snoc(Data_NonEmpty.tail(v.workspaces))(workspace));
        var focusWorkspaceId = Data_Lens_Getter.view(function ($346) {
            return _Workspace(Data_Lens_Internal_Forget.strongForget)(_id(Data_Lens_Internal_Forget.strongForget)($346));
        })(workspace);
        return focusWorkspace(focusWorkspaceId)({
            id: v.id,
            workspaces: newSpaces,
            who: v.who,
            localState: v.localState
        });
    };
};
var newWorkspaces = function (model) {
    return new Data_NonEmpty.NonEmpty(newWorkspace(model)(Data_Maybe.Nothing.value), [  ]);
};
module.exports = {
    LocalState: LocalState,
    Model: Model,
    Workspace: Workspace,
    Dashboard: Dashboard,
    Tile: Tile,
    Row: Row,
    Column: Column,
    Tabbed: Tabbed,
    Widget: Widget,
    LoggedOut: LoggedOut,
    LoggedIn: LoggedIn,
    ModelId: ModelId,
    WorkspaceId: WorkspaceId,
    DashboardId: DashboardId,
    TileId: TileId,
    Title: Title,
    WorkspaceNotFound: WorkspaceNotFound,
    DashboardNotFound: DashboardNotFound,
    TileNotFound: TileNotFound,
    TileDeleteAttemptedOnTileWithChildren: TileDeleteAttemptedOnTileWithChildren,
    TileDeleteAttemptedOnRootTile: TileDeleteAttemptedOnRootTile,
    TileSetWidgetOnNonLeafTile: TileSetWidgetOnNonLeafTile,
    _Model: _Model,
    _localState: _localState,
    _LocalState: _LocalState,
    _focusWorkspace: _focusWorkspace,
    _focusDashboard: _focusDashboard,
    _focusTile: _focusTile,
    _workspaces: _workspaces,
    _WorkspaceId: _WorkspaceId,
    _DashboardId: _DashboardId,
    _TileId: _TileId,
    _Title: _Title,
    _ModelId: _ModelId,
    _Workspace: _Workspace,
    _dashboards: _dashboards,
    _Dashboard: _Dashboard,
    _Tile: _Tile,
    _Widget: _Widget,
    _id: _id,
    _grow: _grow,
    _tiles: _tiles,
    _title: _title,
    _widget: _widget,
    _layout: _layout,
    newModel: newModel,
    defaultLocalState: defaultLocalState,
    defaultWorkspaces: defaultWorkspaces,
    newWorkspaces: newWorkspaces,
    addWorkspace: addWorkspace,
    defaultWorkspace: defaultWorkspace,
    newWorkspace: newWorkspace,
    getWorkspace: getWorkspace,
    focusWorkspace: focusWorkspace,
    modifyWorkspaces: modifyWorkspaces,
    defaultDashboards: defaultDashboards,
    newDashboards: newDashboards,
    defaultDashboard: defaultDashboard,
    newDashboard: newDashboard,
    addDashboard: addDashboard,
    findDashboard: findDashboard,
    focusDashboard: focusDashboard,
    getDashboard: getDashboard,
    setDashboardTitle: setDashboardTitle,
    modifyDashboards: modifyDashboards,
    defaultTiles: defaultTiles,
    getDashboardFromTileId: getDashboardFromTileId,
    focusTile: focusTile,
    newTiles: newTiles,
    defaultGrow: defaultGrow,
    defaultTile: defaultTile,
    newTile: newTile,
    showTileIdTreeFromDashboard: showTileIdTreeFromDashboard,
    showTileIdTree: showTileIdTree,
    addTile: addTile,
    toggleTileLayout: toggleTileLayout,
    toggleShowNav: toggleShowNav,
    dashboards: dashboards,
    tiles: tiles,
    getTile: getTile,
    getTileLoc: getTileLoc,
    nextWorkspaceId: nextWorkspaceId,
    nextDashboardId: nextDashboardId,
    nextTileId: nextTileId,
    nextTile: nextTile,
    previousTile: previousTile,
    deleteTile: deleteTile,
    grow: grow,
    locGrow: locGrow,
    getFocusTile: getFocusTile,
    hasParent: hasParent,
    increaseGrow: increaseGrow,
    decreaseGrow: decreaseGrow,
    setGrow: setGrow,
    modifyTiles: modifyTiles,
    setWidget: setWidget,
    firstWorkspace: firstWorkspace,
    firstWorkspaceId: firstWorkspaceId,
    firstDashboard: firstDashboard,
    firstDashboardId: firstDashboardId,
    firstTile: firstTile,
    firstTileId: firstTileId,
    findDownWhere: findDownWhere,
    findFromRootWhere: findFromRootWhere,
    flattenLocDepthFirst: flattenLocDepthFirst,
    isLeaf: isLeaf,
    firstTileLoc: firstTileLoc,
    firstTileInTiles: firstTileInTiles,
    siblingTiles: siblingTiles,
    childTiles: childTiles,
    getWidgetTitle: getWidgetTitle,
    nonEmptyToArray: nonEmptyToArray,
    eqModelError: eqModelError,
    modelIdEq: modelIdEq,
    workspaceIdEq: workspaceIdEq,
    dashboardIdEq: dashboardIdEq,
    tileIdEq: tileIdEq,
    titleEq: titleEq,
    modelEq: modelEq,
    localStateEq: localStateEq,
    userStateEq: userStateEq,
    workspaceEq: workspaceEq,
    dashboardEq: dashboardEq,
    layoutEq: layoutEq,
    showLayout: showLayout,
    tileEq: tileEq,
    widgetEq: widgetEq
};
