// Generated by purs version 0.12.0
"use strict";
var $foreign = require("./foreign.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Functor_Variant = require("../Data.Functor.Variant/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Prelude = require("../Prelude/index.js");
var Run = require("../Run/index.js");
var Run_Writer = require("../Run.Writer/index.js");
var UI_Style = require("../UI.Style/index.js");
var UI_Style_Layout = require("../UI.Style.Layout/index.js");
var UI_Style_PropTypes_Color = require("../UI.Style.PropTypes.Color/index.js");
var UI_Style_Text = require("../UI.Style.Text/index.js");
var UI_Style_View = require("../UI.Style.View/index.js");
var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");
var SiblingGrows = function (x) {
    return x;
};
var LayoutChain = function (x) {
    return x;
};
var Component = (function () {
    function Component(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Component.create = function (value0) {
        return function (value1) {
            return new Component(value0, value1);
        };
    };
    return Component;
})();
var MakeTouchable = (function () {
    function MakeTouchable(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    MakeTouchable.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new MakeTouchable(value0, value1, value2);
            };
        };
    };
    return MakeTouchable;
})();
var OnChange = (function () {
    function OnChange(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    OnChange.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new OnChange(value0, value1, value2);
            };
        };
    };
    return OnChange;
})();
var Chart = (function () {
    function Chart(value0, value1, value2, value3) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
        this.value3 = value3;
    };
    Chart.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return function (value3) {
                    return new Chart(value0, value1, value2, value3);
                };
            };
        };
    };
    return Chart;
})();
var Article = (function () {
    function Article(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    Article.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new Article(value0, value1, value2);
            };
        };
    };
    return Article;
})();
var Footer = (function () {
    function Footer(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    Footer.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new Footer(value0, value1, value2);
            };
        };
    };
    return Footer;
})();
var Header = (function () {
    function Header(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    Header.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new Header(value0, value1, value2);
            };
        };
    };
    return Header;
})();
var Nav = (function () {
    function Nav(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    Nav.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new Nav(value0, value1, value2);
            };
        };
    };
    return Nav;
})();
var Section = (function () {
    function Section(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    Section.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new Section(value0, value1, value2);
            };
        };
    };
    return Section;
})();
var Div = (function () {
    function Div(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    Div.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new Div(value0, value1, value2);
            };
        };
    };
    return Div;
})();
var Main = (function () {
    function Main(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    Main.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new Main(value0, value1, value2);
            };
        };
    };
    return Main;
})();
var Image = (function () {
    function Image(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    Image.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new Image(value0, value1, value2);
            };
        };
    };
    return Image;
})();
var TextInput = (function () {
    function TextInput(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    TextInput.create = function (value0) {
        return function (value1) {
            return new TextInput(value0, value1);
        };
    };
    return TextInput;
})();
var Checkbox = (function () {
    function Checkbox(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    Checkbox.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new Checkbox(value0, value1, value2);
            };
        };
    };
    return Checkbox;
})();
var RadioButton = (function () {
    function RadioButton(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    RadioButton.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new RadioButton(value0, value1, value2);
            };
        };
    };
    return RadioButton;
})();
var H1 = (function () {
    function H1(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    H1.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new H1(value0, value1, value2);
            };
        };
    };
    return H1;
})();
var H2 = (function () {
    function H2(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    H2.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new H2(value0, value1, value2);
            };
        };
    };
    return H2;
})();
var H3 = (function () {
    function H3(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    H3.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new H3(value0, value1, value2);
            };
        };
    };
    return H3;
})();
var H4 = (function () {
    function H4(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    H4.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new H4(value0, value1, value2);
            };
        };
    };
    return H4;
})();
var H5 = (function () {
    function H5(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    H5.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new H5(value0, value1, value2);
            };
        };
    };
    return H5;
})();
var H6 = (function () {
    function H6(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    H6.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new H6(value0, value1, value2);
            };
        };
    };
    return H6;
})();
var P = (function () {
    function P(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    P.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new P(value0, value1, value2);
            };
        };
    };
    return P;
})();
var Text = (function () {
    function Text(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Text.create = function (value0) {
        return function (value1) {
            return new Text(value0, value1);
        };
    };
    return Text;
})();
var specToTypelessSpec = Unsafe_Coerce.unsafeCoerce;
var showSiblingGrows = new Data_Show.Show(function (v) {
    return v;
});
var showLayoutChain = new Data_Show.Show(function (v) {
    return v;
});
var componentDSLFunctor = new Data_Functor.Functor(function (f) {
    return function (m) {
        if (m instanceof Component) {
            return new Component(m.value0, Data_Functor.map(Data_Functor.functorFn)(Data_Functor.map(Data_Functor.functorFn)(f))(m.value1));
        };
        if (m instanceof MakeTouchable) {
            return new MakeTouchable(m.value0, m.value1, Data_Functor.map(Data_Functor.functorFn)(f)(m.value2));
        };
        if (m instanceof OnChange) {
            return new OnChange(m.value0, m.value1, Data_Functor.map(Data_Functor.functorFn)(f)(m.value2));
        };
        if (m instanceof Chart) {
            return new Chart(m.value0, m.value1, m.value2, Data_Functor.map(Data_Functor.functorFn)(f)(m.value3));
        };
        if (m instanceof Article) {
            return new Article(m.value0, m.value1, Data_Functor.map(Data_Functor.functorFn)(f)(m.value2));
        };
        if (m instanceof Footer) {
            return new Footer(m.value0, m.value1, Data_Functor.map(Data_Functor.functorFn)(f)(m.value2));
        };
        if (m instanceof Header) {
            return new Header(m.value0, m.value1, Data_Functor.map(Data_Functor.functorFn)(f)(m.value2));
        };
        if (m instanceof Nav) {
            return new Nav(m.value0, m.value1, Data_Functor.map(Data_Functor.functorFn)(f)(m.value2));
        };
        if (m instanceof Section) {
            return new Section(m.value0, m.value1, Data_Functor.map(Data_Functor.functorFn)(f)(m.value2));
        };
        if (m instanceof Div) {
            return new Div(m.value0, m.value1, Data_Functor.map(Data_Functor.functorFn)(f)(m.value2));
        };
        if (m instanceof Main) {
            return new Main(m.value0, m.value1, Data_Functor.map(Data_Functor.functorFn)(f)(m.value2));
        };
        if (m instanceof Image) {
            return new Image(m.value0, m.value1, Data_Functor.map(Data_Functor.functorFn)(f)(m.value2));
        };
        if (m instanceof TextInput) {
            return new TextInput(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof Checkbox) {
            return new Checkbox(m.value0, m.value1, Data_Functor.map(Data_Functor.functorFn)(f)(m.value2));
        };
        if (m instanceof RadioButton) {
            return new RadioButton(m.value0, m.value1, Data_Functor.map(Data_Functor.functorFn)(f)(m.value2));
        };
        if (m instanceof H1) {
            return new H1(m.value0, m.value1, Data_Functor.map(Data_Functor.functorFn)(f)(m.value2));
        };
        if (m instanceof H2) {
            return new H2(m.value0, m.value1, Data_Functor.map(Data_Functor.functorFn)(f)(m.value2));
        };
        if (m instanceof H3) {
            return new H3(m.value0, m.value1, Data_Functor.map(Data_Functor.functorFn)(f)(m.value2));
        };
        if (m instanceof H4) {
            return new H4(m.value0, m.value1, Data_Functor.map(Data_Functor.functorFn)(f)(m.value2));
        };
        if (m instanceof H5) {
            return new H5(m.value0, m.value1, Data_Functor.map(Data_Functor.functorFn)(f)(m.value2));
        };
        if (m instanceof H6) {
            return new H6(m.value0, m.value1, Data_Functor.map(Data_Functor.functorFn)(f)(m.value2));
        };
        if (m instanceof P) {
            return new P(m.value0, m.value1, Data_Functor.map(Data_Functor.functorFn)(f)(m.value2));
        };
        if (m instanceof Text) {
            return new Text(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        throw new Error("Failed pattern match at UI.Components line 165, column 8 - line 165, column 60: " + [ m.constructor.name ]);
    };
});
var _component = Data_Symbol.SProxy.value;
var article = function (props) {
    return function (child) {
        return Run.lift()(new Data_Symbol.IsSymbol(function () {
            return "component";
        }))(componentDSLFunctor)(_component)(new Article(props.style, child, Control_Category.identity(Control_Category.categoryFn)));
    };
};
var chart = function (props) {
    return function (child) {
        return Run.lift()(new Data_Symbol.IsSymbol(function () {
            return "component";
        }))(componentDSLFunctor)(_component)(new Chart(props.style, props, child, Control_Category.identity(Control_Category.categoryFn)));
    };
};
var checkbox = function (props) {
    return Run.lift()(new Data_Symbol.IsSymbol(function () {
        return "component";
    }))(componentDSLFunctor)(_component)(new Checkbox(props.style, {
        value: props.value
    }, Control_Category.identity(Control_Category.categoryFn)));
};
var component = function (spec) {
    return Run.lift()(new Data_Symbol.IsSymbol(function () {
        return "component";
    }))(componentDSLFunctor)(_component)(new Component(specToTypelessSpec(spec), $foreign.createReactComponent));
};
var stateless = function (displayName) {
    return function (initialProps) {
        return function (renderProps) {
            var shouldComponentUpdate = function (v) {
                return function (v1) {
                    return function (v2) {
                        return function (v3) {
                            return true;
                        };
                    };
                };
            };
            var render = function (props) {
                return function (v) {
                    return function (v1) {
                        return renderProps(props);
                    };
                };
            };
            var getDerivedStateFromProps = function (props) {
                return function (state) {
                    return new Data_Maybe.Just(state);
                };
            };
            var componentWillUnmount = Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            var componentDidUpdate = function (v) {
                return function (v1) {
                    return function (v2) {
                        return function (v3) {
                            return function (v4) {
                                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                            };
                        };
                    };
                };
            };
            var componentDidMount = function (v) {
                return function (v1) {
                    return function (v2) {
                        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                    };
                };
            };
            var spec = {
                displayName: displayName,
                initialState: {},
                componentDidMount: componentDidMount,
                componentDidUpdate: componentDidUpdate,
                componentWillUnmount: componentWillUnmount,
                getDerivedStateFromProps: getDerivedStateFromProps,
                shouldComponentUpdate: shouldComponentUpdate,
                render: render
            };
            return Data_Functor.map(Run.functorRun)($foreign.createReactElement(initialProps))(component(spec));
        };
    };
};
var div = function (props) {
    return function (child) {
        return Run.lift()(new Data_Symbol.IsSymbol(function () {
            return "component";
        }))(componentDSLFunctor)(_component)(new Div(props.style, child, Control_Category.identity(Control_Category.categoryFn)));
    };
};
var footer = function (props) {
    return function (child) {
        return Run.lift()(new Data_Symbol.IsSymbol(function () {
            return "component";
        }))(componentDSLFunctor)(_component)(new Footer(props.style, child, Control_Category.identity(Control_Category.categoryFn)));
    };
};
var h1 = function (props) {
    return function (child) {
        return Run.lift()(new Data_Symbol.IsSymbol(function () {
            return "component";
        }))(componentDSLFunctor)(_component)(new H1(props.style, child, Control_Category.identity(Control_Category.categoryFn)));
    };
};
var h2 = function (props) {
    return function (child) {
        return Run.lift()(new Data_Symbol.IsSymbol(function () {
            return "component";
        }))(componentDSLFunctor)(_component)(new H2(props.style, child, Control_Category.identity(Control_Category.categoryFn)));
    };
};
var h3 = function (props) {
    return function (child) {
        return Run.lift()(new Data_Symbol.IsSymbol(function () {
            return "component";
        }))(componentDSLFunctor)(_component)(new H3(props.style, child, Control_Category.identity(Control_Category.categoryFn)));
    };
};
var h4 = function (props) {
    return function (child) {
        return Run.lift()(new Data_Symbol.IsSymbol(function () {
            return "component";
        }))(componentDSLFunctor)(_component)(new H4(props.style, child, Control_Category.identity(Control_Category.categoryFn)));
    };
};
var h5 = function (props) {
    return function (child) {
        return Run.lift()(new Data_Symbol.IsSymbol(function () {
            return "component";
        }))(componentDSLFunctor)(_component)(new H5(props.style, child, Control_Category.identity(Control_Category.categoryFn)));
    };
};
var h6 = function (props) {
    return function (child) {
        return Run.lift()(new Data_Symbol.IsSymbol(function () {
            return "component";
        }))(componentDSLFunctor)(_component)(new H6(props.style, child, Control_Category.identity(Control_Category.categoryFn)));
    };
};
var header = function (props) {
    return function (child) {
        return Run.lift()(new Data_Symbol.IsSymbol(function () {
            return "component";
        }))(componentDSLFunctor)(_component)(new Header(props.style, child, Control_Category.identity(Control_Category.categoryFn)));
    };
};
var image = function (props) {
    return Run.lift()(new Data_Symbol.IsSymbol(function () {
        return "component";
    }))(componentDSLFunctor)(_component)(new Image(props.style, props, Control_Category.identity(Control_Category.categoryFn)));
};
var main = function (props) {
    return function (child) {
        return Run.lift()(new Data_Symbol.IsSymbol(function () {
            return "component";
        }))(componentDSLFunctor)(_component)(new Main(props.style, child, Control_Category.identity(Control_Category.categoryFn)));
    };
};
var makeTouchable = function (handler) {
    return function (jsx) {
        return Run.lift()(new Data_Symbol.IsSymbol(function () {
            return "component";
        }))(componentDSLFunctor)(_component)(new MakeTouchable(handler, jsx, Control_Category.identity(Control_Category.categoryFn)));
    };
};
var nav = function (props) {
    return function (child) {
        return Run.lift()(new Data_Symbol.IsSymbol(function () {
            return "component";
        }))(componentDSLFunctor)(_component)(new Nav(props.style, child, Control_Category.identity(Control_Category.categoryFn)));
    };
};
var onChange = function (handler) {
    return function (jsx) {
        return Run.lift()(new Data_Symbol.IsSymbol(function () {
            return "component";
        }))(componentDSLFunctor)(_component)(new OnChange(handler, jsx, Control_Category.identity(Control_Category.categoryFn)));
    };
};
var p = function (props) {
    return function (child) {
        return Run.lift()(new Data_Symbol.IsSymbol(function () {
            return "component";
        }))(componentDSLFunctor)(_component)(new P(props.style, child, Control_Category.identity(Control_Category.categoryFn)));
    };
};
var radioButton = function (props) {
    return Run.lift()(new Data_Symbol.IsSymbol(function () {
        return "component";
    }))(componentDSLFunctor)(_component)(new RadioButton(props.style, {
        name: props.name,
        value: props.value,
        checked: props.checked,
        disabled: props.disabled
    }, Control_Category.identity(Control_Category.categoryFn)));
};
var runComponent = function (handler) {
    return Run.interpret(Run.monadRun)(Data_Functor_Variant.on()(new Data_Symbol.IsSymbol(function () {
        return "component";
    }))(_component)(handler)(Run.send));
};
var section = function (props) {
    return function (child) {
        return Run.lift()(new Data_Symbol.IsSymbol(function () {
            return "component";
        }))(componentDSLFunctor)(_component)(new Section(props.style, child, Control_Category.identity(Control_Category.categoryFn)));
    };
};
var text = function (str) {
    return Run.lift()(new Data_Symbol.IsSymbol(function () {
        return "component";
    }))(componentDSLFunctor)(_component)(new Text(str, Control_Category.identity(Control_Category.categoryFn)));
};
var textInput = function (props) {
    return Run.lift()(new Data_Symbol.IsSymbol(function () {
        return "component";
    }))(componentDSLFunctor)(_component)(new TextInput(props, Control_Category.identity(Control_Category.categoryFn)));
};
module.exports = {
    LayoutChain: LayoutChain,
    SiblingGrows: SiblingGrows,
    Component: Component,
    MakeTouchable: MakeTouchable,
    OnChange: OnChange,
    Chart: Chart,
    Article: Article,
    Footer: Footer,
    Header: Header,
    Nav: Nav,
    Section: Section,
    Div: Div,
    Main: Main,
    Image: Image,
    TextInput: TextInput,
    Checkbox: Checkbox,
    RadioButton: RadioButton,
    H1: H1,
    H2: H2,
    H3: H3,
    H4: H4,
    H5: H5,
    H6: H6,
    P: P,
    Text: Text,
    component: component,
    stateless: stateless,
    makeTouchable: makeTouchable,
    onChange: onChange,
    chart: chart,
    article: article,
    footer: footer,
    header: header,
    nav: nav,
    section: section,
    div: div,
    main: main,
    image: image,
    h1: h1,
    h2: h2,
    h3: h3,
    h4: h4,
    h5: h5,
    h6: h6,
    p: p,
    text: text,
    textInput: textInput,
    checkbox: checkbox,
    radioButton: radioButton,
    runComponent: runComponent,
    showLayoutChain: showLayoutChain,
    showSiblingGrows: showSiblingGrows,
    componentDSLFunctor: componentDSLFunctor,
    createReactComponent: $foreign.createReactComponent,
    createReactElement: $foreign.createReactElement,
    fragment: $foreign.fragment
};
