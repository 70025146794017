// Generated by purs version 0.12.0
"use strict";
var Data_Show = require("../Data.Show/index.js");
var Prelude = require("../Prelude/index.js");
var None = (function () {
    function None() {

    };
    None.value = new None();
    return None;
})();
var Underline = (function () {
    function Underline() {

    };
    Underline.value = new Underline();
    return Underline;
})();
var LineThrough = (function () {
    function LineThrough() {

    };
    LineThrough.value = new LineThrough();
    return LineThrough;
})();
var UnderlineLineThrough = (function () {
    function UnderlineLineThrough() {

    };
    UnderlineLineThrough.value = new UnderlineLineThrough();
    return UnderlineLineThrough;
})();
var showTextDecorationLine = new Data_Show.Show(function (v) {
    if (v instanceof None) {
        return "none";
    };
    if (v instanceof Underline) {
        return "underline";
    };
    if (v instanceof LineThrough) {
        return "line-through";
    };
    if (v instanceof UnderlineLineThrough) {
        return "underline line-through";
    };
    throw new Error("Failed pattern match at UI.Style.PropTypes.TextDecorationLine line 7, column 1 - line 7, column 59: " + [ v.constructor.name ]);
});
module.exports = {
    None: None,
    Underline: Underline,
    LineThrough: LineThrough,
    UnderlineLineThrough: UnderlineLineThrough,
    showTextDecorationLine: showTextDecorationLine
};
