"use strict";

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var React = require("react");

var _require = require("javascript"),
    web = _require.web,
    ui = _require.ui;

var _require2 = require("charts"),
    SampleChart = _require2.SampleChart;

exports.createChart = function (type) {
  return function (layoutChain) {
    return function (siblingGrows) {
      return function () {
        return function () {
          return function () {
            return React.createElement(SampleChart, { type: type, layoutChain: layoutChain, siblingGrows: siblingGrows });
          };
        };
      };
    };
  };
};

exports.domElement = web.domElement;

exports.textInput = function (props) {
  return function (styleInterpreter) {

    props.style = JSON.parse(styleInterpreter(props.style));

    if (props.name.value0) props.name = props.name.value0;else delete props.name;

    if (props.defaultValue.value0) props.defaultValue = props.defaultValue.value0;else delete props.defaultValue;

    if (props.placeholder.value0) props.placeholder = props.placeholder.value0;else delete props.placeholder;

    if (props.value.value0) props.value = props.value.value0;else delete props.value;

    if (props.placeholderTextColor.value0) props.style["color::placeholder"] = props.placeholderTextColor.value0.value0;

    delete props.placeholderTextColor;

    return React.createElement("input", _extends({ type: "text" }, props));
  };
};

exports.radioButton = function (style) {
  return function (props) {
    return function (styleInterpreter) {
      props.style = JSON.parse(styleInterpreter(style));

      if (props.name && props.name.value0) props.name = props.name.value0;else delete props.name;

      if (props.value.value0) props.value = props.value.value0;else delete props.value;

      return React.createElement("input", _extends({ type: "radio" }, props));
    };
  };
};

exports.checkbox = function (style) {
  return function (props) {
    return function (styleInterpreter) {
      props.style = JSON.parse(styleInterpreter(style));
      props.checked = props.value.toString() === "true";
      return React.createElement("input", _extends({ type: "checkbox" }, props));
    };
  };
};

exports.makeTouchable_ = function (handler) {
  return function (jsx) {
    return React.cloneElement(jsx, { onClick: handler });
  };
};

exports.onChange = function (handler) {
  return function (jsx) {
    return React.cloneElement(jsx, { onChange: function onChange(event) {
        return handler(event.target.value)();
      } });
  };
};