// Generated by purs version 0.12.0
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Core_Data = require("../Core.Data/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Prelude = require("../Prelude/index.js");
var Run = require("../Run/index.js");
var UI_App_Common = require("../UI.App.Common/index.js");
var UI_App_Common_Images = require("../UI.App.Common.Images/index.js");
var UI_App_Tiles_TabTitle = require("../UI.App.Tiles.TabTitle/index.js");
var UI_Components = require("../UI.Components/index.js");
var UI_Style = require("../UI.Style/index.js");
var UI_Style_Layout = require("../UI.Style.Layout/index.js");
var UI_Style_PropTypes_Display = require("../UI.Style.PropTypes.Display/index.js");
var UI_Style_PropTypes_FlexDirection = require("../UI.Style.PropTypes.FlexDirection/index.js");
var style = function (isFocus) {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_App_Common.outlineSpec(0)(0)(0)(0))(function () {
        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.display(UI_Style_PropTypes_Display.Flex.value))(function () {
            return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.flexDirection(UI_Style_PropTypes_FlexDirection.Row.value))(function () {
                return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.paddingLeft(new UI_Style.Pixel(10)))(function () {
                    return UI_Style_Layout.paddingRight(new UI_Style.Pixel(10));
                });
            });
        });
    });
};
var render = function (v) {
    var hubIcon = (function () {
        if (v.isFocus) {
            return UI_App_Common_Images.goldHubIcon({
                style: Control_Applicative.pure(Run.applicativeRun)(Data_Unit.unit)
            });
        };
        return UI_App_Common_Images.grayHubIcon({
            style: Control_Applicative.pure(Run.applicativeRun)(Data_Unit.unit)
        });
    })();
    return Control_Bind.bind(Run.bindRun)(hubIcon)(function (v1) {
        return Control_Bind.bind(Run.bindRun)(UI_App_Tiles_TabTitle.component({
            tile: v.tile,
            isFocus: v.isFocus
        }))(function (v2) {
            return UI_Components.div({
                style: style(v.isFocus)
            })(UI_Components.fragment([ v1, v2 ]));
        });
    });
};
var component = function (props) {
    return UI_Components.stateless("Tab")(props)(render);
};
module.exports = {
    component: component,
    render: render,
    style: style
};
