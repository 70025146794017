// Generated by purs version 0.12.0
"use strict";
var $foreign = require("./foreign.js");
var Core_Data = require("../Core.Data/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Data_Variant = require("../Data.Variant/index.js");
var Prelude = require("../Prelude/index.js");
var UI_App_Common = require("../UI.App.Common/index.js");
var UI_Data_AppData = require("../UI.Data.AppData/index.js");
var UI_Data_Navigation = require("../UI.Data.Navigation/index.js");
var UI_Event_ModelEvent = require("../UI.Event.ModelEvent/index.js");
var UI_Event_NavigationEvent = require("../UI.Event.NavigationEvent/index.js");
var KeyDownEvent = (function () {
    function KeyDownEvent(value0) {
        this.value0 = value0;
    };
    KeyDownEvent.create = function (value0) {
        return new KeyDownEvent(value0);
    };
    return KeyDownEvent;
})();
var SizeChangeEvent = (function () {
    function SizeChangeEvent(value0) {
        this.value0 = value0;
    };
    SizeChangeEvent.create = function (value0) {
        return new SizeChangeEvent(value0);
    };
    return SizeChangeEvent;
})();
var SetNavigationEvent = (function () {
    function SetNavigationEvent(value0) {
        this.value0 = value0;
    };
    SetNavigationEvent.create = function (value0) {
        return new SetNavigationEvent(value0);
    };
    return SetNavigationEvent;
})();
var _navigation = Data_Symbol.SProxy.value;
var _model = Data_Symbol.SProxy.value;
var handleKeyDownEvent = function (v) {
    return function (v1) {
        if (v instanceof KeyDownEvent) {
            if (v.value0.shiftKey) {
                if (v.value0.key === "ArrowDown") {
                    return new Data_Maybe.Just(Data_Variant.inj()(new Data_Symbol.IsSymbol(function () {
                        return "model";
                    }))(_model)(UI_Event_ModelEvent.DecreaseGrow.value));
                };
                if (v.value0.key === "ArrowUp") {
                    return new Data_Maybe.Just(Data_Variant.inj()(new Data_Symbol.IsSymbol(function () {
                        return "model";
                    }))(_model)(UI_Event_ModelEvent.IncreaseGrow.value));
                };
                return Data_Maybe.Nothing.value;
            };
            if (v.value0.key === "d") {
                return new Data_Maybe.Just(Data_Variant.inj()(new Data_Symbol.IsSymbol(function () {
                    return "model";
                }))(_model)(UI_Event_ModelEvent.DeleteTile.value));
            };
            if (v.value0.key === "h") {
                return new Data_Maybe.Just(Data_Variant.inj()(new Data_Symbol.IsSymbol(function () {
                    return "model";
                }))(_model)(new UI_Event_ModelEvent.ToggleShowTileNav(v1.navigation.value0.selections.focusTile)));
            };
            if (v.value0.key === "n") {
                return new Data_Maybe.Just(Data_Variant.inj()(new Data_Symbol.IsSymbol(function () {
                    return "model";
                }))(_model)(new UI_Event_ModelEvent.AddTile(v1.navigation.value0.selections.focusDashboard, v1.navigation.value0.selections.focusTile, Data_Maybe.Nothing.value)));
            };
            if (v.value0.key === "t") {
                return new Data_Maybe.Just(Data_Variant.inj()(new Data_Symbol.IsSymbol(function () {
                    return "model";
                }))(_model)(new UI_Event_ModelEvent.ToggleTileLayout(v1.navigation.value0.selections.focusTile)));
            };
            if (v.value0.key === "w") {
                return new Data_Maybe.Just(Data_Variant.inj()(new Data_Symbol.IsSymbol(function () {
                    return "model";
                }))(_model)(UI_Event_ModelEvent.AddWidget.create(v1.navigation.value0.selections.focusTile)(Data_Maybe.Just.create($foreign.randomChart(Data_Unit.unit)))));
            };
            if (v.value0.key === "ArrowDown") {
                return new Data_Maybe.Just(Data_Variant.inj()(new Data_Symbol.IsSymbol(function () {
                    return "navigation";
                }))(_navigation)(UI_Event_NavigationEvent.FocusNextTile.value));
            };
            if (v.value0.key === "ArrowUp") {
                return new Data_Maybe.Just(Data_Variant.inj()(new Data_Symbol.IsSymbol(function () {
                    return "navigation";
                }))(_navigation)(UI_Event_NavigationEvent.FocusPreviousTile.value));
            };
            if (v.value0.key === "ArrowRight") {
                return new Data_Maybe.Just(Data_Variant.inj()(new Data_Symbol.IsSymbol(function () {
                    return "navigation";
                }))(_navigation)(UI_Event_NavigationEvent.FocusNextTile.value));
            };
            if (v.value0.key === "ArrowLeft") {
                return new Data_Maybe.Just(Data_Variant.inj()(new Data_Symbol.IsSymbol(function () {
                    return "navigation";
                }))(_navigation)(UI_Event_NavigationEvent.FocusPreviousTile.value));
            };
            if (v.value0.key === "Escape") {
                return new Data_Maybe.Just(Data_Variant.inj()(new Data_Symbol.IsSymbol(function () {
                    return "navigation";
                }))(_navigation)(UI_Event_NavigationEvent.HideLightBox.value));
            };
            return Data_Maybe.Nothing.value;
        };
        return Data_Maybe.Nothing.value;
    };
};
module.exports = {
    KeyDownEvent: KeyDownEvent,
    SizeChangeEvent: SizeChangeEvent,
    SetNavigationEvent: SetNavigationEvent,
    handleKeyDownEvent: handleKeyDownEvent,
    _model: _model,
    _navigation: _navigation,
    randomChart: $foreign.randomChart
};
