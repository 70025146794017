// Generated by purs version 0.12.0
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Prelude = require("../Prelude/index.js");
var Run = require("../Run/index.js");
var UI_App_Common = require("../UI.App.Common/index.js");
var UI_Components = require("../UI.Components/index.js");
var UI_Style = require("../UI.Style/index.js");
var UI_Style_Layout = require("../UI.Style.Layout/index.js");
var UI_Style_PropTypes_BorderStyle = require("../UI.Style.PropTypes.BorderStyle/index.js");
var UI_Style_PropTypes_Display = require("../UI.Style.PropTypes.Display/index.js");
var UI_Style_PropTypes_FlexDirection = require("../UI.Style.PropTypes.FlexDirection/index.js");
var UI_Style_PropTypes_FontFamily = require("../UI.Style.PropTypes.FontFamily/index.js");
var UI_Style_PropTypes_JustifyContent = require("../UI.Style.PropTypes.JustifyContent/index.js");
var UI_Style_Text = require("../UI.Style.Text/index.js");
var UI_Style_View = require("../UI.Style.View/index.js");
var style = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(Run.expand()(UI_App_Common.outlineSpec(2)(2)(2)(2)))(function () {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_View.backgroundColor(UI_App_Common.dark1))(function () {
        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_View.borderColor(UI_App_Common.yellow))(function () {
            return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_View.borderStyle(UI_Style_PropTypes_BorderStyle.Solid.value))(function () {
                return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_View.borderWidth(new UI_Style.Pixel(0)))(function () {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Text.color(UI_App_Common.white2))(function () {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.display(UI_Style_PropTypes_Display.Flex.value))(function () {
                            return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.flex(1.0))(function () {
                                return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.flexDirection(UI_Style_PropTypes_FlexDirection.Column.value))(function () {
                                    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Text.fontFamily("Fira Code"))(function () {
                                        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.height(new UI_Style.Pixel(30)))(function () {
                                            return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.justifyContent(UI_Style_PropTypes_JustifyContent.Center.value))(function () {
                                                return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.marginTop(new UI_Style.Pixel(10)))(function () {
                                                    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.marginBottom(new UI_Style.Pixel(10)))(function () {
                                                        return UI_Style_Layout.padding(new UI_Style.Pixel(0));
                                                    });
                                                });
                                            });
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });
        });
    });
});
var render = function (v) {
    return UI_Components.textInput({
        style: style,
        placeholder: new Data_Maybe.Just("Search"),
        placeholderTextColor: new Data_Maybe.Just(UI_App_Common.gray3),
        name: new Data_Maybe.Just("Search"),
        value: Data_Maybe.Nothing.value,
        defaultValue: Data_Maybe.Nothing.value
    });
};
var component = UI_Components.stateless("SearchInput")({})(render);
module.exports = {
    component: component,
    render: render,
    style: style
};
