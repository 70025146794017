// Generated by purs version 0.12.0
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Core_Data = require("../Core.Data/index.js");
var Data_Function = require("../Data.Function/index.js");
var Prelude = require("../Prelude/index.js");
var Run = require("../Run/index.js");
var UI_App_Common = require("../UI.App.Common/index.js");
var UI_Components = require("../UI.Components/index.js");
var UI_Style = require("../UI.Style/index.js");
var UI_Style_Layout = require("../UI.Style.Layout/index.js");
var UI_Style_PropTypes_AlignContent = require("../UI.Style.PropTypes.AlignContent/index.js");
var UI_Style_PropTypes_AlignItems = require("../UI.Style.PropTypes.AlignItems/index.js");
var UI_Style_PropTypes_AlignSelf = require("../UI.Style.PropTypes.AlignSelf/index.js");
var UI_Style_PropTypes_Display = require("../UI.Style.PropTypes.Display/index.js");
var UI_Style_PropTypes_JustifyContent = require("../UI.Style.PropTypes.JustifyContent/index.js");
var hubIconStyle = function (additionalStyle) {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.alignSelf(UI_Style_PropTypes_AlignSelf.Center.value))(function () {
        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.margin(new UI_Style.Pixel(0)))(function () {
            return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.height(new UI_Style.Pixel(10)))(function () {
                return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.width(new UI_Style.Pixel(10)))(function () {
                    return additionalStyle;
                });
            });
        });
    });
};
var grayHubIcon = function (props) {
    return UI_Components.image({
        src: "/assets/images/icon-gray-hub.png",
        style: hubIconStyle(props.style)
    });
};
var goldHubIcon = function (props) {
    return UI_Components.image({
        src: "/assets/images/icon-gold-hub.png",
        style: hubIconStyle(props.style)
    });
};
var cogIcon = function (props) {
    var style = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.alignSelf(UI_Style_PropTypes_AlignSelf.Center.value))(function () {
        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.margin(new UI_Style.Pixel(0)))(function () {
            return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.height(new UI_Style.Pixel(18)))(function () {
                return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.width(new UI_Style.Pixel(18)))(function () {
                    return props.additionalStyle;
                });
            });
        });
    });
    return UI_Components.image({
        src: "/assets/images/icon-cog.png",
        style: style
    });
};
var settingsButton = function (v) {
    var style = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_App_Common.outlineSpec(0)(0)(0)(2))(function () {
        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.display(UI_Style_PropTypes_Display.Flex.value))(function () {
            return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.paddingRight(new UI_Style.Pixel(5)))(function () {
                return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.paddingLeft(new UI_Style.Pixel(5)))(function () {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.alignContent(UI_Style_PropTypes_AlignContent.Center.value))(function () {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.alignItems(UI_Style_PropTypes_AlignItems.Center.value))(function () {
                            return UI_Style_Layout.justifyContent(UI_Style_PropTypes_JustifyContent.Center.value);
                        });
                    });
                });
            });
        });
    });
    return Control_Bind.bind(Run.bindRun)(cogIcon({
        additionalStyle: UI_Style_Layout.alignSelf(UI_Style_PropTypes_AlignSelf.Center.value)
    }))(function (v1) {
        return UI_Components.div({
            style: style
        })(v1);
    });
};
module.exports = {
    cogIcon: cogIcon,
    goldHubIcon: goldHubIcon,
    grayHubIcon: grayHubIcon,
    hubIconStyle: hubIconStyle,
    settingsButton: settingsButton
};
