// Generated by purs version 0.12.0
"use strict";
var Data_Profunctor = require("../Data.Profunctor/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Costrong = function (Profunctor0, unfirst, unsecond) {
    this.Profunctor0 = Profunctor0;
    this.unfirst = unfirst;
    this.unsecond = unsecond;
};
var unsecond = function (dict) {
    return dict.unsecond;
};
var unfirst = function (dict) {
    return dict.unfirst;
};
module.exports = {
    unfirst: unfirst,
    unsecond: unsecond,
    Costrong: Costrong
};
