// Generated by purs version 0.12.0
"use strict";
var Control_Alt = require("../Control.Alt/index.js");
var Control_Alternative = require("../Control.Alternative/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Comonad_Cofree = require("../Control.Comonad.Cofree/index.js");
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var Core_Data = require("../Core.Data/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Lens = require("../Data.Lens/index.js");
var Data_Lens_Getter = require("../Data.Lens.Getter/index.js");
var Data_Lens_Internal_Forget = require("../Data.Lens.Internal.Forget/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_NonEmpty = require("../Data.NonEmpty/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_UUID = require("../Data.UUID/index.js");
var Prelude = require("../Prelude/index.js");
var Record = require("../Record/index.js");
var Text_Parsing_StringParser = require("../Text.Parsing.StringParser/index.js");
var Text_Parsing_StringParser_Combinators = require("../Text.Parsing.StringParser.Combinators/index.js");
var Text_Parsing_StringParser_String = require("../Text.Parsing.StringParser.String/index.js");
var UI_Data_LightBoxProps = require("../UI.Data.LightBoxProps/index.js");
var AppNav = (function () {
    function AppNav(value0) {
        this.value0 = value0;
    };
    AppNav.create = function (value0) {
        return new AppNav(value0);
    };
    return AppNav;
})();
var tryWorkspaceDashboardTile = function (v) {
    return function (model) {
        return Control_Bind.bind(Data_Either.bindEither)(Core_Data.getWorkspace(v.value0.selections.focusWorkspace)(model))(function (v1) {
            return Control_Bind.bind(Data_Either.bindEither)(Core_Data.getDashboard(v.value0.selections.focusDashboard)(model))(function (v2) {
                return Control_Bind.bind(Data_Either.bindEither)(Core_Data.getTile(v.value0.selections.focusTile)(model))(function (v3) {
                    return Control_Applicative.pure(Data_Either.applicativeEither)(new AppNav(v.value0));
                });
            });
        });
    };
};
var toUrl = function (v) {
    return "?workspace=" + (Data_Show.show(Data_Show.showInt)(Data_Lens_Getter.viewOn(v.value0.selections.focusWorkspace)(Core_Data._WorkspaceId(Data_Lens_Internal_Forget.strongForget))) + ("&dashboard=" + (Data_Show.show(Data_Show.showInt)(Data_Lens_Getter.viewOn(v.value0.selections.focusDashboard)(Core_Data._DashboardId(Data_Lens_Internal_Forget.strongForget))) + ("&tile=" + Data_Show.show(Data_Show.showInt)(Data_Lens_Getter.viewOn(v.value0.selections.focusTile)(Core_Data._TileId(Data_Lens_Internal_Forget.strongForget)))))));
};
var slash = Data_Functor["void"](Text_Parsing_StringParser.functorParser)(Text_Parsing_StringParser_String.string("/"));
var questionMark = Data_Functor["void"](Text_Parsing_StringParser.functorParser)(Text_Parsing_StringParser_String.string("?"));
var parseUUID = function (str) {
    var v = Data_UUID.parseUUID(str);
    if (v instanceof Data_Maybe.Nothing) {
        return Text_Parsing_StringParser.fail("Could not parse " + (str + " as a UUID"));
    };
    if (v instanceof Data_Maybe.Just) {
        return Control_Applicative.pure(Text_Parsing_StringParser.applicativeParser)(v.value0);
    };
    throw new Error("Failed pattern match at UI.Data.Navigation line 173, column 17 - line 175, column 25: " + [ v.constructor.name ]);
};
var $$parseInt = function (str) {
    var v = Data_Int.fromString(str);
    if (v instanceof Data_Maybe.Nothing) {
        return Text_Parsing_StringParser.fail("Could not convert " + (str + " to an Int"));
    };
    if (v instanceof Data_Maybe.Just) {
        return Control_Applicative.pure(Text_Parsing_StringParser.applicativeParser)(v.value0);
    };
    throw new Error("Failed pattern match at UI.Data.Navigation line 168, column 16 - line 170, column 22: " + [ v.constructor.name ]);
};
var navigationEq = new Data_Eq.Eq(function (v) {
    return function (v1) {
        return Record.equal()(Record.equalFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "focusDashboard";
        }))(Core_Data.dashboardIdEq)()(Record.equalFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "focusTile";
        }))(Core_Data.tileIdEq)()(Record.equalFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "focusWorkspace";
        }))(Core_Data.workspaceIdEq)()(Record.equalFieldsNil))))(v.value0.selections)(v1.value0.selections) && Data_Eq.eq(Core_Data.modelIdEq)(v.value0.modelId)(v1.value0.modelId);
    };
});
var firstTileInDashboard = function (v) {
    return Control_Comonad_Cofree.head(v.tiles);
};
var firstTileIdInDashboard = function ($68) {
    return Data_Lens_Getter.view(function ($69) {
        return Core_Data._Tile(Data_Lens_Internal_Forget.strongForget)(Core_Data._id(Data_Lens_Internal_Forget.strongForget)($69));
    })(firstTileInDashboard($68));
};
var firstDashboardInWorkspace = function (v) {
    return Data_NonEmpty.head(v.dashboards);
};
var firstTileInWorkspace = function ($70) {
    return firstTileInDashboard(firstDashboardInWorkspace($70));
};
var firstTileIdInWorkspace = function ($71) {
    return Data_Lens_Getter.view(function ($72) {
        return Core_Data._Tile(Data_Lens_Internal_Forget.strongForget)(Core_Data._id(Data_Lens_Internal_Forget.strongForget)($72));
    })(firstTileInWorkspace($71));
};
var firstDashboardIdInWorkspace = function ($73) {
    return Data_Lens_Getter.view(function ($74) {
        return Core_Data._Dashboard(Data_Lens_Internal_Forget.strongForget)(Core_Data._id(Data_Lens_Internal_Forget.strongForget)($74));
    })(firstDashboardInWorkspace($73));
};
var equalsSign = Data_Functor["void"](Text_Parsing_StringParser.functorParser)(Text_Parsing_StringParser_String.string("="));
var defaultAppNavWithWorkspaceIdAndDashboardId = function (v) {
    return function (v1) {
        return function (v2) {
            return new AppNav({
                modelId: v2.id,
                selections: {
                    focusWorkspace: v.id,
                    focusDashboard: v1.id,
                    focusTile: firstTileIdInDashboard(v1)
                },
                lightBox: {
                    show: false,
                    title: Data_Maybe.Nothing.value,
                    child: Data_Maybe.Nothing.value
                }
            });
        };
    };
};
var tryWorkspaceDashboard = function (v) {
    return function (model) {
        return Control_Bind.bind(Data_Either.bindEither)(Core_Data.getWorkspace(v.value0.selections.focusWorkspace)(model))(function (v1) {
            return Control_Bind.bind(Data_Either.bindEither)(Core_Data.getDashboard(v.value0.selections.focusDashboard)(model))(function (v2) {
                return Control_Applicative.pure(Data_Either.applicativeEither)(defaultAppNavWithWorkspaceIdAndDashboardId(v1)(v2)(model));
            });
        });
    };
};
var defaultAppNavWithWorkspaceId = function (v) {
    return function (v1) {
        return new AppNav({
            modelId: v1.id,
            selections: {
                focusWorkspace: v.id,
                focusDashboard: firstDashboardIdInWorkspace(v),
                focusTile: firstTileIdInWorkspace(v)
            },
            lightBox: {
                show: false,
                title: Data_Maybe.Nothing.value,
                child: Data_Maybe.Nothing.value
            }
        });
    };
};
var tryWorkspace = function (v) {
    return function (model) {
        return Control_Bind.bind(Data_Either.bindEither)(Core_Data.getWorkspace(v.value0.selections.focusWorkspace)(model))(function (v1) {
            return Control_Applicative.pure(Data_Either.applicativeEither)(defaultAppNavWithWorkspaceId(v1)(model));
        });
    };
};
var defaultAppNav = function (v) {
    return new AppNav({
        modelId: v.id,
        selections: {
            focusWorkspace: Core_Data.firstWorkspaceId(v),
            focusDashboard: Core_Data.firstDashboardId(v),
            focusTile: Core_Data.firstTileId(v)
        },
        lightBox: {
            show: false,
            title: Data_Maybe.Nothing.value,
            child: Data_Maybe.Nothing.value
        }
    });
};
var verifyNavigation = function (model) {
    return function (v) {
        var result = Control_Alt.alt(Data_Either.altEither)(Control_Alt.alt(Data_Either.altEither)(tryWorkspaceDashboardTile(v)(model))(tryWorkspaceDashboard(v)(model)))(tryWorkspace(v)(model));
        return Data_Maybe.fromMaybe(defaultAppNav(model))(Data_Either.hush(result));
    };
};
var ampersand = Data_Functor["void"](Text_Parsing_StringParser.functorParser)(Text_Parsing_StringParser_String.string("&"));
var param = function (name) {
    var value = Data_Functor.map(Text_Parsing_StringParser.functorParser)(Data_Foldable.fold(Data_List_Types.foldableNonEmptyList)(Data_Monoid.monoidString))(Text_Parsing_StringParser_Combinators.many1(Data_Functor.map(Text_Parsing_StringParser.functorParser)(Data_String_CodeUnits.singleton)(Text_Parsing_StringParser_String.satisfy(function (c) {
        return c !== "&";
    }))));
    return Control_Apply.applySecond(Text_Parsing_StringParser.applyParser)(Control_Apply.applySecond(Text_Parsing_StringParser.applyParser)(Control_Apply.applySecond(Text_Parsing_StringParser.applyParser)(Control_Alt.alt(Text_Parsing_StringParser.altParser)(questionMark)(ampersand))(Text_Parsing_StringParser_String.string(name)))(equalsSign))(value);
};
var focusDashboard = Data_Functor.map(Text_Parsing_StringParser.functorParser)(Core_Data.DashboardId)(Control_Bind.bind(Text_Parsing_StringParser.bindParser)(param("dashboard"))($$parseInt));
var focusTile = Data_Functor.map(Text_Parsing_StringParser.functorParser)(Core_Data.TileId)(Control_Bind.bind(Text_Parsing_StringParser.bindParser)(param("tile"))($$parseInt));
var focusWorkspace = Data_Functor.map(Text_Parsing_StringParser.functorParser)(Core_Data.WorkspaceId)(Control_Bind.bind(Text_Parsing_StringParser.bindParser)(param("workspace"))($$parseInt));
var parseAppNav = function (v) {
    return Control_Bind.bind(Text_Parsing_StringParser.bindParser)(focusWorkspace)(function (v1) {
        return Control_Bind.bind(Text_Parsing_StringParser.bindParser)(focusDashboard)(function (v2) {
            return Control_Bind.bind(Text_Parsing_StringParser.bindParser)(focusTile)(function (v3) {
                return Control_Applicative.pure(Text_Parsing_StringParser.applicativeParser)(new AppNav({
                    modelId: v.id,
                    selections: {
                        focusWorkspace: v1,
                        focusDashboard: v2,
                        focusTile: v3
                    },
                    lightBox: {
                        show: false,
                        title: Data_Maybe.Nothing.value,
                        child: Data_Maybe.Nothing.value
                    }
                }));
            });
        });
    });
};
var fromUrl = function (model) {
    return function (url) {
        var v = Text_Parsing_StringParser.runParser(parseAppNav(model))(url);
        if (v instanceof Data_Either.Right) {
            return verifyNavigation(model)(v.value0);
        };
        if (v instanceof Data_Either.Left) {
            return defaultAppNav(model);
        };
        throw new Error("Failed pattern match at UI.Data.Navigation line 41, column 3 - line 43, column 37: " + [ v.constructor.name ]);
    };
};
var segment = (function () {
    var value = Data_Functor.map(Text_Parsing_StringParser.functorParser)(Data_Foldable.fold(Data_List_Types.foldableList)(Data_Monoid.monoidString))(Text_Parsing_StringParser_Combinators.many(Data_Functor.map(Text_Parsing_StringParser.functorParser)(Data_String_CodeUnits.singleton)(Text_Parsing_StringParser_String.satisfy(function (c) {
        return c !== "&" && (c !== "?" && c !== "/");
    }))));
    var end = Text_Parsing_StringParser["try"](Control_Alt.alt(Text_Parsing_StringParser.altParser)(Control_Alt.alt(Text_Parsing_StringParser.altParser)(Control_Alt.alt(Text_Parsing_StringParser.altParser)(Text_Parsing_StringParser_String.eof)(slash))(questionMark))(ampersand));
    return Control_Apply.applySecond(Text_Parsing_StringParser.applyParser)(slash)(value);
})();
var modelId = Data_Functor.map(Text_Parsing_StringParser.functorParser)(Core_Data.ModelId)(Control_Bind.bind(Text_Parsing_StringParser.bindParser)(segment)(parseUUID));
module.exports = {
    AppNav: AppNav,
    toUrl: toUrl,
    fromUrl: fromUrl,
    verifyNavigation: verifyNavigation,
    tryWorkspaceDashboardTile: tryWorkspaceDashboardTile,
    tryWorkspaceDashboard: tryWorkspaceDashboard,
    tryWorkspace: tryWorkspace,
    defaultAppNavWithWorkspaceIdAndDashboardId: defaultAppNavWithWorkspaceIdAndDashboardId,
    defaultAppNavWithWorkspaceId: defaultAppNavWithWorkspaceId,
    firstDashboardInWorkspace: firstDashboardInWorkspace,
    firstDashboardIdInWorkspace: firstDashboardIdInWorkspace,
    firstTileInDashboard: firstTileInDashboard,
    firstTileInWorkspace: firstTileInWorkspace,
    firstTileIdInWorkspace: firstTileIdInWorkspace,
    firstTileIdInDashboard: firstTileIdInDashboard,
    parseAppNav: parseAppNav,
    focusWorkspace: focusWorkspace,
    focusDashboard: focusDashboard,
    focusTile: focusTile,
    modelId: modelId,
    segment: segment,
    param: param,
    slash: slash,
    questionMark: questionMark,
    ampersand: ampersand,
    equalsSign: equalsSign,
    "parseInt": $$parseInt,
    parseUUID: parseUUID,
    defaultAppNav: defaultAppNav,
    navigationEq: navigationEq
};
