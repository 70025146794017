// Generated by purs version 0.12.0
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Functor_Variant = require("../Data.Functor.Variant/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Prelude = require("../Prelude/index.js");
var Run = require("../Run/index.js");
var Run_Writer = require("../Run.Writer/index.js");
var UI_Style = require("../UI.Style/index.js");
var UI_Style_PropTypes_AlignContent = require("../UI.Style.PropTypes.AlignContent/index.js");
var UI_Style_PropTypes_AlignItems = require("../UI.Style.PropTypes.AlignItems/index.js");
var UI_Style_PropTypes_AlignSelf = require("../UI.Style.PropTypes.AlignSelf/index.js");
var UI_Style_PropTypes_Display = require("../UI.Style.PropTypes.Display/index.js");
var UI_Style_PropTypes_FlexDirection = require("../UI.Style.PropTypes.FlexDirection/index.js");
var UI_Style_PropTypes_FlexWrap = require("../UI.Style.PropTypes.FlexWrap/index.js");
var UI_Style_PropTypes_JustifyContent = require("../UI.Style.PropTypes.JustifyContent/index.js");
var UI_Style_PropTypes_Overflow = require("../UI.Style.PropTypes.Overflow/index.js");
var UI_Style_PropTypes_Position = require("../UI.Style.PropTypes.Position/index.js");
var Width = (function () {
    function Width(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Width.create = function (value0) {
        return function (value1) {
            return new Width(value0, value1);
        };
    };
    return Width;
})();
var Height = (function () {
    function Height(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Height.create = function (value0) {
        return function (value1) {
            return new Height(value0, value1);
        };
    };
    return Height;
})();
var Bottom = (function () {
    function Bottom(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Bottom.create = function (value0) {
        return function (value1) {
            return new Bottom(value0, value1);
        };
    };
    return Bottom;
})();
var End = (function () {
    function End(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    End.create = function (value0) {
        return function (value1) {
            return new End(value0, value1);
        };
    };
    return End;
})();
var Left = (function () {
    function Left(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Left.create = function (value0) {
        return function (value1) {
            return new Left(value0, value1);
        };
    };
    return Left;
})();
var Right = (function () {
    function Right(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Right.create = function (value0) {
        return function (value1) {
            return new Right(value0, value1);
        };
    };
    return Right;
})();
var Start = (function () {
    function Start(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Start.create = function (value0) {
        return function (value1) {
            return new Start(value0, value1);
        };
    };
    return Start;
})();
var Top = (function () {
    function Top(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Top.create = function (value0) {
        return function (value1) {
            return new Top(value0, value1);
        };
    };
    return Top;
})();
var FlexBasis = (function () {
    function FlexBasis(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    FlexBasis.create = function (value0) {
        return function (value1) {
            return new FlexBasis(value0, value1);
        };
    };
    return FlexBasis;
})();
var MinWidth = (function () {
    function MinWidth(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    MinWidth.create = function (value0) {
        return function (value1) {
            return new MinWidth(value0, value1);
        };
    };
    return MinWidth;
})();
var MaxWidth = (function () {
    function MaxWidth(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    MaxWidth.create = function (value0) {
        return function (value1) {
            return new MaxWidth(value0, value1);
        };
    };
    return MaxWidth;
})();
var MaxHeight = (function () {
    function MaxHeight(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    MaxHeight.create = function (value0) {
        return function (value1) {
            return new MaxHeight(value0, value1);
        };
    };
    return MaxHeight;
})();
var MinHeight = (function () {
    function MinHeight(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    MinHeight.create = function (value0) {
        return function (value1) {
            return new MinHeight(value0, value1);
        };
    };
    return MinHeight;
})();
var Margin = (function () {
    function Margin(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Margin.create = function (value0) {
        return function (value1) {
            return new Margin(value0, value1);
        };
    };
    return Margin;
})();
var MarginBottom = (function () {
    function MarginBottom(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    MarginBottom.create = function (value0) {
        return function (value1) {
            return new MarginBottom(value0, value1);
        };
    };
    return MarginBottom;
})();
var MarginHorizontal = (function () {
    function MarginHorizontal(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    MarginHorizontal.create = function (value0) {
        return function (value1) {
            return new MarginHorizontal(value0, value1);
        };
    };
    return MarginHorizontal;
})();
var MarginEnd = (function () {
    function MarginEnd(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    MarginEnd.create = function (value0) {
        return function (value1) {
            return new MarginEnd(value0, value1);
        };
    };
    return MarginEnd;
})();
var MarginLeft = (function () {
    function MarginLeft(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    MarginLeft.create = function (value0) {
        return function (value1) {
            return new MarginLeft(value0, value1);
        };
    };
    return MarginLeft;
})();
var MarginRight = (function () {
    function MarginRight(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    MarginRight.create = function (value0) {
        return function (value1) {
            return new MarginRight(value0, value1);
        };
    };
    return MarginRight;
})();
var MarginStart = (function () {
    function MarginStart(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    MarginStart.create = function (value0) {
        return function (value1) {
            return new MarginStart(value0, value1);
        };
    };
    return MarginStart;
})();
var MarginTop = (function () {
    function MarginTop(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    MarginTop.create = function (value0) {
        return function (value1) {
            return new MarginTop(value0, value1);
        };
    };
    return MarginTop;
})();
var MarginVertical = (function () {
    function MarginVertical(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    MarginVertical.create = function (value0) {
        return function (value1) {
            return new MarginVertical(value0, value1);
        };
    };
    return MarginVertical;
})();
var Padding = (function () {
    function Padding(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Padding.create = function (value0) {
        return function (value1) {
            return new Padding(value0, value1);
        };
    };
    return Padding;
})();
var PaddingBottom = (function () {
    function PaddingBottom(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    PaddingBottom.create = function (value0) {
        return function (value1) {
            return new PaddingBottom(value0, value1);
        };
    };
    return PaddingBottom;
})();
var PaddingEnd = (function () {
    function PaddingEnd(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    PaddingEnd.create = function (value0) {
        return function (value1) {
            return new PaddingEnd(value0, value1);
        };
    };
    return PaddingEnd;
})();
var PaddingHorizontal = (function () {
    function PaddingHorizontal(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    PaddingHorizontal.create = function (value0) {
        return function (value1) {
            return new PaddingHorizontal(value0, value1);
        };
    };
    return PaddingHorizontal;
})();
var PaddingLeft = (function () {
    function PaddingLeft(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    PaddingLeft.create = function (value0) {
        return function (value1) {
            return new PaddingLeft(value0, value1);
        };
    };
    return PaddingLeft;
})();
var PaddingRight = (function () {
    function PaddingRight(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    PaddingRight.create = function (value0) {
        return function (value1) {
            return new PaddingRight(value0, value1);
        };
    };
    return PaddingRight;
})();
var PaddingStart = (function () {
    function PaddingStart(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    PaddingStart.create = function (value0) {
        return function (value1) {
            return new PaddingStart(value0, value1);
        };
    };
    return PaddingStart;
})();
var PaddingTop = (function () {
    function PaddingTop(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    PaddingTop.create = function (value0) {
        return function (value1) {
            return new PaddingTop(value0, value1);
        };
    };
    return PaddingTop;
})();
var PaddingVertical = (function () {
    function PaddingVertical(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    PaddingVertical.create = function (value0) {
        return function (value1) {
            return new PaddingVertical(value0, value1);
        };
    };
    return PaddingVertical;
})();
var BorderWidth = (function () {
    function BorderWidth(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    BorderWidth.create = function (value0) {
        return function (value1) {
            return new BorderWidth(value0, value1);
        };
    };
    return BorderWidth;
})();
var BorderBottomWidth = (function () {
    function BorderBottomWidth(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    BorderBottomWidth.create = function (value0) {
        return function (value1) {
            return new BorderBottomWidth(value0, value1);
        };
    };
    return BorderBottomWidth;
})();
var BorderEndWidth = (function () {
    function BorderEndWidth(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    BorderEndWidth.create = function (value0) {
        return function (value1) {
            return new BorderEndWidth(value0, value1);
        };
    };
    return BorderEndWidth;
})();
var BorderLeftWidth = (function () {
    function BorderLeftWidth(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    BorderLeftWidth.create = function (value0) {
        return function (value1) {
            return new BorderLeftWidth(value0, value1);
        };
    };
    return BorderLeftWidth;
})();
var BorderRightWidth = (function () {
    function BorderRightWidth(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    BorderRightWidth.create = function (value0) {
        return function (value1) {
            return new BorderRightWidth(value0, value1);
        };
    };
    return BorderRightWidth;
})();
var BorderStartWidth = (function () {
    function BorderStartWidth(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    BorderStartWidth.create = function (value0) {
        return function (value1) {
            return new BorderStartWidth(value0, value1);
        };
    };
    return BorderStartWidth;
})();
var BorderTopWidth = (function () {
    function BorderTopWidth(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    BorderTopWidth.create = function (value0) {
        return function (value1) {
            return new BorderTopWidth(value0, value1);
        };
    };
    return BorderTopWidth;
})();
var Flex = (function () {
    function Flex(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Flex.create = function (value0) {
        return function (value1) {
            return new Flex(value0, value1);
        };
    };
    return Flex;
})();
var FlexGrow = (function () {
    function FlexGrow(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    FlexGrow.create = function (value0) {
        return function (value1) {
            return new FlexGrow(value0, value1);
        };
    };
    return FlexGrow;
})();
var FlexShrink = (function () {
    function FlexShrink(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    FlexShrink.create = function (value0) {
        return function (value1) {
            return new FlexShrink(value0, value1);
        };
    };
    return FlexShrink;
})();
var AspectRatio = (function () {
    function AspectRatio(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    AspectRatio.create = function (value0) {
        return function (value1) {
            return new AspectRatio(value0, value1);
        };
    };
    return AspectRatio;
})();
var ZIndex = (function () {
    function ZIndex(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    ZIndex.create = function (value0) {
        return function (value1) {
            return new ZIndex(value0, value1);
        };
    };
    return ZIndex;
})();
var DisplayProp = (function () {
    function DisplayProp(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    DisplayProp.create = function (value0) {
        return function (value1) {
            return new DisplayProp(value0, value1);
        };
    };
    return DisplayProp;
})();
var PositionProp = (function () {
    function PositionProp(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    PositionProp.create = function (value0) {
        return function (value1) {
            return new PositionProp(value0, value1);
        };
    };
    return PositionProp;
})();
var FlexDirectionProp = (function () {
    function FlexDirectionProp(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    FlexDirectionProp.create = function (value0) {
        return function (value1) {
            return new FlexDirectionProp(value0, value1);
        };
    };
    return FlexDirectionProp;
})();
var FlexWrapProp = (function () {
    function FlexWrapProp(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    FlexWrapProp.create = function (value0) {
        return function (value1) {
            return new FlexWrapProp(value0, value1);
        };
    };
    return FlexWrapProp;
})();
var JustifyContentProp = (function () {
    function JustifyContentProp(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    JustifyContentProp.create = function (value0) {
        return function (value1) {
            return new JustifyContentProp(value0, value1);
        };
    };
    return JustifyContentProp;
})();
var AlignItemsProp = (function () {
    function AlignItemsProp(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    AlignItemsProp.create = function (value0) {
        return function (value1) {
            return new AlignItemsProp(value0, value1);
        };
    };
    return AlignItemsProp;
})();
var AlignSelfProp = (function () {
    function AlignSelfProp(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    AlignSelfProp.create = function (value0) {
        return function (value1) {
            return new AlignSelfProp(value0, value1);
        };
    };
    return AlignSelfProp;
})();
var AlignContentProp = (function () {
    function AlignContentProp(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    AlignContentProp.create = function (value0) {
        return function (value1) {
            return new AlignContentProp(value0, value1);
        };
    };
    return AlignContentProp;
})();
var OverflowProp = (function () {
    function OverflowProp(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    OverflowProp.create = function (value0) {
        return function (value1) {
            return new OverflowProp(value0, value1);
        };
    };
    return OverflowProp;
})();
var handleLayout = function (v) {
    if (v instanceof Height) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof Width) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof Bottom) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof End) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof Left) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof Right) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof Start) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof Top) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof FlexBasis) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof MinWidth) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof MaxWidth) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof MinHeight) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof MaxHeight) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof Margin) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof MarginBottom) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof MarginHorizontal) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof MarginEnd) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof MarginLeft) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof MarginRight) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof MarginStart) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof MarginTop) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof MarginVertical) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof Padding) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof PaddingBottom) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof PaddingEnd) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof PaddingHorizontal) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof PaddingLeft) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof PaddingRight) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof PaddingStart) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof PaddingTop) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof PaddingVertical) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof BorderWidth) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof BorderBottomWidth) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof BorderEndWidth) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof BorderLeftWidth) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof BorderRightWidth) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof BorderStartWidth) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof BorderTopWidth) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof Flex) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof FlexGrow) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof FlexShrink) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof AspectRatio) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof ZIndex) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof DisplayProp) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof PositionProp) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof FlexDirectionProp) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof FlexWrapProp) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof JustifyContentProp) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof AlignItemsProp) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof AlignSelfProp) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof AlignContentProp) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof OverflowProp) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    throw new Error("Failed pattern match at UI.Style.Layout line 363, column 16 - line 417, column 54: " + [ v.constructor.name ]);
};
var functorLayoutDSL = new Data_Functor.Functor(function (f) {
    return function (m) {
        if (m instanceof Width) {
            return new Width(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof Height) {
            return new Height(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof Bottom) {
            return new Bottom(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof End) {
            return new End(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof Left) {
            return new Left(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof Right) {
            return new Right(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof Start) {
            return new Start(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof Top) {
            return new Top(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof FlexBasis) {
            return new FlexBasis(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof MinWidth) {
            return new MinWidth(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof MaxWidth) {
            return new MaxWidth(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof MaxHeight) {
            return new MaxHeight(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof MinHeight) {
            return new MinHeight(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof Margin) {
            return new Margin(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof MarginBottom) {
            return new MarginBottom(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof MarginHorizontal) {
            return new MarginHorizontal(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof MarginEnd) {
            return new MarginEnd(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof MarginLeft) {
            return new MarginLeft(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof MarginRight) {
            return new MarginRight(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof MarginStart) {
            return new MarginStart(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof MarginTop) {
            return new MarginTop(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof MarginVertical) {
            return new MarginVertical(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof Padding) {
            return new Padding(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof PaddingBottom) {
            return new PaddingBottom(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof PaddingEnd) {
            return new PaddingEnd(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof PaddingHorizontal) {
            return new PaddingHorizontal(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof PaddingLeft) {
            return new PaddingLeft(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof PaddingRight) {
            return new PaddingRight(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof PaddingStart) {
            return new PaddingStart(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof PaddingTop) {
            return new PaddingTop(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof PaddingVertical) {
            return new PaddingVertical(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof BorderWidth) {
            return new BorderWidth(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof BorderBottomWidth) {
            return new BorderBottomWidth(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof BorderEndWidth) {
            return new BorderEndWidth(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof BorderLeftWidth) {
            return new BorderLeftWidth(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof BorderRightWidth) {
            return new BorderRightWidth(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof BorderStartWidth) {
            return new BorderStartWidth(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof BorderTopWidth) {
            return new BorderTopWidth(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof Flex) {
            return new Flex(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof FlexGrow) {
            return new FlexGrow(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof FlexShrink) {
            return new FlexShrink(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof AspectRatio) {
            return new AspectRatio(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof ZIndex) {
            return new ZIndex(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof DisplayProp) {
            return new DisplayProp(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof PositionProp) {
            return new PositionProp(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof FlexDirectionProp) {
            return new FlexDirectionProp(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof FlexWrapProp) {
            return new FlexWrapProp(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof JustifyContentProp) {
            return new JustifyContentProp(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof AlignItemsProp) {
            return new AlignItemsProp(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof AlignSelfProp) {
            return new AlignSelfProp(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof AlignContentProp) {
            return new AlignContentProp(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof OverflowProp) {
            return new OverflowProp(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        throw new Error("Failed pattern match at UI.Style.Layout line 144, column 8 - line 144, column 54: " + [ m.constructor.name ]);
    };
});
var _layout = Data_Symbol.SProxy.value;
var buildDimensionProperty = function (dictCategory) {
    return function (name) {
        return function (dsl) {
            return function (writer) {
                var doValue = function (d) {
                    return function (wrtr) {
                        return Control_Bind.bind(Run.bindRun)(Run.lift()(new Data_Symbol.IsSymbol(function () {
                            return "layout";
                        }))(functorLayoutDSL)(_layout)(d))(wrtr);
                    };
                };
                return Control_Bind.bind(Run.bindRun)(doValue(dsl(Control_Category.identity(dictCategory)))(writer))(function (value) {
                    if (value instanceof UI_Style.Pixel) {
                        return Run_Writer.tell(new UI_Style.IntProperty(name, value.value0));
                    };
                    if (value instanceof UI_Style.Percent) {
                        return Run_Writer.tell(new UI_Style.StringProperty(name, Data_Show.show(Data_Show.showInt)(value.value0) + "%"));
                    };
                    throw new Error("Failed pattern match at UI.Style.Layout line 339, column 89 - line 341, column 65: " + [ value.constructor.name ]);
                });
            };
        };
    };
};
var bottom = function (dimension) {
    return buildDimensionProperty(Control_Category.categoryFn)("bottom")(Bottom.create(dimension))(UI_Style.writeDimension);
};
var end = function (dimension) {
    return buildDimensionProperty(Control_Category.categoryFn)("end")(End.create(dimension))(UI_Style.writeDimension);
};
var flexBasis = function (dimension) {
    return buildDimensionProperty(Control_Category.categoryFn)("flexBasis")(FlexBasis.create(dimension))(UI_Style.writeDimension);
};
var height = function (dimension) {
    return buildDimensionProperty(Control_Category.categoryFn)("height")(Height.create(dimension))(UI_Style.writeDimension);
};
var left = function (dimension) {
    return buildDimensionProperty(Control_Category.categoryFn)("left")(Left.create(dimension))(UI_Style.writeDimension);
};
var margin = function (dimension) {
    return buildDimensionProperty(Control_Category.categoryFn)("margin")(Margin.create(dimension))(UI_Style.writeDimension);
};
var marginBottom = function (dimension) {
    return buildDimensionProperty(Control_Category.categoryFn)("marginBottom")(MarginBottom.create(dimension))(UI_Style.writeDimension);
};
var marginEnd = function (dimension) {
    return buildDimensionProperty(Control_Category.categoryFn)("marginEnd")(MarginEnd.create(dimension))(UI_Style.writeDimension);
};
var marginHorizontal = function (dimension) {
    return buildDimensionProperty(Control_Category.categoryFn)("marginHorizontal")(MarginHorizontal.create(dimension))(UI_Style.writeDimension);
};
var marginLeft = function (dimension) {
    return buildDimensionProperty(Control_Category.categoryFn)("marginLeft")(MarginLeft.create(dimension))(UI_Style.writeDimension);
};
var marginRight = function (dimension) {
    return buildDimensionProperty(Control_Category.categoryFn)("marginRight")(MarginRight.create(dimension))(UI_Style.writeDimension);
};
var marginStart = function (dimension) {
    return buildDimensionProperty(Control_Category.categoryFn)("marginStart")(MarginStart.create(dimension))(UI_Style.writeDimension);
};
var marginTop = function (dimension) {
    return buildDimensionProperty(Control_Category.categoryFn)("marginTop")(MarginTop.create(dimension))(UI_Style.writeDimension);
};
var marginVertical = function (dimension) {
    return buildDimensionProperty(Control_Category.categoryFn)("marginVertical")(MarginVertical.create(dimension))(UI_Style.writeDimension);
};
var maxHeight = function (dimension) {
    return buildDimensionProperty(Control_Category.categoryFn)("maxHeight")(MaxHeight.create(dimension))(UI_Style.writeDimension);
};
var maxWidth = function (dimension) {
    return buildDimensionProperty(Control_Category.categoryFn)("maxWidth")(MaxWidth.create(dimension))(UI_Style.writeDimension);
};
var minHeight = function (dimension) {
    return buildDimensionProperty(Control_Category.categoryFn)("minHeight")(MinHeight.create(dimension))(UI_Style.writeDimension);
};
var minWidth = function (dimension) {
    return buildDimensionProperty(Control_Category.categoryFn)("minWidth")(MinWidth.create(dimension))(UI_Style.writeDimension);
};
var padding = function (dimension) {
    return buildDimensionProperty(Control_Category.categoryFn)("padding")(Padding.create(dimension))(UI_Style.writeDimension);
};
var paddingBottom = function (dimension) {
    return buildDimensionProperty(Control_Category.categoryFn)("paddingBottom")(PaddingBottom.create(dimension))(UI_Style.writeDimension);
};
var paddingEnd = function (dimension) {
    return buildDimensionProperty(Control_Category.categoryFn)("paddingEnd")(PaddingEnd.create(dimension))(UI_Style.writeDimension);
};
var paddingHorizontal = function (dimension) {
    return buildDimensionProperty(Control_Category.categoryFn)("paddingHorizontal")(PaddingHorizontal.create(dimension))(UI_Style.writeDimension);
};
var paddingLeft = function (dimension) {
    return buildDimensionProperty(Control_Category.categoryFn)("paddingLeft")(PaddingLeft.create(dimension))(UI_Style.writeDimension);
};
var paddingRight = function (dimension) {
    return buildDimensionProperty(Control_Category.categoryFn)("paddingRight")(PaddingRight.create(dimension))(UI_Style.writeDimension);
};
var paddingStart = function (dimension) {
    return buildDimensionProperty(Control_Category.categoryFn)("paddingStart")(PaddingStart.create(dimension))(UI_Style.writeDimension);
};
var paddingTop = function (dimension) {
    return buildDimensionProperty(Control_Category.categoryFn)("paddingTop")(PaddingTop.create(dimension))(UI_Style.writeDimension);
};
var paddingVertical = function (dimension) {
    return buildDimensionProperty(Control_Category.categoryFn)("paddingVertical")(PaddingVertical.create(dimension))(UI_Style.writeDimension);
};
var right = function (dimension) {
    return buildDimensionProperty(Control_Category.categoryFn)("right")(Right.create(dimension))(UI_Style.writeDimension);
};
var start = function (dimension) {
    return buildDimensionProperty(Control_Category.categoryFn)("start")(Start.create(dimension))(UI_Style.writeDimension);
};
var top = function (dimension) {
    return buildDimensionProperty(Control_Category.categoryFn)("top")(Top.create(dimension))(UI_Style.writeDimension);
};
var width = function (dimension) {
    return buildDimensionProperty(Control_Category.categoryFn)("width")(Width.create(dimension))(UI_Style.writeDimension);
};
var buildIntProperty = function (dictCategory) {
    return function (name) {
        return function (dsl) {
            return function (writer) {
                var doValue = function (d) {
                    return function (wrtr) {
                        return Control_Bind.bind(Run.bindRun)(Run.lift()(new Data_Symbol.IsSymbol(function () {
                            return "layout";
                        }))(functorLayoutDSL)(_layout)(d))(wrtr);
                    };
                };
                return Control_Bind.bind(Run.bindRun)(doValue(dsl(Control_Category.identity(dictCategory)))(writer))(function (value) {
                    return Run_Writer.tell(new UI_Style.IntProperty(name, value));
                });
            };
        };
    };
};
var buildNumberProperty = function (dictCategory) {
    return function (name) {
        return function (dsl) {
            return function (writer) {
                var doValue = function (d) {
                    return function (wrtr) {
                        return Control_Bind.bind(Run.bindRun)(Run.lift()(new Data_Symbol.IsSymbol(function () {
                            return "layout";
                        }))(functorLayoutDSL)(_layout)(d))(wrtr);
                    };
                };
                return Control_Bind.bind(Run.bindRun)(doValue(dsl(Control_Category.identity(dictCategory)))(writer))(function (value) {
                    return Run_Writer.tell(new UI_Style.NumberProperty(name, value));
                });
            };
        };
    };
};
var aspectRatio = function (number) {
    return buildNumberProperty(Control_Category.categoryFn)("aspectRatio")(AspectRatio.create(number))(UI_Style.writeNumber);
};
var borderBottomWidth = function (number) {
    return buildNumberProperty(Control_Category.categoryFn)("borderBottomWidth")(BorderBottomWidth.create(number))(UI_Style.writeNumber);
};
var borderEndWidth = function (number) {
    return buildNumberProperty(Control_Category.categoryFn)("borderEndWidth")(BorderEndWidth.create(number))(UI_Style.writeNumber);
};
var borderLeftWidth = function (number) {
    return buildNumberProperty(Control_Category.categoryFn)("borderLeftWidth")(BorderLeftWidth.create(number))(UI_Style.writeNumber);
};
var borderRightWidth = function (number) {
    return buildNumberProperty(Control_Category.categoryFn)("borderRightWidth")(BorderRightWidth.create(number))(UI_Style.writeNumber);
};
var borderStartWidth = function (number) {
    return buildNumberProperty(Control_Category.categoryFn)("borderStartWidth")(BorderStartWidth.create(number))(UI_Style.writeNumber);
};
var borderTopWidth = function (number) {
    return buildNumberProperty(Control_Category.categoryFn)("borderTopWidth")(BorderTopWidth.create(number))(UI_Style.writeNumber);
};
var borderWidth = function (number) {
    return buildNumberProperty(Control_Category.categoryFn)("borderWidth")(BorderWidth.create(number))(UI_Style.writeNumber);
};
var flex = function (number) {
    return buildNumberProperty(Control_Category.categoryFn)("flex")(Flex.create(number))(UI_Style.writeNumber);
};
var flexGrow = function (number) {
    return buildNumberProperty(Control_Category.categoryFn)("flexGrow")(FlexGrow.create(number))(UI_Style.writeNumber);
};
var flexShrink = function (number) {
    return buildNumberProperty(Control_Category.categoryFn)("flexShrink")(FlexShrink.create(number))(UI_Style.writeNumber);
};
var zIndex = function (number) {
    return buildNumberProperty(Control_Category.categoryFn)("zIndex")(ZIndex.create(number))(UI_Style.writeNumber);
};
var buildStringProperty = function (dictCategory) {
    return function (dictShow) {
        return function (name) {
            return function (dsl) {
                return function (writer) {
                    var doValue = function (d) {
                        return function (wrtr) {
                            return Data_Functor.mapFlipped(Run.functorRun)(Control_Bind.bind(Run.bindRun)(Run.lift()(new Data_Symbol.IsSymbol(function () {
                                return "layout";
                            }))(functorLayoutDSL)(_layout)(d))(wrtr))(Data_Show.show(dictShow));
                        };
                    };
                    return Control_Bind.bind(Run.bindRun)(doValue(dsl(Control_Category.identity(dictCategory)))(writer))(function (value) {
                        return Run_Writer.tell(new UI_Style.StringProperty(name, value));
                    });
                };
            };
        };
    };
};
var alignContent = function (a) {
    return buildStringProperty(Control_Category.categoryFn)(UI_Style_PropTypes_AlignContent.alignContent)("alignContent")(AlignContentProp.create(a))(UI_Style.writeAlignContent);
};
var alignItems = function (a) {
    return buildStringProperty(Control_Category.categoryFn)(UI_Style_PropTypes_AlignItems.showAlignItems)("alignItems")(AlignItemsProp.create(a))(UI_Style.writeAlignItems);
};
var alignSelf = function (a) {
    return buildStringProperty(Control_Category.categoryFn)(UI_Style_PropTypes_AlignSelf.showAlignSelf)("alignSelf")(AlignSelfProp.create(a))(UI_Style.writeAlignSelf);
};
var display = function (d) {
    return buildStringProperty(Control_Category.categoryFn)(UI_Style_PropTypes_Display.showDisplay)("display")(DisplayProp.create(d))(UI_Style.writeDisplay);
};
var flexDirection = function (f) {
    return buildStringProperty(Control_Category.categoryFn)(UI_Style_PropTypes_FlexDirection.showFlexDirection)("flexDirection")(FlexDirectionProp.create(f))(UI_Style.writeFlexDirection);
};
var flexWrap = function (f) {
    return buildStringProperty(Control_Category.categoryFn)(UI_Style_PropTypes_FlexWrap.showFlexWrap)("flexWrap")(FlexWrapProp.create(f))(UI_Style.writeFlexWrap);
};
var justifyContent = function (j) {
    return buildStringProperty(Control_Category.categoryFn)(UI_Style_PropTypes_JustifyContent.showJustifyContent)("justifyContent")(JustifyContentProp.create(j))(UI_Style.writeJustifyContent);
};
var overflow = function (o) {
    return buildStringProperty(Control_Category.categoryFn)(UI_Style_PropTypes_Overflow.showOverflow)("overflow")(OverflowProp.create(o))(UI_Style.writeOverflow);
};
var overflowX = function (o) {
    return buildStringProperty(Control_Category.categoryFn)(UI_Style_PropTypes_Overflow.showOverflow)("overflowX")(OverflowProp.create(o))(UI_Style.writeOverflow);
};
var overflowY = function (o) {
    return buildStringProperty(Control_Category.categoryFn)(UI_Style_PropTypes_Overflow.showOverflow)("overflowY")(OverflowProp.create(o))(UI_Style.writeOverflow);
};
var position = function (p) {
    return buildStringProperty(Control_Category.categoryFn)(UI_Style_PropTypes_Position.showPosition)("position")(PositionProp.create(p))(UI_Style.writePosition);
};
var runLayout = function (handler) {
    return Run.interpret(Run.monadRun)(Data_Functor_Variant.on()(new Data_Symbol.IsSymbol(function () {
        return "layout";
    }))(_layout)(handler)(Run.send));
};
module.exports = {
    Width: Width,
    Height: Height,
    Bottom: Bottom,
    End: End,
    Left: Left,
    Right: Right,
    Start: Start,
    Top: Top,
    FlexBasis: FlexBasis,
    MinWidth: MinWidth,
    MaxWidth: MaxWidth,
    MaxHeight: MaxHeight,
    MinHeight: MinHeight,
    Margin: Margin,
    MarginBottom: MarginBottom,
    MarginHorizontal: MarginHorizontal,
    MarginEnd: MarginEnd,
    MarginLeft: MarginLeft,
    MarginRight: MarginRight,
    MarginStart: MarginStart,
    MarginTop: MarginTop,
    MarginVertical: MarginVertical,
    Padding: Padding,
    PaddingBottom: PaddingBottom,
    PaddingEnd: PaddingEnd,
    PaddingHorizontal: PaddingHorizontal,
    PaddingLeft: PaddingLeft,
    PaddingRight: PaddingRight,
    PaddingStart: PaddingStart,
    PaddingTop: PaddingTop,
    PaddingVertical: PaddingVertical,
    BorderWidth: BorderWidth,
    BorderBottomWidth: BorderBottomWidth,
    BorderEndWidth: BorderEndWidth,
    BorderLeftWidth: BorderLeftWidth,
    BorderRightWidth: BorderRightWidth,
    BorderStartWidth: BorderStartWidth,
    BorderTopWidth: BorderTopWidth,
    Flex: Flex,
    FlexGrow: FlexGrow,
    FlexShrink: FlexShrink,
    AspectRatio: AspectRatio,
    ZIndex: ZIndex,
    DisplayProp: DisplayProp,
    PositionProp: PositionProp,
    FlexDirectionProp: FlexDirectionProp,
    FlexWrapProp: FlexWrapProp,
    JustifyContentProp: JustifyContentProp,
    AlignItemsProp: AlignItemsProp,
    AlignSelfProp: AlignSelfProp,
    AlignContentProp: AlignContentProp,
    OverflowProp: OverflowProp,
    _layout: _layout,
    alignContent: alignContent,
    alignItems: alignItems,
    alignSelf: alignSelf,
    aspectRatio: aspectRatio,
    borderBottomWidth: borderBottomWidth,
    borderEndWidth: borderEndWidth,
    borderLeftWidth: borderLeftWidth,
    borderRightWidth: borderRightWidth,
    borderStartWidth: borderStartWidth,
    borderTopWidth: borderTopWidth,
    borderWidth: borderWidth,
    bottom: bottom,
    display: display,
    end: end,
    flex: flex,
    flexBasis: flexBasis,
    flexDirection: flexDirection,
    flexGrow: flexGrow,
    flexShrink: flexShrink,
    flexWrap: flexWrap,
    height: height,
    justifyContent: justifyContent,
    left: left,
    margin: margin,
    marginBottom: marginBottom,
    marginEnd: marginEnd,
    marginHorizontal: marginHorizontal,
    marginLeft: marginLeft,
    marginRight: marginRight,
    marginStart: marginStart,
    marginTop: marginTop,
    marginVertical: marginVertical,
    maxHeight: maxHeight,
    maxWidth: maxWidth,
    minHeight: minHeight,
    minWidth: minWidth,
    overflow: overflow,
    overflowX: overflowX,
    overflowY: overflowY,
    padding: padding,
    paddingBottom: paddingBottom,
    paddingEnd: paddingEnd,
    paddingHorizontal: paddingHorizontal,
    paddingLeft: paddingLeft,
    paddingRight: paddingRight,
    paddingStart: paddingStart,
    paddingTop: paddingTop,
    paddingVertical: paddingVertical,
    position: position,
    right: right,
    start: start,
    top: top,
    width: width,
    zIndex: zIndex,
    runLayout: runLayout,
    handleLayout: handleLayout,
    functorLayoutDSL: functorLayoutDSL
};
