// Generated by purs version 0.12.0
"use strict";
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Prelude = require("../Prelude/index.js");
var Color = (function () {
    function Color(value0) {
        this.value0 = value0;
    };
    Color.create = function (value0) {
        return new Color(value0);
    };
    return Color;
})();
var RGBA = (function () {
    function RGBA(value0, value1, value2, value3) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
        this.value3 = value3;
    };
    RGBA.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return function (value3) {
                    return new RGBA(value0, value1, value2, value3);
                };
            };
        };
    };
    return RGBA;
})();
var showColor = new Data_Show.Show(function (v) {
    if (v instanceof Color) {
        return v.value0;
    };
    if (v instanceof RGBA) {
        return "rgba(" + (Data_Show.show(Data_Show.showInt)(v.value0) + ("," + (Data_Show.show(Data_Show.showInt)(v.value1) + ("," + (Data_Show.show(Data_Show.showInt)(v.value2) + ("," + (Data_Show.show(Data_Show.showNumber)(v.value3) + ")")))))));
    };
    throw new Error("Failed pattern match at UI.Style.PropTypes.Color line 9, column 1 - line 9, column 33: " + [ v.constructor.name ]);
});
module.exports = {
    Color: Color,
    RGBA: RGBA,
    showColor: showColor
};
