// Generated by purs version 0.12.0
"use strict";
var $foreign = require("./foreign.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Core_Data = require("../Core.Data/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_UUID = require("../Data.UUID/index.js");
var Effect = require("../Effect/index.js");
var EventListeners = require("../EventListeners/index.js");
var Interpreters = require("../Interpreters/index.js");
var Prelude = require("../Prelude/index.js");
var Run = require("../Run/index.js");
var UI_App_Common = require("../UI.App.Common/index.js");
var UI_Components = require("../UI.Components/index.js");
var UI_Data_Navigation = require("../UI.Data.Navigation/index.js");
var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");
var main = function (pathAndQueryString) {
    return function __do() {
        var v = Data_Functor.map(Effect.functorEffect)(Core_Data.ModelId)(Data_UUID.genUUID)();
        var v1 = Data_Functor.map(Effect.functorEffect)(Core_Data.LoggedOut.create)(Data_UUID.genUUID)();
        var model = UI_App_Common.sampleModel(Core_Data.newModel(v)(v1));
        var config = {
            size: {
                width: 1000,
                height: 1000
            }
        };
        var navigation = UI_Data_Navigation.fromUrl(model)(pathAndQueryString);
        var props = {
            model: model,
            applyThisEvent: Data_Maybe.Nothing.value,
            config: config,
            navigation: navigation,
            skipPushHistory: false
        };
        return Run.extract(UI_Components.runComponent(Interpreters.handleComponent)(Data_Functor.map(Run.functorRun)(UI_Components.createReactElement(props))(EventListeners.component(props))));
    };
};
var mainToString = function (pathAndQueryString) {
    return Control_Bind.bind(Effect.bindEffect)(main(pathAndQueryString))($foreign.toString);
};
module.exports = {
    main: main,
    mainToString: mainToString,
    render: $foreign.render,
    toString: $foreign.toString
};
