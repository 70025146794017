// Generated by purs version 0.12.0
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var Core_Data = require("../Core.Data/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_EuclideanRing = require("../Data.EuclideanRing/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Lens = require("../Data.Lens/index.js");
var Data_Lens_Getter = require("../Data.Lens.Getter/index.js");
var Data_Lens_Internal_Forget = require("../Data.Lens.Internal.Forget/index.js");
var Data_List = require("../Data.List/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ring = require("../Data.Ring/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Effect = require("../Effect/index.js");
var Prelude = require("../Prelude/index.js");
var Run = require("../Run/index.js");
var UI_App_Common = require("../UI.App.Common/index.js");
var UI_App_Common_Checkbox = require("../UI.App.Common.Checkbox/index.js");
var UI_App_Common_Field = require("../UI.App.Common.Field/index.js");
var UI_App_Common_FieldSet = require("../UI.App.Common.FieldSet/index.js");
var UI_App_Common_MenuButton = require("../UI.App.Common.MenuButton/index.js");
var UI_App_Common_RadioButton = require("../UI.App.Common.RadioButton/index.js");
var UI_Components = require("../UI.Components/index.js");
var UI_Event_ModelEvent = require("../UI.Event.ModelEvent/index.js");
var UI_Style = require("../UI.Style/index.js");
var UI_Style_Layout = require("../UI.Style.Layout/index.js");
var UI_Style_PropTypes_Display = require("../UI.Style.PropTypes.Display/index.js");
var UI_Style_PropTypes_FlexDirection = require("../UI.Style.PropTypes.FlexDirection/index.js");
var UI_Style_PropTypes_FlexWrap = require("../UI.Style.PropTypes.FlexWrap/index.js");
var UI_Style_PropTypes_JustifyContent = require("../UI.Style.PropTypes.JustifyContent/index.js");
var toggleNav = function (tileId) {
    return function (publish) {
        return function (v) {
            return publish.model(new UI_Event_ModelEvent.ToggleShowTileNav(tileId));
        };
    };
};
var toggleLayout = function (tileId) {
    return function (publish) {
        return function (v) {
            return publish.model(new UI_Event_ModelEvent.ToggleTileLayout(tileId));
        };
    };
};
var titleBarToggle = function (v) {
    return Control_Bind.bind(Run.bindRun)(UI_App_Common_Checkbox.component({
        label: "Show/Hide",
        value: v.tile.showNav,
        effect: toggleNav(v.tile.id)(v.publish)
    }))(function (v1) {
        return UI_App_Common_Field.component({
            label: "Title Bar",
            child: Control_Applicative.pure(Run.applicativeRun)(v1),
            hint: Data_Maybe.Nothing.value,
            error: Data_Maybe.Nothing.value
        });
    });
};
var setWidget = function (props) {
    var widgets = [ "stacked_bar", "pie", "candlestick", "bar_range", "layout", "line", "stacked_line_area", "scatter", "step_line", "theme_river", "box_plot", "funnel", "radar", "sunburst", "graph", "punch_card" ];
    var child = Data_Functor.map(Run.functorRun)(UI_Components.fragment)(Data_Traversable.sequence(Data_Traversable.traversableArray)(Run.applicativeRun)(Data_Functor.mapFlipped(Data_Functor.functorArray)(widgets)(function (label) {
        return UI_App_Common_RadioButton.component({
            label: label,
            value: label,
            isChecked: Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(Core_Data.getWidgetTitle(props.tile))(new Data_Maybe.Just(label)),
            disabled: props.childCount > 0,
            effect: function (v) {
                return props.publish.model(new UI_Event_ModelEvent.AddWidget(Data_Lens_Getter.viewOn(props.tile)(function ($84) {
                    return Core_Data._Tile(Data_Lens_Internal_Forget.strongForget)(Core_Data._id(Data_Lens_Internal_Forget.strongForget)($84));
                }), Data_Maybe.Just.create(label)));
            }
        });
    })));
    return UI_App_Common_Field.component({
        label: "Select Widget",
        child: child,
        hint: (function () {
            var $49 = props.childCount > 0;
            if ($49) {
                return new Data_Maybe.Just("Widgets can only be set on tiles with no children");
            };
            return Data_Maybe.Nothing.value;
        })(),
        error: Data_Maybe.Nothing.value
    });
};
var widgetProps = function (props) {
    return Control_Bind.bind(Run.bindRun)(setWidget(props))(function (v) {
        return UI_App_Common_FieldSet.component({
            label: "Widget Properties",
            child: Control_Applicative.pure(Run.applicativeRun)(UI_Components.fragment([ v ]))
        });
    });
};
var layoutContainerStyle = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.display(UI_Style_PropTypes_Display.Flex.value))(function () {
    return UI_Style_Layout.flexDirection(UI_Style_PropTypes_FlexDirection.Column.value);
});
var layoutToggle = function (v) {
    return Control_Bind.bind(Run.bindRun)(UI_App_Common_RadioButton.component({
        label: "Row",
        value: "Row",
        isChecked: Data_Eq.eq(Core_Data.layoutEq)(v.tile.layout)(Core_Data.Row.value),
        effect: toggleLayout(v.tile.id)(v.publish),
        disabled: false
    }))(function (v1) {
        return Control_Bind.bind(Run.bindRun)(UI_App_Common_RadioButton.component({
            label: "Column",
            value: "Column",
            isChecked: Data_Eq.eq(Core_Data.layoutEq)(v.tile.layout)(Core_Data.Column.value),
            effect: toggleLayout(v.tile.id)(v.publish),
            disabled: false
        }))(function (v2) {
            return Control_Bind.bind(Run.bindRun)(UI_Components.div({
                style: layoutContainerStyle
            })(UI_Components.fragment([ v1, v2 ])))(function (v3) {
                return UI_App_Common_Field.component({
                    label: "Tile Layout",
                    child: Control_Applicative.pure(Run.applicativeRun)(v3),
                    hint: Data_Maybe.Nothing.value,
                    error: Data_Maybe.Nothing.value
                });
            });
        });
    });
};
var fillPercent = function (v) {
    var siblingCount = Data_List.length(v.siblings);
    var setGrow = function (grow) {
        return function __do() {
            var v1 = v.publish.model(new UI_Event_ModelEvent.SetGrow(v.tile.id, grow))();
            return Data_Functor["void"](Effect.functorEffect)(Data_Traversable.sequence(Data_List_Types.traversableList)(Effect.applicativeEffect)(Data_Functor.mapFlipped(Data_List_Types.functorList)(v.siblings)(function (v2) {
                var $59 = Data_Int.floor(grow) === 100;
                if ($59) {
                    return v.publish.model(new UI_Event_ModelEvent.SetGrow(v2.id, 100.0));
                };
                return v.publish.model(new UI_Event_ModelEvent.SetGrow(v2.id, (100.0 - grow) / Data_Int.toNumber(siblingCount)));
            })))();
        };
    };
    return Control_Bind.bind(Run.bindRun)(UI_App_Common_RadioButton.component({
        label: "10%",
        value: "10.0",
        isChecked: Data_Int.floor(v.tile.grow) === 10,
        effect: function (v1) {
            return setGrow(10.0);
        },
        disabled: siblingCount === 0
    }))(function (v1) {
        return Control_Bind.bind(Run.bindRun)(UI_App_Common_RadioButton.component({
            label: "15%",
            value: "15.0",
            isChecked: Data_Int.floor(v.tile.grow) === 15,
            effect: function (v2) {
                return setGrow(15.0);
            },
            disabled: siblingCount === 0
        }))(function (v2) {
            return Control_Bind.bind(Run.bindRun)(UI_App_Common_RadioButton.component({
                label: "20%",
                value: "20.0",
                isChecked: Data_Int.floor(v.tile.grow) === 20,
                effect: function (v3) {
                    return setGrow(20.0);
                },
                disabled: siblingCount === 0
            }))(function (v3) {
                return Control_Bind.bind(Run.bindRun)(UI_App_Common_RadioButton.component({
                    label: "25%",
                    value: "25.0",
                    isChecked: Data_Int.floor(v.tile.grow) === 25,
                    effect: function (v4) {
                        return setGrow(25.0);
                    },
                    disabled: siblingCount === 0
                }))(function (v4) {
                    return Control_Bind.bind(Run.bindRun)(UI_App_Common_RadioButton.component({
                        label: "33%",
                        value: "33.33",
                        isChecked: Data_Int.floor(v.tile.grow) === 33,
                        effect: function (v5) {
                            return setGrow(33.33);
                        },
                        disabled: siblingCount === 0
                    }))(function (v5) {
                        return Control_Bind.bind(Run.bindRun)(UI_App_Common_RadioButton.component({
                            label: "50%",
                            value: "50.0",
                            isChecked: Data_Int.floor(v.tile.grow) === 50,
                            effect: function (v6) {
                                return setGrow(50.0);
                            },
                            disabled: siblingCount === 0
                        }))(function (v6) {
                            return Control_Bind.bind(Run.bindRun)(UI_App_Common_RadioButton.component({
                                label: "67%",
                                value: "66.67",
                                isChecked: Data_Int.floor(v.tile.grow) === 66,
                                effect: function (v7) {
                                    return setGrow(66.67);
                                },
                                disabled: siblingCount === 0
                            }))(function (v7) {
                                return Control_Bind.bind(Run.bindRun)(UI_App_Common_RadioButton.component({
                                    label: "75%",
                                    value: "75.0",
                                    isChecked: Data_Int.floor(v.tile.grow) === 75,
                                    effect: function (v8) {
                                        return setGrow(75.0);
                                    },
                                    disabled: siblingCount === 0
                                }))(function (v8) {
                                    return Control_Bind.bind(Run.bindRun)(UI_App_Common_RadioButton.component({
                                        label: "80%",
                                        value: "80.0",
                                        isChecked: Data_Int.floor(v.tile.grow) === 80,
                                        effect: function (v9) {
                                            return setGrow(80.0);
                                        },
                                        disabled: siblingCount === 0
                                    }))(function (v9) {
                                        return Control_Bind.bind(Run.bindRun)(UI_App_Common_RadioButton.component({
                                            label: "85%",
                                            value: "85.0",
                                            isChecked: Data_Int.floor(v.tile.grow) === 85,
                                            effect: function (v10) {
                                                return setGrow(85.0);
                                            },
                                            disabled: siblingCount === 0
                                        }))(function (v10) {
                                            return Control_Bind.bind(Run.bindRun)(UI_App_Common_RadioButton.component({
                                                label: "90%",
                                                value: "90.0",
                                                isChecked: Data_Int.floor(v.tile.grow) === 90,
                                                effect: function (v11) {
                                                    return setGrow(90.0);
                                                },
                                                disabled: siblingCount === 0
                                            }))(function (v11) {
                                                return Control_Bind.bind(Run.bindRun)(UI_App_Common_RadioButton.component({
                                                    label: "Equal",
                                                    value: "100.0",
                                                    isChecked: Data_Int.floor(v.tile.grow) === 100,
                                                    effect: function (v12) {
                                                        return setGrow(100.0);
                                                    },
                                                    disabled: siblingCount === 0
                                                }))(function (v12) {
                                                    return UI_App_Common_Field.component({
                                                        label: "Fill Percentage",
                                                        child: Control_Applicative.pure(Run.applicativeRun)(UI_Components.fragment([ v1, v2, v3, v4, v5, v6, v7, v8, v9, v10, v11, v12 ])),
                                                        hint: (function () {
                                                            var $72 = Data_List.length(v.siblings) > 0;
                                                            if ($72) {
                                                                return new Data_Maybe.Just("Set the percent of the \"" + (Data_Show.show(Core_Data.showLayout)(Data_Lens_Getter.viewOn(v.tile)(function ($85) {
                                                                    return Core_Data._Tile(Data_Lens_Internal_Forget.strongForget)(Core_Data._layout(Data_Lens_Internal_Forget.strongForget)($85));
                                                                })) + "\" you'd like this tile to fill"));
                                                            };
                                                            return new Data_Maybe.Just("This property can only be set if the tile has siblings");
                                                        })(),
                                                        error: Data_Maybe.Nothing.value
                                                    });
                                                });
                                            });
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });
        });
    });
};
var lookAndFeel = function (v) {
    return Control_Bind.bind(Run.bindRun)(titleBarToggle(v))(function (v1) {
        return Control_Bind.bind(Run.bindRun)(layoutToggle(v))(function (v2) {
            return Control_Bind.bind(Run.bindRun)(fillPercent(v))(function (v3) {
                return UI_App_Common_FieldSet.component({
                    label: "Look and Feel",
                    child: Control_Applicative.pure(Run.applicativeRun)(UI_Components.fragment([ v1, v2, v3 ]))
                });
            });
        });
    });
};
var containerStyle = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.display(UI_Style_PropTypes_Display.Flex.value))(function () {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.flexDirection(UI_Style_PropTypes_FlexDirection.Column.value))(function () {
        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.flexWrap(UI_Style_PropTypes_FlexWrap.Wrap.value))(function () {
            return UI_Style_Layout.justifyContent(UI_Style_PropTypes_JustifyContent.FlexStart.value);
        });
    });
});
var childrenButtonStyle = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.flexDirection(UI_Style_PropTypes_FlexDirection.RowReverse.value))(function () {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.justifyContent(UI_Style_PropTypes_JustifyContent.FlexEnd.value))(function () {
        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.paddingTop(new UI_Style.Pixel(15)))(function () {
            return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.marginRight(new UI_Style.Pixel(5)))(function () {
                return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.marginBottom(new UI_Style.Pixel(0)))(function () {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.marginLeft(new UI_Style.Pixel(15)))(function () {
                        return UI_Style_Layout.paddingBottom(new UI_Style.Pixel(15));
                    });
                });
            });
        });
    });
});
var additionalHeaderStyle = UI_Style_Layout.marginLeft(new UI_Style.Pixel(5));
var children = function (props) {
    return Control_Bind.bind(Run.bindRun)(UI_App_Common_MenuButton.component({
        label: "Add Tile",
        additionalStyle: childrenButtonStyle,
        additionalHeaderStyle: additionalHeaderStyle,
        onPress: props.publish.model(new UI_Event_ModelEvent.AddTile(props.dashboardId, Data_Lens_Getter.viewOn(props.tile)(function ($86) {
            return Core_Data._Tile(Data_Lens_Internal_Forget.strongForget)(Core_Data._id(Data_Lens_Internal_Forget.strongForget)($86));
        }), Data_Maybe.Nothing.value))
    }))(function (v) {
        return UI_App_Common_FieldSet.component({
            label: "Children (" + (Data_Show.show(Data_Show.showInt)(props.childCount) + ")"),
            child: Control_Applicative.pure(Run.applicativeRun)(v)
        });
    });
};
var render = function (props) {
    return Control_Bind.bind(Run.bindRun)(lookAndFeel(props))(function (v) {
        return Control_Bind.bind(Run.bindRun)(children(props))(function (v1) {
            return Control_Bind.bind(Run.bindRun)(widgetProps(props))(function (v2) {
                return Control_Applicative.pure(Run.applicativeRun)(UI_Components.fragment([ v, v1, v2 ]));
            });
        });
    });
};
var component = function (props) {
    return UI_Components.stateless("TileProperties")(props)(render);
};
module.exports = {
    component: component,
    render: render,
    lookAndFeel: lookAndFeel,
    titleBarToggle: titleBarToggle,
    layoutToggle: layoutToggle,
    toggleLayout: toggleLayout,
    toggleNav: toggleNav,
    layoutContainerStyle: layoutContainerStyle,
    containerStyle: containerStyle,
    fillPercent: fillPercent,
    children: children,
    additionalHeaderStyle: additionalHeaderStyle,
    childrenButtonStyle: childrenButtonStyle,
    widgetProps: widgetProps,
    setWidget: setWidget
};
