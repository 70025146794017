"use strict";

exports.addKeyDownListener = function (externalEventWrapper) {
  return function (effectFn) {
    return function () {
      var runEffect = function runEffect(event) {
        effectFn(externalEventWrapper(event))();
      };
      document.addEventListener("keydown", runEffect, false);
    };
  };
};

exports.removeKeyDownListener = function () {
  document.removeEventListener("keydown", console.log, false);
};

exports.addSizeChangeListener = function (externalEventWrapper) {
  return function (effectFn) {
    return function () {
      var runEffect = function runEffect() {
        var event = getSize();
        effectFn(externalEventWrapper(event))();
      };
      window.addEventListener("resize", runEffect, false);
    };
  };
};

exports.addPopStateListener = function (navFn) {

  var fn = function fn(event) {
    var state = event.state || "";
    var nav = navFn(state)();
    return nav;
  };

  return function () {
    window.addEventListener("popstate", fn, false);
  };
};

exports.pushHistory = function (path) {
  return function () {
    return history.pushState(path, null, path);
  };
};

exports.removeSizeChangeListener = function () {
  window.removeEventListener("resize", console.log, false);
};

exports.getSize = getSize;

function getSize() {
  return { width: window.innerWidth, height: window.innerHeight };
}