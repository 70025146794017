// Generated by purs version 0.12.0
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Functor_Variant = require("../Data.Functor.Variant/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Prelude = require("../Prelude/index.js");
var Run = require("../Run/index.js");
var Run_Writer = require("../Run.Writer/index.js");
var UI_Style = require("../UI.Style/index.js");
var UI_Style_PropTypes_Color = require("../UI.Style.PropTypes.Color/index.js");
var UI_Style_PropTypes_FontFamily = require("../UI.Style.PropTypes.FontFamily/index.js");
var UI_Style_PropTypes_FontStyle = require("../UI.Style.PropTypes.FontStyle/index.js");
var UI_Style_PropTypes_FontWeight = require("../UI.Style.PropTypes.FontWeight/index.js");
var UI_Style_PropTypes_TextAlign = require("../UI.Style.PropTypes.TextAlign/index.js");
var UI_Style_PropTypes_TextDecorationLine = require("../UI.Style.PropTypes.TextDecorationLine/index.js");
var UI_Style_PropTypes_TextOverflow = require("../UI.Style.PropTypes.TextOverflow/index.js");
var ColorProp = (function () {
    function ColorProp(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    ColorProp.create = function (value0) {
        return function (value1) {
            return new ColorProp(value0, value1);
        };
    };
    return ColorProp;
})();
var FontSize = (function () {
    function FontSize(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    FontSize.create = function (value0) {
        return function (value1) {
            return new FontSize(value0, value1);
        };
    };
    return FontSize;
})();
var FontStyleProp = (function () {
    function FontStyleProp(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    FontStyleProp.create = function (value0) {
        return function (value1) {
            return new FontStyleProp(value0, value1);
        };
    };
    return FontStyleProp;
})();
var FontFamilyProp = (function () {
    function FontFamilyProp(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    FontFamilyProp.create = function (value0) {
        return function (value1) {
            return new FontFamilyProp(value0, value1);
        };
    };
    return FontFamilyProp;
})();
var FontWeightProp = (function () {
    function FontWeightProp(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    FontWeightProp.create = function (value0) {
        return function (value1) {
            return new FontWeightProp(value0, value1);
        };
    };
    return FontWeightProp;
})();
var TextShadowRadius = (function () {
    function TextShadowRadius(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    TextShadowRadius.create = function (value0) {
        return function (value1) {
            return new TextShadowRadius(value0, value1);
        };
    };
    return TextShadowRadius;
})();
var TextShadowColor = (function () {
    function TextShadowColor(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    TextShadowColor.create = function (value0) {
        return function (value1) {
            return new TextShadowColor(value0, value1);
        };
    };
    return TextShadowColor;
})();
var LineHeight = (function () {
    function LineHeight(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    LineHeight.create = function (value0) {
        return function (value1) {
            return new LineHeight(value0, value1);
        };
    };
    return LineHeight;
})();
var TextAlignProp = (function () {
    function TextAlignProp(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    TextAlignProp.create = function (value0) {
        return function (value1) {
            return new TextAlignProp(value0, value1);
        };
    };
    return TextAlignProp;
})();
var TextDecorationLineProp = (function () {
    function TextDecorationLineProp(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    TextDecorationLineProp.create = function (value0) {
        return function (value1) {
            return new TextDecorationLineProp(value0, value1);
        };
    };
    return TextDecorationLineProp;
})();
var TextOverflowProp = (function () {
    function TextOverflowProp(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    TextOverflowProp.create = function (value0) {
        return function (value1) {
            return new TextOverflowProp(value0, value1);
        };
    };
    return TextOverflowProp;
})();
var handleText = function (v) {
    if (v instanceof ColorProp) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof FontSize) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof FontStyleProp) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof FontFamilyProp) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof FontWeightProp) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof TextShadowRadius) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof TextShadowColor) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof LineHeight) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof TextAlignProp) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof TextOverflowProp) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    if (v instanceof TextDecorationLineProp) {
        return Control_Applicative.pure(Run.applicativeRun)(v.value1(v.value0));
    };
    throw new Error("Failed pattern match at UI.Style.Text line 151, column 14 - line 162, column 48: " + [ v.constructor.name ]);
};
var functorTextDSL = new Data_Functor.Functor(function (f) {
    return function (m) {
        if (m instanceof ColorProp) {
            return new ColorProp(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof FontSize) {
            return new FontSize(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof FontStyleProp) {
            return new FontStyleProp(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof FontFamilyProp) {
            return new FontFamilyProp(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof FontWeightProp) {
            return new FontWeightProp(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof TextShadowRadius) {
            return new TextShadowRadius(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof TextShadowColor) {
            return new TextShadowColor(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof LineHeight) {
            return new LineHeight(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof TextAlignProp) {
            return new TextAlignProp(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof TextDecorationLineProp) {
            return new TextDecorationLineProp(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        if (m instanceof TextOverflowProp) {
            return new TextOverflowProp(m.value0, Data_Functor.map(Data_Functor.functorFn)(f)(m.value1));
        };
        throw new Error("Failed pattern match at UI.Style.Text line 60, column 8 - line 60, column 50: " + [ m.constructor.name ]);
    };
});
var _text = Data_Symbol.SProxy.value;
var buildDimensionProperty = function (dictCategory) {
    return function (name) {
        return function (dsl) {
            return function (writer) {
                var doValue = function (d) {
                    return function (wrtr) {
                        return Control_Bind.bind(Run.bindRun)(Run.lift()(new Data_Symbol.IsSymbol(function () {
                            return "text";
                        }))(functorTextDSL)(_text)(d))(wrtr);
                    };
                };
                return Control_Bind.bind(Run.bindRun)(doValue(dsl(Control_Category.identity(dictCategory)))(writer))(function (value) {
                    if (value instanceof UI_Style.Pixel) {
                        return Run_Writer.tell(new UI_Style.IntProperty(name, value.value0));
                    };
                    if (value instanceof UI_Style.Percent) {
                        return Run_Writer.tell(new UI_Style.StringProperty(name, Data_Show.show(Data_Show.showInt)(value.value0) + "%"));
                    };
                    throw new Error("Failed pattern match at UI.Style.Text line 125, column 89 - line 127, column 69: " + [ value.constructor.name ]);
                });
            };
        };
    };
};
var buildIntProperty = function (dictCategory) {
    return function (name) {
        return function (dsl) {
            return function (writer) {
                var doValue = function (d) {
                    return function (wrtr) {
                        return Control_Bind.bind(Run.bindRun)(Run.lift()(new Data_Symbol.IsSymbol(function () {
                            return "text";
                        }))(functorTextDSL)(_text)(d))(wrtr);
                    };
                };
                return Control_Bind.bind(Run.bindRun)(doValue(dsl(Control_Category.identity(dictCategory)))(writer))(function (value) {
                    return Run_Writer.tell(new UI_Style.IntProperty(name, value));
                });
            };
        };
    };
};
var buildNumberProperty = function (dictCategory) {
    return function (name) {
        return function (dsl) {
            return function (writer) {
                var doValue = function (d) {
                    return function (wrtr) {
                        return Control_Bind.bind(Run.bindRun)(Run.lift()(new Data_Symbol.IsSymbol(function () {
                            return "text";
                        }))(functorTextDSL)(_text)(d))(wrtr);
                    };
                };
                return Control_Bind.bind(Run.bindRun)(doValue(dsl(Control_Category.identity(dictCategory)))(writer))(function (value) {
                    return Run_Writer.tell(new UI_Style.NumberProperty(name, value));
                });
            };
        };
    };
};
var fontSize = function (f) {
    return buildNumberProperty(Control_Category.categoryFn)("fontSize")(FontSize.create(f))(UI_Style.writeNumber);
};
var buildStringProperty = function (dictCategory) {
    return function (dictShow) {
        return function (name) {
            return function (dsl) {
                return function (writer) {
                    var doValue = function (d) {
                        return function (wrtr) {
                            return Data_Functor.mapFlipped(Run.functorRun)(Control_Bind.bind(Run.bindRun)(Run.lift()(new Data_Symbol.IsSymbol(function () {
                                return "text";
                            }))(functorTextDSL)(_text)(d))(wrtr))(Data_Show.show(dictShow));
                        };
                    };
                    return Control_Bind.bind(Run.bindRun)(doValue(dsl(Control_Category.identity(dictCategory)))(writer))(function (value) {
                        return Run_Writer.tell(new UI_Style.StringProperty(name, value));
                    });
                };
            };
        };
    };
};
var color = function (c) {
    return buildStringProperty(Control_Category.categoryFn)(UI_Style_PropTypes_Color.showColor)("color")(ColorProp.create(c))(UI_Style.writeColor);
};
var fontFamily = function (f) {
    return buildStringProperty(Control_Category.categoryFn)(UI_Style_PropTypes_FontFamily.showFontFamily)("fontFamily")(FontFamilyProp.create(f))(UI_Style.writeFontFamily);
};
var fontStyle = function (f) {
    return buildStringProperty(Control_Category.categoryFn)(UI_Style_PropTypes_FontStyle.showFontStyle)("fontStyle")(FontStyleProp.create(f))(UI_Style.writeFontStyle);
};
var fontWeight = function (f) {
    return buildStringProperty(Control_Category.categoryFn)(UI_Style_PropTypes_FontWeight.showFontWeight)("fontWeight")(FontWeightProp.create(f))(UI_Style.writeFontWeight);
};
var lineHeight = function (l) {
    return buildStringProperty(Control_Category.categoryFn)(Data_Show.showNumber)("lineHeight")(LineHeight.create(l))(UI_Style.writeNumber);
};
var textAlign = function (t) {
    return buildStringProperty(Control_Category.categoryFn)(UI_Style_PropTypes_TextAlign.showTextAlign)("textAlign")(TextAlignProp.create(t))(UI_Style.writeTextAlign);
};
var textDecorationLine = function (t) {
    return buildStringProperty(Control_Category.categoryFn)(UI_Style_PropTypes_TextDecorationLine.showTextDecorationLine)("textDecorationLine")(TextDecorationLineProp.create(t))(UI_Style.writeTextDecorationLine);
};
var textOverflow = function (t) {
    return buildStringProperty(Control_Category.categoryFn)(UI_Style_PropTypes_TextOverflow.showTextOverflow)("textOverflow")(TextOverflowProp.create(t))(UI_Style.writeTextOverflow);
};
var textShadowColor = function (c) {
    return buildStringProperty(Control_Category.categoryFn)(UI_Style_PropTypes_Color.showColor)("textShadowColor")(TextShadowColor.create(c))(UI_Style.writeColor);
};
var textShadowRadius = function (t) {
    return buildStringProperty(Control_Category.categoryFn)(Data_Show.showNumber)("textShadowRadius")(TextShadowRadius.create(t))(UI_Style.writeNumber);
};
var runText = function (handler) {
    return Run.interpret(Run.monadRun)(Data_Functor_Variant.on()(new Data_Symbol.IsSymbol(function () {
        return "text";
    }))(_text)(handler)(Run.send));
};
module.exports = {
    _text: _text,
    ColorProp: ColorProp,
    FontSize: FontSize,
    FontStyleProp: FontStyleProp,
    FontFamilyProp: FontFamilyProp,
    FontWeightProp: FontWeightProp,
    TextShadowRadius: TextShadowRadius,
    TextShadowColor: TextShadowColor,
    LineHeight: LineHeight,
    TextAlignProp: TextAlignProp,
    TextDecorationLineProp: TextDecorationLineProp,
    TextOverflowProp: TextOverflowProp,
    color: color,
    fontSize: fontSize,
    fontWeight: fontWeight,
    fontFamily: fontFamily,
    fontStyle: fontStyle,
    textShadowRadius: textShadowRadius,
    lineHeight: lineHeight,
    textAlign: textAlign,
    textDecorationLine: textDecorationLine,
    textOverflow: textOverflow,
    textShadowColor: textShadowColor,
    runText: runText,
    handleText: handleText,
    functorTextDSL: functorTextDSL
};
