// Generated by purs version 0.12.0
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Effect = require("../Effect/index.js");
var Prelude = require("../Prelude/index.js");
var Run = require("../Run/index.js");
var UI_Components = require("../UI.Components/index.js");
var UI_Style = require("../UI.Style/index.js");
var UI_Style_Layout = require("../UI.Style.Layout/index.js");
var UI_Style_PropTypes_AlignItems = require("../UI.Style.PropTypes.AlignItems/index.js");
var UI_Style_PropTypes_Display = require("../UI.Style.PropTypes.Display/index.js");
var UI_Style_PropTypes_FlexDirection = require("../UI.Style.PropTypes.FlexDirection/index.js");
var UI_Style_Text = require("../UI.Style.Text/index.js");
var layoutContainerStyle = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.display(UI_Style_PropTypes_Display.Flex.value))(function () {
    return UI_Style_Layout.flexDirection(UI_Style_PropTypes_FlexDirection.Row.value);
});
var checkboxStyle = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.alignItems(UI_Style_PropTypes_AlignItems.Center.value))(function () {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.display(UI_Style_PropTypes_Display.Flex.value))(function () {
        return UI_Style_Layout.flexDirection(UI_Style_PropTypes_FlexDirection.Row.value);
    });
});
var checkboxLabelStyle = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.display(UI_Style_PropTypes_Display.Flex.value))(function () {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.margin(new UI_Style.Pixel(0)))(function () {
        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.marginRight(new UI_Style.Pixel(10)))(function () {
            return UI_Style_Text.fontSize(12.0);
        });
    });
});
var boolFnToStringFn = function (boolFn) {
    return function (str) {
        return boolFn(str === "true");
    };
};
var component = function (props) {
    return Control_Bind.bind(Run.bindRun)(Control_Bind.bind(Run.bindRun)(UI_Components.checkbox({
        style: checkboxStyle,
        value: props.value
    }))(UI_Components.onChange(boolFnToStringFn(props.effect))))(function (v) {
        return Control_Bind.bind(Run.bindRun)(Control_Bind.bind(Run.bindRun)(Control_Bind.bindFlipped(Run.bindRun)(UI_Components.p({
            style: checkboxLabelStyle
        }))(UI_Components.text(props.label)))(UI_Components.makeTouchable(props.effect(props.value))))(function (v1) {
            return UI_Components.div({
                style: checkboxStyle
            })(UI_Components.fragment([ v, v1 ]));
        });
    });
};
module.exports = {
    component: component,
    boolFnToStringFn: boolFnToStringFn,
    checkboxLabelStyle: checkboxLabelStyle,
    checkboxStyle: checkboxStyle,
    layoutContainerStyle: layoutContainerStyle
};
