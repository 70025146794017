// Generated by purs version 0.12.0
"use strict";
var $foreign = require("./foreign.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Core_Data = require("../Core.Data/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Data_Variant = require("../Data.Variant/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Effect = require("../Effect/index.js");
var ExternalEvents = require("../ExternalEvents/index.js");
var Prelude = require("../Prelude/index.js");
var Run = require("../Run/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var UI_App = require("../UI.App/index.js");
var UI_App_Common = require("../UI.App.Common/index.js");
var UI_Components = require("../UI.Components/index.js");
var UI_Data_Navigation = require("../UI.Data.Navigation/index.js");
var makePublishers = function (setState) {
    return {
        model: UI_App_Common.publish()(new Data_Symbol.IsSymbol(function () {
            return "model";
        }))(setState)(Data_Symbol.SProxy.value),
        navigation: UI_App_Common.publish()(new Data_Symbol.IsSymbol(function () {
            return "navigation";
        }))(setState)(Data_Symbol.SProxy.value)
    };
};
var handlePopStateEvent = function (setState) {
    return function (url) {
        return setState(function (state) {
            return {
                applyThisEvent: state.applyThisEvent,
                model: state.model,
                navigation: UI_Data_Navigation.fromUrl(state.model)(url),
                skipPushHistory: true,
                config: state.config
            };
        });
    };
};
var handleNavigationUpdate = function (v) {
    return function (v1) {
        if (Data_Eq.notEq(UI_Data_Navigation.navigationEq)(v.navigation)(v1.navigation) && !v1.skipPushHistory) {
            return $foreign.pushHistory(UI_Data_Navigation.toUrl(v1.navigation));
        };
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    };
};
var handleApplyThisEvent = function (newState) {
    return function (setState) {
        if (newState.applyThisEvent instanceof Data_Maybe.Nothing) {
            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
        };
        if (newState.applyThisEvent instanceof Data_Maybe.Just) {
            return function __do() {
                var v = Data_Variant.match()(Data_Variant_Internal.variantMatchCons(Data_Variant_Internal.variantMatchCons(Data_Variant_Internal.variantMatchNil)()(Type_Equality.refl))()(Type_Equality.refl))()(makePublishers(setState))(newState.applyThisEvent.value0)();
                return setState(function (state) {
                    return {
                        applyThisEvent: Data_Maybe.Nothing.value,
                        model: state.model,
                        navigation: state.navigation,
                        skipPushHistory: state.skipPushHistory,
                        config: state.config
                    };
                })();
            };
        };
        throw new Error("Failed pattern match at EventListeners line 87, column 3 - line 91, column 60: " + [ newState.applyThisEvent.constructor.name ]);
    };
};
var component = function (initialState) {
    var render = function (v) {
        return function (state) {
            return function (setState) {
                var publish = makePublishers(setState);
                return UI_App.app({
                    model: state.model,
                    navigation: state.navigation,
                    config: state.config,
                    publish: publish
                });
            };
        };
    };
    var componentWillUnmount = function __do() {
        var v = $foreign.removeKeyDownListener();
        return $foreign.removeSizeChangeListener();
    };
    var componentDidUpdate = function (v) {
        return function (v1) {
            return function (oldState) {
                return function (newState) {
                    return function (setState) {
                        return Control_Apply.applySecond(Effect.applyEffect)(Control_Apply.applySecond(Effect.applyEffect)(handleApplyThisEvent(newState)(setState))(handleNavigationUpdate(oldState)(newState)))((function () {
                            if (newState.skipPushHistory) {
                                return setState(function (state) {
                                    return {
                                        applyThisEvent: state.applyThisEvent,
                                        model: state.model,
                                        navigation: state.navigation,
                                        skipPushHistory: false,
                                        config: state.config
                                    };
                                });
                            };
                            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                        })());
                    };
                };
            };
        };
    };
    var componentDidMount = function (v) {
        return function (v1) {
            return function (setState) {
                return function __do() {
                    var v2 = $foreign.addPopStateListener(handlePopStateEvent(setState))();
                    var v3 = $foreign.addKeyDownListener(ExternalEvents.KeyDownEvent.create)(function (event) {
                        return setState(function (state) {
                            return {
                                applyThisEvent: ExternalEvents.handleKeyDownEvent(event)({
                                    model: state.model,
                                    navigation: state.navigation
                                }),
                                model: state.model,
                                navigation: state.navigation,
                                skipPushHistory: state.skipPushHistory,
                                config: state.config
                            };
                        });
                    })();
                    return $foreign.addSizeChangeListener(ExternalEvents.SizeChangeEvent.create)(function (event) {
                        return setState(function (state) {
                            if (event instanceof ExternalEvents.SizeChangeEvent) {
                                return {
                                    applyThisEvent: state.applyThisEvent,
                                    model: state.model,
                                    navigation: state.navigation,
                                    skipPushHistory: state.skipPushHistory,
                                    config: {
                                        size: event.value0
                                    }
                                };
                            };
                            return state;
                        });
                    })();
                };
            };
        };
    };
    var spec = {
        displayName: "EventListeners",
        initialState: initialState,
        getDerivedStateFromProps: function (v) {
            return function (v1) {
                return Data_Maybe.Nothing.value;
            };
        },
        render: render,
        componentDidMount: componentDidMount,
        componentDidUpdate: componentDidUpdate,
        componentWillUnmount: componentWillUnmount,
        shouldComponentUpdate: function (v) {
            return function (v1) {
                return function (v2) {
                    return function (v3) {
                        return true;
                    };
                };
            };
        }
    };
    return UI_Components.component(spec);
};
module.exports = {
    component: component,
    handlePopStateEvent: handlePopStateEvent,
    handleApplyThisEvent: handleApplyThisEvent,
    handleNavigationUpdate: handleNavigationUpdate,
    makePublishers: makePublishers,
    addKeyDownListener: $foreign.addKeyDownListener,
    removeKeyDownListener: $foreign.removeKeyDownListener,
    addSizeChangeListener: $foreign.addSizeChangeListener,
    addPopStateListener: $foreign.addPopStateListener,
    pushHistory: $foreign.pushHistory,
    removeSizeChangeListener: $foreign.removeSizeChangeListener,
    getSize: $foreign.getSize
};
