// Generated by purs version 0.12.0
"use strict";
var Control_Category = require("../Control.Category/index.js");
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Lens_Internal_Exchange = require("../Data.Lens.Internal.Exchange/index.js");
var Data_Lens_Internal_Re = require("../Data.Lens.Internal.Re/index.js");
var Data_Lens_Types = require("../Data.Lens.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Profunctor = require("../Data.Profunctor/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Prelude = require("../Prelude/index.js");
var withIso = function (l) {
    return function (f) {
        var v = l(new Data_Lens_Internal_Exchange.Exchange(Control_Category.identity(Control_Category.categoryFn), Control_Category.identity(Control_Category.categoryFn)));
        return f(v.value0)(v.value1);
    };
};
var under = function (l) {
    return withIso(l)(function (sa) {
        return function (bt) {
            return function (ts) {
                return function ($19) {
                    return sa(ts(bt($19)));
                };
            };
        };
    });
};
var re = function (t) {
    return Data_Newtype.unwrap(Data_Lens_Internal_Re.newtypeRe)(t(Control_Category.identity(Control_Category.categoryFn)));
};
var iso = function (f) {
    return function (g) {
        return function (dictProfunctor) {
            return function (pab) {
                return Data_Profunctor.dimap(dictProfunctor)(f)(g)(pab);
            };
        };
    };
};
var mapping = function (dictFunctor) {
    return function (dictFunctor1) {
        return function (l) {
            return function (dictProfunctor) {
                return withIso(l)(function (sa) {
                    return function (bt) {
                        return iso(Data_Functor.map(dictFunctor)(sa))(Data_Functor.map(dictFunctor1)(bt))(dictProfunctor);
                    };
                });
            };
        };
    };
};
var non = function (dictEq) {
    return function (def) {
        return function (dictProfunctor) {
            var g = function (a) {
                if (Data_Eq.eq(dictEq)(a)(def)) {
                    return Data_Maybe.Nothing.value;
                };
                if (Data_Boolean.otherwise) {
                    return new Data_Maybe.Just(a);
                };
                throw new Error("Failed pattern match at Data.Lens.Iso line 45, column 9 - line 46, column 33: " + [ a.constructor.name ]);
            };
            return iso(Data_Maybe.fromMaybe(def))(g)(dictProfunctor);
        };
    };
};
var uncurried = function (dictProfunctor) {
    return iso(Data_Tuple.uncurry)(Data_Tuple.curry)(dictProfunctor);
};
var flipped = function (dictProfunctor) {
    return iso(Data_Function.flip)(Data_Function.flip)(dictProfunctor);
};
var dimapping = function (dictProfunctor) {
    return function (dictProfunctor1) {
        return function (f) {
            return function (g) {
                return function (dictProfunctor2) {
                    return withIso(f)(function (sa) {
                        return function (bt) {
                            return withIso(g)(function (ssaa) {
                                return function (bbtt) {
                                    return iso(Data_Profunctor.dimap(dictProfunctor)(sa)(ssaa))(Data_Profunctor.dimap(dictProfunctor1)(bt)(bbtt))(dictProfunctor2);
                                };
                            });
                        };
                    });
                };
            };
        };
    };
};
var curried = function (dictProfunctor) {
    return iso(Data_Tuple.curry)(Data_Tuple.uncurry)(dictProfunctor);
};
var cloneIso = function (l) {
    return function (dictProfunctor) {
        return withIso(l)(function (x) {
            return function (y) {
                return function (p) {
                    return iso(x)(y)(dictProfunctor)(p);
                };
            };
        });
    };
};
var auf = function (dictProfunctor) {
    return function (l) {
        return withIso(l)(function (sa) {
            return function (bt) {
                return function (f) {
                    return function (g) {
                        return function (e) {
                            return bt(f(Data_Profunctor.rmap(dictProfunctor)(sa)(g))(e));
                        };
                    };
                };
            };
        });
    };
};
var au = function (l) {
    return withIso(l)(function (sa) {
        return function (bt) {
            return function (f) {
                return function (e) {
                    return sa(f(bt)(e));
                };
            };
        };
    });
};
module.exports = {
    iso: iso,
    withIso: withIso,
    cloneIso: cloneIso,
    re: re,
    au: au,
    auf: auf,
    under: under,
    non: non,
    curried: curried,
    uncurried: uncurried,
    flipped: flipped,
    mapping: mapping,
    dimapping: dimapping
};
