// Generated by purs version 0.12.0
"use strict";
var Data_Lens_Internal_Exchange = require("../Data.Lens.Internal.Exchange/index.js");
var Data_Lens_Internal_Forget = require("../Data.Lens.Internal.Forget/index.js");
var Data_Lens_Internal_Grating = require("../Data.Lens.Internal.Grating/index.js");
var Data_Lens_Internal_Indexed = require("../Data.Lens.Internal.Indexed/index.js");
var Data_Lens_Internal_Market = require("../Data.Lens.Internal.Market/index.js");
var Data_Lens_Internal_Re = require("../Data.Lens.Internal.Re/index.js");
var Data_Lens_Internal_Shop = require("../Data.Lens.Internal.Shop/index.js");
var Data_Lens_Internal_Tagged = require("../Data.Lens.Internal.Tagged/index.js");
var Data_Lens_Internal_Wander = require("../Data.Lens.Internal.Wander/index.js");
var Data_Profunctor = require("../Data.Profunctor/index.js");
var Data_Profunctor_Choice = require("../Data.Profunctor.Choice/index.js");
var Data_Profunctor_Closed = require("../Data.Profunctor.Closed/index.js");
var Data_Profunctor_Strong = require("../Data.Profunctor.Strong/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
module.exports = {};
