// Generated by purs version 0.12.0
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var Core_Data = require("../Core.Data/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Lens = require("../Data.Lens/index.js");
var Data_Lens_Getter = require("../Data.Lens.Getter/index.js");
var Data_Lens_Internal_Forget = require("../Data.Lens.Internal.Forget/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Prelude = require("../Prelude/index.js");
var Run = require("../Run/index.js");
var UI_App_Common = require("../UI.App.Common/index.js");
var UI_App_Workspace_DashboardList = require("../UI.App.Workspace.DashboardList/index.js");
var UI_App_Workspace_WorkspaceHeader = require("../UI.App.Workspace.WorkspaceHeader/index.js");
var UI_Components = require("../UI.Components/index.js");
var UI_Style = require("../UI.Style/index.js");
var UI_Style_Layout = require("../UI.Style.Layout/index.js");
var UI_Style_PropTypes_FlexDirection = require("../UI.Style.PropTypes.FlexDirection/index.js");
var title = Data_Lens_Getter.view(function ($9) {
    return Core_Data._Workspace(Data_Lens_Internal_Forget.strongForget)(Core_Data._title(Data_Lens_Internal_Forget.strongForget)($9));
});
var style = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_App_Common.panel)(function () {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_App_Common.outline)(function () {
        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.flexDirection(UI_Style_PropTypes_FlexDirection.Column.value))(function () {
            return UI_Style_Layout.maxWidth(new UI_Style.Pixel(200));
        });
    });
});
var render = function (v) {
    var workspaceId = Data_Lens_Getter.view(function ($10) {
        return Core_Data._Workspace(Data_Lens_Internal_Forget.strongForget)(Core_Data._id(Data_Lens_Internal_Forget.strongForget)($10));
    })(v.workspace);
    var dashboards = Data_Lens_Getter.view(function ($11) {
        return Core_Data._Workspace(Data_Lens_Internal_Forget.strongForget)(Core_Data._dashboards(Data_Lens_Internal_Forget.strongForget)($11));
    })(v.workspace);
    return Control_Bind.bind(Run.bindRun)(UI_App_Workspace_WorkspaceHeader.component(title(v.workspace)))(function (v1) {
        return Control_Bind.bind(Run.bindRun)(UI_App_Workspace_DashboardList.component({
            workspaceId: workspaceId,
            dashboards: dashboards,
            focusDashboard: v.focusDashboard,
            publish: v.publish
        }))(function (v2) {
            return UI_Components.nav({
                style: style
            })(UI_Components.fragment([ v1, v2 ]));
        });
    });
};
var component = function (props) {
    return UI_Components.stateless("WorkspaceNavProps")(props)(render);
};
module.exports = {
    component: component,
    render: render,
    title: title,
    style: style
};
