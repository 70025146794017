// Generated by purs version 0.12.0
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Functor_Variant = require("../Data.Functor.Variant/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Prelude = require("../Prelude/index.js");
var Run = require("../Run/index.js");
var Writer = (function () {
    function Writer(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Writer.create = function (value0) {
        return function (value1) {
            return new Writer(value0, value1);
        };
    };
    return Writer;
})();
var functorWriter = new Data_Functor.Functor(function (f) {
    return function (m) {
        return new Writer(m.value0, f(m.value1));
    };
});
var liftWriterAt = function (dictIsSymbol) {
    return function (dictCons) {
        return Run.lift(dictCons)(dictIsSymbol)(functorWriter);
    };
};
var tellAt = function (dictIsSymbol) {
    return function (dictCons) {
        return function (sym) {
            return function (w) {
                return liftWriterAt(dictIsSymbol)(dictCons)(sym)(new Writer(w, Data_Unit.unit));
            };
        };
    };
};
var foldWriterAt = function (dictIsSymbol) {
    return function (dictCons) {
        return function (sym) {
            var handle = Data_Functor_Variant.on(dictCons)(dictIsSymbol)(sym)(Data_Either.Left.create)(Data_Either.Right.create);
            var loop = function ($copy_k) {
                return function ($copy_w) {
                    return function ($copy_r) {
                        var $tco_var_k = $copy_k;
                        var $tco_var_w = $copy_w;
                        var $tco_done = false;
                        var $tco_result;
                        function $tco_loop(k, w, r) {
                            var v = Run.peel(r);
                            if (v instanceof Data_Either.Left) {
                                var v1 = handle(v.value0);
                                if (v1 instanceof Data_Either.Left) {
                                    $tco_var_k = k;
                                    $tco_var_w = k(w)(v1.value0.value0);
                                    $copy_r = v1.value0.value1;
                                    return;
                                };
                                if (v1 instanceof Data_Either.Right) {
                                    $tco_done = true;
                                    return Control_Bind.bind(Run.bindRun)(Run.send(v1.value0))(foldWriterAt(dictIsSymbol)(dictCons)(sym)(k)(w));
                                };
                                throw new Error("Failed pattern match at Run.Writer line 99, column 14 - line 103, column 45: " + [ v1.constructor.name ]);
                            };
                            if (v instanceof Data_Either.Right) {
                                $tco_done = true;
                                return Control_Applicative.pure(Run.applicativeRun)(new Data_Tuple.Tuple(w, v.value0));
                            };
                            throw new Error("Failed pattern match at Run.Writer line 98, column 16 - line 105, column 23: " + [ v.constructor.name ]);
                        };
                        while (!$tco_done) {
                            $tco_result = $tco_loop($tco_var_k, $tco_var_w, $copy_r);
                        };
                        return $tco_result;
                    };
                };
            };
            return loop;
        };
    };
};
var runWriterAt = function (dictIsSymbol) {
    return function (dictMonoid) {
        return function (dictCons) {
            return function (sym) {
                return foldWriterAt(dictIsSymbol)(dictCons)(sym)(Data_Semigroup.append(dictMonoid.Semigroup0()))(Data_Monoid.mempty(dictMonoid));
            };
        };
    };
};
var censorAt = function (dictIsSymbol) {
    return function (dictCons) {
        return function (sym) {
            var handle = Data_Functor_Variant.on(dictCons)(dictIsSymbol)(sym)(Data_Either.Left.create)(Data_Either.Right.create);
            var loop = function (f) {
                return function (r) {
                    var v = Run.peel(r);
                    if (v instanceof Data_Either.Left) {
                        var v1 = handle(v.value0);
                        if (v1 instanceof Data_Either.Left) {
                            return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(tellAt(dictIsSymbol)(dictCons)(sym)(f(v1.value0.value0)))(function () {
                                return loop(f)(v1.value0.value1);
                            });
                        };
                        if (v1 instanceof Data_Either.Right) {
                            return Control_Bind.bind(Run.bindRun)(Run.send(v.value0))(loop(f));
                        };
                        throw new Error("Failed pattern match at Run.Writer line 74, column 14 - line 79, column 30: " + [ v1.constructor.name ]);
                    };
                    if (v instanceof Data_Either.Right) {
                        return Control_Applicative.pure(Run.applicativeRun)(v.value0);
                    };
                    throw new Error("Failed pattern match at Run.Writer line 73, column 14 - line 81, column 13: " + [ v.constructor.name ]);
                };
            };
            return loop;
        };
    };
};
var _writer = Data_Symbol.SProxy.value;
var censor = censorAt(new Data_Symbol.IsSymbol(function () {
    return "writer";
}))()(_writer);
var foldWriter = foldWriterAt(new Data_Symbol.IsSymbol(function () {
    return "writer";
}))()(_writer);
var liftWriter = liftWriterAt(new Data_Symbol.IsSymbol(function () {
    return "writer";
}))()(_writer);
var runWriter = function (dictMonoid) {
    return runWriterAt(new Data_Symbol.IsSymbol(function () {
        return "writer";
    }))(dictMonoid)()(_writer);
};
var tell = tellAt(new Data_Symbol.IsSymbol(function () {
    return "writer";
}))()(_writer);
module.exports = {
    Writer: Writer,
    _writer: _writer,
    liftWriter: liftWriter,
    liftWriterAt: liftWriterAt,
    tell: tell,
    tellAt: tellAt,
    censor: censor,
    censorAt: censorAt,
    foldWriter: foldWriter,
    foldWriterAt: foldWriterAt,
    runWriter: runWriter,
    runWriterAt: runWriterAt,
    functorWriter: functorWriter
};
