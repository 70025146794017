// Generated by purs version 0.12.0
"use strict";
var Data_Function = require("../Data.Function/index.js");
var Data_Lens = require("../Data.Lens/index.js");
var Data_Lens_Lens = require("../Data.Lens.Lens/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Prelude = require("../Prelude/index.js");
var Record = require("../Record/index.js");
var prop = function (dictIsSymbol) {
    return function (dictCons) {
        return function (dictCons1) {
            return function (l) {
                return function (dictStrong) {
                    return Data_Lens_Lens.lens(Record.get(dictIsSymbol)(dictCons)(l))(Data_Function.flip(Record.set(dictIsSymbol)(dictCons)(dictCons1)(l)))(dictStrong);
                };
            };
        };
    };
};
module.exports = {
    prop: prop
};
