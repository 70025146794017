// Generated by purs version 0.12.0
"use strict";
var Control_Alt = require("../Control.Alt/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Comonad_Cofree = require("../Control.Comonad.Cofree/index.js");
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_List = require("../Data.List/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Tree = require("../Data.Tree/index.js");
var Prelude = require("../Prelude/index.js");
var Loc = function (x) {
    return x;
};
var siblings = function (v) {
    return Data_Semigroup.append(Data_List_Types.semigroupList)(Data_List.reverse(v.before))(new Data_List_Types.Cons(v.node, v.after));
};
var setNode = function (a) {
    return function (v) {
        return {
            node: a,
            before: v.before,
            after: v.after,
            parents: v.parents
        };
    };
};
var prev = function (v) {
    if (v.before instanceof Data_List_Types.Nil) {
        return Data_Maybe.Nothing.value;
    };
    if (v.before instanceof Data_List_Types.Cons) {
        return Data_Maybe.Just.create({
            node: v.before.value0,
            before: v.before.value1,
            after: new Data_List_Types.Cons(v.node, v.after),
            parents: v.parents
        });
    };
    throw new Error("Failed pattern match at Data.Tree.Zipper line 49, column 3 - line 55, column 27: " + [ v.before.constructor.name ]);
};
var parents = function (v) {
    return v.parents;
};
var node = function (v) {
    return v.node;
};
var setValue = function (a) {
    return function (l) {
        return setNode(Data_Tree.setNodeValue(a)(node(l)))(l);
    };
};
var value = function ($94) {
    return Control_Comonad_Cofree.head(node($94));
};
var next = function (v) {
    if (v.after instanceof Data_List_Types.Nil) {
        return Data_Maybe.Nothing.value;
    };
    if (v.after instanceof Data_List_Types.Cons) {
        return Data_Maybe.Just.create({
            node: v.after.value0,
            before: new Data_List_Types.Cons(v.node, v.before),
            after: v.after.value1,
            parents: v.parents
        });
    };
    throw new Error("Failed pattern match at Data.Tree.Zipper line 38, column 3 - line 44, column 27: " + [ v.after.constructor.name ]);
};
var modifyValue = function (f) {
    return function (l) {
        return setNode(Data_Tree.modifyNodeValue(f)(node(l)))(l);
    };
};
var modifyNode = function (f) {
    return function (v) {
        return {
            node: f(v.node),
            before: v.before,
            after: v.after,
            parents: v.parents
        };
    };
};
var last = function (v) {
    var v1 = Data_List.reverse(v.after);
    if (v1 instanceof Data_List_Types.Nil) {
        return v;
    };
    if (v1 instanceof Data_List_Types.Cons) {
        return Loc({
            node: v1.value0,
            before: Data_Semigroup.append(Data_List_Types.semigroupList)(v1.value1)(v.before),
            after: Data_List_Types.Nil.value,
            parents: v.parents
        });
    };
    throw new Error("Failed pattern match at Data.Tree.Zipper line 71, column 3 - line 77, column 20: " + [ v1.constructor.name ]);
};
var fromTree = function (n) {
    return {
        node: n,
        before: Data_List_Types.Nil.value,
        after: Data_List_Types.Nil.value,
        parents: Data_List_Types.Nil.value
    };
};
var first = function (v) {
    if (v.before instanceof Data_List_Types.Nil) {
        return v;
    };
    if (v.before instanceof Data_List_Types.Cons) {
        return Loc({
            node: v.before.value0,
            before: Data_List_Types.Nil.value,
            after: Data_Semigroup.append(Data_List_Types.semigroupList)(Data_List.reverse(v.before.value1))(v.after),
            parents: v.parents
        });
    };
    throw new Error("Failed pattern match at Data.Tree.Zipper line 60, column 3 - line 66, column 20: " + [ v.before.constructor.name ]);
};
var eqLoc = function (dictEq) {
    return new Data_Eq.Eq(function (v) {
        return function (v1) {
            return Data_Eq.eq(Control_Comonad_Cofree.eqCofree(Data_List_Types.eq1List)(dictEq))(v.node)(v1.node) && (Data_Eq.eq(Data_List_Types.eqList(Control_Comonad_Cofree.eqCofree(Data_List_Types.eq1List)(dictEq)))(v.before)(v1.before) && (Data_Eq.eq(Data_List_Types.eqList(Control_Comonad_Cofree.eqCofree(Data_List_Types.eq1List)(dictEq)))(v.after)(v1.after) && Data_Eq.eq(Data_List_Types.eqList(eqLoc(dictEq)))(v.parents)(v1.parents)));
        };
    });
};
var children = function ($95) {
    return Control_Comonad_Cofree.tail(node($95));
};
var firstChild = function (n) {
    var v = children(n);
    if (v instanceof Data_List_Types.Nil) {
        return Data_Maybe.Nothing.value;
    };
    if (v instanceof Data_List_Types.Cons) {
        return Data_Maybe.Just.create({
            node: v.value0,
            before: Data_List_Types.Nil.value,
            after: v.value1,
            parents: new Data_List_Types.Cons(n, parents(n))
        });
    };
    throw new Error("Failed pattern match at Data.Tree.Zipper line 100, column 3 - line 107, column 19: " + [ v.constructor.name ]);
};
var down = firstChild;
var findDownWhere = function (predicate) {
    return function (loc) {
        if (predicate(value(loc))) {
            return new Data_Maybe.Just(loc);
        };
        var lookNext = Control_Bind.bind(Data_Maybe.bindMaybe)(next(loc))(findDownWhere(predicate));
        var lookDown = Control_Bind.bind(Data_Maybe.bindMaybe)(down(loc))(findDownWhere(predicate));
        return Control_Alt.alt(Data_Maybe.altMaybe)(lookNext)(lookDown);
    };
};
var findDown = function (dictEq) {
    return function (a) {
        return findDownWhere(function (v) {
            return Data_Eq.eq(dictEq)(v)(a);
        });
    };
};
var flattenLocDepthFirst = function (loc) {
    var goDir = function (loc$prime) {
        return function (dirFn) {
            var v = dirFn(loc$prime);
            if (v instanceof Data_Maybe.Just) {
                return new Data_List_Types.Cons(v.value0, go(v.value0));
            };
            if (v instanceof Data_Maybe.Nothing) {
                return Data_List_Types.Nil.value;
            };
            throw new Error("Failed pattern match at Data.Tree.Zipper line 280, column 24 - line 282, column 21: " + [ v.constructor.name ]);
        };
    };
    var go = function (loc$prime) {
        var nexts = goDir(loc$prime)(next);
        var downs = goDir(loc$prime)(down);
        return Data_Semigroup.append(Data_List_Types.semigroupList)(downs)(nexts);
    };
    return new Data_List_Types.Cons(loc, go(loc));
};
var lastChild = function (v) {
    return Data_Functor.map(Data_Maybe.functorMaybe)(last)(down(v));
};
var before = function (v) {
    return v.before;
};
var after = function (v) {
    return v.after;
};
var $$delete = function (v) {
    if (v.after instanceof Data_List_Types.Cons) {
        return {
            node: v.after.value0,
            before: v.before,
            after: v.after.value1,
            parents: v.parents
        };
    };
    if (v.after instanceof Data_List_Types.Nil) {
        if (v.before instanceof Data_List_Types.Cons) {
            return {
                node: v.before.value0,
                before: v.before.value1,
                after: v.after,
                parents: v.parents
            };
        };
        if (v.before instanceof Data_List_Types.Nil) {
            if (v.parents instanceof Data_List_Types.Nil) {
                return v;
            };
            if (v.parents instanceof Data_List_Types.Cons) {
                return {
                    node: Data_Tree.mkTree(value(v.parents.value0))(Data_List_Types.Nil.value),
                    before: before(v.parents.value0),
                    after: after(v.parents.value0),
                    parents: parents(v.parents.value0)
                };
            };
            throw new Error("Failed pattern match at Data.Tree.Zipper line 223, column 11 - line 229, column 26: " + [ v.parents.constructor.name ]);
        };
        throw new Error("Failed pattern match at Data.Tree.Zipper line 215, column 7 - line 229, column 26: " + [ v.before.constructor.name ]);
    };
    throw new Error("Failed pattern match at Data.Tree.Zipper line 208, column 3 - line 229, column 26: " + [ v.after.constructor.name ]);
};
var insertAfter = function (n) {
    return function (l) {
        return {
            node: n,
            after: after(l),
            before: new Data_List_Types.Cons(node(l), before(l)),
            parents: parents(l)
        };
    };
};
var insertChild = function (n) {
    return function (l) {
        var v = down(l);
        if (v instanceof Data_Maybe.Just) {
            return insertAfter(n)(v.value0);
        };
        if (v instanceof Data_Maybe.Nothing) {
            return {
                node: n,
                after: Data_List_Types.Nil.value,
                before: Data_List_Types.Nil.value,
                parents: new Data_List_Types.Cons(l, parents(l))
            };
        };
        throw new Error("Failed pattern match at Data.Tree.Zipper line 197, column 3 - line 203, column 21: " + [ v.constructor.name ]);
    };
};
var insertBefore = function (n) {
    return function (l) {
        return {
            node: n,
            after: new Data_List_Types.Cons(node(l), after(l)),
            before: before(l),
            parents: parents(l)
        };
    };
};
var up = function (v) {
    if (v.parents instanceof Data_List_Types.Nil) {
        return Data_Maybe.Nothing.value;
    };
    if (v.parents instanceof Data_List_Types.Cons) {
        return Data_Maybe.Just.create({
            node: Control_Comonad_Cofree.mkCofree(value(v.parents.value0))(siblings(v)),
            before: before(v.parents.value0),
            after: after(v.parents.value0),
            parents: v.parents.value1
        });
    };
    throw new Error("Failed pattern match at Data.Tree.Zipper line 82, column 3 - line 88, column 27: " + [ v.parents.constructor.name ]);
};
var findUpWhere = function (predicate) {
    return function (loc) {
        if (predicate(value(loc))) {
            return new Data_Maybe.Just(loc);
        };
        var lookUp = Control_Bind.bind(Data_Maybe.bindMaybe)(up(loc))(findUpWhere(predicate));
        var lookPrev = Control_Bind.bind(Data_Maybe.bindMaybe)(prev(loc))(findUpWhere(predicate));
        return Control_Alt.alt(Data_Maybe.altMaybe)(lookPrev)(lookUp);
    };
};
var findUp = function (dictEq) {
    return function (a) {
        return findUpWhere(function (v) {
            return Data_Eq.eq(dictEq)(v)(a);
        });
    };
};
var root = function ($copy_l) {
    var $tco_done = false;
    var $tco_result;
    function $tco_loop(l) {
        var v = up(l);
        if (v instanceof Data_Maybe.Nothing) {
            $tco_done = true;
            return l;
        };
        if (v instanceof Data_Maybe.Just) {
            $copy_l = v.value0;
            return;
        };
        throw new Error("Failed pattern match at Data.Tree.Zipper line 93, column 3 - line 95, column 21: " + [ v.constructor.name ]);
    };
    while (!$tco_done) {
        $tco_result = $tco_loop($copy_l);
    };
    return $tco_result;
};
var findFromRootWhere = function (predicate) {
    return function (loc) {
        if (predicate(value(loc))) {
            return new Data_Maybe.Just(loc);
        };
        return findDownWhere(predicate)(root(loc));
    };
};
var findFromRoot = function (dictEq) {
    return function (a) {
        return findFromRootWhere(function (v) {
            return Data_Eq.eq(dictEq)(v)(a);
        });
    };
};
var toTree = function ($96) {
    return node(root($96));
};
var siblingAt = function (i) {
    return function (v) {
        var v1 = up(v);
        if (v1 instanceof Data_Maybe.Nothing) {
            return Data_Maybe.Nothing.value;
        };
        if (v1 instanceof Data_Maybe.Just) {
            var v2 = Data_List.index(children(v1.value0))(i);
            if (v2 instanceof Data_Maybe.Nothing) {
                return Data_Maybe.Nothing.value;
            };
            if (v2 instanceof Data_Maybe.Just) {
                var before$prime = Data_List.reverse(Data_List.take(i)(children(v1.value0)));
                var after$prime = Data_List.drop(i + 1 | 0)(children(v1.value0));
                return Data_Maybe.Just.create({
                    node: v2.value0,
                    before: before$prime,
                    after: after$prime,
                    parents: v.parents
                });
            };
            throw new Error("Failed pattern match at Data.Tree.Zipper line 123, column 7 - line 132, column 26: " + [ v2.constructor.name ]);
        };
        throw new Error("Failed pattern match at Data.Tree.Zipper line 120, column 3 - line 132, column 26: " + [ v1.constructor.name ]);
    };
};
var childAt = function (i) {
    return function (p) {
        return Control_Bind.bind(Data_Maybe.bindMaybe)(firstChild(p))(siblingAt(i));
    };
};
module.exports = {
    Loc: Loc,
    next: next,
    prev: prev,
    first: first,
    last: last,
    up: up,
    root: root,
    firstChild: firstChild,
    down: down,
    lastChild: lastChild,
    siblingAt: siblingAt,
    childAt: childAt,
    toTree: toTree,
    fromTree: fromTree,
    setNode: setNode,
    modifyNode: modifyNode,
    setValue: setValue,
    modifyValue: modifyValue,
    insertAfter: insertAfter,
    insertBefore: insertBefore,
    insertChild: insertChild,
    "delete": $$delete,
    findDownWhere: findDownWhere,
    findDown: findDown,
    findUpWhere: findUpWhere,
    findUp: findUp,
    findFromRootWhere: findFromRootWhere,
    findFromRoot: findFromRoot,
    flattenLocDepthFirst: flattenLocDepthFirst,
    node: node,
    value: value,
    before: before,
    after: after,
    parents: parents,
    children: children,
    siblings: siblings,
    eqLoc: eqLoc
};
