// Generated by purs version 0.12.0
"use strict";
var Data_Functor = require("../Data.Functor/index.js");
var Data_Functor_Variant = require("../Data.Functor.Variant/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Prelude = require("../Prelude/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var Type_Row = require("../Type.Row/index.js");
var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");
var Empty = (function () {
    function Empty() {

    };
    Empty.value = new Empty();
    return Empty;
})();
var Alt = (function () {
    function Alt(value0) {
        this.value0 = value0;
    };
    Alt.create = function (value0) {
        return new Alt(value0);
    };
    return Alt;
})();
var toRows = function (dictTypeEquals) {
    return Unsafe_Coerce.unsafeCoerce;
};
var functorChoose = new Data_Functor.Functor(function (f) {
    return function (m) {
        if (m instanceof Empty) {
            return Empty.value;
        };
        if (m instanceof Alt) {
            return new Alt(Data_Functor.map(Data_Functor.functorFn)(f)(m.value0));
        };
        throw new Error("Failed pattern match at Run.Internal line 20, column 8 - line 20, column 47: " + [ m.constructor.name ]);
    };
});
var fromRows = function (dictTypeEquals) {
    return Unsafe_Coerce.unsafeCoerce;
};
var _choose = Data_Symbol.SProxy.value;
module.exports = {
    Empty: Empty,
    Alt: Alt,
    _choose: _choose,
    toRows: toRows,
    fromRows: fromRows,
    functorChoose: functorChoose
};
