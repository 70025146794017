// Generated by purs version 0.12.0
"use strict";
var Data_Lens_Common = require("../Data.Lens.Common/index.js");
var Data_Lens_Fold = require("../Data.Lens.Fold/index.js");
var Data_Lens_Getter = require("../Data.Lens.Getter/index.js");
var Data_Lens_Grate = require("../Data.Lens.Grate/index.js");
var Data_Lens_Iso = require("../Data.Lens.Iso/index.js");
var Data_Lens_Lens = require("../Data.Lens.Lens/index.js");
var Data_Lens_Prism = require("../Data.Lens.Prism/index.js");
var Data_Lens_Setter = require("../Data.Lens.Setter/index.js");
var Data_Lens_Traversal = require("../Data.Lens.Traversal/index.js");
var Data_Lens_Types = require("../Data.Lens.Types/index.js");
module.exports = {};
