// Generated by purs version 0.12.0
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Data_Function = require("../Data.Function/index.js");
var Effect = require("../Effect/index.js");
var Prelude = require("../Prelude/index.js");
var Run = require("../Run/index.js");
var UI_App_Common = require("../UI.App.Common/index.js");
var UI_Components = require("../UI.Components/index.js");
var UI_Style = require("../UI.Style/index.js");
var UI_Style_Layout = require("../UI.Style.Layout/index.js");
var UI_Style_PropTypes_AlignSelf = require("../UI.Style.PropTypes.AlignSelf/index.js");
var UI_Style_PropTypes_Display = require("../UI.Style.PropTypes.Display/index.js");
var UI_Style_PropTypes_FlexDirection = require("../UI.Style.PropTypes.FlexDirection/index.js");
var UI_Style_PropTypes_JustifyContent = require("../UI.Style.PropTypes.JustifyContent/index.js");
var UI_Style_Text = require("../UI.Style.Text/index.js");
var topStyle = function (additionalStyle) {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.display(UI_Style_PropTypes_Display.Flex.value))(function () {
        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.flex(1.0))(function () {
            return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.flexGrow(1.0))(function () {
                return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.flexDirection(UI_Style_PropTypes_FlexDirection.Row.value))(function () {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.height(new UI_Style.Pixel(18)))(function () {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.justifyContent(UI_Style_PropTypes_JustifyContent.SpaceBetween.value))(function () {
                            return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.maxHeight(new UI_Style.Pixel(18)))(function () {
                                return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.minHeight(new UI_Style.Pixel(18)))(function () {
                                    return additionalStyle;
                                });
                            });
                        });
                    });
                });
            });
        });
    });
};
var headerStyle = function (additionalHeaderStyle) {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_App_Common.standardText)(function () {
        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.alignSelf(UI_Style_PropTypes_AlignSelf.Center.value))(function () {
            return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Text.color(UI_App_Common.gray4))(function () {
                return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Text.fontSize(12.0))(function () {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.margin(new UI_Style.Pixel(0)))(function () {
                        return additionalHeaderStyle;
                    });
                });
            });
        });
    });
};
var addButtonStyle = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.alignSelf(UI_Style_PropTypes_AlignSelf.Center.value))(function () {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.height(new UI_Style.Pixel(18)))(function () {
        return UI_Style_Layout.width(new UI_Style.Pixel(18));
    });
});
var render = function (v) {
    return Control_Bind.bind(Run.bindRun)(Control_Bind.bind(Run.bindRun)(Control_Bind.bindFlipped(Run.bindRun)(UI_Components.h2({
        style: headerStyle(v.additionalHeaderStyle)
    }))(UI_Components.text(v.label)))(UI_Components.makeTouchable(v.onPress)))(function (v1) {
        return Control_Bind.bind(Run.bindRun)(Control_Bind.bind(Run.bindRun)(UI_Components.image({
            src: "/assets/images/add-button.png",
            style: addButtonStyle
        }))(UI_Components.makeTouchable(v.onPress)))(function (v2) {
            return UI_Components.div({
                style: topStyle(v.additionalStyle)
            })(UI_Components.fragment([ v1, v2 ]));
        });
    });
};
var component = function (props) {
    return UI_Components.stateless("MenuButton")(props)(render);
};
module.exports = {
    component: component,
    render: render,
    addButtonStyle: addButtonStyle,
    headerStyle: headerStyle,
    topStyle: topStyle
};
