"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hushSpy = hushSpy;
function hushSpy(arg) {
  console.log(arg);
  return arg;
}

;