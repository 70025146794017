// Generated by purs version 0.12.0
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var Core_Data = require("../Core.Data/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Lens = require("../Data.Lens/index.js");
var Data_Lens_Getter = require("../Data.Lens.Getter/index.js");
var Data_Lens_Internal_Forget = require("../Data.Lens.Internal.Forget/index.js");
var Data_List = require("../Data.List/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Prelude = require("../Prelude/index.js");
var Run = require("../Run/index.js");
var UI_App_Common = require("../UI.App.Common/index.js");
var UI_App_Common_Lightbox = require("../UI.App.Common.Lightbox/index.js");
var UI_App_Search = require("../UI.App.Search/index.js");
var UI_App_Tiles_TileProperties = require("../UI.App.Tiles.TileProperties/index.js");
var UI_App_Workspaces = require("../UI.App.Workspaces/index.js");
var UI_Components = require("../UI.Components/index.js");
var UI_Data_Navigation = require("../UI.Data.Navigation/index.js");
var UI_Style_Layout = require("../UI.Style.Layout/index.js");
var UI_Style_PropTypes_FlexDirection = require("../UI.Style.PropTypes.FlexDirection/index.js");
var style = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_App_Common.panel)(function () {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_App_Common.outlineSpec(0)(2)(2)(2))(function () {
        return UI_Style_Layout.flexDirection(UI_Style_PropTypes_FlexDirection.Column.value);
    });
});
var renderApp = function (model) {
    return function (appNav) {
        return function (publish) {
            return function (config) {
                var workspaces = Data_Lens_Getter.view(function ($14) {
                    return Core_Data._Model(Data_Lens_Internal_Forget.strongForget)(Core_Data._workspaces(Data_Lens_Internal_Forget.strongForget)($14));
                })(model);
                return Control_Bind.bind(Run.bindRun)(UI_App_Workspaces.component({
                    appNav: appNav,
                    size: config.size,
                    workspaces: workspaces,
                    publish: publish
                }))(function (v) {
                    return Control_Bind.bind(Run.bindRun)(UI_App_Search.component)(function (v1) {
                        var child = Data_Maybe.Just.create(function (m) {
                            return UI_App_Tiles_TileProperties.component({
                                tile: Core_Data.firstTile(m),
                                dashboardId: Core_Data.firstDashboardId(m),
                                publish: publish,
                                childCount: 0,
                                siblings: Data_List_Types.Nil.value
                            });
                        });
                        return Control_Bind.bind(Run.bindRun)(UI_App_Common_Lightbox.component({
                            publish: publish,
                            lightBox: appNav.lightBox,
                            appData: {
                                model: model,
                                navigation: new UI_Data_Navigation.AppNav(appNav)
                            }
                        }))(function (v2) {
                            return UI_Components.section({
                                style: style
                            })(UI_Components.fragment([ v, v1, v2 ]));
                        });
                    });
                });
            };
        };
    };
};
var render = function (v) {
    return renderApp(v.model)(v.navigation.value0)(v.publish)(v.config);
};
var app = function (props) {
    return UI_Components.stateless("App")(props)(render);
};
module.exports = {
    app: app,
    render: render,
    renderApp: renderApp,
    style: style
};
