// Generated by purs version 0.12.0
"use strict";
var Data_Show = require("../Data.Show/index.js");
var Prelude = require("../Prelude/index.js");
var Wrap = (function () {
    function Wrap() {

    };
    Wrap.value = new Wrap();
    return Wrap;
})();
var NoWrap = (function () {
    function NoWrap() {

    };
    NoWrap.value = new NoWrap();
    return NoWrap;
})();
var showFlexWrap = new Data_Show.Show(function (v) {
    if (v instanceof Wrap) {
        return "wrap";
    };
    if (v instanceof NoWrap) {
        return "NoWrap";
    };
    throw new Error("Failed pattern match at UI.Style.PropTypes.FlexWrap line 7, column 1 - line 7, column 39: " + [ v.constructor.name ]);
});
module.exports = {
    Wrap: Wrap,
    NoWrap: NoWrap,
    showFlexWrap: showFlexWrap
};
