// Generated by purs version 0.12.0
"use strict";
var Data_Lens_Lens = require("../Data.Lens.Lens/index.js");
var Data_Profunctor_Strong = require("../Data.Profunctor.Strong/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var _2 = function (dictStrong) {
    return Data_Profunctor_Strong.second(dictStrong);
};
var _1 = function (dictStrong) {
    return Data_Profunctor_Strong.first(dictStrong);
};
module.exports = {
    _1: _1,
    _2: _2
};
