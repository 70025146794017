// Generated by purs version 0.12.0
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Core_Data = require("../Core.Data/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Prelude = require("../Prelude/index.js");
var Run = require("../Run/index.js");
var UI_App_Common = require("../UI.App.Common/index.js");
var UI_Components = require("../UI.Components/index.js");
var UI_Event_NavigationEvent = require("../UI.Event.NavigationEvent/index.js");
var UI_Style = require("../UI.Style/index.js");
var UI_Style_Layout = require("../UI.Style.Layout/index.js");
var UI_Style_PropTypes_Display = require("../UI.Style.PropTypes.Display/index.js");
var UI_Style_PropTypes_JustifyContent = require("../UI.Style.PropTypes.JustifyContent/index.js");
var Gold = (function () {
    function Gold() {

    };
    Gold.value = new Gold();
    return Gold;
})();
var Green = (function () {
    function Green() {

    };
    Green.value = new Green();
    return Green;
})();
var Magenta = (function () {
    function Magenta() {

    };
    Magenta.value = new Magenta();
    return Magenta;
})();
var Orange = (function () {
    function Orange() {

    };
    Orange.value = new Orange();
    return Orange;
})();
var Red = (function () {
    function Red() {

    };
    Red.value = new Red();
    return Red;
})();
var Violet = (function () {
    function Violet() {

    };
    Violet.value = new Violet();
    return Violet;
})();
var style = function (isFocus) {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.display(UI_Style_PropTypes_Display.Flex.value))(function () {
        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.justifyContent(UI_Style_PropTypes_JustifyContent.Center.value))(function () {
            return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.height(new UI_Style.Pixel(54)))(function () {
                return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.minHeight(new UI_Style.Pixel(54)))(function () {
                    return UI_Style_Layout.marginBottom(new UI_Style.Pixel(15));
                });
            });
        });
    });
};
var showPolygonColor = new Data_Show.Show(function (v) {
    if (v instanceof Gold) {
        return "gold";
    };
    if (v instanceof Green) {
        return "green";
    };
    if (v instanceof Magenta) {
        return "magenta";
    };
    if (v instanceof Orange) {
        return "orange";
    };
    if (v instanceof Red) {
        return "red";
    };
    if (v instanceof Violet) {
        return "violet";
    };
    throw new Error("Failed pattern match at UI.App.Workspaces.WorkspaceIconButton line 18, column 1 - line 18, column 47: " + [ v.constructor.name ]);
});
var onPress = function (workspaceId) {
    return function (publish) {
        return publish.navigation(new UI_Event_NavigationEvent.FocusWorkspace(workspaceId));
    };
};
var getAssetLink = function (polygonColor) {
    return "/assets/images/polygon-" + (Data_Show.show(showPolygonColor)(polygonColor) + ".png");
};
var render = function (v) {
    return Control_Bind.bind(Run.bindRun)(Control_Bind.bind(Run.bindRun)(UI_Components.image({
        style: Control_Applicative.pure(Run.applicativeRun)(Data_Unit.unit),
        src: getAssetLink(v.polygonColor)
    }))(UI_Components.makeTouchable(onPress(v.workspaceId)(v.publish))))(function (v1) {
        return UI_Components.div({
            style: style(Data_Eq.eq(Core_Data.workspaceIdEq)(v.workspaceId)(v.focusWorkspace))
        })(v1);
    });
};
var component = function (props) {
    return UI_Components.stateless("WorkspaceIconButton")(props)(render);
};
module.exports = {
    Gold: Gold,
    Green: Green,
    Magenta: Magenta,
    Orange: Orange,
    Red: Red,
    Violet: Violet,
    component: component,
    render: render,
    onPress: onPress,
    style: style,
    getAssetLink: getAssetLink,
    showPolygonColor: showPolygonColor
};
