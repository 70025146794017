// Generated by purs version 0.12.0
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var Core_Data = require("../Core.Data/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_EuclideanRing = require("../Data.EuclideanRing/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Lens = require("../Data.Lens/index.js");
var Data_Lens_Getter = require("../Data.Lens.Getter/index.js");
var Data_Lens_Internal_Forget = require("../Data.Lens.Internal.Forget/index.js");
var Data_NonEmpty = require("../Data.NonEmpty/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Prelude = require("../Prelude/index.js");
var Run = require("../Run/index.js");
var UI_App_Common = require("../UI.App.Common/index.js");
var UI_App_Workspaces_WorkspaceIconButton = require("../UI.App.Workspaces.WorkspaceIconButton/index.js");
var UI_Components = require("../UI.Components/index.js");
var UI_Style_Layout = require("../UI.Style.Layout/index.js");
var UI_Style_PropTypes_Display = require("../UI.Style.PropTypes.Display/index.js");
var UI_Style_PropTypes_FlexDirection = require("../UI.Style.PropTypes.FlexDirection/index.js");
var UI_Style_PropTypes_Overflow = require("../UI.Style.PropTypes.Overflow/index.js");
var style = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.display(UI_Style_PropTypes_Display.Flex.value))(function () {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.flex(2.0))(function () {
        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.flexDirection(UI_Style_PropTypes_FlexDirection.Column.value))(function () {
            return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.overflowY(UI_Style_PropTypes_Overflow.Scroll.value))(function () {
                return UI_App_Common.hideLeftScroll;
            });
        });
    });
});
var idxToPolygonColor = function (i) {
    if (Data_EuclideanRing.mod(Data_EuclideanRing.euclideanRingInt)(i)(6) === 0) {
        return UI_App_Workspaces_WorkspaceIconButton.Gold.value;
    };
    if (Data_EuclideanRing.mod(Data_EuclideanRing.euclideanRingInt)(i)(6) === 1) {
        return UI_App_Workspaces_WorkspaceIconButton.Orange.value;
    };
    if (Data_EuclideanRing.mod(Data_EuclideanRing.euclideanRingInt)(i)(6) === 2) {
        return UI_App_Workspaces_WorkspaceIconButton.Red.value;
    };
    if (Data_EuclideanRing.mod(Data_EuclideanRing.euclideanRingInt)(i)(6) === 3) {
        return UI_App_Workspaces_WorkspaceIconButton.Magenta.value;
    };
    if (Data_EuclideanRing.mod(Data_EuclideanRing.euclideanRingInt)(i)(6) === 4) {
        return UI_App_Workspaces_WorkspaceIconButton.Violet.value;
    };
    if (Data_EuclideanRing.mod(Data_EuclideanRing.euclideanRingInt)(i)(6) === 5) {
        return UI_App_Workspaces_WorkspaceIconButton.Green.value;
    };
    return UI_App_Workspaces_WorkspaceIconButton.Gold.value;
};
var render = function (v) {
    var workspaceIdStr = function (workspace) {
        return Data_Show.show(Data_Show.showInt)(Data_Lens_Getter.view(function ($8) {
            return Core_Data._Workspace(Data_Lens_Internal_Forget.strongForget)(Core_Data._id(Data_Lens_Internal_Forget.strongForget)(Core_Data._WorkspaceId(Data_Lens_Internal_Forget.strongForget)($8)));
        })(workspace));
    };
    var workspaceId = function (workspace) {
        return Data_Lens_Getter.view(function ($9) {
            return Core_Data._Workspace(Data_Lens_Internal_Forget.strongForget)(Core_Data._id(Data_Lens_Internal_Forget.strongForget)($9));
        })(workspace);
    };
    var createButton = function (idx) {
        return function (workspace) {
            return UI_App_Workspaces_WorkspaceIconButton.component({
                focusWorkspace: v.focusWorkspace,
                workspaceId: workspaceId(workspace),
                key: workspaceIdStr(workspace),
                publish: v.publish,
                polygonColor: idxToPolygonColor(idx)
            });
        };
    };
    return Control_Bind.bind(Run.bindRun)(Data_Traversable.sequence(Data_Traversable.traversableArray)(Run.applicativeRun)(Data_Array.mapWithIndex(createButton)(Core_Data.nonEmptyToArray(v.workspaces))))(function (v1) {
        return UI_Components.nav({
            style: style
        })(UI_Components.fragment(v1));
    });
};
var component = function (props) {
    return UI_Components.stateless("WorkspaceIconList")(props)(render);
};
module.exports = {
    component: component,
    render: render,
    style: style,
    idxToPolygonColor: idxToPolygonColor
};
