// Generated by purs version 0.12.0
"use strict";
var Data_Show = require("../Data.Show/index.js");
var Prelude = require("../Prelude/index.js");
var Ellipsis = (function () {
    function Ellipsis() {

    };
    Ellipsis.value = new Ellipsis();
    return Ellipsis;
})();
var showTextOverflow = new Data_Show.Show(function (v) {
    return "ellipsis";
});
module.exports = {
    Ellipsis: Ellipsis,
    showTextOverflow: showTextOverflow
};
