// Generated by purs version 0.12.0
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Effect = require("../Effect/index.js");
var Prelude = require("../Prelude/index.js");
var Run = require("../Run/index.js");
var UI_App_Common = require("../UI.App.Common/index.js");
var UI_Components = require("../UI.Components/index.js");
var UI_Event_ModelEvent = require("../UI.Event.ModelEvent/index.js");
var UI_Style = require("../UI.Style/index.js");
var UI_Style_Layout = require("../UI.Style.Layout/index.js");
var UI_Style_PropTypes_AlignSelf = require("../UI.Style.PropTypes.AlignSelf/index.js");
var UI_Style_PropTypes_Display = require("../UI.Style.PropTypes.Display/index.js");
var UI_Style_PropTypes_FlexDirection = require("../UI.Style.PropTypes.FlexDirection/index.js");
var style = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.alignSelf(UI_Style_PropTypes_AlignSelf.Center.value))(function () {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.height(new UI_Style.Pixel(46)))(function () {
        return UI_Style_Layout.width(new UI_Style.Pixel(46));
    });
});
var onPress = function (publish) {
    return publish.model(new UI_Event_ModelEvent.AddWorkspace(Data_Maybe.Nothing.value));
};
var divStyle = Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_App_Common.outlineSpec(2)(0)(0)(0))(function () {
    return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.display(UI_Style_PropTypes_Display.Flex.value))(function () {
        return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.flexDirection(UI_Style_PropTypes_FlexDirection.Column.value))(function () {
            return Control_Bind.discard(Control_Bind.discardUnit)(Run.bindRun)(UI_Style_Layout.marginTop(new UI_Style.Pixel(5)))(function () {
                return UI_Style_Layout.padding(new UI_Style.Pixel(5));
            });
        });
    });
});
var render = function (v) {
    return Control_Bind.bind(Run.bindRun)(Control_Bind.bind(Run.bindRun)(UI_Components.image({
        src: "/assets/images/add-button.png",
        style: style
    }))(UI_Components.makeTouchable(onPress(v.publish))))(function (v1) {
        return UI_Components.div({
            style: divStyle
        })(v1);
    });
};
var component = function (props) {
    return UI_Components.stateless("AddWorkspaceButton")(props)(render);
};
module.exports = {
    component: component,
    render: render,
    onPress: onPress,
    style: style,
    divStyle: divStyle
};
